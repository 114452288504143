/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Menu,
  Toolbar,
  FileList,
  Context,
  useModel,
  History,
  useStore,
} from '.'
import { observer } from 'mobx-react-lite'
import { Resizable } from 're-resizable'
import { useResize } from '@/domain'
import { Icon } from '@ys/components'
import { useLayoutRect } from '@ys/utils/hooks'

const StyledLayout = styled.div`
  height: 100%;

  > .header {
    padding: 20px;
  }

  > .body {
    display: flex;
    width: 100%;
    border-top: 4px solid ${({ theme }) => theme.backgroundColorBase};

    > .menu {
      .resizeBar {
        z-index: 5;
      }
      height: 100%;
    }

    > .mockbar {
      position: relative;
      width: 1px;
      background-color: ${({ theme }) => theme.borderColorBase};
      z-index: 1;

      > .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 26px;
        color: #c9c9c9;
        background-color: #eee;
        border-radius: 5px;
        > .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    > .files {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .toolbar {
        padding: 20px;
      }

      > .list {
        flex: 1;
      }
    }
  }
`

type Props = {
  model?: ReturnType<typeof useModel>
}

// TODO: 添加区域选择
export const BaseSuite = observer(function Suite(props: any) {
  const { dirTree, getWidget, initDirTree } = useStore()
  const [width, setWidth] = useState(250)
  // const [areaValue, setAreaValue] = useState('1')
  const [headerRect, headerRef, headerResize] = useLayoutRect()
  const [rect, ref, resize] = useResize()

  // hack: harmony header size
  useEffect(() => {
    headerResize()
    setTimeout(resize, 100)
  }, [])

  useEffect(() => {
    initDirTree()
  }, [])


  return (
    <StyledLayout>
      {getWidget('history') || (
        <div className='header' ref={headerRef}>
          <History />
        </div>
      )}
      <div
        className='body'
        ref={ref}
        style={{ height: `calc(100% - ${headerRect.height}px)` }}>
        <div className='menu'>
          <Resizable
            handleClasses={{ right: 'resizeBar' }}
            minWidth={120}
            enable={{ right: true }}
            size={{ width, height: '100%' }}
            onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width)
              resize()
            }}>
            <Menu />
          </Resizable>
        </div>
        <div className='mockbar'>
          <div className='wrapper'>
            <Icon className='icon' type='drag' />
          </div>
        </div>
        <div className='files'>
          <div className='toolbar'>
            <Toolbar />
          </div>
          <div className='list'>
            {dirTree.children.length > 0 && (
              <FileList
                width={rect.width - width - 43}
                height={rect.height - 127}
              />
            )}
          </div>
        </div>
      </div>
    </StyledLayout>
  )
})

export function Suite({ model, ...props }: Props) {
  const defaultModel = useModel()
  const finalModel = model || defaultModel

  return (
    <Context.Provider value={finalModel}>
      <BaseSuite {...props} />
    </Context.Provider>
  )
}
