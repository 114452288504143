/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect, useMemo } from 'react'
import moment, { Moment } from 'moment'
import { ceilNumber } from '@/utils'
import { maxBy, union } from 'lodash'
import { find } from 'ramda'
import { BaseStats } from './BaseStats'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'
import { APP_COREHOUR_STATS } from '@/server/dashboardServer'
import { env } from '@/domain'
import { Slider } from 'bizcharts'
import { useTranslation } from 'react-i18next'

type Data = {
  date: string
  type: string
  value: number
}

type Props = {
  timeStep: number
  timeRange: [Moment, Moment]
}

export const AppCoreHourStats = observer(function AppCoreHourStats({
  timeStep,
  timeRange
}: Props) {
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    maxValue: 10,
    data: [],
    update(
      props: Partial<{
        maxValue: number
        data: {
          type: string
          value: any
          date: string
        }[]
      }>
    ) {
      Object.assign(this, props)
    }
  }))
  const timeGap = useMemo(() => timeRange[1].diff(timeRange[0], 'days'), [
    timeRange[1],
    timeRange[0]
  ])

  const queryResult = useQuery(APP_COREHOUR_STATS, {
    variables: {
      payload: {
        project_id: env.project?.id,
        start_time: timeRange[0]?.unix(),
        end_time: timeRange[1]?.unix(),
        time_step: timeStep
      }
    }
  })

  const data = queryResult.data?.appCoreHourStats
  useEffect(() => {
    if (!data) {
      return
    }
    // 1. 将 all 后缀去掉
    let result = data.map(item => ({
      ...item,
      type: item.type.slice(0, item.type.lastIndexOf(' all'))
    }))
    // 2. 每天所有软件的总消耗数据
    const total = result.reduce((result: Data[], currentValue) => {
      const target: Data = find(
        (item: any) => item?.date === currentValue.date
      )(result)
      if (target) {
        target.value += Number(currentValue.value)
      } else {
        const target: Data = {
          date: currentValue.date,
          type: 'total',
          value: Number(currentValue.value)
        }
        result.push(target)
      }
      return result
    }, [])
    // 3. 将每天的消耗量都为0的软件的数据去掉
    const softwareNames: Set<string> = result.reduce(
      (softwareNames: Set<string>, currentValue) => {
        if (Number(currentValue.value) !== 0)
          softwareNames.add(currentValue.type)
        return softwareNames
      },
      new Set()
    )
    result = result.filter(item => softwareNames.has(item.type))
    result = union(result, total)
    state.update({
      data: result
    })
    if (result.length !== 0) {
      state.update({
        maxValue: ceilNumber(maxBy(result, 'value')?.['value'])
      })
    }
  }, [data])

  return (
    <BaseStats
      title={t('SoftwareConsumption') + `(${t('unit')}：${t('WhenTheNuclear')}`}
      {...queryResult}
      data={state.data}
      maxValue={state.maxValue}
      scale={{
        type: {
          formatter: text => ({ total: t('ATotalOf') }[text] || text)
        },
        date: {
          formatter: date =>
            moment
              .unix(date)
              .format(
                timeStep === 1
                  ? timeGap < 4
                    ? 'MM/DD HH:00'
                    : 'MM/DD'
                  : 'YY/MM'
              )
        }
      }}>
      <Slider
        formatter={date =>
          moment.unix(date).format(timeStep === 1 ? 'YYYY/MM/DD' : 'YYYY/MM')
        }
      />
    </BaseStats>
  )
})
