/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { FileAction } from './FileAction'
import { FileActionsStyle } from './style'
import { useStore } from '../store'
import { observer } from 'mobx-react-lite'
import { downloadTestFile, buryPoint } from '@/utils'
import { env } from '@/domain'
import { useStore as useJobStore } from '../../../store'

export const FileActions = observer(() => {
  const store = useStore()
  const jobStore = useJobStore()

  const fileActions = React.useMemo(() => {
    const temp = [
      {
        title: '创建文件夹',
        icon: 'new_folder',
        onClick: () => {
          buryPoint({
            category: '作业提交',
            action: '新建文件夹'
          })
          store.createFolder(jobStore.fileTree.id)
        }
      },
      {
        title: '上传文件夹',
        icon: 'folder_upload',
        onClick: () => {
          buryPoint({
            category: '作业提交',
            action: '上传文件夹'
          })
          store.upload(jobStore.fileTree.id, true)
        }
      },
      {
        title: '上传文件',
        icon: 'file_upload',
        onClick: () => {
          buryPoint({
            category: '作业提交',
            action: '上传文件'
          })
          store.upload(jobStore.fileTree.id)
        }
      },
      {
        title: '我的文件',
        icon: 'folder_mine',
        onClick: () => {
          buryPoint({
            category: '作业提交',
            action: '我的文件'
          })
          store.copyFilesFromFileManager(
            jobStore.fileTree.id,
            jobStore.fileTree.path
          )
        }
      }
    ]

    return env.isPersonal
      ? [
          ...temp,
          {
            title: '下载示例文件',
            icon: null,
            onClick: downloadTestFile
          }
        ]
      : temp
  }, [jobStore.fileTree])

  return (
    <FileActionsStyle className='fileUploaderToolbar'>
      {fileActions.map((item, index) => (
        <FileAction icon={item.icon} onClick={item.onClick} key={index}>
          {item.title}
        </FileAction>
      ))}
    </FileActionsStyle>
  )
})
