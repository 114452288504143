/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { BottomActionStyle } from './style'
import { Button, Modal } from '@ys/components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Completeness } from './Completeness'
import { account, env } from '@/domain'
import { useStore } from '../store'
import { showSummary } from './Summary'
import { buryPoint, getFilenameByPath, history } from '@/utils'
import { message } from 'antd'

type Props = {
  onOk?: () => void
  pushHistoryUrl?: string
}

export const BottomAction = observer(({ onOk, pushHistoryUrl }: Props) => {
  const store = useStore()

  const state = useLocalStore(() => ({
    get prerequisites() {
      return [
        {
          label: '应用选择',
          isCompleted: !!store.data.currentApp?.id
        },
        {
          label: '上传模型',
          isCompleted:
            store.fileTree.flatten().filter(node => node.status === 'done')
              .length > 0 &&
            store.fileTree.filterNodes(
              file => file.isFile && file.status !== 'done'
            ).length === 0
        },
        {
          label: '设置主文件',
          isCompleted: store.mainFiles?.length > 0
        },
        {
          label: '参数配置',
          isCompleted: store.data.scIds.length > 0 && store.displayCoreValid
        }
      ]
    },
    async onSubmit() {
      buryPoint({
        category: '作业提交',
        action: '提交'
      })
      const map = new Map()

      const jobs = await showSummary({
        store,
        jobs: store.mainFiles.map(file => {
          let jobName = getFilenameByPath(file.path)

          if (map.has(jobName)) {
            let num = map.get(jobName)
            map.set(jobName, num + 1)
            jobName = `${jobName}_${num}`
          } else {
            map.set(jobName, 1)
          }

          return {
            mainFile: file.path,
            jobName,
            content: ''
          }
        })
      })
      const isSuccess = await store.create(jobs)
      if (isSuccess) {
        store.removeHistoryBlock()
        message.success('作业创建成功')
        onOk && onOk()
        pushHistoryUrl && history.push(pushHistoryUrl)
      }
    },
    async onCancel() {
      const content = {
        default: '重置将会清空工作区的文件和已填写的参数，是否确认？',
        redeploy: '重置将会恢复重提交作业时的参数，是否确认？',
        continuous: '重置将会恢复续算提交作业时的参数，是否确认？'
      }[store.jobBuildMode]

      await Modal.showConfirm({
        title: '重置',
        content
      })

      buryPoint({
        category: '作业提交',
        action: '重置'
      })

      await store.clean(true)
      message.success('已重置')
    },
    get createBtnDisabled() {
      const { data, isJobSubmitting, fileTree } = store
      const { currentApp, paramsModel } = data

      if (!env.canSubmitJob) {
        return '无作业提交权限'
      }

      // if (env.boxExhaust) {
      //   return '存储空间已耗尽'
      // }

      if (store.is_trial === true) {
        if (account.free_trail_balance <= 0) {
          return '试用核时不足，请申请后使用'
        }

        if (env.accountIsFreeze) {
          return '您的账户已经冻结，无法提交作业，继续使用请联系客服！'
        }
        if (account.account_balance < 0) {
          return '账户余额不足，请充值后使用'
        }
      } else if (store.is_trial === false) {
        if (env.accountIsFreeze) {
          return '您的账户已经冻结，无法提交作业，继续使用请联系客服！'
        }
        if (env.accountFrozen) {
          return '账户余额不足，请充值后使用'
        }
      }

      const successFiles = store.fileTree
        .flatten()
        .filter(node => node.status === 'done')

      if (successFiles.length === 0) {
        return '请上传模型至作业目录'
      }

      if (!store.mainFilePaths.length) {
        return '请至少选择一个主文件'
      }

      if (
        store.fileTree.filterNodes(
          file => file.isFile && file.status !== 'done'
        ).length > 0
      ) {
        return '请等待全部文件上传完成'
      }

      if (store.data.scIds.length === 0) {
        return '请勾选算力资源'
      }

      if (!store.displayCoreValid) {
        return '请填写正确的核数'
      }

      if (!currentApp) {
        return '请选择应用'
      }

      for (const key of Object.keys(paramsModel)) {
        const param = paramsModel[key]
        if (
          param &&
          param.required &&
          !param.value &&
          param.values.length === 0
        ) {
          return `请填写${param.label}`
        }
      }

      const uploadingFile = fileTree
        .flatten()
        .find(node => node.isFile && node.status === 'uploading')
      if (uploadingFile) {
        return `请等待${uploadingFile.name}上传完成`
      }

      return isJobSubmitting
    }
  }))

  return (
    <BottomActionStyle className='bottomToolbar'>
      <div className='formCompleteness'>
        <span className='label'>作业完整情况：</span>
        {state.prerequisites.map((prerequisite, index) => (
          <span className='completeness' key={index}>
            <Completeness isCompleted={prerequisite.isCompleted} />
            {prerequisite.label}
          </span>
        ))}
      </div>

      <div className='actions'>
        <Button onClick={state.onCancel}>重置</Button>
        <Button
          type='primary'
          className='submitJob'
          onClick={state.onSubmit}
          disabled={state.createBtnDisabled}
          loading={store.isJobSubmitting}>
          提交
        </Button>
      </div>
    </BottomActionStyle>
  )
})
