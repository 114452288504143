/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { Icon } from '@ys/components'
import { permList, env, visualConfig, visIBVConfig } from '@/domain'
import { RouterType } from '@/components/PageLayout/typing'
import {
  DesktopOutlined,
  KeyOutlined,
  CommentOutlined,
  AppstoreOutlined
} from '@ant-design/icons'

const iconStyle = {
  position: 'absolute',
  right: '-35px',
  top: '-2px'
}

const cloudBoxes = [
  's3-box.intern.yuansuan.cn',
  'cloudbox-s3.yuansuan.cn',
  'cloudbox-s3-test.yuansuan.cn'
]

const isCloudBox = (url: string): boolean => {
  return [...cloudBoxes, ...env.DomainList].includes(url)
}

const customName = name => (
  <span style={{ position: 'relative' }}>
    <span>{name}</span>
    <Icon type='nys-new' style={iconStyle as any} />
  </span>
)

// TODO 临时设置可视化开关
const search = new URLSearchParams(window.location.search)
let showApiMgr = search.get('show-api')
let showNewJobs = search.get('show-newjobs')

export const NORMAL_PAGES: RouterType[] = [
  {
    key: 'cloud-shell',
    path: '/cloud-shell',
    component: () => import('./pages/CloudShell')
  }
]

export const COMPANY_PAGES: RouterType[] = [
  {
    name: '企业管理',
    key: 'company',
    icon: <Icon type='company_mgt_default' />,
    visible: () => !env.isPersonal && permList.check('company_management'),
    children: [
      {
        path: '/company/workspaces',
        component: () => import('./pages/Project'),
        key: 'workspaces',
        name: '空间管理'
      },
      {
        path: '/company/jobs',
        key: 'jobs',
        name: '作业中心',
        component: () => import('./pages/JobCenter'),
        children: [
          {
            path: '/company/job/:id',
            key: 'job-id',
            name: '作业详情',
            component: () => import('@/pages/JobDetail'),
            isMenu: false
          }
        ]
      },
      {
        path: '/visual-task',
        key: 'visual-task',
        name: '云应用使用明细',
        component: () => import('./pages/VisualTask'),
        visible: () => !!visualConfig.showVisualizeApp,
        isMenu: false
      },
      {
        path: '/licensemgr',
        key: 'licensemgr',
        name: '许可证管理',
        component: () => import('./pages/LicenseMGT'),
        visible: () =>
          env.company.id === '4inCAv4EcJQ' &&
          permList.check('project_management') // for T3
      },
      {
        path: '/company/departments',
        key: 'departments',
        name: '部门管理',
        component: () => import('./pages/DepartmentMGT'),
        visible: () =>
          env.company.isOpenDepMgr && permList.check('project_management')
      },
      {
        path: '/company/members',
        key: 'members',
        name: '成员管理',
        component: () => import('./pages/MemberMGT')
      },
      {
        path: '/company/invitations',
        key: 'invitations',
        name: '邀请列表',
        component: () => import('./pages/InvitationList')
      },
      {
        component: () => import('./pages/ResourceCenter'),
        path: '/company/resource',
        key: 'resource',
        name: '资源中心',
      },
      {
        component: () => import('./pages/OwnLicense'),
        path: '/company/own_license',
        key: 'own_license',
        name: '软件管理',
        visible: () => permList.check('project_management')
      },
      {
        component: () => import('./pages/Account'),
        path: '/company/account',
        key: 'account',
        name: '账户管理',
        visible: () => permList.check('project_management')
      },
      {
        path: '/company/bill_detail',
        key: 'bill_detail',
        name: '账单管理',
        component: () => import('./pages/BillDetail'),
        visible: () => permList.check('project_management')
      },
      {
        component: () => import('./pages/SystemSetting'),
        path: '/company/system',
        key: 'system',
        name: '系统设置'
      }
    ]
  }
]

export const PROJECT_PAGES: RouterType[] = [
  {
    path: '/workspaces',
    component: () => import('./pages/Project'),
    name: '空间管理',
    icon: <Icon style={{ fontSize: 16 }} type='workspace' />,
    visible: () => env.isPersonal
  },
  {
    component: () => import('./pages/Dashboard'),
    path: '/dashboard',
    name: '仪表盘',
    icon: <Icon style={{ fontSize: 16 }} type='dashboard_default' />
  },
  {
    component: () => import('./pages/FileMGT'),
    path: '/files',
    name: '文件管理',
    icon: <Icon style={{ fontSize: 16 }} type='file_mgt_default' />,
    visible: () => env.isPersonal || !env?.company?.isOpenNewFileMgr,
    children: [
      {
        path: '/file/operation-record',
        name: '文件操作记录',
        component: () => import('./pages/OperationRecord'),
        isMenu: false,
        visible: () => !env.isPersonal && permList.check('company_management')
      }
    ]
  },
  {
    component: () => import('./pages/FileMGT'),
    path: '/files',
    name: '文件管理',
    icon: <Icon style={{ fontSize: 16 }} type='file_mgt_default' />,
    customName: () => customName('文件管理'),
    visible: () => !env.isPersonal && env?.company?.isOpenNewFileMgr,
    children: [
      {
        path: '/file/operation-record',
        name: '文件操作记录',
        component: () => import('./pages/OperationRecord'),
        isMenu: false,
        visible: () => !env.isPersonal && permList.check('company_management')
      }
    ]
  },
  {
    component: () => import('./pages/JobCreator'),
    path: '/job-creator',
    name: '作业提交',
    id: 'menu-job-creator',
    icon: <Icon style={{ fontSize: 16 }} type='job_submission_default' />,
    visible: () => env.isPersonal || (!env?.company?.isOpenNewJobMgr && !env?.company?.isOpenStandardJobMgr)
  },
  {
    component: () => import('./pages/JobManager'),
    path: '/jobs',
    name: '作业管理',
    icon: <Icon style={{ fontSize: 16 }} type='job_mgt_default' />,
    visible: () => env.isPersonal || (!env?.company?.isOpenNewJobMgr && !env?.company?.isOpenStandardJobMgr),
    children: [
      {
        path: '/job/:id',
        name: '作业详情',
        component: () => import('@/pages/JobDetail'),
        isMenu: false
      },
      {
        path: '/jobset/:id',
        name: '作业集详情',
        component: () => import('@/pages/JobManager/JobSetDetail'),
        isMenu: false
      }
    ]
  },
  {
    component: () => import('./pages/StandardJobCreator'),
    path: '/standard-job-creator',
    name: '作业提交',
    icon: <Icon style={{ fontSize: 16 }} type='job_submission_default' />,
    customName: () => customName('作业提交'),
    visible: () =>
      (!env.isPersonal && env?.company?.isOpenStandardJobMgr) || !!showNewJobs
  },
  {
    component: () => import('./pages/StandardJobMGT'),
    path: '/standard-jobs',
    name: '作业管理',
    customName: () => customName('作业管理'),
    icon: <Icon style={{ fontSize: 16 }} type='job_mgt_default' />,
    visible: () =>
      (!env.isPersonal && env?.company?.isOpenStandardJobMgr) || !!showNewJobs,
    children: [
      {
        path: '/standard-job/:id',
        name: '作业详情',
        component: () => import('@/pages/StandardJobDetail'),
        isMenu: false
      }
    ]
  },
  // 新版作业提交
  {
    component: () => import('./pages/NewJobCreator'),
    path: '/new-job-creator',
    name: '作业提交',
    icon: <Icon style={{ fontSize: 16 }} type='job_submission_default' />,
    customName: () => customName('作业提交'),
    visible: () =>
      (!env.isPersonal && env?.company?.isOpenNewJobMgr) || !!showNewJobs
  },
  // 新版作业提交
  {
    component: () => import('./pages/NewJobManager'),
    path: '/new-jobs',
    name: '作业管理',
    customName: () => customName('作业管理'),
    icon: <Icon style={{ fontSize: 16 }} type='job_mgt_default' />,
    visible: () =>
      (!env.isPersonal && env?.company?.isOpenNewJobMgr) || !!showNewJobs,
    children: [
      {
        path: '/new-job/:id',
        name: '作业详情',
        component: () => import('@/pages/NewJobDetail'),
        isMenu: false
      },
      {
        path: '/new-jobset/:id',
        name: '作业集详情',
        component: () => import('@/pages/NewJobManager/JobSetDetail'),
        isMenu: false
      }
    ]
  },
  {
    component: () => import('./pages/VisList/List'),
    path: '/vis-session',
    name: '3D云应用',
    icon: <DesktopOutlined />,
    customName: () => customName('3D云应用'),
    // TODO 暂时设置开关可视化
    visible: () =>
      (!!visIBVConfig.showVisIBVApp || env.isKaiwu) &&
      isCloudBox(env.project?.box_domain || env.company?.box?.box_domain)
  },
  {
    path: '/company/bill_user',
    icon: <Icon style={{ fontSize: 16 }} type='account_settings_hover' />,
    name: '个人账单',
    component: () => import('./pages/BillForUser')
  },
  {
    path: '/workspace/members',
    visible: () => permList.check('company_management'),
    component: () => import('./pages/ProjectMemberMGT'),
    name: '空间成员',
    icon: <Icon style={{ fontSize: 16 }} type='project_members_default' />
  },
  {
    path: '/expert_community',
    visible: () => env.isKaiwu,
    component: () => import('./pages/ExpertCommunity'),
    name: '专家社区',
    icon: <CommentOutlined />
  },
  {
    path: '/industrial_APP',
    visible: () => env.isKaiwu,
    component: () => import('./pages/IndustrialAPP'),
    name: '工业APP',
    icon: <AppstoreOutlined />
  },
  {
    component: () => import('./pages/TokenMgr'),
    path: '/token',
    name: 'API密钥管理',
    icon: <KeyOutlined />,
    // TODO 暂时隐藏
    visible: () => !!showApiMgr
  },
  {
    component: () => import('./pages/PersonalSetting'),
    path: '/personal-setting',
    name: '个人设置',
    isMenu: false
  },
  {
    component: () => import('./pages/MessageMGT'),
    path: '/messages',
    name: '消息中心',
    isMenu: false
  },
  {
    path: '/account',
    component: () => import('./pages/Account'),
    visible: () => env.isPersonal,
    name: '账户管理',
    isMenu: false
  },
  {
    path: '/recharge',
    component: () => import('./pages/Recharge'),
    visible: () => env.isPersonal,
    name: '充值',
    isMenu: false
  },
  {
    path: '/recharge_record',
    component: () => import('./pages/RechargeRecord'),
    visible: () => env.isPersonal,
    name: '充值记录',
    isMenu: false
  },
  {
    path: '/recharge_result',
    component: () => import('./pages/RechargeResult'),
    name: '充值结果',
    isMenu: false
  },
  {
    path: '/workorder',
    component: () => import('./pages/Workorder'),
    name: '我的工单',
    isMenu: false,
    children: [
      {
        path: '/workorder-detail/:id',
        name: '工单详情',
        component: () => import('./pages/Workorder/WorkorderDetail'),
        isMenu: false
      }
    ]
  }
]
