/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useDispatch } from 'react-redux'
import { buryPoint, history } from '@/utils'
import { Tooltip } from 'antd'
import { Modal } from '@ys/components'
import { Monitors } from './Monitors'
import { LineChartOutlined } from '@ant-design/icons'
import { useStore } from '../../store'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name {
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryColor};
  }

  .icons {
    display: flex;
    flex: 0 0 48px;
    align-items: center;
    justify-content: flex-start;
    .icon {
    }
  }

  .anticon {
    margin-left: 10px;
  }
`

type Props = {
  id: string
  name: string
  display_state: number
  residualVisible: boolean
  monitorVisible: boolean
  cloudGraphicVisible?: boolean
  projectId: string
  userId: string
  jobRuntimeId: string
  onClick?: (id: string) => void
  zoneId?: string
  file_storage_zone?: string
}

export const JobName = observer(function JobName({
  id,
  name,
  display_state,
  residualVisible,
  monitorVisible,
  cloudGraphicVisible,
  projectId,
  userId,
  jobRuntimeId,
  onClick,
  zoneId,
  file_storage_zone
}: Props) {
  const { t } = useTranslation()
  const title = t('VisualizationAnalysis')
  const store = useStore()
  const dispatch = useDispatch()
  // t('IfYouClickOnTheListOfDataStorageAreaWithTheSelectionOfTheFilterAreaIsNotTheSameRiskIsOldData,NeedSpecialTreatment')// t('IfYouClickOnTheListOfDataStorageAreaWithTheSelectionOfTheFilterAreaIsNotTheSameRiskIsOldData')，t('NeedSpecialHandling')// t('IfYouClickOnTheListOfDataStorageAreaWithTheSelectionOfTheFilterAreaIsNotTheSameRiskIsOldData,NeedSpecialTreatment')
  !zoneId &&
    (zoneId = store?.domainList?.filter(
      item => item.zone === file_storage_zone
    )[0]?.storage_domains?.[0])
  function onJobClick() {
    buryPoint({
      category: t('JobManagement'),
      action: t('JobName')
    })
    onClick ? onClick(id) : history.push(`/new-job/${id}?zoneId=${zoneId}`)
    dispatch({
      type: 'NEWJODETAIL',
      payload: 'close'
    })
    setTimeout(() => {
      dispatch({
        type: 'JOBMANAGE',
        payload: 'togg'
      })
      dispatch({
        type: 'NEWJODETAIL',
        payload: 'full'
      })
    }, 500)
  }

  function showMonitor() {
    Modal.show({
      title,
      width: 1000,
      bodyStyle: {
        paddingTop: 0
      },
      destroyOnClose: display_state === 1, // t('InTheOperationOfThe') job，t('ToCloseTheDialog.') t('NeedToBe') destory modal
      footer: null,
      content: () => {
        return (
          <Monitors
            id={id}
            projectId={projectId}
            userId={userId}
            jobRuntimeId={jobRuntimeId}
            jobState={display_state}
            residualVisible={residualVisible}
            monitorVisible={monitorVisible}
            cloudGraphicVisible={cloudGraphicVisible}
          />
        )
      }
    })
  }

  return (
    <StyledLayout>
      <div className='name' title={name} onClick={onJobClick}>
        {name}
      </div>
      <div className='icons'>
        {(monitorVisible || residualVisible || cloudGraphicVisible) && (
          <Tooltip title={title} className='residual-plot'>
            <LineChartOutlined
              style={{ fontSize: 18, flex: '0 0 24px' }}
              onClick={showMonitor}
            />
          </Tooltip>
        )}
      </div>
    </StyledLayout>
  )
})
