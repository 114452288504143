/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Spin } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import App from '@/App'
import AppDesktop from '@/AppDesktop'
import Page500 from '@/pages/500'
import { env } from '@/domain'
import { StyledRoot } from './style'
// import './index.css'
import './indexDesktop.css'
import { Modal, Drawer } from '@ys/components'
import { theme } from '@ys/utils/constant'
import { Provider as YSProvider } from '@/components'
import { guide } from '@/utils'
import { Provider } from 'react-redux'
import store from './reducers'
import './i18nextConf'

Modal.theme = theme
Modal.configProviderProps = { locale: zhCN }
Drawer.Wrapper = YSProvider
const HACKRLOAD = 'HACKRLOAD'

// ignore trivial rejection
window.addEventListener('unhandledrejection', event => {
  if (!event.reason) {
    event.preventDefault()
  }
})

window.addEventListener(
  'keydown',
  function (e) {
    //可以判断是不是mac，如果是mac,ctrl变为花键
    //event.preventDefault() 方法阻止元素发生默认的行为。
    if ([83].includes(e.keyCode) && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      e.preventDefault()
      // Process event...
    }
  },
  false
)


const render = (Child: React.ReactNode) =>
  ReactDOM.render(
    <Suspense
      fallback={
        <div id='sus-fallback'>
          <h1>Loading</h1>
        </div>
      }>
      <Provider store={store}>{Child}</Provider>
    </Suspense>,
    document.querySelector('#root')
  )

render(
  <div
    style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <Spin />
  </div>
)

env
  // init env && load youmeng script
  .initEnv()
  .then(() => env.init())
  .then(() => {
    if (
      !env.custom.id &&
      [
        '4hCkDLjiVJ1',
        '4hto9zidxxf',
        '4hrpyGGizA1',
        '4f9aWGn6EuY',
        '4evs5zeodN5',
        '4epgn7xYam5',
        '4dbPL4qXx6b',
        '48Bg5wxyUuG',
        '48Au3Y8uyaE',
        '48qaaGmSSVE',
        '46yj7xghby9',
        '46dohsVe3t3',
        '419eQtiH167',
        '3ZmTrFN86GC',
        '3T2gk36J3fL',
        '3S5JU8iyuzE'
      ].includes(env.company?.id)
    ) {
      // window.location.replace('https://kaiwu.yuansuan.cn')
      console.log('should redirect to kaiwu.yuansuan.cn')
    }
    guide.activate().then(() => {
      if (!window.localStorage.getItem(HACKRLOAD)) {
        render(
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            桌面加载中 <Spin />
          </div>
        )
        window.location.reload()
        window.localStorage.setItem(HACKRLOAD, HACKRLOAD)
      }
    })

    window.localStorage.getItem(HACKRLOAD) &&
      render(
        <StyledRoot id='styledRoot'>
          <YSProvider>
            <AppDesktop />
          </YSProvider>
        </StyledRoot>
      )
  })
  .catch(error => {
    const { status } = error.response || {}
    if (status !== 401) {
      render(<Page500 description={error.message} />)
    }
  })
