/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { Button, Modal } from '@ys/components'
import { Divider, message } from 'antd'
import { box, account, env } from '@/domain'
import { Dropdown } from '@/components/Dropdown'
import { jobServer } from '@/server'
import { useStore } from '../store'
import { useStore as fatherStore } from '../../store'
import { history } from '@/utils'
import { showDownloader } from '../showDownloader'
import { applyHelp } from './applyHelp'
import { buryPoint } from '@/utils'
import {
  getContinuousRedeployInfo,
  getRedeployInfo
} from '@/domain/JobBuilder/NewJobBuilder'
import { getUrlParams } from '@ys/utils'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div``

type Props = {
  id: string
  name: string
  cancelable: boolean
  downloadable: boolean
  deleteable: boolean
  showContinuousRedeploy: boolean
  display_back_state: number
  troubleshoot_id: number
  file_storage_zone?: string
  sc_id?: string
}

export const Operator = observer(function Operator({
  id,
  name,
  cancelable,
  downloadable,
  deleteable,
  showContinuousRedeploy,
  display_back_state,
  troubleshoot_id,
  file_storage_zone,
  sc_id
}: Props) {
  const store = useStore()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const managerStore = fatherStore()
  const { model } = store
  const params = getUrlParams()
  const zoneId = params?.zoneId
    ? (params?.zoneId as string)
    : managerStore?.domainList.filter(
        item => item.zone === file_storage_zone
      )?.[0]?.storage_domains[0]
  const zoneKey = params?.zoneKey
    ? params?.zoneKey
    : managerStore?.domainList.filter(
        item => item.zone === file_storage_zone
      )?.[0]?.zone
  const scId = params?.scId ? params?.scId : sc_id

  async function cancelJob() {
    await Modal.showConfirm({
      title: t('confirmCancel'),
      content: `${t('cancelJob')}【${name}】，${t('confirmOrNot')}`
    })
    buryPoint({
      category: t('job.title'),
      action: t('cancelJob')
    })
    await jobServer.cancel([id])
    store.refresh()
    message.success(t('cancelSucceed'))
  }

  async function goToRedeployPage(redeployId) {
    buryPoint({
      category: t('job.title'),
      action: t('resubmit')
    })
    await getRedeployInfo({
      id: redeployId,
      type: 'job',
      clean: true,
      zoneId
    })

    history.push(
      `/new-job-creator?${qs.stringify({
        id: redeployId,
        type: 'job',
        mode: 'redeploy',
        zoneId,
        zoneKey,
        scId
      })}`
    )
    dispatch({
      type: 'CALCUAPP',
      payload: 'close'
    })
    dispatch({
      type: 'JOBMANAGE',
      payload: 'close'
    })
    setTimeout(() => {
      dispatch({
        type: 'CALCUAPP',
        payload: 'togg'
      })
    })
  }

  async function goToContinuousRedeployPage(id) {
    buryPoint({
      category: t('job.title'),
      action: t('continueComputeCommit')
    })

    await getContinuousRedeployInfo({
      id,
      type: 'job',
      clean: false,
      zoneId
    })

    history.push(
      `/new-job-creator?${qs.stringify({
        id: id,
        type: 'job',
        mode: 'continuous',
        zoneId,
        zoneKey,
        scId
      })}`
    )
    dispatch({
      type: 'CALCUAPP',
      payload: 'close'
    })
    dispatch({
      type: 'JOBMANAGE',
      payload: 'close'
    })
    setTimeout(() => {
      dispatch({
        type: 'CALCUAPP',
        payload: 'full'
      })
    })
  }

  function downloadJob() {
    buryPoint({
      category: t('job.title'),
      action: t('DownloadedToTheLocal')
    })
    box.newJob.downloadJobs(
      [
        {
          jobId: id,
          jobName: name
        }
      ],
      zoneId
    )
  }

  async function downloadJobToCommon() {
    buryPoint({
      category: t('job.title'),
      action: t('DownloadTheFilesToMe')
    })
    const job = model.list.find(job => job.id === id)
    const resolvedJobs = await showDownloader(
      [
        {
          id: job.id,
          name: job.name
        }
      ],
      zoneId,
      t
    )
    if (Object.keys(resolvedJobs).length > 0) {
      await box.newResult.linkToCommon(resolvedJobs, zoneId)
      message.success(t('downloadComplete'))
      store.setSelectedKeys([])
    }
  }

  async function deleteJob() {
    buryPoint({
      category: t('job.title'),
      action: t('job.delete')
    })
    await Modal.showConfirm({
      title: t('confirmDelete'),
      content: t('job.confirmDelete')
    })
    await jobServer.delete([id])
    store.refresh()
    message.success(t('deleteSucceed'))
  }

  return (
    <StyledLayout>
      <Button
        style={{ padding: 0 }}
        disabled={!cancelable}
        onClick={() => cancelJob()}
        type='link'>
        {t('cancelJob')}
      </Button>

      <Divider type='vertical' style={{ margin: 2 }} />

      <Button
        style={{ padding: 0 }}
        disabled={
          !env.canSubmitJob
            ? t('cannotCommit')
            : env.accountFrozen
            ? t('job.accountBalance')
            : zoneKey !== file_storage_zone
            ? t('oldJobCannotCommit')
            : false
        }
        onClick={() => goToRedeployPage(id)}
        type='link'>
          {t('resubmit')}
      </Button>

      <Divider type='vertical' style={{ margin: 2 }} />

      <Dropdown
        menuContentList={[
          showContinuousRedeploy && {
            children: (
              <Button
                disabled={
                  display_back_state !== 2
                    ? t('cannotBeContinued')
                    : zoneKey !== file_storage_zone
                    ? t('oldJobCannotCommit')
                    : false
                }
                style={{
                  color: 'rgba(0,0,0,0.65)'
                }}
                onClick={() => goToContinuousRedeployPage(id)}
                type='link'>
                {t('continueComputeCommit')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => downloadJob()}
                disabled={
                  env.accountHasDebt
                    ? t('job.accountBalance')
                    : !downloadable
                }
                type='link'>
                {t('job.downloadToLocal')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => downloadJobToCommon()}
                disabled={
                  env.accountHasDebt
                    ? t('job.accountBalance')
                    : !downloadable
                }
                type='link'>
                {t('job.downloadToMyFileExplorer')}
              </Button>
            )
          },
          {
            children: (
              <Button
                type='link'
                disabled={(() => {
                  let str = ''
                  if (display_back_state !== 2) {
                    str += t('cannotHelpBeforeReturn')
                  }
                  if (troubleshoot_id !== 0) {
                    str += t('helped')
                  }
                  return str || false
                })()}
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => applyHelp(id, t)}>
                {t('applyForHelp')}
              </Button>
            )
          },
          {
            children: (
              <Button
                disabled={troubleshoot_id === 0 && t('noRecord')}
                style={{
                  color: 'rgba(0,0,0,0.65)'
                }}
                onClick={() => {
                  history.push(
                    `/workorder-detail/${troubleshoot_id}`,
                    history.location.state
                  )
                }}
                type='link'>
                {t('helpRecord')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'red' }}
                onClick={() => deleteJob()}
                disabled={!deleteable}
                type='link'>
                {t('remove')}
              </Button>
            )
          }
        ].filter(Boolean)}
      />
    </StyledLayout>
  )
})
