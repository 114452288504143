/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useState, useEffect, useMemo } from 'react'
import { ListActionWrapper, ModalListDataWrapper } from '../style'
import { Hardware, CHARGE_TYPE_MAP } from '@/domain/Vis'
import { Spin, Select, Space, Tooltip, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { propertyMapReduce } from '../utils'
import { Content, Layout, Sider, SiderTitle } from './styles'
import { useTranslation } from 'react-i18next'

const selectStyles = { width: '200px' }

interface IProps {
  loading?: boolean
  hardware: Array<Hardware>
  onSelect: (hardwareId: string) => void
  defaultId?: String
}

export const HardwareSelector = observer(
  ({ hardware, loading, onSelect, defaultId }: IProps) => {
    const [cpu, setCpu] = useState(0)
    const [mem, setMem] = useState(0)
    const [gpu, setGpu] = useState(0)
    const [charge, setCharge] = useState('')
    const [filteredHardware, setFilteredHardware] = useState(hardware)
    const [selectRowKeys, setSelectRowKeys] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
      setCharge(propertyMapReduce(hardware, 'charge_type')[0])
    }, [])

    useEffect(() => {
      setFilteredHardware(
        hardware.filter(item => {
          if (cpu != 0 && item.number_of_cpu != cpu) return false
          if (mem != 0 && item.number_of_mem != mem) return false
          if (gpu != 0 && item.number_of_gpu != gpu) return false
          if (charge != '0' && item.charge_type != charge) return false
          return true
        })
      )
    }, [cpu, mem, gpu, hardware, charge])

    useEffect(() => {
      onSelect(selectRowKeys.toString())
    }, [selectRowKeys])

    //软件切换后，硬件自动选择软件对应的预设清单中的默认配置
    useEffect(() => {
      setSelectRowKeys([defaultId])
    }, [defaultId])

    const columns = useMemo(() => {
      return [
        {
          title: t('models'),
          dataIndex: 'name',
          width: 200,
          render: (_, record) => (
            <Tooltip placement='topLeft' title={record.name}>
              {record.name}
            </Tooltip>
          )
        },
        {
          title: t('specifications'),
          dataIndex: 'desc',
          width: 200,
          onCell: () => {
            return {
              style: {
                maxWidth: 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
              }
            }
          },
          render: (_, record) => (
            <Tooltip placement='topLeft' title={record.desc}>
              {record.desc}
            </Tooltip>
          )
        },
        {
          title: t('TheProcessorType'),
          dataIndex: '',
          width: 100,
          render: (_, record) => {
            return (
              <Space>
                <span> {record.cpu_model || '--'}</span>
              </Space>
            )
          }
        },
        {
          title: t('PricingModel'),
          dataIndex: 'charge_type',
          width: 100,
          render: (_, record) => {
            return (
              <Space>
                <span> {CHARGE_TYPE_MAP[record.charge_type]}</span>
              </Space>
            )
          }
        },
        {
          title: 'vCPU',
          dataIndex: 'number_of_cpu',
          width: 100,
          render: (_, record) => {
            return (
              <Space>
                <span>{record.number_of_cpu} {t('nuclear')}</span>
              </Space>
            )
          }
        },
        {
          title: t('memory'),
          dataIndex: 'number_of_mem',
          width: 100,
          render: (_, record) => {
            return (
              <Space>
                <span>{record.number_of_mem} GB</span>
              </Space>
            )
          }
        },
        {
          title: t('TheGraphicsCard'),
          dataIndex: 'number_of_gpu',
          width: 100,
          render: (_, record) => {
            return (
              <Space>
                <span>{record.number_of_gpu + t('a') + record.gpu_model} </span>
              </Space>
            )
          }
        }
      ]
    }, [])

    const rowSelectionChange = (selectRowKeys, rowSelection) => {
      setSelectRowKeys(selectRowKeys)
      if (selectRowKeys) {
        document.querySelector('.validate_hard_tip').innerHTML = ''
      }
    }
    const rowSelection: any = {
      type: 'radio',
      onChange: rowSelectionChange,
      selectedRowKeys: selectRowKeys
    }

    return (
      <Layout>
        <Sider>
          <SiderTitle>{t('HardwareSpecifications')}</SiderTitle>
        </Sider>
        <Content>
          <Spin spinning={loading}>
            <ListActionWrapper style={{ padding: 0 }}>
              <div className='item'>
                <Select
                  className={'status'}
                  value={cpu}
                  onChange={setCpu}
                  style={selectStyles}>
                  <Select.Option value={0}>{t('AllThePVCU')}</Select.Option>
                  {propertyMapReduce(hardware, 'number_of_cpu').map(v => (
                    <Select.Option value={v} key={v}>
                      {v} vCPU
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className='item'>
                <Select
                  className={'status'}
                  value={mem}
                  onChange={setMem}
                  style={selectStyles}>
                  <Select.Option value={0}>{t('AllMemory')}</Select.Option>
                  {propertyMapReduce(hardware, 'number_of_mem').map(v => (
                    <Select.Option value={v} key={v}>
                      {v} GiB
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className='item'>
                <Select
                  className={'status'}
                  value={gpu}
                  onChange={setGpu}
                  style={selectStyles}>
                  <Select.Option value={0}>{t('AllTheGPU')}</Select.Option>
                  {propertyMapReduce(hardware, 'number_of_gpu').map(v => (
                    <Select.Option value={v} key={v}>
                      {v} vGPU
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className='item'>
                <Select
                  className={'status'}
                  onChange={setCharge}
                  defaultValue={propertyMapReduce(hardware, 'charge_type')[0]}
                  style={selectStyles}>
                  {propertyMapReduce(hardware, 'charge_type').map(v => (
                    <Select.Option value={v} key={v}>
                      {CHARGE_TYPE_MAP[v]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </ListActionWrapper>
            <ModalListDataWrapper style={{ padding: 0, marginTop: '10px' }}>
              <Table
                size='small'
                rowKey={record => record.id}
                onRow={record => {
                  return {
                    onClick: () => {
                      rowSelectionChange([record.id], [record])
                    }
                  }
                }}
                rowSelection={{ ...rowSelection }}
                dataSource={filteredHardware}
                columns={columns}
                pagination={false}></Table>
            </ModalListDataWrapper>
          </Spin>
          {hardware && (
            <div className='validate_tip validate_hard_tip'>
              {t('PleaseSelectAHardwareSpecifications')}
            </div>
          )}
        </Content>
      </Layout>
    )
  }
)
