/* Copyright (C) 2016-present, Yuansuan.cn */
export const gene_name = () =>
  Math.random().toString(36).substring(2, 10).toUpperCase()

let installed = JSON.parse(localStorage.getItem('installed') || '[]')
import { vis } from '@/domain'
// // 获取应用
// appList.fetch()
// const iconTypeMap = {}
// const applications = JSON.parse(
//   localStorage.getItem('FLAG_ENTERTAINMENT') || '[]'
// )
// const appType = [...new Set([...applications.map(item => item.type)])]
// applications.forEach(item => {
//   if (!iconTypeMap.hasOwnProperty(item.type)) {
//     iconTypeMap[item.type] = item.icon
//   }
// })
// const generateAppInfo = appType.map(item => ({
//   name: item,
//   title: item,
//   icon: iconTypeMap[item] || item,
//   routerPath: `/new-job-creator?action=${
//     iconTypeMap[item] || item
//   }&appType=${item}`,
//   type: 'app',
//   action: iconTypeMap[item] || item,
//   appType: 'generate',
//   className: 'CalculateWrap'
//   // action: 'CALCUAPP',
// }))

export function generateCalcApp(data) {
  const iconTypeMap = {}
  const appType = [...new Set([...data.map(item => item.type)])]
  data.forEach(item => {
    if (!iconTypeMap.hasOwnProperty(item.type)) {
      iconTypeMap[item.type] = item.icon
    }
  })
  const handleIcon = item => {
    switch (true) {
      case /^Star-?CCM\+?\d*/gi.test(item):
        return 'STAR-CCM+'
      case item === 'Telemac':
        return 'Telemac'
      case item === 'Code Saturne':
        return 'Code Saturne'
      case item === 'Code Aster':
        return 'Code Aster'
      case item === 'Fluent':
        return 'Fluent'
      case item === 'ABAQUS':
        return 'ABAQUS'
      case item === 'Mechanical':
        return 'Mechanical'
      case item === 'LS-DYNA':
        return 'LS-DYNA'
      default:
        return iconTypeMap[item] || item
    }
  }
  const generateAppInfo = appType.map(item => {
    // HACK:
    // const icon = [
    //   'STAR-CCM+',
    //   'Telemac',
    //   'Code Saturne',
    //   'Code Aster',
    //   'Fluent'
    // ].includes(item)
    //   ? item
    //   : iconTypeMap[item] || item
    const icon = handleIcon(item)
    return {
      name: item,
      title: item,
      // icon: iconTypeMap[item] || item,
      icon,
      routerPath: `/new-job-creator?action=${icon}&appType=${item}`,
      type: 'app',
      action: icon,
      appType: 'generate',
      className: 'CalculateWrap',
      renderType: 'calcApp'
      // action: 'CALCUAPP',
    }
  })
  return {
    appType,
    generateAppInfo
  }
}

function handleCloudIcon(name, icon, id) {
  switch (true) {
    // case name.includes('Battery_Design_Studio'):
    //   return 'bds'
    // case /^Star-?CCM\+?\d*/gi.test(name):
    //   return 'StarCCMCloud'
    // case name === 'SalomeMeca_2021':
    //   return 'SalomeMeca'
    case name.includes('开物'):
      return 'kw'
    // case name === 'HyperWorks2022':
    //   return 'fluent'
    default:
      return icon || id + 'CLOUDAPP'
  }
}

export function generateCloudApp(software, session) {
  // // 获取软件
  // const software = JSON.parse(localStorage.getItem('SOFTWARE') || '[]')
  // // 获取已经打开的3D云应用
  // const session = JSON.parse(localStorage.getItem('SESSION') || '[]')
  // 生成桌面icon
  const softWareType = software.map(item => item.name)
  const generateCloudApp = software.map(item => {
    const openingSession = session.filter(
      v =>
        v?.session?.zone?.name === item?.zone?.name &&
        item?.id === v?.instance?.software?.id
    )
    const icon = handleCloudIcon(item.name, item.icon, item.id)
    const result = {
      name: item.name || item.desc,
      title: item.name || item.desc,
      zone: item.zone?.name,
      id: item.id,
      softwareId: item.id,
      icon,
      routerPath: `/vis-session?zone=${item?.zone?.name}&actionApp=${icon}`,
      type: 'app',
      action: icon,
      appType: 'generate',
      className: 'CloudAppWrap_close',
      menu: 'cloudMenuClose',
      renderType: 'cloudApp'
    }
    if (openingSession.length > 0) {
      let url = openingSession[0]?.session?.stream_url.replace(/https?:/, 'https:')
      result.url = url
      result.className = 'CloudAppWrap_open'
      result.menu = 'cloudMenuOpen'
      result['id'] = openingSession[0]?.session?.id
      if (openingSession[0]?.session.remote_apps.length > 0) {
        vis
          .getRemoteAppUrl(
            openingSession[0]?.session?.id,
            openingSession[0]?.session?.remote_apps[0]?.remote_app_name
          )
          .then(data => {
            // console.log(data, 'data')
            // url = data.replace(/https?:/, 'https:')
            result.url = data.replace(/https?:/, 'https:')
          })
      }
      setTimeout(() => {
        console.log('url: ', url)
        result.action = 'EXTERNAL' //TODO:重新打开链接的时候payload需要设置为对应的链接
        result.payload = url
        result['id'] = openingSession[0]?.session?.id
        result.className = 'CloudAppWrap_open'
        result.menu = 'cloudMenuOpen'
      })
    }
    return result
  })
  return { softWareType, CloudApp: generateCloudApp }
}

const apps = [
  {
    name: 'FileManage',
    title: '文件管理',
    icon: 'explorer',
    routerPath: '/files',
    type: 'app',
    action: 'FILEMANAGE'
  },
  {
    name: 'FileExplorer',
    title: 'File Explorer',
    icon: 'FileExplorer',
    routerPath: '/files',
    type: 'app',
    action: 'FILEXOLORER'
  },
  {
    name: 'Calculator',
    title: '作业提交',
    icon: 'calculator',
    routerPath: '/new-job-creator',
    type: 'app',
    action: 'CALCUAPP'
  },
  {
    name: 'JobManage',
    title: '作业管理',
    icon: 'jobLists',
    routerPath: '/new-jobs',
    type: 'app',
    action: 'JOBMANAGE'
  },
  {
    name: 'EnterpriseManage', // 应用名称
    title: '企业管理',
    routerPath: '/company/workspaces',
    icon: 'enterpriseManage', // 应用icon
    type: 'app', // 应用类型
    action: 'ENTERPRISEMANAGE' // 触发的action，一般为name的全大写
  },
  {
    name: 'Dashboard', // 应用名称
    title: '仪表盘',
    routerPath: '/dashboard',
    icon: 'dashboard', // 应用icon
    type: 'app', // 应用类型
    action: 'DASHBOARD' // 触发的action，一般为name的全大写
  },
  {
    name: 'Messages', // 应用名称
    title: '消息中心',
    routerPath: '/messages?tab=messages',
    icon: 'mail', // 应用icon
    type: 'app', // 应用类型
    action: 'MESSAGES' // 触发的action，一般为name的全大写
  },
  {
    name: '3dcloudApp', // 应用名称
    title: '3d云应用',
    icon: '3dcloudApp', // 应用icon
    routerPath: '/vis-session',
    type: 'app', // 应用类型
    action: '3DCLOUDAPP' // 触发的action，一般为name的全大写
  },
  {
    name: 'NewJobDetail', // 应用名称
    title: '作业详情',
    icon: 'jobDetail', // 应用icon
    type: 'app', // 应用类型
    action: 'NEWJODETAIL' // 触发的action，一般为name的全大写
  },
  // {
  //   name: 'Starccm+', // 应用名称
  //   title: 'STAR-CCM+',
  //   className: 'CloudAppWrap_open',
  //   icon: 'starccm+', // 应用icon
  //   type: 'app', // 应用类型
  //   action: 'EXTERNAL', // 触发的action，一般为name的全大写
  //   payload:
  //     'https://remoteapp-jn.yuansuan.cn:34528/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wiZ3VhY2FkX2FkZHJcIjpcImd1YWNkOjQ4MjJcIixcImFzc2V0X3Byb3RvY29sXCI6XCJyZHBcIixcImFzc2V0X2hvc3RcIjpcIjE3Mi4xNi4wLjI2XCIsXCJhc3NldF9wb3J0XCI6XCIzMzg5XCIsXCJhc3NldF91c2VyXCI6XCJhZG1pbmlzdHJhdG9yXCIsXCJhc3NldF9wYXNzd29yZFwiOlwiQUZ6Vjl1NlF-MnhkTWVlOC4zaGE4SzNfXCIsXCJhc3NldF9zZWN1cml0eVwiOlwiXCIsXCJhc3NldF9yZW1vdGVfYXBwXCI6XCJ8fHN0YXJjY21cIixcImFzc2V0X3JlbW90ZV9hcHBfYXJnc1wiOlwiXCIsXCJhc3NldF9yZW1vdGVfYXBwX2RpclwiOlwiXCIsXCJzdG9yYWdlX2lkXCI6XCJcIixcImRpc2FibGVfZ2Z4XCI6XCJmYWxzZVwifSJ9.Xv_7OjIRVWooqUicnzSIL4CzLctRv1HVPyPv1HZ0TAA'
  // },
  // {
  //   name: 'Starccm+Calc', // 应用名称
  //   title: '求解-StarCCM+',
  //   icon: 'starccm+calc', // 应用icon
  //   type: 'app', // 应用类型
  //   action: 'STARCCM+CALC' // 触发的action，一般为name的全大写
  // },
  {
    name: 'Template', // 应用名称
    title: '模板',
    icon: 'template', // 应用icon
    type: 'app', // 应用类型
    action: 'TEMPLATE' // 触发的action，一般为name的全大写
  }
  // ...generateAppInfo,
  // ...generateCloudApp
]

for (let i = 0; i < installed.length; i++) {
  installed[i].action = gene_name()
  apps.push(installed[i])
}

// 注册应用
const mainApp = [
  'FileManage',
  // 'FileExplorer',
  // 'Calculator',
  'JobManage'
  // '3dcloudApp'
  // 'EnterpriseManage'
]

const HACKDESKTOPAPP = [
  // {
  //   name: 'Starccm+', // 应用名称
  //   title: 'STAR-CCM+',
  //   className: 'CloudAppWrap_open',
  //   icon: 'starccm+', // 应用icon
  //   type: 'app', // 应用类型
  //   action: 'EXTERNAL', // 触发的action，一般为name的全大写
  //   payload:
  //     'https://remoteapp-jn.yuansuan.cn:34528/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wiZ3VhY2FkX2FkZHJcIjpcImd1YWNkOjQ4MjJcIixcImFzc2V0X3Byb3RvY29sXCI6XCJyZHBcIixcImFzc2V0X2hvc3RcIjpcIjE3Mi4xNi4wLjI2XCIsXCJhc3NldF9wb3J0XCI6XCIzMzg5XCIsXCJhc3NldF91c2VyXCI6XCJhZG1pbmlzdHJhdG9yXCIsXCJhc3NldF9wYXNzd29yZFwiOlwiQUZ6Vjl1NlF-MnhkTWVlOC4zaGE4SzNfXCIsXCJhc3NldF9zZWN1cml0eVwiOlwiXCIsXCJhc3NldF9yZW1vdGVfYXBwXCI6XCJ8fHN0YXJjY21cIixcImFzc2V0X3JlbW90ZV9hcHBfYXJnc1wiOlwiXCIsXCJhc3NldF9yZW1vdGVfYXBwX2RpclwiOlwiXCIsXCJzdG9yYWdlX2lkXCI6XCJcIixcImRpc2FibGVfZ2Z4XCI6XCJmYWxzZVwifSJ9.Xv_7OjIRVWooqUicnzSIL4CzLctRv1HVPyPv1HZ0TAA'
  // },
  {
    name: 'gwCAE', // 应用名称
    title: '格物云CAE',
    icon: 'gwCAE', // 应用icon
    type: 'app', // 应用类型
    action: 'EXTERNAL', // 触发的action，一般为name的全大写
    payload: 'https://cae365.yuansuan.com'
  }
]

// const HACKDESKTOPAPPTYPE = ['Starccm+']
const HACKDESKTOPAPPTYPE = ['gwCAE']
// const HACKDESKTOPAPPTYPE = []
// const desktopApp = ['Starccm+', 'Starccm+Calc']
// const desktopApp = ['Starccm+']

const { taskbar, desktop, pinned, recent } = {
  // 设置底部taskbar的数组
  taskbar: mainApp,
  // 设置桌面应用
  desktop: [...mainApp, 'gwCAE'],
  // desktop: [...mainApp, ...appType],
  // 设置便捷连接
  pinned: [],
  // (localStorage.getItem("pinned") &&
  //   JSON.parse(localStorage.getItem("pinned"))) || [
  //   "Browser",
  //   "Get Started",
  //   "Task Manager",
  //   "Mail",
  //   "Settings",
  //   "Store",
  //   "Unescape",
  //   "Buy me a coffee",
  //   "Notepad",
  //   "Whiteboard",
  //   "Calculator",
  //   "Spotify",
  //   "Twitter",
  //   "File Explorer",
  //   "Terminal",
  //   "Github",
  //   // "Discord",
  //   "Camera",
  // ],

  // 设置推荐
  recent: []
}

// if (desktop.includes("Buy me a coffee") === false) {
//   desktop.push("Buy me a coffee");
// }

export const taskApps = apps.filter(x => taskbar.includes(x.name))

export const desktopApps = apps
  .filter(x => desktop.includes(x.name))
  .sort((a, b) => {
    return desktop.indexOf(a.name) > desktop.indexOf(b.name) ? 1 : -1
  })

export const GenerateDesktopApps = (apps, generateDesktop) => {
  const desktopType = [...desktop, ...generateDesktop]
  return apps
    .filter(x => desktopType.includes(x.name))
    .sort((a, b) => {
      return desktopType.indexOf(a.name) > desktopType.indexOf(b.name) ? 1 : -1
    })
}

export const pinnedApps = apps
  .filter(x => pinned.includes(x.name))
  .sort((a, b) => {
    return pinned.indexOf(a.name) > pinned.indexOf(b.name) ? 1 : -1
  })

export const recentApps = apps
  .filter(x => recent.includes(x.name))
  .sort((a, b) => {
    return recent.indexOf(a.name) > recent.indexOf(b.name) ? 1 : -1
  })

export const allApps = apps.filter(app => {
  return app.type === 'app'
})

export const dfApps = {
  taskbar,
  desktop,
  pinned,
  recent
}
export default apps

export const HACKDESKTOPDATA = {
  HACKDESKTOPAPP,
  HACKDESKTOPAPPTYPE
}
