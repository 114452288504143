/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Select } from 'antd'
// import { useStore } from './store'

interface PropsType {
  zoneList: any
  changeCallBack: (id: string, option: any) => void
  value: string
  showTitle?: boolean
  disabled?: boolean
}
 const AreaSelect = observer(function AreaSelect({
  zoneList,
  changeCallBack,
  value,
  showTitle = true,
  disabled = false
}: PropsType) {
  return (
    <div className='AreaSelectContainer'>
      {showTitle && <div className='areaWrap'>区域：</div>}
      <Select
        style={{ width: 224 }}
        value={value}
        // defaultValue={value}
        disabled={disabled}
        onChange={(v, option) => changeCallBack(v, option)}>
        {zoneList.map(item => (
          <Select.Option key={item.name} sc_id={item.sc_id} domain={item.domain} value={item.key}>
            {item.desc}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
})
export default AreaSelect