/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { createStore } from '@ys/utils/hooks'
import { DirectoryTree } from './DirectoryTree'
import { serverFactory } from './common'
import { History } from './History'
import { useLocalStore } from 'mobx-react-lite'
import { BaseDirectory } from '@/utils/FileSystem'
import { useCallback } from 'react'
import { newBoxServer, FileServer, companyServer } from '@/server'
import { Http as v2Http } from '@/utils/v2Http'
import { Http } from '@/utils/Http'
import { runInAction } from 'mobx'
import { env, box } from '@/domain'
import { message } from 'antd'

const widgets = {
  download: null,
  upload: null,
  move: null,
  delete: null,
  refresh: null,
  testFile: null,
  newDir: null,
  history: null,
  edit: null,
  preview: null,
  rename: null,
  'cloud-app': null,
  'custom-column': null,
  record: null
}

const zoneMap = {
  'az-shanghai': '华东1',
  'az-wuxi': '华东2',
  'az-jinan': '华东3',
  'az-gansu': '西北1',
  'ys-box': '远算盒子'
}

// 默认列表静态数据
const zoneListStaticData = [
  {
    key: 'az-shanghai',
    name: '华东1-上海',
    status: '已打开',
    remark: '-',
    checked: true
  },
  // {
  //   key: '2',
  //   name: '华东2-无锡',
  //   status: '已关闭',
  //   remark: '-',
  //   checked: false
  // }
  {
    key: 'az-jinan',
    name: '华东3-济南',
    status: '已打开',
    remark: '-',
    checked: true
  }
]

export type Store = ReturnType<typeof useModel>

type WidgetType = keyof typeof widgets
type WidgetFactory = (store: Store) => React.ReactNode
type WidgetConfig = (WidgetType | [WidgetType, WidgetFactory])[]

export const defaultWidgets = Object.keys(widgets) as WidgetConfig

type DIR = {
  name: string
  path: string
}

export type ModelProps = {
  widgets: (props: WidgetConfig) => WidgetConfig
  server?: FileServer
  startDIRS?: DIR[]
}

export function useModel(props?: Partial<ModelProps>) {
  return useLocalStore(() => ({
    // HACK:新的server
    server: serverFactory(props?.server || newBoxServer),
    dirTree: new DirectoryTree(),
    dir: new BaseDirectory(),
    setDir(dir) {
      this.dir = dir
    },
    // area: '1', // 区域设置
    // setArea(value) {
    //   this.area = value
    // },
    zoneId: '',
    setZoneId(id: string, options = {}) {
      this.zoneId = id
      this.initDirTree()
    },
    zoneList: [],
    setZoneList(list) {
      this.zoneList = list
    },

    /**
     * 由于目前后端接口没有齐全，通过代理的方式进行跨域处理操作
     * @param url 分区对应的url
     * @returns url
     */
    getBaseUrl(url: string) {
      // switch (window.location.hostname) {
      //   case 'localhost': // 本地开发环境
      //     return url === 'az-jinan' ? window.location.origin : box.url
      //   case 'platform.intern.yuansuan.cn': // 开发开发环境
      //     return url === 'az-jinan' ? 'https://10.0.64.60' : box.url
      //   case 'euclid-test.yuansuan.cn':
      //     return url === 'az-jinan'
      //       ? 'https://shanhe-test.yuansuan.cn:21312'
      //       : box.url // 测试环境 https://shanhe-test.yuansuan.cn:21312
      //   case 'future-test.yuansuan.cloud':
      //     return url === 'az-jinan'
      //       ? 'https://shanhe-test.yuansuan.cn:21312'
      //       : box.url // 智算未来测试环境
      //   default:
      //     return url.includes('az-jinan')
      //       ? 'https://jinan-storage.yuansuan.cn:21443'
      //       : box.url // 线上环境 https://jinan-storage.yuansuan.cn:21443
      // }
      if (window.location.hostname === 'localhost') {
        // 本地开发环境济南进行代理
        return url === 'https://10.0.64.60' ? window.location.origin : url
      }
      return url // 其他环境直接返回这个地址
    },

    async refresh() {
        try {
          // 企业列表开关
          let selectData = []
          // 请求domain接口获取分区前缀
          const {
            data,
            success,
            message: errmsg
          } = await companyServer.getDomain(env.company_id)
          if (!success) {
            return message.error(errmsg)
          }
          const OMS_zone_list = env?.zone_list?.length
            ? JSON.parse(env.zone_list)
            : []
          // 和oms的开关切换进行过滤筛选
          // 如果OMS_zone_list没有数据则证明没有操作过oms区域的开关控制，那么默认无锡和甘肃关闭, 反之则与OMS_zone_list列表进行兼容过滤
          const filterZoneList = !OMS_zone_list ? data.result.filter(item => item.zone !== 'az-wuxi' || item.zone !== 'az-gansu') :  data.result.filter(item => {
            // 判断有没有该区域
            const findResult = OMS_zone_list.find(
              value => value.key === item.zone
            ) // find没找到是undfined
            if (!findResult) {
              return true
            }
            // 如果有，判断checked是否为false
            return findResult.checked
          })
          // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
          filterZoneList.forEach(item => {
            selectData.push({
              name: item.zone,
              // key: result[item].includes('az-jinan') ? 'http://10.0.64.60:8899' : result[item], // 调试济南测试地址
              // key: this.getBaseUrl(item.key),
              key: this.getBaseUrl(item.storage_domains[0]),
              desc: zoneMap[item.zone]
            })
          })
          runInAction(() => {
            this.setZoneList(selectData)
            this.setZoneId(this.zoneList[0]?.key || '')
          })
        } catch (error) {
          console.log('error: ', error)
      }
    },
    dirLoading: false,
    setDirLoading(flag) {
      this.dirLoading = flag
    },
    history: new History(),
    nodeId: undefined,
    setNodeId(nodeId) {
      this.nodeId = nodeId
    },
    get currentNode() {
      const { dirTree, nodeId } = this
      return dirTree.filterFirstNode(item => item.id === nodeId)
    },
    selectedKeys: [],
    setSelectedKeys(keys) {
      this.selectedKeys = [...keys]
    },
    searchKey: undefined,
    setSearchKey(key) {
      this.searchKey = key
    },
    async initDirTree() {
      this.dirTree.setChildren(
        props?.startDIRS || [
          {
            name: 'Home',
            path: '/'
          }
        ]
      )

      const node = this.dirTree.children[0]
      this.setNodeId(node.id)
    },
    useRefresh: function useRefresh(): [() => Promise<void>, boolean] {
      const store = this

      const fetch = useCallback(
        async function fetch() {
          const { nodeId, dirTree, server, zoneId } = store
          if (!nodeId) {
            return
          }
          if (!zoneId) {
            // 没有区域domain清空table数据
            store.setDir([])
            return
          }

          const node = dirTree.filterFirstNode(item => item.id === nodeId)
          try {
            store.setDirLoading(true)
            const dir = await server.fetch(node.path, { zoneId }) // 添加区域设置

            // fix race condition
            if (nodeId !== store.nodeId) {
              store.setDirLoading(false)
              return
            }

            store.setDir(dir)
            zoneId &&
              (await server.sync(
                dirTree.filterFirstNode(item => item.path === dir.path),
                { zoneId } // 添加区域设置
              ))
            store.setSelectedKeys([])
            store.setSearchKey('')
          } finally {
            store.setDirLoading(false)
          }
        },
        [store.nodeId, store.zoneId] // 添加区域设置
      )

      return [fetch, store.dirLoading]
    },
    widgets: props?.widgets ? props.widgets(defaultWidgets) : defaultWidgets,
    getWidget(key: WidgetType) {
      const widget = this.widgets.find(item => {
        if ((Array.isArray(item) && item[0] === key) || item === key) {
          return true
        }
        return false
      })

      if (!widget) {
        return <></>
      }

      if (Array.isArray(widget)) {
        return widget[1](this)
      }

      return null
    },
    isWidgetVisible(key): boolean {
      const widget = this.widgets.find(item => {
        if ((Array.isArray(item) && item[0] === key) || item === key) {
          return true
        }
        return false
      })

      return !!widget
    }
  }))
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
