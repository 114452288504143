/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import qs from 'qs'
import { Button, Modal } from '@ys/components'
import { Divider, message } from 'antd'
import { box, account, env } from '@/domain'
import { Dropdown } from '@/components/Dropdown'
import { jobServer } from '@/server'
import { useStore } from '../store'
import { history } from '@/utils'
import { showDownloader } from '../showDownloader'
import { applyHelp } from './applyHelp'
import { showHelpHistory } from './showHelpHistory'
import { buryPoint } from '@/utils'
import { useTranslation } from 'react-i18next'
import {
  getContinuousRedeployInfo,
  getRedeployInfo
} from '@/domain/JobBuilder/JobBuilder'

const StyledLayout = styled.div``

type Props = {
  id: string
  name: string
  cancelable: boolean
  downloadable: boolean
  deleteable: boolean
  showContinuousRedeploy: boolean
  display_back_state: number
  troubleshoot_id: number
}

export const Operator = observer(function Operator({
  id,
  name,
  cancelable,
  downloadable,
  deleteable,
  showContinuousRedeploy,
  display_back_state,
  troubleshoot_id
}: Props) {
  const { t } = useTranslation()
  const store = useStore()
  const { model } = store

  async function cancelJob() {
    await Modal.showConfirm({
      title: t('ConfirmToCancel'),
      content: `${t('CancelTheOperation')}【${name}】，${t('CancelTheOperation')}？`
    })
    buryPoint({
      category: t('JobManagement'),
      action: t('CancelTheOperation')
    })
    await jobServer.cancel([id])
    store.refresh()
    message.success(t('CancelTheSuccess'))
  }

  async function goToRedeployPage(redeployId) {
    buryPoint({
      category: t('JobManagement'),
      action: t('ToResubmit')
    })
    await getRedeployInfo({
      id: redeployId,
      type: 'job',
      clean: true
    })

    history.push(
      `/job-creator?${qs.stringify({
        id: redeployId,
        type: 'job',
        mode: 'redeploy'
      })}`
    )
  }

  async function goToContinuousRedeployPage(id) {
    buryPoint({
      category: t('JobManagement'),
      action: t('RenewalIsSubmitted')
    })

    await getContinuousRedeployInfo({
      id,
      type: 'job',
      clean: false
    })

    history.push(
      `/job-creator?${qs.stringify({
        id: id,
        type: 'job',
        mode: 'continuous'
      })}`
    )
  }

  function downloadJob() {
    buryPoint({
      category: t('JobManagement'),
      action: t('DownloadedToTheLocal')
    })
    box.job.downloadJobs([
      {
        jobId: id,
        jobName: name
      }
    ])
  }

  async function downloadJobToCommon() {
    buryPoint({
      category: t('JobManagement'),
      action: t('DownloadTheFilesToMe')
    })
    const job = model.list.find(job => job.id === id)
    const resolvedJobs = await showDownloader([
      {
        id: job.id,
        name: job.name
      },
    ], t)
    if (Object.keys(resolvedJobs).length > 0) {
      await box.result.linkToCommon(resolvedJobs)
      message.success(t('TheDownloadIsComplete'))
      store.setSelectedKeys([])
    }
  }

  async function deleteJob() {
    buryPoint({
      category: t('JobManagement'),
      action: t('delete')
    })
    await Modal.showConfirm({
      title: t('ConfirmTheDeletion'),
      content: t('DeleteOperationCanProduceFileDeleteOperationAtTheSameTime,ToConfirm')
    })
    await jobServer.delete([id])
    store.refresh()
    message.success(t('DeleteTheSuccess'))
  }

  return (
    <StyledLayout>
      <Button
        style={{ padding: 0 }}
        disabled={!cancelable}
        onClick={() => cancelJob()}
        type='link'>
        {t('CancelTheOperation')}
      </Button>

      <Divider type='vertical' style={{ margin: 2 }} />

      <Button
        style={{ padding: 0 }}
        disabled={
          !env.canSubmitJob
            ? t('NoJobSubmission')
            : env.accountFrozen
            ? t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
            : false
        }
        onClick={() => goToRedeployPage(id)}
        type='link'>
        {t('ToResubmit')}
      </Button>

      <Divider type='vertical' style={{ margin: 2 }} />

      <Dropdown
        menuContentList={[
          showContinuousRedeploy && {
            children: (
              <Button
                disabled={
                  display_back_state !== 2 && t('FileNotFinishIsUnableToContinueToSubmitBack')
                }
                style={{
                  color: 'rgba(0,0,0,0.65)'
                }}
                onClick={() => goToContinuousRedeployPage(id)}
                type='link'>
                {t('RenewalIsSubmitted')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => downloadJob()}
                disabled={
                  env.accountHasDebt
                    ? t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
                    : !downloadable
                }
                type='link'>
                {t('DownloadedToTheLocal')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => downloadJobToCommon()}
                disabled={
                  env.accountHasDebt
                    ? t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
                    : !downloadable
                }
                type='link'>
                {t('DownloadTheFilesToMe')}
              </Button>
            )
          },
          {
            children: (
              <Button
                type='link'
                disabled={(() => {
                  let str = ''
                  if (display_back_state !== 2) {
                    str += t('CannotApplyForToHelpCompleteTheFileNotFeed-back')
                  }
                  if (troubleshoot_id !== 0) {
                    str += t('TheHomeworkHasBeenAppliedForTheHelp,PleaseSeeTheHelpDocument')
                  }
                  return str || false
                })()}
                style={{ color: 'rgba(0,0,0,0.65)' }}
                onClick={() => applyHelp(id)}>
                {t('ApplyForHelp')}
              </Button>
            )
          },
          {
            children: (
              <Button
                disabled={troubleshoot_id === 0 && t('NoRecordHasYetToApplyForHelp')}
                style={{
                  color: 'rgba(0,0,0,0.65)'
                }}
                onClick={() => {
                  history.push(
                    `/workorder-detail/${troubleshoot_id}`,
                    history.location.state
                  )
                }}
                type='link'>
                {t('HelpRecord')}
              </Button>
            )
          },
          {
            children: (
              <Button
                style={{ color: 'red' }}
                onClick={() => deleteJob()}
                disabled={!deleteable}
                type='link'>
                {t('delete')}
              </Button>
            )
          }
        ].filter(Boolean)}
      />
    </StyledLayout>
  )
})
