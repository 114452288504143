/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
// import { Modal } from 'antd'
// import './i18nextConf'
import './indexDesktop.css'

import ActMenu from './components/menu'
import {
  BandPane,
  CalnWid,
  DesktopApp,
  SidePane,
  StartMenu,
  WidPane
} from './components/start'
import { appList, vis, ZoneList, env } from '@/domain'
import { ListSessionRequest, SESSION_STATUS_ALL } from '@/domain/Vis'
import Taskbar from './components/taskbar'
import { Background } from './containers/background'
import { Uploader } from '@/components/DrawerUploader'

import { loadSettings } from './actions'
import * as Applications from './containers/applications'
import * as Drafts from './containers/applications/draft'
import Page500 from '@/pages/500'
import { RouterTogg } from '@/constant'
import {
  parseUrlParam,
  allApps,
  generateCalcApp,
  GenerateDesktopApps,
  generateCloudApp,
  HACKDESKTOPDATA
} from '@/utils'
import { currentUser } from '@/domain'
import { nextTick } from '@ys/utils'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div>
      <Page500 description={error.message} />
    </div>
  )
}

function App() {
  const zoneList = new ZoneList()
  const apps = useSelector(state => state.apps)
  const wall = useSelector(state => state.wallpaper)
  const [open, setOpen] = useState(env.company?.id !== '4MUhn9WyyQ1')
  const dispatch = useDispatch()
  const [] = useState(false)

  const afterMath = event => {
    let ess = [
      ['START', 'STARTHID'],
      ['BAND', 'BANDHIDE'],
      ['PANE', 'PANEHIDE'],
      ['WIDG', 'WIDGHIDE'],
      ['CALN', 'CALNHIDE'],
      ['MENU', 'MENUHIDE']
    ]

    let actionType = ''
    try {
      actionType = event.target.dataset.action || ''
    } catch (err) {}

    let actionType0 = getComputedStyle(event.target).getPropertyValue(
      '--prefix'
    )

    ess.forEach((item, i) => {
      if (!actionType.startsWith(item[0]) && !actionType0.startsWith(item[0])) {
        dispatch({
          type: item[1]
        })
      }
    })
  }

  window.oncontextmenu = e => {
    afterMath(e)
    e.preventDefault()
    // dispatch({ type: 'GARBAGE'});
    let data = {
      top: e.clientY,
      left: e.clientX
    }

    if (e.target.dataset.menu != null) {
      data.menu = e.target.dataset.menu
      data.attr = e.target.attributes
      data.dataset = e.target.dataset
      dispatch({
        type: 'MENUSHOW',
        payload: data
      })
    }
  }

  window.onclick = afterMath

  window.onload = e => {
    dispatch({ type: 'WALLBOOTED' })
  }

  useEffect(() => {
    if (!window.onstart) {
      loadSettings()
      window.onstart = setTimeout(() => {
        // console.log("prematurely loading ( ﾉ ﾟｰﾟ)ﾉ");
        dispatch({ type: 'WALLBOOTED' })
      }, 5000)
    }
  })

  useEffect(() => {
    // 调用app接口生成app应用
    let timer = null
    appList.fetch().then(async () => {
      try {
        const { data } = await zoneList.fetch()
        const collectFetchSoftWare = [],
          collectFetchSession = []
        data?.items?.forEach(item => {
          collectFetchSoftWare.push(vis.listAllSoftware(item?.name))
          collectFetchSession.push(
            vis.listSession({
              ...new ListSessionRequest({ status: SESSION_STATUS_ALL }),
              zone: item?.name,
              user_id: currentUser.id
            })
          )
        })
        const SOFTWARE = await Promise.all(collectFetchSoftWare)
        const SESSION = await Promise.all(collectFetchSession)
        const sessionHandle = SESSION?.map(item => item.items)
          ?.flat()
          ?.filter(item => item?.session?.status === 3)
        const { softWareType, CloudApp } = generateCloudApp(
          SOFTWARE?.flat(),
          sessionHandle
        )
        const { generateAppInfo, appType } = generateCalcApp(appList.list)
        // console.log('generateAppInfo: ', generateAppInfo)
        const appsAll = [
          ...allApps,
          ...(env.company?.id === '4MUhn9WyyQ1'
            ? HACKDESKTOPDATA.HACKDESKTOPAPP
            : []),
          ...generateAppInfo,
          ...CloudApp
        ]
        const allType = [
          ...appType,
          ...softWareType,
          ...(env.company?.id === '4MUhn9WyyQ1'
            ? HACKDESKTOPDATA.HACKDESKTOPAPPTYPE
            : [])
        ]
        dispatch({ type: 'apps', data: appsAll, payload: 'generateApp' })
        dispatch({
          type: 'desktop',
          data: GenerateDesktopApps(appsAll, allType),
          payload: 'generateApp'
        })
        timer = setInterval(async () => {
          const collectFetchSession = []
          data?.items?.forEach(item => {
            collectFetchSession.push(
              vis.listSession({
                ...new ListSessionRequest({ status: SESSION_STATUS_ALL }),
                zone: item?.name,
                user_id: currentUser.id
              })
            )
          })
          const SESSION = await Promise.all(collectFetchSession)
          const sessionHandle = SESSION?.map(item => item.items)
            ?.flat()
            ?.filter(item => item?.session?.status === 3)
          // 循环修改allApp
          sessionHandle.forEach(item => {
            const findIndex = appsAll.findIndex(
              v =>
                item?.session?.zone?.name === v?.zone &&
                v?.softwareId === item?.instance?.software?.id
            )
            if (
              findIndex > -1 &&
              !appsAll[findIndex].menu?.includes('cloudMenuOpen')
            ) {
              let url = item?.session?.stream_url.replace(/https?:/, 'https:')
              appsAll[findIndex].url = url
              // appsAll[findIndex].className = 'CloudAppWrap_open'
              // appsAll[findIndex].menu = softWareType?.length > 1 ? 'cloudMenuOpen' : 'cloudMenuOpenOne'
              appsAll[findIndex]['id'] = item?.session?.id
              if (item?.session?.remote_apps?.length > 0) {
                vis
                  .getRemoteAppUrl(
                    item?.session?.id,
                    item?.session?.remote_apps[0]?.remote_app_name
                  )
                  .then(data => {
                    appsAll[findIndex].url = data.replace(/https?:/, 'https:')
                    // dispatch({ type: 'UPDATE', payload: appsAll[findIndex] })
                    // dispatch({
                    //   type: 'desktop',
                    //   data: GenerateDesktopApps(appsAll, allType),
                    //   payload: 'generateApp'
                    // })
                  })
              }
              // else {
              //   dispatch({ type: 'UPDATE', payload: appsAll[findIndex] })
              //   // dispatch({
              //   //   type: 'desktop',
              //   //   data: GenerateDesktopApps(appsAll, allType),
              //   //   payload: 'generateApp'
              //   // })
              // }
              vis.pollFetchRequest(item.roomId).then(data => {
                if (data.ready) {
                  appsAll[findIndex].className = 'CloudAppWrap_open'
                  appsAll[findIndex].menu =
                    softWareType?.length > 1
                      ? 'cloudMenuOpen'
                      : 'cloudMenuOpenOne'
                  item['realStatus'] = 'READIED'
                  dispatch({ type: 'UPDATE', payload: appsAll[findIndex] })
                }
              })
            }
          })
        }, 5000)
      } catch (error) {
        console.log('error: ', error)
      }

      nextTick(() => {
        // 获取刷新之后路由pathname，进行togg
        const routePath = window.location.pathname
          .split('/')
          .filter(item => Boolean(item))
        if (routePath[0]) {
          if (routePath[0] === 'new-job-creator') {
            const params = parseUrlParam(window.location.search)
            setTimeout(() => {
              dispatch({
                type: params?.action || 'CALCUAPP',
                payload: 'togg'
              })
            }, 100)
          } else if (routePath[0] === 'vis-session') {
            const params = parseUrlParam(window.location.search)
            setTimeout(() => {
              dispatch({
                type: params?.actionApp || '3dcloudApp',
                payload: 'togg'
              })
            }, 100)
          } else {
            const taskType = RouterTogg[routePath[0]]
            dispatch({
              type: taskType,
              payload: 'togg'
            })
          }
        }
      })
    })
    return () => {
      clearInterval(timer)
    }
  }, [])

  // useEffect(() => {}, [])
  const renderDesktop = () => {
    return (
      <div className='desktop' data-menu='desk'>
        <Uploader />
        <DesktopApp />
        {Object.keys(Applications).map((key, idx) => {
          let WinApp = Applications[key]
          return <WinApp key={idx} />
        })}
        {Object.keys(apps)
          .filter(x => x != 'hz')
          .map(key => apps[key])
          .map((app, i) => {
            if (app.pwa) {
              let WinApp = Drafts[app.data.type]
              return <WinApp key={i} icon={app.icon} {...app.data} />
            }
          })}
        <StartMenu />
        <BandPane />
        <SidePane />
        <WidPane />
        <CalnWid />
      </div>
    )
  }
  return (
    <div className='App'>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* 进入加载页面和登录页面 */}
        {/* {!wall.booted ? <BootScreen dir={wall.dir} /> : null} */}
        {/* {wall.locked ? <LockScreen dir={wall.dir} /> : null} */}
        <div className='appwrap'>
          <Background />
          {/* {(env.company?.id === '4MUhn9WyyQ1' || process.env.NODE_ENV === 'development') && renderDesktop()} */}
          {renderDesktop()}
          {/* <Modal
            title='温馨提示'
            visible={open}
            // visible={false}
            okText='好的'
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={400}>
            <p>当前CSP平台仅对CSP内部测试企业开放！</p>
            <p>体验请联系耿兵洋 Noah！</p>
          </Modal> */}
          <Taskbar />
          <ActMenu />
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default App
