/* Copyright (C) 2016-present, Yuansuan.cn */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { AppList } from './AppList'
import { Tabs } from 'antd'
import { useStore } from './store'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content {
    padding-top: 10px;
    background-color: white;
  }
`

const { TabPane } = Tabs

export const AppListTabs = observer(function AppListTabs() {
  const store = useStore()
  const { t } = useTranslation()
  // t('AssignmentsSubmitted,BasedOnTheSoftware') is_trail t('AttributeAutomaticallySwitchTheTab')
  useEffect(() => {
    const is_trial = store.data.currentApp?.is_trial
    // t('WhenANumberOfSoftwareTo0')，t('SoTheCurrentAppIsEmpty')，t('AtThisPointIsNotAccordingToTheSoftware') is_trial t('AttributeAutomaticallySwitchTheTab')
    if (store.data.currentApp === undefined) return

    if (is_trial && !store.is_trial) {
      store.setTabKey('trial')
    } else if (!is_trial && store.is_trial) {
      store.setTabKey('formal')
    }
  }, [store.currentAppId])

  return (
    <StyledLayout>
      <Tabs
        type='card'
        activeKey={store.tabKey}
        onChange={key => {
          store.setTabKey(key)
          store.data.currentApp =
            key === 'trial'
              ? store.apps.filter(item => item.is_trial)[0]
              : store.apps.filter(item => !item.is_trial)[0]
        }}>
        <TabPane key='formal' tab={t('AFormalSoftware')}></TabPane>
        <TabPane key='trial' tab={t('TrialSoftware')}></TabPane>
      </Tabs>
      {store.tabKey === 'formal' && <AppList is_trial={false} />}
      {store.tabKey === 'trial' && <AppList is_trial={true} />}
    </StyledLayout>
  )
})
