/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Dropdown, Menu, message } from 'antd'
import { Button, Modal } from '@ys/components'
import { useStore } from './store'
import { box, account, env } from '@/domain'
import { showDownloader } from './showDownloader'
import { boxServer, jobServer } from '@/server'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    > * {
      margin-right: 10px;
    }
  }
`

export const Toolbar = observer(function Toolbar() {
  const { t } = useTranslation()
  const store = useStore()
  const { model } = store
  const state = useLocalStore(() => ({
    get downloadDisabled() {
      if (env.accountHasDebt) {
        return t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
      }

      if (!store.selectedKeys.length) return t('PleaseChooseToDownloadTheHomework')
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.downloadable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('homework')} ${invalidJobNames.join(', ')} ${t('HasNotReturnedToComplete,CannotDownload')}`
      }
      return false
    },
    get cancelDisabled() {
      if (!store.selectedKeys.length) return t('PleaseChooseToCancelTheHomework')
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.cancelable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('homework')} ${invalidJobNames.join(', ')} ${t('CannotCancel')}`
      }
      return false
    },
    get deleteDisabled() {
      if (!store.selectedKeys.length) return t('PleaseChooseToDeleteOperation')
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.deleteable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('homework')} ${invalidJobNames.join(', ')} ${t('NotOverYet,CannotBeDeleted')}`
      }
      return false
    }
  }))

  async function downloadJobs() {
    const jobs = store.selectedKeys
      .map(key => model.list.find(item => item.id === key))
      .map(item => ({
        jobId: item.id,
        jobName: item.name
      }))
    box.job.downloadJobs(jobs)
    store.setSelectedKeys([])
  }

  async function downloadJobsToCommon() {
    const selectedJobs = model.list
      .filter(item => store.selectedKeys.includes(item.id))
      .map(item => ({
        id: item.id,
        name: item.name
      }))
    const resolvedJobs = await showDownloader(selectedJobs, t)
    if (Object.keys(resolvedJobs).length > 0) {
      await box.result.linkToCommon(resolvedJobs)
      message.success(t('TheDownloadIsComplete'))
      store.setSelectedKeys([])
    }
  }

  async function cancelJobs() {
    await Modal.showConfirm({
      title: t('ConfirmToCancel'),
      content: t('IfConfirmUncheckTheHomework')
    })
    await jobServer.cancel(store.selectedKeys)
    await store.refresh()
    store.setSelectedKeys([])
    message.success(t('CancelTheSuccess'))
  }

  async function deleteJobs() {
    await Modal.showConfirm({
      title: t('ConfirmTheDeletion'),
      content: t('DeleteOperationCanProduceFileDeleteOperationAtTheSameTime,ToConfirm')
    })
    await jobServer.delete(store.selectedKeys)
    const paths = store.selectedKeys
      .map(key => model.list.find(item => item.id === key))
      .map(item => item.id)
    await boxServer.delete({
      bucket: 'result',
      paths
    })

    await store.refresh()
    //id && (await jobSetList.fetch())
    store.setSelectedKeys([])
    message.success(t('DeleteTheSuccess'))
  }

  return (
    <StyledLayout>
      <div className='left'>
        {!state.downloadDisabled ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={downloadJobs}>t('DownloadedToTheLocal')</Menu.Item>
                <Menu.Item onClick={downloadJobsToCommon}>
                  t('DownloadTheFilesToMe')
                </Menu.Item>
              </Menu>
            }
            placement='bottomLeft'>
            <Button type='primary'>t('download')</Button>
          </Dropdown>
        ) : (
          <Button type='primary' disabled={state.downloadDisabled}>
            t('download')
          </Button>
        )}
        <Button onClick={cancelJobs} disabled={state.cancelDisabled}>
          t('cancel')
        </Button>
        <Button onClick={deleteJobs} disabled={state.deleteDisabled}>
          t('delete')
        </Button>
      </div>
    </StyledLayout>
  )
})
