/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { newBoxServer } from '@/server'
import { observable, runInAction } from 'mobx'
import { JobFileTypeEnum } from '@/constant'
import { Http } from '@/utils'

type File = {
  is_dir: boolean
  mod_time: number
  size: number
  name: string
  type: JobFileTypeEnum
}

class BaseJobFileList {
  @observable list: File[] = []
}

export class NewJobFileList extends BaseJobFileList {
  constructor(props?: Partial<BaseJobFileList>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  update = (props: Partial<BaseJobFileList>) => {
    Object.assign(this, props)
  }

  async fetch({
    useRemote,
    download_task_id,
    id,
    project_id,
    url
  }: {
    id: string
    useRemote: boolean
    download_task_id: string
    project_id?: string
    url?: string
  }) {
    const { data } = await newBoxServer.list({
      project_id,
      url,
      ...(useRemote
        ? {
            sync_id: download_task_id
          }
        : {
            path: id,
            bucket: 'result'
          })
    })
    let files = data.infos
    const { data: types } = await Http.post(`/job/${id}/classifier`, {
      files: files.map(file => file.name),
      project_id
    })
    files = files
      .map((file, index) => ({ ...file, type: types[index] }))
      .filter(file => !file.is_dir)

    runInAction(() => {
      this.update({
        list: files
      })
    })

    return files
  }

  getName(name: string) {
    const file = this.list.find(item => item.name === name)
    return file
  }
}
