/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Tabs, Spin } from 'antd'
import { MonitorChart } from './MonitorChart'
import { Residual } from './Residual'
import { CloudGraphic } from './CloudGraphic'
import { ConnectionFactory } from '@/utils/WebSocket'
import { Http as JobLogHttp } from '@/utils/JobLogHttp'
import { useTranslation } from 'react-i18next'
const { TabPane } = Tabs

type Props = {
  id: string // jobId
  residualVisible: boolean
  monitorVisible: boolean
  cloudGraphicVisible?: boolean
  jobState: number
  projectId: string
  userId: string
  jobRuntimeId: string
}

let preChartsData = [] // persist history data

const host = location.host // 'euclid-test.yuansuan.cn'
const wsUrl = `wss://${host}/ws/monitor_chart`
const factory = new ConnectionFactory(wsUrl)

export const Monitors = observer(
  ({
    id,
    residualVisible,
    monitorVisible,
    cloudGraphicVisible,
    jobState,
    projectId,
    userId,
    jobRuntimeId
  }: Props) => {
    const [chartsData, setChartsData] = useState([])
    const [graphicData, setGraphicData] = useState([])
    const { t } = useTranslation()
    let initKey = 'M0'

    if (cloudGraphicVisible) {
      initKey = 'C0'
    } else if (residualVisible) {
      initKey = 'Residual'
    } else if (monitorVisible) {
      initKey = 'M0'
    }

    const [activeKey, setActiveKey] = useState(initKey)

    const [errGraphic, setErrGraphic] = useState(null)
    const [err, setErr] = useState(null)

    const setData = chartsData => {
      chartsData.sort((a, b) => a.key - b.key)
      preChartsData = [...chartsData]
      setChartsData(chartsData)
    }

    useEffect(() => {
      if (!monitorVisible) return undefined

      const conn = factory.create(`job_id=${id}`)
      conn.onReceive(data => {
        const { charts, status, code, msg } = JSON.parse(data)

        if (code) setErr(msg)
        // status：2 t('TheFinalDataResponse,NeedToDisconnect')
        if (status === 2) {
          setChartsData(charts)
          conn.close()
        } else if (status === 0) {
          // status：0 t('ResponseForTheFirstTime')
          setData(chartsData)
        } else if (status === 1) {
          // status：1 t('InTheMiddleOfTheResponseData')
          // t('TheDataNeedToAccumulate')
          const addData = charts.reduce((pre, curr) => {
            pre[curr.key] = curr.items
            return pre
          }, {})

          const chartsData =
            preChartsData.length !== 0
              ? preChartsData.map(d => {
                  if (addData[d.key]) d.items = [...d.items, ...addData[d.key]]
                  return d
                })
              : charts

          setData(chartsData)
        }
      })

      conn.onClose(() => {
        console.log(t('WsDisconnects'))
      })

      return () => {
        conn?.close()
        preChartsData = [] // clear data
        console.log('close ws conn when destory Modal')
      }
    }, [id])

    const refreshCloudGraphicData = async () => {
      let res = null
      try {
        res = await JobLogHttp.get('/snapshot/list', {
          params: {
            project_id: projectId,
            user_id: userId,
            job_runtime_id: jobRuntimeId
          }
        })

        if (res.success) {
          const keys = Object.keys(res.data)
          setGraphicData(
            keys.map(k => ({
              key: k,
              items: res.data[k].map(
                path =>
                  `/snapshot/img?project_id=${projectId}&user_id=${userId}&job_runtime_id=${jobRuntimeId}&path=${path}`
              )
            }))
          )
        } else {
          setErrGraphic(true)
        }
      } catch (e) {
        setErrGraphic(true)
      } finally {
        setErrGraphic(true)
      }
      return res
    }

    useEffect(() => {
      if (!cloudGraphicVisible) return undefined

      let intervalId = null

      ;(async () => {
        const res = await refreshCloudGraphicData()

        if (res.success) {
          intervalId = setInterval(async () => {
            if (jobState === 2 || jobState === 3 || jobState === 4) {
              intervalId && clearInterval(intervalId)
              return
            }
            await refreshCloudGraphicData()
          }, 5000)
        }
      })()

      return () => {
        intervalId && clearInterval(intervalId)
      }
    }, [id])

    const renderMonitorChartTab = chartsData => {
      return chartsData.length !== 0 ? (
        <>
          {
            <TabPane tab={t('MonitoringItems')} key={'M0'}>
              <MonitorChart data={chartsData} />
            </TabPane>
          }
        </>
      ) : (
        <>
          <TabPane tab={t('MonitoringItems')} key={'M0'}>
            {err ? (
              t('UnableToGetMonitoringData,PleaseCheckTheRelevantConfigurationFiles')
            ) : (
              <>
                t('MonitoringDataLoading,PleaseWaitPatiently') <Spin size='small' />
              </>
            )}
          </TabPane>
        </>
      )
    }

    const renderCloudGraphicTab = graphicData => {
      return graphicData.length !== 0 ? (
        <>
          {graphicData.map((data, index) => (
            <TabPane tab={`${t('CloudPictureItem')}: ${data.key}`} key={'C' + index}>
              <CloudGraphic key={data.key} data={data.items} />
            </TabPane>
          ))}
        </>
      ) : (
        <>
          <TabPane tab={t('CloudPictureItem')} key={'C0'}>
            {errGraphic ? (
              t('UnableToGetCloudPicture,PleaseCheckTheRelevantConfigurationFiles')
            ) : (
              <>
                {t('CloudImageLoading,PleaseWaitPatiently')} <Spin size='small' />
              </>
            )}
          </TabPane>
        </>
      )
    }

    return (
      <Tabs
        activeKey={activeKey}
        onChange={activeKey => setActiveKey(activeKey)}>
        {monitorVisible && renderMonitorChartTab(chartsData)}
        {residualVisible && (
          <TabPane tab={t('ResidualFigure')} key={'Residual'}>
            <Residual id={id} />
          </TabPane>
        )}
        {cloudGraphicVisible && renderCloudGraphicTab(graphicData)}
      </Tabs>
    )
  }
)
