/* Copyright (C) 2016-present, Yuansuan.cn */

import moment, { Moment } from 'moment'

export const timeStepMapper = {
  day: 1,
  month: 2
}

export const yesterday = moment().subtract(1, 'day')
export const dayBeforeYesterday = moment().subtract(2, 'days')
export const lastWeek = moment().subtract(1, 'days').subtract(1, 'week')
export const lastMonth = moment().subtract(1, 'day').subtract(1, 'month')
export const lastYear = moment().subtract(1, 'day').subtract(12, 'month')
export const defaultTimeRange = [lastMonth, yesterday] as [Moment, Moment]
export const defaultTimeStep = timeStepMapper.day
