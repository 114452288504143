/* Copyright (C) 2016-present, Yuansuan.cn */
import { createStore } from '@ys/utils/hooks'
import { useLocalStore } from 'mobx-react-lite'
import { vis } from '@/domain'
import { runInAction } from 'mobx'
import { ZoneList } from '@/domain'
import { parseUrlParam } from '@/utils'
const zoneList = new ZoneList()

export function useModel() {
  const params: any = parseUrlParam(window.location.search)
  return useLocalStore(() => ({
    vis,

    zoneId: '',
    setZoneId(id: string) {
      zoneList.currentZoneName = id
      this.zoneId = id
    },
    zoneList: [],
    setZoneList(list) {
      this.zoneList = list
    },

    async refresh(zone) {
      const { data } = await zoneList.fetch()
      runInAction(() => {
        this.setZoneList(data?.items)
        if (zone) {
          this.setZoneId(zone)
        } else {
          this.setZoneId(zoneList.currentZoneName)
        }
      })
    }
  }))
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
