/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { SearchSelect } from '@ys/components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { buryPoint } from '@/utils'
import { useStore } from '../store'
import { appList } from '@/domain'

const Wrapper = styled.div`
  padding: 4px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  height: 120px;
  width: 300px;
  cursor: pointer;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;

  &.selected {
    border-color: ${({ theme }) => theme.linkColor};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

    &::before,
    &::after {
      display: block;
    }
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 30px solid ${({ theme }) => theme.linkColor};
    border-right: 30px solid transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
  }

  &::after {
    content: '\\2713';
    color: white;
    position: absolute;
    top: -2px;
    left: 4px;
    display: none;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  }

  > .icon {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColorBase};
    padding: 4px 0;

    img {
      width: 120px;
      height: 30px;
      box-sizing: border-box;
    }
  }

  > .name {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 4px 6px;
    margin-top: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }

  > .ant-select {
    width: 100%;
  }

  .black_cover {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    cursor: auto;
  }
`

interface ISoftwareProps {
  name: string
  icon: string
  type: string
  versions: [string, string][]
  action?: any
}

export const Software = observer(function Software(props: ISoftwareProps) {
  const store = useStore()
  const ref = useRef(null)
  const state = useLocalStore(() => ({
    get selected() {
      return props.versions.map(([key]) => key).includes(store.currentAppId)
    }
  }))

  function selectApp(id) {
    buryPoint({
      category: '作业提交',
      action: '软件选择'
    })
    store.updateData({
      currentApp: appList.list.find(item => item.id === id)
    })
  }

  useEffect(() => {
    if (props.action === props.type) {
      if (props.versions.length === 1) {
        selectApp(props.versions[0][0])
      } else if (ref.current) {
        // expand select options
        const clickEvent = document.createEvent('MouseEvents')
        clickEvent.initEvent('mousedown', true, true)
        const select: any = ReactDOM.findDOMNode(ref.current)
        select.querySelector('.ant-select-selector').dispatchEvent(clickEvent)
        selectApp(props.versions[0][0])
      }
    }
  }, [props.action])

  return (
    <Wrapper
      className={`app_selector_${props.type} ${
        state.selected ? 'selected' : ''
      }`}
      onClick={() => {
        // if there is only one version, select it on click
        if (props.versions.length === 1) {
          selectApp(props.versions[0][0])
        } else if (ref.current) {
          // expand select options
          const clickEvent = document.createEvent('MouseEvents')
          clickEvent.initEvent('mousedown', true, true)
          const select: any = ReactDOM.findDOMNode(ref.current)
          select.querySelector('.ant-select-selector').dispatchEvent(clickEvent)
        }
      }}>
      <div className='icon'>
        <img src={props.icon} />
      </div>
      <div className='name'>{props.name}</div>
      <SearchSelect
        ref={ref}
        onClick={e => e.stopPropagation()}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        size='middle'
        options={props.versions.map(([key, name]) => ({
          key,
          name
        }))}
        {...(state.selected && {
          value: store.data.currentApp.version
        })}
        placeholder='请选择版本'
        onChange={selectApp}
      />
    </Wrapper>
  )
})
