/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { ZoneWrapper } from '../style'
import { Select } from 'antd'
import { useStore } from './store'
import { useTranslation } from 'react-i18next'

export const ZoneToolbar = observer(function ZoneToolbar() {
  const store = useStore()
  const { t } = useTranslation()
  return (
    <ZoneWrapper>
      <div className='tip'>{t('area')}：</div>
      <Select
        style={{ width: 224 }}
        value={store.zoneId}
        onChange={v => store.setZoneId(v)}>
        {store.zoneList.map(item => (
          <Select.Option key={item.name} value={item.name}>
            {item.desc}
          </Select.Option>
        ))}
      </Select>
    </ZoneWrapper>
  )
})
