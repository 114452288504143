/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { Modal, Table, Button } from '@ys/components'
import styled from 'styled-components'
import { message, Popconfirm } from 'antd'
import { Job } from '@/domain/JobList/Job'
import { formatByte, formatUnixTime } from '@ys/utils'
import { observer, useLocalStore } from 'mobx-react-lite'
import { JobFileList } from '@/domain/JobList/JobFileList'
import { RichTextEditor } from '@/components'
import { JobFileTypeEnum } from '@/constant'
import { boxServer, jobServer } from '@/server'
import { runInAction } from 'mobx'
import { buryPoint } from '@/utils'
import { box, env, currentUser } from '@/domain'
import { TroubleShootWorkOrderListService } from '@/server'

const StyledLayout = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding: 20px 20px 0;
    overflow: auto;
    textarea {
      resize: none;
    }
    section {
      margin-bottom: 20px;
      h2 {
        font-size: 14px;
        color: #151515;
        line-height: 20px;
        &::after {
          content: '：';
        }
        &.required::before {
          content: '*';
          color: red;
        }
      }
    }
  }

  > .footer {
    flex: 0 0 auto;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`

interface Props {
  onOk: () => void
  onCancel: () => void
  jobID: string
}

const ApplyForm = observer(({ onOk, onCancel, jobID }: Props) => {
  const [selectedLogFilenames, setSelectedLogFilenames] = React.useState([])
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const state = useLocalStore(() => ({
    job: new Job(),
    logs: [],
    jobFile: null,
    async init() {
      const { data } = await jobServer.get(jobID)
      runInAction(() => {
        this.job.update(data)
      })
      const jobFile = new JobFileList()
      this.jobFile = jobFile
      await jobFile?.fetch({
        id: this.job.id,
        app_id: this.job.app_id,
        download_task_id: this.job?.runtime?.download_task_id,
        useRemote: this.job.useRemote
      })
      this.logs = jobFile?.list?.filter(
        file => file.type === JobFileTypeEnum.log
      )
    }
  }))

  const handleEditorChange = editorState => {
    if (!editorState) {
      return
    }
    const htmlContent = editorState.toHTML()
    setMessage(htmlContent)
  }

  const submit = async () => {
    setLoading(true)

    try {
      let url = null

      if (selectedLogFilenames.length > 0) {
        const params = state.job.useRemote
          ? {
              paths: selectedLogFilenames,
              sync_id: state.job.runtime?.download_task_id
            }
          : {
              base: state.job.id,
              paths: selectedLogFilenames.map(
                filename => `${state.job.id}/${filename}`
              ),
              bucket: 'result'
            }
        params['boxUrl'] = box.rawUrl
        url = await boxServer.getFileUrl(params)
      }

      await TroubleShootWorkOrderListService.createTroubleShoot({
        job_id: jobID,
        url,
        job_name: state.job.name,
        product_id: env.productId,
        company_id: env.company?.id,
        submitter_user_id: currentUser.id,
        submitter_user_name:
          currentUser.real_name || currentUser.display_user_name,
        submitter_user_phone: currentUser.phone,
        msg: message,
        logs: state.jobFile.list.filter(
          file =>
            selectedLogFilenames.findIndex(
              filename => filename === file.name
            ) !== -1
        )
      })
    } finally {
      setLoading(false)
      RichTextEditor.createEditorState('')
    }

    onOk()
  }

  React.useEffect(() => {
    state.init()
  }, [jobID])

  return (
    <StyledLayout>
      <div className='content'>
        <section>
          <h2 className='required'>问题描述</h2>
          <RichTextEditor
            contentStyle={{
              height: 210,
              boxShadow: 'inset 0 1px 3px rgb(30, 128, 255)'
            }}
            value={message}
            placeholder='请输入您遇到的问题，支持系统剪切板的图片(比如微信截图)粘贴'
            onChange={handleEditorChange}
          />
        </section>
        <section>
          <h2 className='required'>日志文件选择</h2>
          <Table
            props={{
              data: state.logs,
              rowKey: 'name',
              height: 400
            }}
            rowSelection={{
              selectedKeys: selectedLogFilenames,
              onChange: setSelectedLogFilenames,
              props: {
                fixed: true
              }
            }}
            columns={[
              {
                header: '名称',
                props: {
                  flexGrow: 3
                },
                dataKey: 'name'
              },
              {
                header: '创建时间',
                props: {
                  flexGrow: 2
                },
                dataKey: 'lastModified',
                cell: {
                  render: ({ rowData }) => (
                    <div>{formatUnixTime(rowData.mod_time)}</div>
                  )
                }
              },
              {
                header: '大小',
                props: {
                  flexGrow: 1
                },
                dataKey: 'size',
                cell: {
                  render: ({ rowData }) => <div>{formatByte(rowData.size)}</div>
                }
              }
            ]}
          />
        </section>
      </div>
      <div className='footer'>
        <Modal.Footer
          onCancel={onCancel}
          OkButton={
            <Popconfirm
              disabled={selectedLogFilenames.length === 0}
              trigger={['click']}
              icon={null}
              title={() => (
                <div
                  style={{
                    color: '#666',
                    marginLeft: -18,
                    width: 220,
                    fontSize: 14,
                    textAlign: 'justify'
                  }}>
                  点击确定后，远算工作人员可查看到您选择的日志信息，问题解决后，该日志信息将会自动删除。
                </div>
              )}
              onConfirm={submit}
              cancelButtonProps={{ type: 'link', style: { color: '#999' } }}
              okButtonProps={{ type: 'link' }}>
              <Button
                type='primary'
                disabled={(() => {
                  let str = ''
                  if (message?.length < 9) {
                    str += '问题描述需要至少2个字、'
                  }
                  if (selectedLogFilenames.length === 0) {
                    str += '请选择至少一个日志文件、'
                  }
                  return str.replace(/、$/, '') || loading
                })()}
                loading={loading}>
                确定
              </Button>
            </Popconfirm>
          }
          CancelButton={
            <Button disabled={loading} onClick={onCancel}>
              取消
            </Button>
          }
        />
      </div>
    </StyledLayout>
  )
})

export const applyHelp = async jobID => {
  buryPoint({
    category: '作业管理',
    action: '申请帮助'
  })
  await Modal.show({
    title: '申请帮助',
    footer: null,
    content: ({ onOk, onCancel }) => (
      <ApplyForm jobID={jobID} onOk={onOk} onCancel={onCancel} />
    ),
    width: 600,
    bodyStyle: {
      padding: 0
    }
  })
  message.success('申请帮助成功')
}
