/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { createStore } from '@ys/utils/hooks'
import { message } from 'antd'
import { useLocalStore } from 'mobx-react-lite'
import { JobList } from '@/domain/JobList'
import { box, env } from '@/domain'
import { jobServer, companyServer } from '@/server'
import { ListParams } from '@/server/jobServer'
import { runInAction } from 'mobx'
import { pageStateStore } from '@/utils'

const zoneMap = {
  'az-shanghai': '华东1',
  'az-wuxi': '华东2',
  'az-jinan': '华东3',
  'az-gansu': '西北1',
  'ys-box': '远算盒子'
}

export const initialQuery = {
  user_filters: [],
  app_filters: [],
  state_filters: [],
  core_filters: [],
  download_filters: [],
  jobset_filters: [],
  fuzzy_key: '',
  start_seconds: '',
  end_seconds: '',
  job_id: ''
}

export function useModel() {
  return useLocalStore(() => {
    // restore state from pageStateStore
    const historyState = pageStateStore.getByPath<{
      query: typeof initialQuery
      pageIndex: number
      pageSize: number
    }>()
    const query = historyState?.query || initialQuery

    return {
      model: new JobList(),
      loading: false,
      setLoading(flag) {
        this.loading = flag
      },
      selectedKeys: [],
      setSelectedKeys(keys) {
        this.selectedKeys = keys
      },
      query,
      setQuery(query) {
        this.query = query
      },
      pageIndex: historyState?.pageIndex || 1,
      setPageIndex(index) {
        this.pageIndex = index
      },
      pageSize: historyState?.pageSize || 10,
      setPageSize(size) {
        this.pageSize = size
      },
      order: '',
      setOrder(order) {
        this.order = order
      },
      zoneId: '',
      setZoneId(id: string, option = { name: '' }) {
        option?.name && this.setScId(option?.name)
        option?.name && this.setZoneKey(option?.name)
        this.zoneId = id
      },
      zoneKey: '', // 分区对的key，az-shanghai az-jinan ...
      setZoneKey(key: string) {
        this.zoneKey = key
      },
      zoneList: [],
      setZoneList(list) {
        this.zoneList = list
      },
      scId: '',
      setScId(id: string) {
        // 根据zone找到对应的scid
        // const findResult = this.SCList.find(item => item.zone === id)
        this.scId = id
        this.setPageIndex(1)
        this.setPageSize(10)
        this.refresh()
      },
      SCList: [],
      setSCList(list) {
        this.SCList = list
      },
      domainList: [],
      setDoaminList(list) {
        this.domainList = list
      },
      downloadFlag: false,
      setDownloadFlag(value) {
        this.downloadFlag = value
      },
      selectDataZone: '',
      setSelectDataZone(value) {
        this.selectDataZone = value?.[0]
      },
      async fetch(params: ListParams) {
        const { data } = await jobServer.list(params)

        runInAction(() => {
          this.model.update({
            list: data.jobs,
            page_ctx: data.page_ctx
          })
        })
      },
      /**
       * 由于目前后端接口没有齐全，通过代理的方式进行跨域处理操作
       * @param url 分区对应的url
       * @returns url
       */
      getBaseUrl(url: string) {
        // switch (window.location.hostname) {
        //   case 'localhost': // 本地开发环境
        //     return url === 'az-jinan' ? window.location.origin : box.url
        //   case 'platform.intern.yuansuan.cn': // 开发开发环境
        //     return url === 'az-jinan' ? 'https://10.0.64.60' : box.url
        //   case 'euclid-test.yuansuan.cn':
        //     return url === 'az-jinan'
        //       ? 'https://shanhe-test.yuansuan.cn:21312'
        //       : box.url // 测试环境 https://shanhe-test.yuansuan.cn:21312
        //   case 'future-test.yuansuan.cloud':
        //     return url === 'az-jinan'
        //       ? 'https://shanhe-test.yuansuan.cn:21312'
        //       : box.url // 智算未来测试环境
        //   default:
        //     return url.includes('az-jinan')
        //       ? 'https://jinan-storage.yuansuan.cn:21443'
        //       : box.url // 线上环境 https://jinan-storage.yuansuan.cn:21443
        // }
        if (window.location.hostname === 'localhost') {
          // 本地开发环境济南进行代理
          return url === 'https://10.0.64.60' ? window.location.origin : url
        }
        return url // 其他环境直接返回这个地址
      },
      async refresh() {
        try {
          this.setLoading(true)
          this.scId &&
            (await this.fetch({
              ...this.query,
              order_by: this.order,
              page_index: this.pageIndex,
              page_size: this.pageSize
              // sc_zone: this.zoneKey,
              //  隐藏超算选项
              // sc_id: this.scId // 根据超算id过滤数据
              // sc_filters: [this.zoneKey]
            }))
        } finally {
          this.setLoading(false)
        }
      },
      // 获取超算列表
      async getSCList() {
        try {
          const {
            data,
            success,
            message: errmsg
          } = await companyServer.getSCList()
          if (!success) {
            return message.error(errmsg)
          }
          // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
          const OMS_job_zone_list = env?.job_zone_list?.length
            ? JSON.parse(env?.job_zone_list)
            : []
          const filterData = data.result.filter(item => {
            // 如果是盒子直接false
            if (item.zone.includes('box')) {
              return false
            }
            // 判断有没有该区域
            const findResult = OMS_job_zone_list.find(
              value => value.key === item.zone
            ) // find没找到是undfined
            if (!findResult) {
              return true
            }
            // 如果有，判断checked是否为false
            return findResult.checked
          })
          const selectData = filterData.map(item => ({
            name: item.zone,
            // key: result[item].includes('az-jinan') ? 'http://10.0.64.60:8899' : result[item], // 调试济南测试地址
            // key: this.getBaseUrl(item.key),
            key: item.sc_id,
            desc: zoneMap[item.zone]
          }))
          this.setSCList(selectData)
          this.setScId(selectData[0]?.key || '') // 超算ID
          this.setZoneKey(selectData[0]?.name || '') // 分区对的key，az-shanghai az-jinan
          try {
            // 请求domain接口获取分区前缀
            const {
              data,
              success,
              message: errmsg
            } = await companyServer.getDomain(env.company_id)
            if (!success) {
              return message.error(errmsg)
            }
            this.setDoaminList(data.result)
            // // 判断区域domain在超算列表中是否有对应的区域
            // let filterZoneList = data.result.filter(item => {
            //   return (
            //     this.SCList.findIndex(
            //       sc_item => sc_item.zone === item.zone
            //     ) !== -1
            //   )
            // })
            // // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
            // filterZoneList.forEach(item => {
            //   selectData.push({
            //     name: item.zone,
            //     // key: result[item].includes('az-jinan') ? 'http://10.0.64.60:8899' : result[item], // 调试济南测试地址
            //     // key: this.getBaseUrl(item.key),
            //     key: this.getBaseUrl(item.storage_domains[0]),
            //     desc: zoneMap[item.zone]
            //   })
            // })
            // runInAction(() => {
            //   this.setZoneList(selectData)
            //   this.setScId(this.zoneList[0]?.name || '')
            //   this.setZoneKey(this.zoneList[0]?.name || '') // 分区对的key，az-shanghai az-jinan ...
            //   this.setZoneId(this.zoneList[0]?.key || '')
            // })
          } catch (error) {
            console.log('error: ', error)
          }
        } catch (error) {
          console.log('error: ', error)
        }
      }
    }
  })
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
