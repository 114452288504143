/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { Modal } from '@ys/components'

export const applying = (t) => {
  Modal.showConfirm({
    title: t('IsApplyingForThe'),
    content: '',
    CancelButton: null,
    okText: t('ShutDown'),
  })
}
