/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { useSelector } from 'react-redux'
import { ToolBar } from '@/utils/general'
import NewJobCreatorByApp from '@/pages/NewJobCreatorByApp'
import JobCreator from '@/pages/JobCreator'
import { parseUrlParam } from '@/utils'
import { env } from '@/domain'
interface ParamsProps {
  action?: string
  appType?: string
}
export const CalcAppContainers = props => {
  const params: ParamsProps = parseUrlParam(window.location.search)
  const wnapp = useSelector((state: any) => {

    // return state.apps[params?.action]
    return state.apps[props.id]
  })
  return wnapp ? (
    <div
      className='calcApp floatTab dpShad'
      data-size={wnapp.size}
      id={wnapp.icon + 'App'}
      data-max={wnapp.max}
      style={{
        ...(wnapp.size == 'cstm' ? wnapp.dim : null),
        zIndex: wnapp.z
      }}
      data-hide={wnapp.hide}>
      <ToolBar
        app={wnapp.action}
        icon={wnapp.icon}
        size={wnapp.size}
        name={wnapp.title}
      />
      <div className='windowScreen flex flex-col' data-dock='true'>
        {!wnapp.hide && (env.isPersonal ? <JobCreator action={params?.appType}/> : <NewJobCreatorByApp action={params?.appType} />)}
      </div>
    </div>
  ) : null
}
