/* Copyright (C) 2016-present, Yuansuan.cn */
import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  .footer {
    padding: 10px 17px 10px 0;
    border-top: 1px solid ${({ theme }) => theme.borderColorBase};

    .main {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`

export const CloseTimeWrapper = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  padding-left: 20px;
`

export const ZoneWrapper = styled.div`
  display: flex;
  line-height: 32px;
  padding: 20px;
  margin-bottom: 3px;
  background: #fff;

  display: none;
`

export const ListActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;

  .item {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      padding: 0 0 10px 0;
    }

    .label {
      flex: 0 0 auto;
      width: 80px;
      margin-left: 20px;
    }

    .field {
      width: 200px;
    }

    .btn {
      margin: 0 5px;
    }
  }
`

export const ListDataWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  .pagination {
    text-align: right;
    margin: 20px;
  }
`

export const ModalListDataWrapper = styled.div`
  width: 100%;
  max-height: 220px;
  overflow-y: scroll;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
    position: relative;
    box-sizing: content-box;
  }

  .text {
    margin-left: 4px;
  }

  .icon-right {
    margin-left: 4px;

    .anticon {
      height: 12px;
      width: 12px;
      position: absolute;
      top: 21px;
    }
  }
`
export const UpdateWapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`
