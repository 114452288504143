/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Observer } from 'mobx-react'
import { EditableText, Modal, Table, Button } from '@ys/components'
import { observable } from 'mobx'
import { SummaryStyle, AlertCheckboxAlertStyle } from './style'
import { Input, Checkbox, message } from 'antd'
import { userServer } from '@/server'
import { Context, useStore } from '../store'
import { useTranslation } from 'react-i18next'

type Props = {
  jobs: { jobName: string; mainFile: string }[]
  store: any
  onCancel: any
  onOk: (props: { jobName: string; mainFile: string }[]) => void
}

class Job {
  @observable mainFile: string
  @observable jobName: string
  @observable willAlert: boolean = false
  @observable inputVal: string

  constructor({ jobName, mainFile }) {
    this.mainFile = mainFile
    this.jobName = jobName
    this.inputVal = jobName
  }
}

export const Summary = observer(({ jobs, onCancel, onOk }: Props) => {
  const store = useStore()
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    jobs: jobs.map(item => new Job(item)),
    get alertAll() {
      return this.jobs.every(job => job.willAlert)
    },
    notification_activated: false,
    setNA(bool) {
      this.notification_activated = bool
      if (bool) {
        this.jobs.forEach(v => (v.willAlert = true))
      }
    }
  }))

  useEffect(() => {
    // t('ProvideDefaultValuesForHomeworkSetName,TheDefaultIsTheFirstJobOfTheMainFileName')
    store.updateData({ name: state?.jobs[0]?.jobName || '' })
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const {
        data: { notification_activated }
      } = await userServer.checkWxBind('job')
      state.setNA(!!notification_activated)
    }

    fetch()
  }, [])

  return (
    <SummaryStyle>
      <div className='jobName'>
        <div className='label'>
          <span className='star'>*</span>
          <span>{t('HomeworkSetName')}：</span>
        </div>
        <Input
          value={store.data.name}
          onChange={e => {
            store.updateData({ name: e.target.value })
          }}
          placeholder={t('PleaseEnterThe')}
        />
      </div>
      <div className='main'>
        <div className='table'>
          <Table
            columns={[
              {
                header: t('JobName'),
                props: {
                  flexGrow: 1
                },
                cell: {
                  props: {
                    dataKey: 'jobName'
                  },
                  render({ rowData }) {
                    const currentMainFile = rowData['mainFile']
                    return (
                      <div style={{ paddingRight: 50 }}>
                        <EditableText
                          filter={item => { rowData['inputVal'] = item; return item }}
                          defaultValue={rowData['jobName']}
                          beforeConfirm={input => {
                            if (input === '') {
                              return t('JobNameCannotBeEmpty')
                            } else if (/\s/.test(input)) {
                              return t('JobNameCannotContainSpaces')
                            }

                            if (
                              state.jobs.find(
                                ({ jobName, mainFile }) =>
                                  mainFile !== currentMainFile &&
                                  jobName === input
                              )
                            ) {
                              return t('JobNameAlreadyExists')
                            }
                            return true
                          }}
                          onConfirm={input => {
                            rowData.jobName = input
                          }}
                          onCancel={() => {
                            rowData['inputVal'] = rowData.jobName
                          }}
                        />
                      </div>
                    )
                  }
                }
              },
              {
                header: t('MasterFile'),
                props: {
                  flexGrow: 1
                },
                dataKey: 'mainFile'
              },
              {
                header: () => (
                  <Observer>
                    {() => (
                      <AlertCheckboxAlertStyle>
                        <Checkbox
                          disabled={state.notification_activated === false}
                          checked={state.alertAll}
                          onChange={({ target: { checked } }) => {
                            state.jobs.forEach(job => {
                              job.willAlert = checked
                            })
                          }}
                        />
                        {t('AllNotifications')}
                      </AlertCheckboxAlertStyle>
                    )}
                  </Observer>
                ),
                props: {
                  width: 180
                },
                cell: {
                  render({ rowData }) {
                    return (
                      <Observer>
                        {() => (
                          <AlertCheckboxAlertStyle>
                            <Checkbox
                              disabled={state.notification_activated === false}
                              checked={rowData.willAlert}
                              onChange={({ target: { checked } }) => {
                                rowData.willAlert = checked
                              }}
                            />
                            {t('notice')}
                          </AlertCheckboxAlertStyle>
                        )}
                      </Observer>
                    )
                  }
                }
              }
            ]}
            props={{
              data: state.jobs,
              height: 450
            } as any}
          />
        </div>
        {state.notification_activated === false && (
          <span className='note'>
            {t('note') + '：' + t('PleaseGoTo') + '【' + t('PersonalSettings') + '-' + t('MyWechat') + '】' + t('UsedAfterBindingWechatNotifications')}
          </span>
        )}
      </div>
      <Modal.Footer
        className='footer'
        OkButton={() => (
          <Button
            disabled={!store.data.name && t('PleaseEnterTheHomeworkSetName')}
            type={'primary'}
            onClick={() => {
              if (state.jobs.some(job => job.jobName !== job.inputVal)) {
                message.error(t('JobNameHasChanged,PleaseCompleteTheSaveOperation'))
              } else if (state.jobs.some(job => job.jobName.trim() === '')) {
                message.error(t('JobNameCannotBeEmpty,PleaseAmend'))
              } else {
                onOk(state.jobs)
              }
            }}>
            {t('confirm')}
          </Button>
        )}
        onCancel={onCancel}
      />
    </SummaryStyle>
  )
})

export const showSummary = (props: Omit<Props, 'onCancel' | 'onOk'>, t: any) =>
  Modal.show({
    title: t('ConfirmToSubmit'),
    width: 860,
    footer: null,
    content: ({ onCancel, onOk }) => (
      <Context.Provider value={props.store}>
        <Summary {...props} onCancel={onCancel} onOk={onOk} />
      </Context.Provider>
    )
  })
