/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useMemo } from 'react'
import { FileAction } from './FileAction'
import { FileActionsStyle, AreaSelectStyle } from './style'
import { useStore } from '../store'
import { observer } from 'mobx-react-lite'
import { downloadTestFile, buryPoint } from '@/utils'
import { env } from '@/domain'
import { getUrlParams } from '@ys/utils'
import { useStore as useJobStore } from '../../../store'
import { message } from 'antd'
import AreaSelect from '@/components/areaSelect'
import { useTranslation } from 'react-i18next'

export const FileActions = observer(() => {
  const { mode } = useMemo(() => getUrlParams(), [window.location.search])
  const store = useStore()
  const jobStore = useJobStore()
  const { t, i18n } = useTranslation()

  const fileActions = React.useMemo(() => {
    const temp = [
      {
        title: t('CreateAFolder'),
        icon: 'new_folder',
        onClick: () => {
          if (!jobStore.moduleZoneId) {
            return message.warning(t('NoUseArea'))
          }
          buryPoint({
            category: t('JobSubmission'),
            action: t('TheNewFolder')
          })
          store.createFolder(jobStore.fileTree.id)
        }
      },
      {
        title: t('TheUploadFolder'),
        icon: 'folder_upload',
        onClick: () => {
          if (!jobStore.moduleZoneId) {
            return message.warning(t('NoUseArea'))
          }
          buryPoint({
            category: t('JobSubmission'),
            action: t('TheUploadFolder')
          })
          store.upload(jobStore.fileTree.id, true, t)
        }
      },
      {
        title: t('UploadAFile'),
        icon: 'file_upload',
        onClick: () => {
          if (!jobStore.moduleZoneId) {
            return message.warning(t('NoUseArea'))
          }
          buryPoint({
            category: t('JobSubmission'),
            action: t('UploadAFile')
          })
          store.upload(jobStore.fileTree.id, false, t)
        }
      },
      {
        title: t('MyFile'),
        icon: 'folder_mine',
        onClick: () => {
          if (!jobStore.moduleZoneId) {
            return message.warning(t('NoUseArea'))
          }
          buryPoint({
            category: t('JobSubmission'),
            action: t('MyFile')
          })
          store.copyFilesFromFileManager(
            jobStore.fileTree.id,
            jobStore.fileTree.path
          )
        }
      }
    ]

    return env.isPersonal
      ? [
          ...temp,
          {
            title: t('DownloadTheSampleFiles'),
            icon: null,
            onClick: downloadTestFile
          }
        ]
      : temp
  }, [jobStore.fileTree, i18n.language])

  return (
    <FileActionsStyle className='fileUploaderToolbar'>
      <AreaSelectStyle style={{ display: 'none' }}>
        <AreaSelect
          {...{
            zoneList: jobStore.moduleZoneList,
            value: jobStore.moduleZoneId,
            changeCallBack: jobStore.setModuleZoneId,
            showTitle: false,
            disabled: ['redeploy', 'continuous'].includes(mode as string)
          }}
        />
      </AreaSelectStyle>
      {fileActions.map((item, index) => (
        <FileAction icon={item.icon} onClick={item.onClick} key={index}>
          {item.title}
        </FileAction>
      ))}
    </FileActionsStyle>
  )
})
