/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { Directory } from './Directory'
import { File } from './File'
import { BaseDirectory } from '@/utils/FileSystem'
import { formatRequest } from './common'
import { FileServer } from '@/server'

type GetContentParams = { path: string; offset: number; length: number }

const formatPath = path => path.replace(/\/+/, '/').replace(/^\//, '')

export const serverFactory = (fileServer: FileServer) => {
  const server = {
    delete: (paths: string[], url: string) => {
      return fileServer.delete({
        bucket: 'common',
        paths,
        url // 分区地址
      })
    },
    // 获取文件列表
    fetch: async (
      path: string,
      options?: { recursion?: boolean; is_dir?: boolean; zoneId?: string }
      ): Promise<BaseDirectory> => {
      const { recursion, is_dir } = Object.assign(
        {
          recursion: false,
          is_dir: undefined
        },
        options
      )
      const dir = new BaseDirectory({ path })
      // TODO: 文件列表请求，区域参数
      try {
        const res = await fileServer.list({
          bucket: 'common',
          path: path || '.',
          recursion,
          folder_only: is_dir,
          url: options.zoneId // 分区url
        })
        const files = (res.data?.infos || [])
          .sort((x, y) => x.name.split('/').length - y.name.split('/').length)
          .map(item => ({
            ...item,
            name: formatPath(`${path}/${item.name}`)
          }))

        files.forEach(item => {
          let parentPath = item.name.split('/')
          parentPath.pop()
          parentPath = parentPath.join('/')
          let parent = dir

          if (parentPath) {
            parent = dir.filterFirstNode(item => item.path === parentPath)
          }
          parent.push(item.is_dir ? new Directory(item) : new File(item))
        })

        return dir
      } catch (e) {
        return dir
      }
    },
    move: async (params: [string, string][], url?: string) => {
      await fileServer.move({
        bucket: 'common',
        items: params,
        url
      })
    },
    mkdir: (path = '', url?: string) => {
      return fileServer.mkdir({ bucket: 'common', path, url })
    },
    sync: async (oldNode: BaseDirectory, options?: { zoneId?: string }) => {
      const { path } = oldNode
      const newNode = await server.fetch(path, { is_dir: true, zoneId: options.zoneId }) // 分区URL
      const oldChildren = [...oldNode.children]
      const newChildren = [...newNode.children]
      const oldPaths = oldChildren.map(item => item.path)
      const newPaths = newChildren.map(item => item.path)

      newChildren.forEach(item => {
        // add new node
        if (!oldPaths.includes(item.path)) {
          oldNode.unshift(item)

          if (!item.isFile) {
            server.fetch(item.path, { zoneId: options.zoneId }).then(dir => {  // 分区url
              ;(item as any).children = dir.children
            })
          }
        }
      })

      oldChildren.forEach(item => {
        // remove dir
        if (!newPaths.includes(item.path)) {
          item.parent.removeFirstNode(n => n.id === item.id)
        } else {
          // update
          const node = newNode.filterFirstNode(n => n.path === item.path)
          item.update({
            name: node.name,
            path: node.path,
            size: node.size,
            mtime: node.mtime
          })
        }
      })
    },
    getContent: (params: GetContentParams) => {
      return fileServer.getContent({ bucket: 'common', ...params })
    },
    stat: async (path: string, url?: string): Promise<ReturnType<typeof formatRequest>> => {
      const { data } = await fileServer.stat({ path, bucket: 'common', url })

      return formatRequest({
        ...data,
        name: formatPath(`${path}/${data.name}`)
      })
    },
    getFileUrl: async (
      paths: string[],
      types?: boolean[],
      sizes?: string[],
      isImage?: boolean,
      url?: string
    ) => {
      return fileServer.getFileUrl({
        base: '.',
        paths,
        types,
        sizes,
        isImage,
        bucket: 'common',
        url
      })
    },
    download: async (paths: string[], types?: boolean[], sizes?: string[], zoneId?: string) => {
      return fileServer.download({
        base: '.',
        paths,
        types,
        sizes,
        bucket: 'common',
        url: zoneId // 分区url
      })
    }
  }

  return server
}
