/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Menu,
  Toolbar,
  FileList,
  Context,
  useModel,
  History,
  useStore,
} from '.'
import AreaSelect from '../areaSelect'
import { observer } from 'mobx-react-lite'
import { Resizable } from 're-resizable'
import { useResize, env } from '@/domain'
import { Icon } from '@ys/components'
import { useLayoutRect } from '@ys/utils/hooks'
import { message } from 'antd'

const StyledLayout = styled.div`
  height: 100%;
  > .areaSelectWrap {
    display: none;
    padding: 10px 20px;
    border-bottom: 6px solid #f5f5f5;
    > div {
      display: flex;
      align-items: center;
      /* h3 {
        margin-bottom: 0;
        font-weight: normal;
      } */
    }
  }

  > .file_header {
    padding: 20px;
  }

  > .file_body {
    display: flex;
    width: 100%;
    border-top: 4px solid ${({ theme }) => theme.backgroundColorBase};

    > .file_menu {
      .resizeBar {
        z-index: 5;
      }
      height: 100%;
    }

    > .mockbar {
      position: relative;
      width: 1px;
      background-color: ${({ theme }) => theme.borderColorBase};
      z-index: 1;

      > .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 26px;
        color: #c9c9c9;
        background-color: #eee;
        border-radius: 5px;
        > .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    > .files {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .file_toolbar {
        padding: 20px;
      }

      > .file_list {
        flex: 1;
      }
    }
  }
`

type Props = {
  model?: ReturnType<typeof useModel>
  globalAreaSelect?: boolean
  areaSelect?: boolean
  zoneId?: string // 区域id
  jobManger?: boolean // 是否是作业管理模块渲染
}

// TODO: 添加区域选择
export const BaseSuite = observer(function Suite(props: any) {
  const { dirTree, getWidget, initDirTree, refresh, zoneList, zoneId, setZoneId } = useStore()
  const [width, setWidth] = useState(250)
  // const [areaValue, setAreaValue] = useState('1')
  const [headerRect, headerRef, headerResize] = useLayoutRect()
  const [rect, ref, resize] = useResize()

  // hack: harmony file_header size
  useEffect(() => {
    headerResize()
    setTimeout(resize, 100)
  }, [])

  useEffect(() => {
    initDirTree()
    // 作业管理无需请求
    !props.jobManger && refresh()
    // 如果props有区域id zoneId证明是作业提交渲染的我的文件
    props.zoneId && setZoneId(props.zoneId)
  }, [])

  // useEffect(() => {
  //   // 文件管理弹框不需要使用顶部的domain数据
  //   if (props.jobManger) {
  //     return
  //   }
  //   // 从oms获取开关列表数据，判断当前区域是否已启用
  //   if (env?.zone_list?.length) {
  //     const OMS_zone_list = JSON.parse(env.zone_list)
  //     const findRes = OMS_zone_list.find(item => item.key === env.currentZoneKey)
  //     if (findRes && !findRes.checked) {
  //       // 找到并且开关未开false
  //       setZoneId('')
  //       message.warning('该区域暂未开启！')
  //     } else {
  //       // 没找到或者取开关为true
  //       setZoneId(env.currentZoneId)
  //       // 如果当前区域的domain没有数据证明没有开放
  //       !env.currentZoneId && message.warning('该区域暂未开放！')
  //     }
  //   } else {
  //     // 默认无锡存储关闭
  //     if (env.currentZoneKey === 'az-wuxi') {
  //       setZoneId('')
  //       return message.warning('该区域暂未开启！')
  //     }
  //     setZoneId(env.currentZoneId)
  //   }
  // }, [env.currentZoneId])


  return (
    <StyledLayout>
      {/* 文件管理的区域设置(作业提交不展示该位置的区域选择) */}
        <div className='areaSelectWrap'>
          {!props.jobManger && <AreaSelect {...{zoneList, value: zoneId, changeCallBack: setZoneId}} />}
        </div>
      {getWidget('history') || (
        <div className='file_header' ref={headerRef}>
          <History />
        </div>
      )}
      <div
        className='file_body'
        ref={ref}
        style={{ height: `calc(100% - ${headerRect.height}px)` }}>
        <div className='file_menu'>
          <Resizable
            handleClasses={{ right: 'resizeBar' }}
            minWidth={120}
            enable={{ right: true }}
            size={{ width, height: '100%' }}
            onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width)
              resize()
            }}>
            <Menu />
          </Resizable>
        </div>
        <div className='mockbar'>
          <div className='wrapper'>
            <Icon className='icon' type='drag' />
          </div>
        </div>
        <div className='files'>
          <div className='file_toolbar'>
            {/* TODO: 新版作业提交我的文件添加区域 */}
            <Toolbar areaSelect={props.areaSelect} />
          </div>
          <div className='file_list'>
            {dirTree.children.length > 0 && (
              <FileList
                // width={rect.width - width - 43}
                height={rect.height - 127}
              />
            )}
          </div>
        </div>
      </div>
    </StyledLayout>
  )
})

export function Suite({ model, ...props }: Props) {
  const defaultModel = useModel()
  const finalModel = model || defaultModel

  return (
    <Context.Provider value={finalModel}>
      <BaseSuite {...props} />
    </Context.Provider>
  )
}
