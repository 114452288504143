/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect, useMemo } from 'react'
import { Checkbox, InputNumber, Tooltip, message } from 'antd'
import { observer } from 'mobx-react-lite'
import * as FormField from '@/components/FormField'
import { scList, env, currentUser } from '@/domain'
import { FieldType } from '@/domain/Application/AppParam'
import { history, Http } from '@/utils'
import { getUrlParams } from '@ys/utils'
import { PageWrapper } from './style'
import { BottomAction, InputFiles, Section } from './components'
import { QuestionCircleFilled } from '@ant-design/icons'
import { LightComp } from './NewComer/Light'
import { useLocalStore } from 'mobx-react-lite'
import { Provider, useStore } from './store'
import styled from 'styled-components'
import { AppListTabs } from './AppListTabs'
import { AppList } from './AppList'
import { RemainingResouce } from './RemainingResouce'
import AreaSelect from '@/components/areaSelect'
import { useTranslation } from 'react-i18next'
const JobCreator = observer((props: Props) => {
  const {
    id,
    type,
    mode,
    zoneId: moduleUploadZoneId,
    zoneKey: moduleUploadZoneKey,
    scId: SCID
  } = useMemo(() => getUrlParams(), [window.location.search])

  const { t } = useTranslation()
  const store = useStore()
  const {
    data,
    fileTree,
    params,
    refresh,
    setScId,
    setZoneKey,
    getSCList,
    scId,
    SCList
  } = store

  useEffect(() => {
    getSCList(SCID)
    refresh(moduleUploadZoneId, moduleUploadZoneKey)
  }, [])

  useEffect(() => {
    if (['redeploy', 'continuous'].includes(mode as string)) {
      store.setJobBuilderMode(mode as any, {
        id: id as string,
        type: type as any
      })

      // store.setUnblock(
      //   history.block(() => t('LeaveThePageWillNotRetainTheCurrentWork,ConfirmToLeaveThePage')？')
      // )

      // store.setUnlisten(
      //   history.listen(location => {
      //     // t('StillNeedToJumpToReloadTheCurrentPage')
      //     location.pathname === '/new-job-creator' &&
      //       window.location.replace('/')
      //   })
      // )
    } else {
      store.setJobBuilderMode('default')
    }

    store.init(moduleUploadZoneId)

    return () => {
      store.removeHistoryBlock()
      store.reset()
    }
  }, [id, mode, store, type, moduleUploadZoneId, moduleUploadZoneKey])

  useEffect(() => {
    // t('AfterClickingTheResetButton') t('ThisAppIsEmpty') t('AutomaticallyChooseTheFirstApp')
    if (!store.data.currentApp) {
      store.updateData({
        currentApp: store.apps.filter(app => app.is_trial === store.is_trial)[0]
      })
      return
    }
    store.fetchParams()
  }, [store.data.currentApp])

  useEffect(() => {
    if (store.data.paramsModel.isTyping) {
      if (store.isInRedeployMode) return

      localStorage.setItem(
        store.draftKey,
        JSON.stringify({
          ...data,
          user_id: currentUser.id,
          project_id: env.project?.id
        })
      )
    }
  }, [
    store.data,
    store.mainFilePaths,
    store.currentAppId,
    store.data.paramsModel.isTyping
  ])

  const state = useLocalStore(() => ({
    burstConfig: {
      timeBeforeBoost: '0',
      cloudType: 'none'
    },
    setBurstConfig(data) {
      this.burstConfig = data
    },
    get isMixed() {
      return this.burstConfig.cloudType === 'mixed'
    },
    // 显示试用软件
    get showTrialApps() {
      return env.isPersonal && !env.isFuture
    },
    // 显示混合云提示   条件 0.是混合云配置  1.有云端资源  2.选中了本地选项至少一
    get showMixedPrompt() {
      const localList = store.scs_cores_list?.filter(o => o.is_local)

      return (
        this.isMixed &&
        store.scs_cores_list?.length - localList?.length > 0 &&
        localList?.findIndex(o => store.data.scIds.includes(o.sc_id)) > -1
      )
    },
    // 显示智能调度提示    条件 0.未勾选本地资源  1.勾选了大于一个的云端资源
    get showAISchedulePrompt() {
      const localList = store.scs_cores_list?.filter(
        scs_core => scs_core.is_local
      )

      return (
        localList?.findIndex(o => store.data.scIds.includes(o.sc_id)) < 0 &&
        store.data.scIds.filter(
          id => !localList?.map(o => o.sc_id).includes(id)
        ).length > 1
      )
    },

    get isCoreValid() {
      // if (store.data.scIds.length === 0) {
      //   return false
      // } else
      if (store.data.numCores === null || store.data.numCores === undefined) {
        return true
      } else {
        return store.displayExpectCoreValid
      }
    }
  }))

  const getComponentByType = (type: string) => {
    return FormField[
      {
        [FieldType.text]: 'Input',
        [FieldType.list]: 'Select',
        [FieldType.multiple]: 'MultiSelect',
        [FieldType.checkbox]: 'Checkbox',
        [FieldType.radio]: 'Radio',
        [FieldType.label]: 'Label',
        [FieldType.date]: 'Date',
        [FieldType.lsfile]: 'Input',
        [FieldType.texarea]: 'TextArea'
      }[type]
    ]
  }

  const { currentApp } = data

  useEffect(() => {
    // 获取企业配置
    if (!env.isPersonal) {
      Http.get('/company/config/list', {
        params: { configKeys: ['timeBeforeBoost', 'cloudType'] }
      }).then(({ data }) => {
        state.setBurstConfig(data)
      })
    }
  }, [currentApp])

  // useEffect(() => {
  //   if (env?.job_zone_list?.length) {
  //     const OMS_job_zone_list = JSON.parse(env?.job_zone_list)
  //     const findRes = OMS_job_zone_list.find(item => item.key === env.currentZoneKey)
  //     if (findRes && !findRes.checked) {
  //       // 找到并且开关未开false
  //       setScId('')
  //       message.warning('该区域暂未开启！')
  //     } else {
  //       // 没找到或者开关未true
  //       setScId(env.currentZoneScId)
  //        // 如果当前区域的sc_id没有数据证明没有开放
  //        !env.currentZoneScId && message.warning('该区域暂未开放！')
  //     }
  //   } else {
  //     setScId(env.currentZoneScId)
  //   }
  //   setZoneKey(env.currentZoneKey)
  // }, [env.currentZoneScId])

  // 上传模型区域切换
  // const fileZoneSelector = (
  //   <div className='areaSelectWrap'>
  //     <AreaSelect {...{ zoneList: moduleZoneList, value: moduleZoneId, changeCallBack: setModuleZoneId, showTitle: false, disabled: ['redeploy', 'continuous'].includes(mode as string) }} />
  //   </div>
  // )

  return (
    <PageWrapper id='job_creator'>
      {/* 区域切换 */}
      <div className='areaSelectWrap'>
        <AreaSelect
          {...{ zoneList: SCList, value: scId, changeCallBack: setScId }}
        />
      </div>
      <div className='input-content'>
        {env.isPersonal && <LightComp />}

        {state.showTrialApps ? <AppListTabs /> : <AppList action={props.action} is_trial={false} />}

        {/* <Section title=t('UploadTheModel') toolbar={fileZoneSelector} className='moduleUpload'> */}
        <Section title={t('UploadTheModel')} className='moduleUpload'>
          <InputFiles draft={store.draft} fileTree={fileTree} />
        </Section>

        <Section title={t('ParameterConfiguration')} className='paramSettings'>
          {/* {currentApp && (
            <div className='field' style={{ display: 'none' }}>
              <div className='label' title=t('WorkForceResources')>
                <span className='required'>*</span>
                t('WorkForceResources'):
              </div>
              <Checkbox.Group
                className='calculate_selection'
                value={data.scIds}
                onChange={keys => {
                  store.updateData({
                    scIds: keys as string[],
                    numCores: null
                  })
                }}>
                {store.scs_cores_list?.map(({ sc_id }) => (
                  <Checkbox value={sc_id} key={sc_id}>
                    {scList.getName(sc_id)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          )} */}
          {(state.showMixedPrompt && (
            <div className='field-description'>
              <div className='post-text'>
                {t('CheckYourHomeworkWithTheCloudResources,SuchAsTheCloudResources')}{t('LocalQueue')}
                {(+state.burstConfig.timeBeforeBoost).toFixed(2)}
                {t('HoursStillNotCounted,WillBeSubmittedToTheCloudForHomework')}。
              </div>
            </div>
          )) ||
            (state.showAISchedulePrompt && (
              <div className='field-description'>
                <div className='post-text'>
                  {t('SelectMultipleSuperCalculateResources,IntoTheIntelligentSchedulingModel')}
                </div>
              </div>
            ))}
          <RemainingResouce />
          <div className='field'>
            <div className='label' title={t('auditing')}>
              <span></span>{t('ExpectAuditing')}
              {state.showMixedPrompt && (
                <Tooltip
                  placement='top'
                  title={t('AuditingTheOptionalScopeByTheIntersectionOfTheSelectedSuperCalculateResources,PracticalCalculationOfNucleusWhenLessThanExpected')}>
                  <QuestionCircleFilled
                    style={{ margin: '0 2px', color: 'rgba(0,0,0,0.45)' }}
                  />
                </Tooltip>
              )}
              :
            </div>
            <InputNumber
              style={{ width: '210px' }}
              value={data.numCores}
              onChange={value => {
                store.updateData({ numCores: value })
              }}
              // placeholder={store.displayCoresScope}
              parser={value => {
                if (Number.isNaN(parseInt(value))) {
                  return undefined
                }
                return parseInt(value)
              }}
            />
            <span>{t('nuclear')}</span>
            {/* TODO: 放开核数限制 */}
            {/* <div className='valid-core'>
              {!state.isCoreValid && store.displayExpectCoresScope}
            </div> */}
            <div className='suggested-cores'>
              {t('TheNuclearOfResourceSchedulingResultsShallPrevail')}
              {/* {store.data.currentApp &&
                store.suggestedCores &&
                +store.suggestedCores?.cores !== 0 &&
                `${store.data.currentApp?.type} t('MostOfTheJobChoice') ${+store
                  .suggestedCores?.cores} t('nuclear')} */}
            </div>  
          </div>
          {params.map(field => {
            const Field = getComponentByType(field.type)
            return (
              <Field
                key={field.id}
                model={field}
                formModel={data.paramsModel}
              />
            )
          })}
        </Section>
      </div>

      <BottomAction {...props} />
    </PageWrapper>
  )
})

export const StyledDiv = styled.div`
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
`

export default (props: { action?: any }) => (
  <Provider>
    <StyledDiv>
      <JobCreator {...props} pushHistoryUrl='/new-jobs?tab=jobs' />
    </StyledDiv>
  </Provider>
)

export const StyledInDrawerJobCreatorDiv = styled.div`
  height: calc(100% - 20px);
`

type Props = {
  onOk?: () => void
  pushHistoryUrl?: string
  action?: any
}

export function InDrawerJobCreator(props: Props) {
  return (
    <Provider>
      <StyledInDrawerJobCreatorDiv>
        <JobCreator {...props} />
      </StyledInDrawerJobCreatorDiv>
    </Provider>
  )
}
