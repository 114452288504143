/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Layout } from 'antd'
import { history } from '@/utils'
import { Menu } from '@/components/PageLayout/Menu'
import { RouterType } from '@/components/PageLayout/typing'
import { Breadcrumb } from '@/components/PageLayout/Breadcrumb'
import { Header } from './Header'

const { Content, Sider } = Layout

const StyledLayout = styled.div`
  > .layout {
    > .header {
      position: fixed;
      z-index: 1;
      width: calc(100% - 90px);
    }

    > .main {
      padding-top: 64px;
      min-height: 100vh;

      aside {
        position: fixed;
        height: 100%;

        .ant-layout-sider-children {
          padding-bottom: 60px;

          .sider-footer {
            position: absolute;
            bottom: 60px;
            left: 0;
            width: 100%;
            font-size: 14px;
            color: #333333;
            line-height: 22px;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              color: #001b43;
            }

            > .icon {
              margin-right: 12px;
            }
          }
        }
      }

      .ant-layout-sider-light {
        background-color: #f1f3f6;
        box-shadow: 0 8px 8px 0 rgba(213, 213, 213, 0.86);
      }

      > .body {
        padding-left: 200px;

        > .toolbar {
          display: flex;
          margin-top: 14px;
        }
      }
    }
  }
`

type Props = {
  routers: RouterType[]
  children?: React.ReactNode
}

export const CompanyLayout = observer(function CompanyLayout({
  routers,
  children
}: Props) {
  return (
    <StyledLayout>
      <Layout className='layout'>
        <Header className='header' />
        <Layout className='main'>
          <Sider theme='light' width={200}>
            <Menu
              style={{ flex: 1 }}
              routers={routers}
              menuProps={{
                theme: 'light',
                defaultOpenKeys: ['company']
              }}
            />
          </Sider>
          <Layout className='body'>
            <div className='toolbar'>
              <Breadcrumb routers={routers} history={history} />
            </div>
            <Content>{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </StyledLayout>
  )
})
