/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useStore } from './store'
import { JobStatus } from '@/components'
import { scList } from '@/domain'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  padding: 0 20px 20px;
  background: #fff;

  .header {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid ${props => props.theme.borderColorBase};

    .title {
      /* width: 88px; */
      /* text-align: center; */
      font-weight: 500;
      position: relative;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 246px);
    grid-row-gap: 12px;
    overflow-x: hidden;
    padding: 16px;
    color: #000;
    > .job-status {
      display: flex;
    }
  }
`

const InfoItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  max-width: 240px;
`

export const Info = observer(function Info() {
  const { t } = useTranslation()
  const store = useStore()
  const { job } = store
  const state = useLocalStore(() => ({
    get appTier() {
      return scList.getName(job?.sc_id) || '--'
    },
  }))

  return (
    <StyledLayout>
      <div className='header'>
        <div className='title'>{t('TheBasicInformation')}</div>
      </div>
      <div className='content'>
        <InfoItem title={job.name}>{t('TheNameOfThe')}：{job.name}</InfoItem>
        <InfoItem title={job.app_name}>{t('application')}：{job.app_name}</InfoItem>
        <InfoItem>{t('founder')}：{job.user_name}</InfoItem>
        <InfoItem className={'job-status'}>
          {t('JobStatus')}：
          <JobStatus job={job as any} />
        </InfoItem>
        <InfoItem>{t('WorkForceResources')}：{state.appTier}</InfoItem>
        <InfoItem>{t('NumberOfNodes')}：{job.resource_usage.nodes}</InfoItem>
        <InfoItem>{t('auditing')}：{job.resource_usage.cpus}</InfoItem>
        <InfoItem>
          {t('area')}：{scList.list.filter(sc => sc.sc_id === job.sc_id)[0]?.sc_name}
        </InfoItem>
        <InfoItem>{t('TheRunningTime')}：{job.displayRunTime}</InfoItem>
        <InfoItem>{t('SubmitTime')}：{job.create_time?.toString()}</InfoItem>
        <InfoItem>{t('TheStartTime')}：{job?.runtime?.start_time?.toString()}</InfoItem>
        <InfoItem>{t('TheEndOfTime')}：{job?.runtime?.end_time?.toString()}</InfoItem>
      </div>
    </StyledLayout>
  )
})
