/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { env, currentUser } from '@/domain'
import { Status } from './Status'
import moment from 'moment'
import { showJobQRCodeModal, showWXQrModal } from '@/components'
import { userServer } from '@/server'
import { guide } from '@/utils'
import { dashboardServer } from '@/server'
import { Statistic } from 'antd'
import { Chart } from './Chart'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;

  > .header {
    background-color: white;
    padding: 12px 20px;
    display: flex;
    align-items: center;

    > .title {
      display: flex;
      align-items: center;
      margin-right: 16px;

      > .name {
        font-size: 20px;
        font-weight: 600;
        margin-right: 16px;
      }

      > .status {
        display: flex;
      }
    }

    > .overview {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;

      > div {
        padding: 0 40px;

        &:not(:last-child) {
          border-right: 1px solid ${({ theme }) => theme.borderColorBase};
        }
      }
    }
  }

  > .body {
    margin: 12px 0;
  }
`


type Overview = {
  amount: number
  coreHours: number
  jobs: number
  members: number
}

export default observer(function Dashboard() {
  const { t } = useTranslation()
  const overview = [
    {
      name: t('TheTotalNumberOfHomework'),
      value: info => info['jobs'],
      unit: t('a')
    },
    {
      name: t('UseNuclearHours'),
      value: info => Math.ceil(info['coreHours'] / 3600)
    },
    {
      name: t('ConsumptionAmount'),
      value: info => `¥${Math.ceil(info['amount'] / 100000)}`
    },
    {
      name: t('MembersOfTheSpace'),
      value: info => info['members'],
      unit: t('people')
    }
  ]
  const state = useLocalStore(() => ({
    overview: {
      amount: 0,
      coreHours: 0,
      jobs: 0,
      members: 0
    },
    update(props: Partial<{ overview: Overview }>) {
      Object.assign(this, props)
    }
  }))

  useEffect(() => {
    dashboardServer
      .getProjectOverview()
      .then(({ data: { projectOverview } }) => {
        state.update({
          overview: projectOverview
        })
      })
  }, [env.project?.id])

  useEffect(() => {
    // 展示新手引导的时候不显示微信绑定
    if (guide?.runtime?.status !== undefined) {
      return
    }
    if (
      !env.isCompanyManager &&
      moment().format('YMD') !== localStorage.getItem('showQrCode')
    ) {
      // 非企业管理员时检查当前用户作业通知绑定
      // userServer.checkWxBind('job').then(({ data }) => {
      //   if (!data?.notification_activated) {
      //     bindWeChat()
      //   }
      // })
    }
  }, [])

  async function bindWeChat() {
    await showJobQRCodeModal({
      fetchQRCodeFunc: async () => {
        return await userServer.getWxCode('job')
      },
      validConfig: {
        getCheckValue: async () => {
          const {
            data: { notification_activated }
          } = await userServer.checkWxBind('job')
          return !!notification_activated
        }
      },
      afterOk: () => {
        localStorage.setItem('showQrCode', `${moment().format('YMD')}`)
      }
    })
  }

  // 年度报告
  // useEffect(() => {
  //   let count = Number(window.localStorage.getItem('show_yearly'))
  //   if (count > 3) return
  //   env.getWxQrCode(currentUser.id).then(data => {
  //     if (data?.image && data.show) {
  //       showWXQrModal()
  //     }
  //   })
  // }, [])

  return (
    <StyledLayout id='project_dashboard'>
      <div className='header'>
        <div className='title'>
          <div className='name'>{env.project.name}</div>
          <div className='status'>
            <Status />
          </div>
        </div>
        <div className='overview'>
          {overview.map(item => (
            <Statistic
              key={item.name}
              title={item.name}
              value={item?.value?.(state.overview)}
            />
          ))}
        </div>
      </div>
      <div className='body'>
        <div className='chart'>
          <Chart />
        </div>
      </div>
    </StyledLayout>
  )
})
