/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { Tooltip, message } from 'antd'
import { observer } from 'mobx-react-lite'
import { EditableText, Icon } from '@ys/components'
import { HoverableIcon } from '@/components'
import { ChildNode } from '@/domain/JobBuilder/JobDirectory'
import { EditableName } from './EditableName'
import Draft from '@/domain/Box/Draft'
import { FileNameStyle } from './style'
import { buryPoint } from '@/utils'
import { useStore } from '../../../store'
import { useTranslation } from 'react-i18next'
interface IProps {
  record: ChildNode
  createFolder: any
  upload: any
  isExpand: boolean
  copyFilesFromFileManager: any
  draft: Draft
}

export const FileName = observer((props: IProps) => {
  const {
    record,
    createFolder,
    upload,
    isExpand,
    copyFilesFromFileManager,
    draft,
  } = props

  const model = EditableText.useModel({
    defaultEditing: !record.isRoot && record.name === '',
    defaultValue: record.name,
  })
  const jobStore = useStore()
  const { t } = useTranslation()

  const iconType = record.isFile
    ? 'file_table'
    : isExpand
    ? 'folder_open'
    : 'folder_close'

  const rename = () => {
    model.setEditing(true)
  }

  const fileActions = [
    <Tooltip title={t('rename')} key='rename'>
      <span>
        <HoverableIcon type='edit' onClick={rename} />
      </span>
    </Tooltip>,
  ]

  const dirActions = [
    <Tooltip title={t('TheNewFolder')} key='newDir'>
      <span>
        <HoverableIcon
          type='new_folder'
          onClick={() => {
            if (!jobStore.moduleZoneId) {
              return message.warning(t('NoUseArea'))
            }
            buryPoint({
              category: t('JobSubmission'),
              action: t('TheNewFolder'),
            })
            createFolder(record.id)
          }}
        />
      </span>
    </Tooltip>,
    <Tooltip title={t('TheUploadFolder')} key='uploadDir'>
      <span>
        <HoverableIcon
          type='folder_upload'
          onClick={() => {
            if (!jobStore.moduleZoneId) {
              return message.warning(t('NoUseArea'))
            }
            buryPoint({
              category: t('JobSubmission'),
              action: t('TheUploadFolder'),
            })
            upload(record.id, true, t)
          }}
        />
      </span>
    </Tooltip>,
    <Tooltip title={t('UploadAFile')} key='uploadFile'>
      <span>
        <HoverableIcon
          type='file_upload'
          onClick={() => {
            if (!jobStore.moduleZoneId) {
              return message.warning(t('NoUseArea'))
            }
            buryPoint({
              category: t('JobSubmission'),
              action: t('UploadAFile'),
            })
            upload(record.id)
          }}
        />
      </span>
    </Tooltip>,
    <Tooltip title={t('MyFile')} key='myFile'>
      <span>
        <HoverableIcon
          type='folder_mine'
          onClick={() => {
            if (!jobStore.moduleZoneId) {
              return message.warning(t('NoUseArea'))
            }
            buryPoint({
              category: t('JobSubmission'),
              action: t('MyFile'),
            })
            copyFilesFromFileManager(record.id, record.path)
          }}
        />
      </span>
    </Tooltip>,
    <Tooltip title={t('rename')} key='rename'>
      <span>
        <HoverableIcon type='edit' onClick={rename} />
      </span>
    </Tooltip>,
  ]

  const rootActions = dirActions.slice(0, -1)

  const getActions = () => {
    if (model.editing) return []
    if (record.isFile) {
      return (record as any).status === 'done' ? fileActions : []
    }
    if (record.isRoot) {
      return rootActions
    }
    return dirActions
  }

  return (
    <FileNameStyle>
      <Icon className='icon-prefix' type={iconType} />
      <div className='filename-text'>
        {record.isRoot ? (
          'HomeFile'
        ) : (
          <EditableName node={record} model={model} draft={draft} />
        )}
        <div className={record.isRoot ? 'actions root' : 'actions'}>
          {getActions()}
        </div>
      </div>
    </FileNameStyle>
  )
})
