/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Section } from '../components'
import { Software } from './Software'
import { Input, Empty } from 'antd'
import { Button } from '@ys/components'
import { appList, account, softwareFreeTrial } from '@/domain'
import { TrialApplyStatus } from '@/domain/SoftwareFreeTrial'
import { showTrial } from '@/components'
import { useStore } from '../store'
import { useTranslation } from 'react-i18next'

const upFlod = require('@/assets/images/up-flod.svg')
const downFlod = require('@/assets/images/down-flod.svg')

const StyledToolbar = styled.div`
  display: flex;
  align-items: center;

  > .appSearch {
    display: flex;
    align-items: center;
  }

  > .unfold,
  > .fold {
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    align-items: center;
    user-select: none;
    .ysicon {
      font-size: 38px;
      margin-right: 8px;
    }
  }

  > .fold {
    .ysicon {
      transform: rotate(90deg);
    }
  }

  > .unfold {
    .ysicon {
      transform: rotate(-90deg);
    }
  }
`
const StyledAppList = styled.div`
  display: flex;
  flex-direction: column;

  > .flodBtnBox {
    width: 100%;
    margin: 13px auto 0;
    height: 0px;
    border-top: 1px solid #e9e4e4;
    position: relative;

    .inner {
      width: 60%;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 50%;
      cursor: pointer;
      margin-left: -100px;
      font-size: 20px;
      > span {
        position: absolute;
        top: -18px;
        left: 56px;
      }
    }
  }
  .appListSection {
    div.header {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      h2 {
        font-size: 14px;
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        line-height: 22px;
        padding-left: 10px;
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  .trail.appListSection {
    margin-bottom: 10px;
  }
`

type App = {
  name: string
  icon: string
  type: string
  versions: [string, string][]
  is_trial: boolean
}

type Props = {
  is_trial: boolean
  action?: any
}

export const AppList = observer(function AppList(props: Props) {
  const { t } = useTranslation()
  const store = useStore()
  const state = useLocalStore(() => ({
    searchKey: '',
    setSearchKey(key) {
      this.searchKey = key
    },
    active: true,
    setActive(active) {
      this.active = active
    },
    get apps(): App[] {
      // t('IfThereIsNoSuperCalculateIdAppListIsEmpty')
      if (!store.scId) {
        return []
      }
      const { searchKey } = this
      let list = []
      store.scId &&
        (list = appList.list
          .filter(item => !item.type.includes('workflow'))
          .filter(item => item.is_trial === props.is_trial))
      const key = searchKey.toLocaleLowerCase()
      // let list = store.apps
      if (searchKey) {
        list = list.filter(item => item.name.toLowerCase().includes(key))
      }

      const apps = list.reduce((res, item) => {
        res[item.type] = res[item.type] || {
          name: item.type,
          icon: item.icon,
          type: item.type,
          is_trial: item.is_trial,
          versions: []
        }
        res[item.type].versions.push([item.id, item.version])

        return res
      }, {})
      return Object.values(apps)
    }
  }))

  React.useEffect(() => {
    softwareFreeTrial.fetch()
    account.fetch()
    // appList.fetch()
  }, [])

  const toggleFoldBtn = active => {
    return {
      backgroundImage: `${active ? `url(${upFlod})` : `url(${downFlod})`}`
    }
  }

  return (
    <Section
      className='appList'
      title={t('TheApplicationList')}
      toolbar={
        <StyledToolbar>
          <div className='appSearch'>
            <Input.Search
              placeholder={t('PleaseEnterTheKeywords')}
              onChange={e => state.setSearchKey(e.target.value)}
            />
          </div>
          {/* {state.active ? (
            <div className='fold' onClick={() => state.setActive(false)}>
              <Icon type='fold' />
            </div>
          ) : (
            <div className='unfold' onClick={() => state.setActive(true)}>
              <Icon type='fold' />
            </div>
          )} */}
        </StyledToolbar>
      }>
      {state.active ? (
        state.apps.length ? (
          <StyledAppList>
            <div className='appListSection'>
              <div className='header'>
                {props.is_trial && (
                  <Button
                    size='small'
                    style={{ marginRight: 8 }}
                    type='primary'
                    onClick={() => showTrial('user')}>
                    {t('ToApplyForTrial')}
                  </Button>
                )}
                {props.is_trial &&
                  softwareFreeTrial.status ===
                    TrialApplyStatus.APPLY_APPROVED && (
                    <p>{t('TheRemainingTrialWhenNuclear')}: {account.free_trail_balance.toFixed(2)}</p>
                  )}
              </div>
              <div className='apps'>
                {state.apps.map((app: App) => (
                  <Software action={props.action} key={app.type} {...app} icon={app.icon} />
                ))}
              </div>
            </div>
            <div className='flodBtnBox' onClick={() => state.setActive(false)}>
              <div
                className='inner'
                style={{
                  backgroundImage: toggleFoldBtn(state.active).backgroundImage
                }}></div>
            </div>
          </StyledAppList>
        ) : (
          <Empty description={t('AnyApplication')} />
        )
      ) : (
        state.apps.length && (
          <StyledAppList>
            <div className='appListSection'>
              <div className='header'>
                {props.is_trial && (
                  <Button
                    size='small'
                    style={{ marginRight: 8 }}
                    type='primary'
                    onClick={() => showTrial('user')}>
                    {t('ToApplyForTrial')}
                  </Button>
                )}
                {props.is_trial &&
                  softwareFreeTrial.status ===
                    TrialApplyStatus.APPLY_APPROVED && (
                    <p>{t('TheRemainingTrialWhenNuclear')}: {account.free_trail_balance.toFixed(2)}</p>
                  )}
              </div>
              <div className='apps'>
                {state.apps.map((app: App) => {
                  return (
                    app.versions.length > 0 &&
                    app.versions.map(v => {
                      if (v[0] === store.currentAppId) {
                        return (
                          <Software action={props.action} key={app.type} {...app} icon={app.icon} />
                        )
                      } else {
                        return ''
                      }
                    })
                  )
                })}
              </div>
            </div>
            <div className='flodBtnBox' onClick={() => state.setActive(true)}>
              <div
                className='inner'
                style={{
                  backgroundImage: toggleFoldBtn(state.active).backgroundImage
                }}></div>
            </div>
          </StyledAppList>
        )
      )}
    </Section>
  )
})
