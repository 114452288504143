/* Copyright (C) 2016-present, Yuansuan.cn */
const defState = {
  lays: [
    [
      {
        dim: {
          width: '50%',
          height: '100%',
          top: 0,
          left: 0,
        },
        br: 14,
      },
      {
        dim: {
          width: '50%',
          height: '100%',
          top: 0,
          left: '50%',
        },
        br: 15,
      },
    ],
    [
      {
        dim: {
          width: '66%',
          height: '100%',
          top: 0,
          left: 0,
        },
        br: 14,
      },
      {
        dim: {
          width: '34%',
          height: '100%',
          top: 0,
          left: '66%',
        },
        br: 15,
      },
    ],
    [
      {
        dim: {
          width: '33%',
          height: '100%',
          top: 0,
          left: 0,
        },
        br: 14,
      },
      {
        dim: {
          width: '34%',
          height: '100%',
          top: 0,
          left: '33%',
        },
        br: 1,
      },
      {
        dim: {
          width: '33%',
          height: '100%',
          top: 0,
          left: '67%',
        },
        br: 15,
      },
    ],
    [
      {
        dim: {
          width: '50%',
          height: '100%',
          top: 0,
          left: 0,
        },
        br: 14,
      },
      {
        dim: {
          width: '50%',
          height: '50%',
          top: 0,
          left: '50%',
        },
        br: 3,
      },
      {
        dim: {
          width: '50%',
          height: '50%',
          top: '50%',
          left: '50%',
        },
        br: 5,
      },
    ],
    [
      {
        dim: {
          width: '50%',
          height: '50%',
          top: 0,
          left: 0,
        },
        br: 2,
      },
      {
        dim: {
          width: '50%',
          height: '50%',
          top: 0,
          left: '50%',
        },
        br: 3,
      },
      {
        dim: {
          width: '50%',
          height: '50%',
          top: '50%',
          left: 0,
        },
        br: 7,
      },
      {
        dim: {
          width: '50%',
          height: '50%',
          top: '50%',
          left: '50%',
        },
        br: 5,
      },
    ],
    [
      {
        dim: {
          width: '25%',
          height: '100%',
          top: 0,
          left: 0,
        },
        br: 14,
      },
      {
        dim: {
          width: '50%',
          height: '100%',
          top: 0,
          left: '25%',
        },
        br: 1,
      },
      {
        dim: {
          width: '25%',
          height: '100%',
          top: 0,
          left: '75%',
        },
        br: 15,
      },
    ],
  ],
  ribbon: [
    'luca',
    'unescape',
    'essential apps',
    'xbox gamepass',
    'spotify',
    'social media',
    'security',
    'utility apps',
    'forza horizon',
    'kids apps',
  ],
  apprib: [
    'netflix',
    'whatsApp',
    'telegram',
    'facebook',
    'amazon prime',
    'office',
    'lightroom',
  ],
  gamerib: [
    'call of duty',
    'cyberpunk 2077',
    'minecraft',
    'battle field v',
    'far cry 5',
    'hitman 3',
    'residental evil',
  ],
  movrib: [
    'antman',
    'godzilla vs kong',
    'tom and jerry',
    'wrath of man',
    'john wick',
    'wonder woman 1984',
    'nobody',
  ],
}

const globalReducer = (state = defState, action) => {
  return state
}

export default globalReducer
