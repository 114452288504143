/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { serverFactory } from './store/common/server'
import { boxServer, terminalServer } from '@/server'

export { FileList } from './FileList'
export { Menu } from './Menu'
export { History } from './History'
export { Toolbar } from './Toolbar'
export * from './store'
export { Suite } from './Suite'
export { showFileSelector } from './FileSelector'
export { showDirSelector } from './DirSelector'
export { showFailure } from './Failure'

export const boxFileServer = serverFactory(boxServer)
export const terminalFileServer = serverFactory(terminalServer)
