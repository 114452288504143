/* Copyright (C) 2016-present, Yuansuan.cn */
const defState = {
  hide: true,
  top: 80,
  left: 360,
  opts: 'desk',
  attr: null,
  dataset: null,
  data: {
    desk: {
      width: '310px',
      secwid: '200px'
    },
    task: {
      width: '220px',
      secwid: '120px',
      ispace: false // show the space for icons in menu
    },
    app: {
      width: '310px',
      secwid: '200px'
    },
    fileManager: {
      width: '310px',
      secwid: '200px'
    },
    cloudMenuClose: {
      width: '310px',
      secwid: '200px'
    },
    cloudMenuOpen: {
      width: '310px',
      secwid: '200px'
    },
    fileMenu: {
      width: '310px',
      secwid: '200px'
    },
    rightMenu: {
      width: '310px',
      secwid: '200px'
    }
  },
  menus: {
    desk: [
      {
        name: '查看',
        icon: 'view',
        type: 'svg',
        payload: 'view',
        key: 'view',
        opts: [
          {
            name: '大图标',
            action: 'changeIconSize',
            payload: 'large',
            key: 'large'
          },
          {
            name: '中等图标',
            action: 'changeIconSize',
            payload: 'medium',
            key: 'medium'
          },
          {
            name: '小图标',
            action: 'changeIconSize',
            payload: 'small',
            key: 'small',
            dot: true
          },
          {
            type: 'hr'
          },
          {
            name: '展示桌面图标',
            action: 'deskHide',
            check: true,
            payload: 'deskHide',
            key: 'deskHide'
          }
        ]
      },
      {
        name: '排序方式',
        icon: 'sort',
        type: 'svg',
        payload: 'sort',
        key: 'sort',
        opts: [
          {
            name: '名称',
            action: 'changeSort',
            payload: 'name',
            key: 'name'
          },
          {
            name: '大小',
            action: 'changeSort',
            payload: 'size',
            key: 'size'
          },
          {
            name: '修改时间',
            action: 'changeSort',
            payload: 'date',
            key: 'date'
          }
        ]
      },
      {
        name: '刷新',
        action: 'refresh',
        type: 'svg',
        icon: 'refresh',
        payload: 'refresh',
        key: 'refresh'
      }
      // {
      //   type: 'hr',
      // },
      // {
      //   name: 'New',
      //   icon: 'New',
      //   type: 'svg',
      //   opts: [
      //     {
      //       name: 'Folder',
      //     },
      //     {
      //       name: 'Shortcut',
      //     },
      //     {
      //       name: 'Text Document',
      //     },
      //     {
      //       name: 'Compressed (zipped) Folder',
      //     },
      //   ],
      // },
      // {
      //   type: 'hr',
      // },
      // {
      //   name: 'Display settings',
      //   icon: 'display',
      //   type: 'svg',
      //   action: 'SETTINGS',
      //   payload: 'full',
      // keyyload: 'full',
      // },
      // {
      //   name: 'Personalize',
      //   icon: 'personalize',
      //   type: 'svg',
      //   action: 'SETTINGS',
      //   payload: 'full',
      // keyyload: 'full',
      // },
      // {
      //   type: 'hr',
      // },
      // {
      //   name: '下一张壁纸',
      //   action: 'WALLNEXT',
      // },
      // {
      //   name: 'Open in Terminal',
      //   icon: 'terminal',
      //   action: 'OPENTERM',
      //   payload: 'C:\\Users\\Blue\\Desktop',
      // keyyload: 'C:\\Users\\Blue\\Desktop',
      // },
      // {
      //   name: 'About',
      //   action: 'DESKABOUT',
      //   icon: 'win/info',
      //   payload: true,
      // keyyload: true,
      // },
    ],
    task: [
      // {
      //   name: 'Align icons',
      //   key: 'Align icons',
      //   opts: [
      //     {
      //       name: 'Left',
      //       action: 'changeTaskAlign',
      //       payload: 'left',
      //       key: 'left'
      //     },
      //     {
      //       name: 'Center',
      //       action: 'changeTaskAlign',
      //       payload: 'center',
      //       key: 'center',
      //       dot: true
      //     }
      //   ]
      // },
      // {
      //   type: 'hr'
      // },
      // {
      //   name: 'Search',
      //   key: 'Search',
      //   opts: [
      //     {
      //       name: 'Show',
      //       action: 'TASKSRCH',
      //       payload: true,
      //       key: true
      //     },
      //     {
      //       name: 'Hide',
      //       action: 'TASKSRCH',
      //       payload: false,
      //       key: false
      //     }
      //   ]
      // },
      // {
      //   name: 'Widgets',
      //   key: 'Widgets',
      //   opts: [
      //     {
      //       name: 'Show',
      //       action: 'TASKWIDG',
      //       payload: true,
      //       key: true
      //     },
      //     {
      //       name: 'Hide',
      //       action: 'TASKWIDG',
      //       payload: false,
      //       key: false
      //     }
      //   ]
      // },
      {
        type: 'hr'
      },
      {
        name: 'Show Desktop',
        key: 'Show Desktop',
        action: 'SHOWDSK'
      }
    ],
    app: [
      {
        name: '打开',
        action: 'performApp',
        payload: 'open',
        key: 'open'
      }
      // {
      //   name: 'Run as administrator',
      //   action: 'performApp',
      //   payload: 'open',
      // keyyload: 'open',
      //   icon: 'win/shield',
      // },
      // {
      //   name: 'Open file location',
      //   dsb: true,
      // },
      // {
      //   name: 'Unpin from start',
      //   dsb: true,
      // },
      // {
      //   name: 'Compress to Zip file',
      //   dsb: true,
      // },
      // {
      //   name: 'Copy as path',
      //   dsb: true,
      // },
      // {
      //   name: 'Properties',
      //   dsb: true,
      // },
      // {
      //   type: 'hr',
      // },
      // {
      //   name: '删除',
      //   action: 'performApp',
      //   payload: 'delshort',
      // keyyload: 'delshort',
      // },
      // {
      //   name: 'Delete',
      //   action: 'delApp',
      //   payload: 'delete',
      // keyyload: 'delete',
      // },
    ],
    fileManager: [
      {
        name: '新建文件夹',
        action: 'fileAction',
        payload: 'createFile',
        key: 'createFile'
      },
      {
        name: '上传文件',
        action: 'fileAction',
        payload: 'uploadFile',
        key: 'uploadFile'
      },
      {
        name: '上传文件夹',
        action: 'fileAction',
        payload: 'uploadFiles',
        key: 'uploadFiles'
      }
    ],
    cloudMenuOpen: [
      {
        name: '打开',
        action: 'performApp',
        payload: 'open',
        key: 'open'
      },
      {
        name: '删除',
        action: 'performApp',
        payload: 'delshort',
        key: 'delshort'
      }
    ],
    cloudMenuOpen: [
      {
        name: '打开',
        action: 'performApp',
        payload: 'open',
        key: 'open'
      },
      {
        name: '应用列表',
        action: 'performApp',
        payload: 'appList',
        key: 'appList'
      },
      {
        name: '删除',
        action: 'performApp',
        payload: 'delshort',
        key: 'delshort'
      }
    ],
    cloudMenuClose: [
      {
        name: '创建',
        action: 'performApp',
        payload: 'open',
        key: 'create'
      }
    ],
    fileMenu: [
      {
        name: '重新命名',
        action: 'fileMenu',
        payload: 'rename',
        key: 'rename'
      },
      {
        name: '移动',
        action: 'fileMenu',
        payload: 'move',
        key: 'move'
      },
      {
        name: '删除',
        action: 'fileMenu',
        payload: 'remove',
        key: 'remove'
      },
      {
        name: '下载',
        action: 'fileMenu',
        payload: 'download',
        key: 'download'
      }
    ],
    rightMenu: [
      {
        name: '移动',
        action: 'rightMenu',
        payload: 'move',
        key: 'move'
      },
      {
        name: '删除',
        action: 'rightMenu',
        payload: 'remove',
        key: 'remove'
      },
      {
        name: '下载',
        action: 'rightMenu',
        payload: 'download',
        key: 'download'
      }
    ]
  }
}

const menusReducer = (state = defState, action) => {
  let tmpState = {
    ...state
  }
  if (action.type == 'MENUHIDE') {
    tmpState.hide = true
  } else if (action.type == 'MENUSHOW') {
    tmpState.hide = false
    tmpState.top = (action.payload && action.payload.top) || 272
    tmpState.left = (action.payload && action.payload.left) || 430
    tmpState.opts = (action.payload && action.payload.menu) || 'desk'
    tmpState.attr = action.payload && action.payload.attr
    tmpState.dataset = action.payload && action.payload.dataset
  } else if (action.type == 'MENUCHNG') {
    tmpState = {
      ...action.payload
    }
  }

  return tmpState
}

export default menusReducer
