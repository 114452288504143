/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable } from 'mobx'
import { Http } from '@/utils'
import App from './App'

export default class ApplicationList {
  @observable list: App[] = []

  async fetch() {
    const res = await Http.get('/application')
    this.list = res.data.map(item => new App(item))
    // hack enough
    localStorage.setItem('FLAG_ENTERTAINMENT', JSON.stringify(this.list))
  }
}
