/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { useSelector } from 'react-redux'
import { ToolBar } from '../../../utils/general'
import NewJobManager from '@/pages/NewJobManager'
import JobManager from '@/pages/JobManager'
import { env } from '@/domain'
import { useTranslation } from 'react-i18next'
export const JobManage = () => {
  const { t } = useTranslation()
  const wnapp = useSelector((state) => state.apps.jobLists)

  return wnapp ? (
    <div
      className="notepad floatTab dpShad"
      data-size={wnapp.size}
      data-max={wnapp.max}
      style={{
        ...(wnapp.size == 'cstm' ? wnapp.dim : null),
        zIndex: wnapp.z,
      }}
      data-hide={wnapp.hide}
      id={wnapp.icon + 'App'}
    >
      <ToolBar
        app={wnapp.action}
        icon={wnapp.icon}
        size={wnapp.size}
        name={t('job.title')}
      />
      <div className="windowScreen flex flex-col" data-dock="true">
        { !wnapp.hide && (env.isPersonal ? <JobManager/> : <NewJobManager />)}
      </div>
    </div>
  ) : null
}
