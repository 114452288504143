/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Chart } from '@/components'
import moment from 'moment'
import { Slider } from 'bizcharts'
import { QueryResult } from '@apollo/client/react/types/types'
import { Spin } from 'antd'
import { IViewProps } from 'bizcharts/src/interface'

const StyledLayout = styled.div`
  padding: 8px 0px;
  height: 335px;
  position: relative;

  > .spin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

type Props = QueryResult & {
  data: {
    type: string
    value: any
    date: string
  }[]
  maxValue: number
  title: string
  children?: React.ReactElement
  scale?: IViewProps['scale']
}

export const BaseStats = observer(function BaseStats({
  title,
  data,
  maxValue,
  error,
  loading,
  children,
  scale
}: Props) {
  if (error) {
    return (
      <>
        {error?.graphQLErrors?.map((err, i) => (
          <pre key={i}>{JSON.stringify(err?.message, null, 2)}</pre>
        ))}
      </>
    )
  }

  return (
    <StyledLayout>
      <h3>{title}</h3>
      {(loading && (
        <div className='spin'>
          <Spin />
        </div>
      )) || (
        <Chart
          scale={{
            value: {
              min: 0,
              max: maxValue,
              formatter: value => Number(value).toFixed(2)
            },
            ...scale
          }}
          position={{ x: 'date', y: 'value', color: 'type' }}
          height={300}
          padding='auto'
          appendPadding={[20]}
          autoFit
          config={{ showYLabel: false, markerType: 'circle' }}
          data={data}
          interactions={['element-active']}>
          {children}
        </Chart>
      )}
    </StyledLayout>
  )
})
