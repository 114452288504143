/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { AppCoreHourStats } from './AppCoreHourStats'
import { JobCountStats } from './JobCountStats'
import { CoreHourStats } from './CoreHourStats'
import { projectList } from '@/domain'
import { defaultTimeRange, defaultTimeStep } from './constants'
import { TimeController } from './TimeController'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid white;
  background-color: white;
  padding: 16px 20px;

  > .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .consume_limit_amount {
      font-size: 18px;
      font-weight: 500;
      .tipWords {
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }

  > .main {
    > div {
      display: flex;

      > div {
        flex: 1;
      }
    }
  }
`

export const JobStats = observer(function JobStats() {
  const { current } = projectList
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    timeStep: defaultTimeStep,
    timeRange: defaultTimeRange,
    update(
      props: Partial<{
        timeStep: number
        timeRange: [moment.Moment, moment.Moment]
      }>
    ) {
      Object.assign(this, props)
    },
  }))

  return (
    <StyledLayout>
      <div className='toolbar'>
        <TimeController
          defaultTimeRange={defaultTimeRange}
          defaultTimeStep={defaultTimeStep}
          onChange={values => {
            if (values.timeStep) {
              state.update({
                timeStep: values.timeStep,
              })
            }
            if (values.timeRange) {
              state.update({
                timeRange: values.timeRange,
              })
            }
          }}
        />
        {/* show consume_limit_amount */}
        <div className='consume_limit_amount'>
          {current.consume_limit_enabled ? (
            <>
              <span>
                {t('SpaceLimit')}:{' '}
                {current.consume_limit_amount
                  ? current.consume_limit_amount / 100000
                  : 0}{' '}
                t('yuan')
              </span>
              <span className='tipWords'>
                {current.allow_submit_job_over_limit
                  ? t('BeyondTheLimitsAllowedToSubmitAJob')
                  : t('BeyondTheLimitAreNotAllowedToSubmitAJob')}
              </span>
            </>
          ) : (
            <span className='tipWords'>{t('IsNotEnabledSpaceLimitation')}</span>
          )}
        </div>
      </div>
      <div className='main'>
        <div>
          <AppCoreHourStats
            timeStep={state.timeStep}
            timeRange={state.timeRange}
          />
          <CoreHourStats
            timeStep={state.timeStep}
            timeRange={state.timeRange}
          />
        </div>
        <div>
          <JobCountStats
            timeStep={state.timeStep}
            timeRange={state.timeRange}
          />
        </div>
      </div>
    </StyledLayout>
  )
})
