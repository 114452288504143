/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { FileTree } from './FileTree'
import { JobFile } from './JobFile'

interface FileListItem {
  is_dir?: boolean
  mod_time: number
  name: string
  size: number
}

export function fromJSON2Tree(list: FileListItem[] = []) {
  const root = new FileTree({
    name: '',
  })
  list.forEach(({ name, is_dir, ...rest }) => {
    if (is_dir) {
      root.ensureDir(name)
    } else {
      const names = name.split('/')
      const parentPath = names.slice(0, names.length - 1).join('/')
      const parent = parentPath ? root.ensureDir(parentPath) : root
      const file = new JobFile({ name: names[names.length - 1], ...rest })
      parent.push(file)
    }
  })
  return root
}
