/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { box, account, env } from '@/domain'
import { useStore } from '../store'
import { Dropdown, Input, Menu, message } from 'antd'
import {
  boxFileServer,
  showDirSelector,
  showFailure
} from '@/components/NewFileMGT'
import { Button } from '@ys/components'
import { newBoxServer } from '@/server'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  display: flex;
  margin: 4px 0px 20px;

  > .right {
    margin-left: auto;
  }
`

export const Toolbar = observer(function Toolbar() {
  const { t } = useTranslation()
  const store = useStore()
  const { job } = store
  const state = useLocalStore(() => ({
    get downloadDisabled() {
      if (env.accountHasDebt) {
        return t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
      }

      if (store.selectedKeys.length === 0) {
        return t('NoSelectedFiles')
      } else if (job?.useRemote && store.selectedKeys.length > 1) {
        return t("HomeworkFileBack,PleaseDon'tBatchDownloadFile")
      }

      return false
    }
  }))

  const downloadMultiLocal = async () => {
    let size
    const jobParams = job.useRemote
      ? {
          paths: store.selectedKeys,
          sync_id: job.runtime?.download_task_id
        }
      : {
          base: job.id,
          paths: store.selectedKeys.map(filename => `${job.id}/${filename}`),
          bucket: 'result'
        }

    if (store.selectedKeys.length === 1) {
      const file = store.jobFile.getName(store.selectedKeys[0])
      size = file.size
    }

    const params =
      store.selectedKeys.length === 1
        ? { ...jobParams, sizes: [size], types: [true] }
        : jobParams
    await newBoxServer.download({
      url: store.params.zoneId as string,
      ...params
    })

    store.setSelectedKeys([])
  }

  const downloadMultiCommon = async () => {
    const targetDir = await showDirSelector({
      zoneId: store.params.zoneId as string
    })
    const existNodes = []
    const dir = await boxFileServer.fetch(targetDir, {
      zoneId: store.params.zoneId as string
    }) // 分区url
    const allDirPaths = dir.flatten().map(item => item.path)
    const pathsObj = store.selectedKeys.reduce((o, p) => {
      const srcPath = `${job.id}/${p}`
      const dstPath = targetDir ? `${targetDir}/${p}` : p
      if (allDirPaths.includes(dstPath)) {
        existNodes.push({
          path: dstPath,
          name: dstPath.split('/').slice(-1)[0],
          isFile: true
        })
      } else {
        o[srcPath] = dstPath
      }
      return o
    }, {})

    if (existNodes.length > 0) {
      const coverNodes = await showFailure({
        actionName: t('download'),
        items: existNodes
      })
      if (coverNodes.length > 0) {
        // del dest first
        await boxFileServer.delete(
          coverNodes.map(item => item.path),
          store.params.zoneId as string
        )

        // add to objMap for cover to mv
        coverNodes.reduce((o, node) => {
          o[`${job.id}/${node.name}`] = `${targetDir}/${node.name}`
          return o
        }, pathsObj)
      }
    }
    console.log(pathsObj)
    if (Object.keys(pathsObj).length > 0) {
      await box.newResult.linkToCommon(pathsObj, store.params.zoneId as string)
      message.success(t('TheDownloadIsComplete'))
      store.selectedKeys = []
    }
  }

  const downloadDropdownMenu = (
    <Menu>
      <Menu.Item
        onClick={downloadMultiLocal}
        // 针对上海优跑（正式）定制化需求：隐藏下载功能
        style={{ display: env?.company?.id === '4wBZP1cZW3u' ? 'none' : '' }}>
        {t('DownloadedToTheLocal')}
      </Menu.Item>
      {!job?.useRemote && (
        <Menu.Item onClick={downloadMultiCommon}>{t('DownloadTheFilesToMe')}</Menu.Item>
      )}
    </Menu>
  )
  

  return (
    <StyledLayout>
      {!state.downloadDisabled && (
        <Dropdown overlay={downloadDropdownMenu} placement='bottomLeft'>
          <Button type='primary'>{t('download')}</Button>
        </Dropdown>
      )}
      {state.downloadDisabled && (
        <Button type='primary' disabled={state.downloadDisabled}>
          {t('download')}
        </Button>
      )}
      <div className='right'>
        <Input.Search
          allowClear
          placeholder={t('TheInputFileNameSearch')}
          value={store.searchKey}
          onChange={({ target: { value } }) => store.setSearchKey(value)}
        />
      </div>
    </StyledLayout>
  )
})
