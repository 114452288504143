/* Copyright (C) 2016-present, Yuansuan.cn */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { i18nextPlugin } from 'translation-check'
import translateEN from '@/assets/locales/en/translate.json'
import translateZH from '@/assets/locales/zh/translate.json'
const fallbackLng = ['en']
const availableLanguages = [
  'en',
  'zh',
]

const resources = {
  en: {
    translation: translateEN
  },
  zh: {
    translation: translateZH
  },
  'zh-CN': {
    translation: translateZH
  },
}

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(i18nextPlugin)
  .init({
    fallbackLng, // fallback language is english.
    // ns: ['translate'],
    // backend: {
    //   loadPath: 'locales/{{lng}}/{{ns}}.json',
    //   crossDomain: true
    // },
    resources,
    lng: "zh",

    detection: {
      checkWhitelist: true, // options for language detection
      // order: ['cookie', 'htmlTag'],
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  },function(err, t) {
    console.log('err: ', err)
    // initialized and ready to go!
  })

export default i18n
