/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { account, env } from '@/domain'
import { notification } from '@/components'
import { single } from '@ys/utils'

const temp = async (t) => {
  await account.fetch(env.accountId)

  if (!env.isPersonal) {
    // 企业用户
    if (account.account_balance > 0) {
      if (account.account_balance / 100000 < 1000) {
        await notification.info({
          message: t('PleasePayAttention!'),
          description:
            t('AccountBalanceWillSoonRunOut,PleaseContactTheAdministratorToPrepaidPhoneAsSoonAsPossible,')+t('SoAsNotToAffectTheNormalUseProcess.')
        })
        return
      }
    } else if (account.account_balance <= 0) {
      if (account.account_balance > account.credit_quota * -1) {
        /*
        await notification.warning({
          message: t('PleasePayAttention!'),
          description:
            t('AccountBalanceHasRunOut,IsTheUseOfCredit,')t('PleaseContactYourAdministratorToPrepaidPhoneAsSoonAsPossible,LestAffectNormalUseProcess.')'
        })
        */
      } else {
        await notification.warning({
          message: t('PleasePayAttention!'),
          description:
            t('AccountBalancesAndCreditHaveBeenUsedUp,PleaseContactYourAdministratorToPrepaidPhoneAsSoonAsPossible,')+t('SoAsNotToAffectTheNormalUseProcess.')
        })
      }
      return
    }
  } else {
    // t('IndividualUser')
    if (account.account_balance > 0) {
      if (account.account_balance / 100000 < 100) {
        await notification.info({
          message: t('PleasePayAttention!'),
          description: t('ACostAccountBalanceWillBeFinished,PleaseAsSoonAsPossible,')+t('SoAsNotToAffectTheNormalUseProcess.')
        })
        return
      }
    } else if (account.account_balance < 0) {
      await notification.warning({
        message: t('PleasePayAttention!'),
        description: t('AccountBalanceHasBeenFinished,PleasePrepaidPhoneAsSoonAsPossible.')
      })
      return
    }
  }
}

export const accountBurnOutAlert = (t) => {
  single('balanceAlert',() => temp(t))
}
