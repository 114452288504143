/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import { Empty, message } from 'antd'
import { observer, useLocalStore } from 'mobx-react-lite'
import { StyledItem, StyledLayout } from './style'
import { Page } from '@/components'
import { Icon, Button, Modal } from '@ys/components'
import { Phone } from './Phone'
import { Password } from './Password'
import { Name } from './Name'
import { HeadImg } from './HeadImg'
import { currentUser, env } from '@/domain'
import { history } from '@/utils'
import { Wechat } from './Wechat'
import { companyServer } from '@/server'
const PersonalSetting = observer(function PersonalSetting() {
  const store = useLocalStore(() => {
    return {
      role: '--',
      setRole(role) {
        this.role = role
      }
    }
  })
  function goBack() {
    history.goBack()
  }
  async function exitCompany() {
    await Modal.showConfirm({
      title: '退出企业',
      content: `是否退出 ${env.company?.name} ？`
    })
    companyServer
      .delete_perm_user_default({
        user_id: currentUser.id,
        company_name: env.company?.name,
        company_id: env.company?.id
      })
      .then(res => {
        const data = res.data
        if (data.msg === 'success') {
          message.success('退出企业成功')

          location.replace('/')
        } else {
          data.is_run_job
            ? message.error('有正在运行的作业，无法退出当前企业')
            : data.is_open_app
              ? message.error('有正在运行的应用，无法退出当前企业')
              : message.error('退出企业失败')
        }
      })
  }
  async function getUserRole() {
    return await companyServer.getUserRoleInSetting()
  }
  useEffect(() => {
    getUserRole().then(res => {
      store.setRole(res.data.role_list[0].name)
    })
  }, [])
  return (
    // <Page header={null}>
    <StyledLayout>
      {currentUser.id ? (
        <>
          {/* <div className='click-back-div' onClick={goBack}>
              <Icon type='export' />
              返回
            </div> */}
          <HeadImg />
          <Phone />
          <Password />
          <Name />
          <StyledItem>
            <label>所属企业：</label>
            <span className='text'>{env?.company?.name || '--'}</span>

            <label>角色：</label>
            <span className='text'>{store.role || '--'}</span>

            {env.company?.id &&
              store.role != '管理员' &&
              store.role != '--' &&
              store.role != '超级管理员' && (
                <Button type='danger' size='small' onClick={exitCompany}>
                  退出当前企业
                </Button>
              )}
          </StyledItem>
          {/* <Wechat /> */}
        </>
      ) : (
        <Empty description='用户未登录' />
      )}
    </StyledLayout>
    // </Page>
  )
})

export default PersonalSetting
