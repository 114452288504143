/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { Checkbox, Tooltip, Divider } from 'antd'
import { Observer, observer } from 'mobx-react'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { FileListStyle } from './style'
import { FileName } from './FileName'
import { FileProgress } from './FileProgress'
import { TreeTable } from '@/components'
import { Button } from '@ys/components'
import { FileTree } from '@/domain/JobBuilder/FileTree'
import Draft from '@/domain/Box/Draft'
import { useStore } from '../store'
import { useStore as JobStore, zoneMap } from '../../../store'
import { buryPoint } from '@/utils'
import { showTextEditor } from '@/components/TextEditor'
import { boxServer, newBoxServer } from '@/server'
import { EDITABLE_SIZE } from '@/constant'
import { useTranslation } from 'react-i18next'

interface IProps {
  fileTree: FileTree
  draft: Draft
}
const isImage = type => /gif|jpe?g|tiff|png|webp|bmp$/i.test(type)

export const FileList = observer(({ fileTree, draft }: IProps) => {
  const { t } = useTranslation()
  const store = useStore()
  const { zoneKey, moduleZoneKey, moduleZoneId } = JobStore()
  const columns = [
    {
      title: <span>{t('TheFileName')} <span style={{color: '#D5D5D7', fontWeight: 'normal'}}>（{zoneKey !== moduleZoneKey ? t('SuggestedThatFileInARegionalOperation,InterregionalTransmissionMayAffectTheFileTransmissionSpeed') : zoneMap[moduleZoneKey]}）</span></span>,
      key: 'name',
      render: (text, record, index, isExpand) => (
        <FileName
          record={record}
          isExpand={isExpand}
          createFolder={store.createFolder}
          copyFilesFromFileManager={store.copyFilesFromFileManager}
          upload={store.upload}
          draft={draft}
        />
      )
    },
    {
      title: t('TheFileSize'),
      key: 'displaySize',
      width: 140,
      render: (text, record) => {
        if (!record.parent) {
          return <span className='disabled'>/</span>
        }
        if (record.isFile) {
          return text
        }
        return '--'
      }
    },
    {
      title: t('state'),
      key: 'status',
      width: 120,
      render: (text, record) => {
        if (!record.parent) {
          return <span className='disabled'>/</span>
        }
        if (record.isFile) {
          return <FileProgress file={record} />
        }
        return '--'
      }
    },
    {
      title: t('MasterFile'),
      key: 'mainFile',
      width: 160,
      headerRender: text => {
        return (
          <div className='mainFile'>
            <span style={{ color: '#F5222D', marginRight: '2px' }}>*</span>
            {text}
          </div>
        )
      },
      render: (text, record) => {
        if (!record.parent) {
          return <span className='disabled'>/</span>
        }
        return (
          <Observer>
            {() => (
              <div className='mainFileChecker'>
                <Tooltip
                  title={
                    record.name.includes(' ') ? t('TheMainFileNameCannotContainSpaces') : ''
                  }>
                  <Checkbox
                    disabled={!record.isFile || record.name.includes(' ')}
                    value={record.id}
                    checked={record.isMain}
                    onChange={e => {
                      if (e.target.checked) {
                        buryPoint({
                          category: t('JobSubmission'),
                          action: t('SetTheMainFile')
                        })
                      }
                      record.isMain = e.target.checked
                    }}>
                    {record.isFile ? t('MainlySetFile') : t('DoNotSet')}
                  </Checkbox>
                </Tooltip>
              </div>
            )}
          </Observer>
        )
      }
    },
    {
      title: t('operation'),
      key: 'options',
      width: 180,
      render: (text, record) =>
        record.parent ? (
          <>
            <Button
              type='link'
              onClick={() => {
                buryPoint({
                  category: t('JobSubmission'),
                  action: t('delete')
                })
                store.deleteNode(record.id, t)
              }}>
              {t('delete')}
            </Button>
            <Divider type='vertical' />
            <Button
              type='link'
              disabled={!record.isFile || isImage(record.name)}
              onClick={() => {
                buryPoint({
                  category: t('JobSubmission'),
                  action: t('TheEditor')+ '/' +t('ToView')
                })
                showTextEditor({
                  path: record.path,
                  bucket: 'draft',
                  readonly: !(record.isFile && record.size <= EDITABLE_SIZE),
                  bucket_keys: { draft_type: draft.type },
                  zoneId: moduleZoneId,
                  boxServerUtil: newBoxServer,
                })
              }}>
              {record.isFile && record.size <= EDITABLE_SIZE ? t('TheEditor') : t('ToView')}
            </Button>
          </>
        ) : (
          <span className='disabled'>/</span>
        )
    }
  ]

  return (
    <FileListStyle className='uploadedFileTree'>
      <TreeTable
        dataSource={[fileTree]}
        columns={columns}
        expandedKeys={store.expandedKeys}
        onExpand={store.onExpand}
        rowKey='id'
        expandIcon={isExpand =>
          isExpand ? <CaretDownOutlined /> : <CaretRightOutlined />
        }
        onDragEnd={store.onDrop}
      />
    </FileListStyle>
  )
})
