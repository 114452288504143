/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect, useMemo } from 'react'
import { Checkbox, InputNumber, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as FormField from '@/components/FormField'
import { scList, env, currentUser } from '@/domain'
import { FieldType } from '@/domain/Application/AppParam'
import { history, Http } from '@/utils'
import { getUrlParams } from '@ys/utils'
import { PageWrapper } from './style'
import { BottomAction, InputFiles, Section } from './components'
import { QuestionCircleFilled } from '@ant-design/icons'
import { LightComp } from './NewComer/Light'
import { useLocalStore } from 'mobx-react-lite'
import { Provider, useStore } from './store'
import styled from 'styled-components'
import { AppListTabs } from './AppListTabs'
import { AppList } from './AppList'
import { RemainingResouce } from './RemainingResouce'

const JobCreator = observer((props: Props) => {
  const { id, type, mode } = useMemo(
    () => getUrlParams(),
    [window.location.search]
  )

  const store = useStore()
  const { data, fileTree, params } = store

  useEffect(() => {
    if (['redeploy', 'continuous'].includes(mode as string)) {
      store.setJobBuilderMode(mode as any, {
        id: id as string,
        type: type as any
      })

      store.setUnblock(
        history.block(() => '离开页面将不会保留当前工作，确认要离开页面吗？')
      )

      store.setUnlisten(
        history.listen(location => {
          // 仍需跳转到当前页面时reload
          location.pathname === '/job-creator' && window.location.replace('/')
        })
      )
    } else {
      store.setJobBuilderMode('default')
    }

    store.init()

    return () => {
      store.removeHistoryBlock()
      store.reset()
    }
  }, [id, mode, store, type])

  useEffect(() => {
    // 点击重置按钮后 此时app为空 自动选择第一个app
    if (!store.data.currentApp) {
      store.updateData({
        currentApp: store.apps.filter(app => app.is_trial === store.is_trial)[0]
      })
      return
    }
    store.fetchParams()
  }, [store.data.currentApp])

  useEffect(() => {
    if (store.data.paramsModel.isTyping) {
      if (store.isInRedeployMode) return

      localStorage.setItem(
        store.draftKey,
        JSON.stringify({
          ...data,
          user_id: currentUser.id,
          project_id: env.project?.id
        })
      )
    }
  }, [
    store.data,
    store.mainFilePaths,
    store.currentAppId,
    store.data.paramsModel.isTyping
  ])

  const state = useLocalStore(() => ({
    burstConfig: {
      timeBeforeBoost: '0',
      cloudType: 'none'
    },
    setBurstConfig(data) {
      this.burstConfig = data
    },
    get isMixed() {
      return this.burstConfig.cloudType === 'mixed'
    },
    // 显示试用软件
    get showTrialApps() {
      return env.isPersonal && !env.isFuture
    },
    // 显示混合云提示   条件 0.是混合云配置  1.有云端资源  2.选中了本地选项至少一
    get showMixedPrompt() {
      const localList = store.scs_cores_list.filter(o => o.is_local)

      return (
        this.isMixed &&
        store.scs_cores_list.length - localList.length > 0 &&
        localList.findIndex(o => store.data.scIds.includes(o.sc_id)) > -1
      )
    },
    // 显示智能调度提示    条件 0.未勾选本地资源  1.勾选了大于一个的云端资源
    get showAISchedulePrompt() {
      const localList = store.scs_cores_list.filter(
        scs_core => scs_core.is_local
      )

      return (
        localList.findIndex(o => store.data.scIds.includes(o.sc_id)) < 0 &&
        store.data.scIds.filter(id => !localList.map(o => o.sc_id).includes(id))
          .length > 1
      )
    },

    get isCoreValid() {
      if (store.data.scIds.length === 0) {
        return false
      } else if (
        store.data.numCores === null ||
        store.data.numCores === undefined
      ) {
        return true
      } else {
        return store.displayCoreValid
      }
    }
  }))

  const getComponentByType = (type: string) => {
    return FormField[
      {
        [FieldType.text]: 'Input',
        [FieldType.list]: 'Select',
        [FieldType.multiple]: 'MultiSelect',
        [FieldType.checkbox]: 'Checkbox',
        [FieldType.radio]: 'Radio',
        [FieldType.label]: 'Label',
        [FieldType.date]: 'Date',
        [FieldType.lsfile]: 'Input',
        [FieldType.texarea]: 'TextArea'
      }[type]
    ]
  }

  const { currentApp } = data

  useEffect(() => {
    // 获取企业配置
    if (!env.isPersonal) {
      Http.get('/company/config/list', {
        params: { configKeys: ['timeBeforeBoost', 'cloudType'] }
      }).then(({ data }) => {
        state.setBurstConfig(data)
      })
    }
  }, [currentApp])

  return (
    <PageWrapper id='job_creator'>
      <div className='input-content'>
        {env.isPersonal && <LightComp />}

        {state.showTrialApps ? <AppListTabs action={props.action} /> : <AppList is_trial={false} />}

        <Section title='上传模型'>
          <InputFiles draft={store.draft} fileTree={fileTree} />
        </Section>

        <Section title='参数配置' className='paramSettings'>
          {currentApp && (
            <div className='field'>
              <div className='label' title='算力资源'>
                <span className='required'>*</span>
                算力资源:
              </div>
              <Checkbox.Group
                className='calculate_selection'
                value={data.scIds}
                onChange={keys => {
                  store.updateData({
                    scIds: keys as string[],
                    numCores: null
                  })
                }}>
                {store.scs_cores_list?.map(({ sc_id }) => (
                  <Checkbox value={sc_id} key={sc_id}>
                    {scList.getName(sc_id)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          )}
          {(state.showMixedPrompt && (
            <div className='field-description'>
              <div className='post-text'>
                您的作业有云端资源，如勾选云端资源，本地排队{' '}
                {(+state.burstConfig.timeBeforeBoost).toFixed(2)}{' '}
                小时仍未开始计算，将会提交到云端进行作业。
              </div>
            </div>
          )) ||
            (state.showAISchedulePrompt && (
              <div className='field-description'>
                <div className='post-text'>
                  选择多个超算资源，进入智能调度模式
                </div>
              </div>
            ))}
          <RemainingResouce />
          <div className='field'>
            <div className='label' title='核数'>
              <span className='required'>*</span>核数
              {state.showMixedPrompt && (
                <Tooltip
                  placement='top'
                  title='核数可选范围由各所选超算资源的交集得出，实际计算核时不超过预期核数。'>
                  <QuestionCircleFilled
                    style={{ margin: '0 2px', color: 'rgba(0,0,0,0.45)' }}
                  />
                </Tooltip>
              )}
              :
            </div>
            <InputNumber
              style={{ width: '200px' }}
              value={data.numCores}
              onChange={value => {
                store.updateData({ numCores: +value })
              }}
              placeholder={store.displayCoresScope}
              parser={value => {
                if (Number.isNaN(parseInt(value))) {
                  return undefined
                }
                return parseInt(value)
              }}
            />
            <span>核</span>
            <div className='valid-core'>
              {!state.isCoreValid && store.displayCoresScope}
            </div>
            <div className='suggested-cores'>
              {store.data.currentApp &&
                store.suggestedCores &&
                +store.suggestedCores?.cores !== 0 &&
                `${store.data.currentApp?.type} 大部分的作业选择了 ${+store
                  .suggestedCores?.cores} 核`}
            </div>
          </div>
          {params.map(field => {
            const Field = getComponentByType(field.type)
            return (
              <Field
                key={field.id}
                model={field}
                formModel={data.paramsModel}
              />
            )
          })}
        </Section>
      </div>

      <BottomAction {...props} />
    </PageWrapper>
  )
})

export const StyledDiv = styled.div`
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
`

export default (props: { action?: any }) => (
  <Provider>
    <StyledDiv>
      <JobCreator {...props}  pushHistoryUrl='/jobs?tab=jobs' />
    </StyledDiv>
  </Provider>
)

export const StyledInDrawerJobCreatorDiv = styled.div`
  height: calc(100% - 20px);
`

type Props = {
  onOk?: () => void
  pushHistoryUrl?: string
  action?: any
}

export function InDrawerJobCreator(props: Props) {
  return (
    <Provider>
      <StyledInDrawerJobCreatorDiv>
        <JobCreator {...props} />
      </StyledInDrawerJobCreatorDiv>
    </Provider>
  )
}
