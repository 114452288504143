/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Form, Input, DatePicker } from 'antd'
import { useStore, initialQuery } from '../store'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { Button } from '@ys/components'
import { GeneralDatePickerRange } from '@/constant'
import { jobServer } from '@/server'
import { Selector } from './Selector'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next';

const StyledLayout = styled.div`
  display: flex;
  padding: 20px 20px 0px;

  > .left {
    flex: 1;

    .ant-form-inline .ant-form-item {
      margin-bottom: 16px;

      .ant-form-item-label {
        width: 70px;
        text-align: right;
      }
    }
  }

  > .right {
    width: 60px;
  }

  margin-bottom: -16px;
`

const { useForm } = Form
const { RangePicker } = DatePicker

type Props = {
  showJobSetName?: boolean
}

export const Filter = observer(function Filter(props: Props) {
  const { t } = useTranslation()
  const store = useStore()
  const [form] = useForm()
  const state = useLocalStore(() => ({
    loading: false,
    setLoading(flag) {
      this.loading = flag
    },
    filters: {
      app: [],
      user: [],
      state: [],
      download: [],
      jobset: []
    },
    setFilters(filters) {
      this.filters = filters
    }
  }))

  useEffect(() => {
    state.setLoading(true)
    jobServer
      .getFilters()
      .then(({ data }) => {
        state.setFilters({
          app: data.app_filters,
          user: data.user_filters,
          state: data.state_filters.map(item => ({
            key: item.key,
            name: {
              1: t('InTheOperationOfThe'),
              2: t('HasBeenSuccessfully'),
              3: t('failure'),
              4: t('HasBeenCancelled'),
              7: t('InTheQueue')
            }[item.name]
          })),
          download: data.download_filters.map(item => ({
            key: item.key,
            name: {
              0: t('InTheBack'),
              1: t('BackToComplete')
            }[item.name]
          })),
          jobset: data.jobset_filters
        })
      })
      .finally(() => {
        state.setLoading(false)
      })
  }, [])

  // fullfill form with initial query
  useEffect(() => {
    const { start_seconds, end_seconds } = store.query
    form.setFieldsValue({
      ...store.query,
      submit_time_range: [
        start_seconds ? moment.unix(+start_seconds) : undefined,
        end_seconds ? moment.unix(+end_seconds) : undefined
      ]
    })
  }, [])

  function submit() {
    form.submit()
  }

  const debounceSubmit = useCallback(
    debounce(function () {
      form.submit()
    }, 300),
    []
  )

  function onFinish(values) {
    const submitTime = values['submit_time_range']

    runInAction(() => {
      store.setQuery({
        job_id: values['job_id'],
        fuzzy_key: values['fuzzy_key'],
        user_filters: values['user_filters'],
        app_filters: values['app_filters'],
        state_filters: values['state_filters'],
        download_filters: values['download_filters'],
        jobset_filters: values['jobset_filters'],
        start_seconds: submitTime && submitTime[0]?.unix(),
        end_seconds: submitTime && submitTime[1]?.unix()
      })
      store.setPageIndex(1)
    })
  }

  function reset() {
    form.setFieldsValue({
      ...initialQuery,
      submit_time_range: [undefined, undefined]
    })
    submit()
  }

  return (
    <StyledLayout className='job-search-bar'>
      <div className='left'>
        <Form form={form} layout='inline' onFinish={onFinish}>
          <Form.Item label={t('JobName')} name='fuzzy_key'>
            <Input
              style={{ width: 220 }}
              placeholder={t('EnterTheJobNameSearch')}
              onChange={debounceSubmit}
            />
          </Form.Item>
          <Form.Item label={t('JobNumber')} name='job_id'>
            <Input
              style={{ width: 220 }}
              placeholder={t('EnterTheJobNumberSearch')}
              onChange={debounceSubmit}
            />
          </Form.Item>
          <Form.Item label={t('founder')} name='user_filters'>
            <Selector
              loading={state.loading}
              filters={state.filters.user}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item label={t('ApplicationSoftware')} name='app_filters'>
            <Selector
              loading={state.loading}
              filters={state.filters.app}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item label={t('JobStatus')} name='state_filters'>
            <Selector
              loading={state.loading}
              filters={state.filters.state}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item label={t('ReturnsStatus')} name='download_filters'>
            <Selector
              loading={state.loading}
              filters={state.filters.download}
              onChange={submit}
            />
          </Form.Item>
          {props?.showJobSetName !== false && (
            <Form.Item label={t('HomeworkSet')} name='jobset_filters'>
              <Selector
                loading={state.loading}
                filters={state.filters.jobset}
                onChange={submit}
              />
            </Form.Item>
          )}
          <Form.Item label={t('SubmitTime')} name='submit_time_range'>
            <RangePicker
              ranges={GeneralDatePickerRange}
              style={{ width: 400 }}
              showTime={{
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss')
                ]
              }}
              onChange={submit}
            />
          </Form.Item>
        </Form>
      </div>
      <div className='right'>
        <Button type='default' onClick={reset}>
          t('reset')
        </Button>
      </div>
    </StyledLayout>
  )
})
