/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Input, Upload, message } from 'antd'
import { v4 as uuid } from 'uuid'
import { Icon, Image, ToolBar } from '@/utils/general'
import { dispatchAction, handleFileOpen } from '@/actions'
import { serverFactory } from '@/components/NewFileMGT/store/common'
import { newBoxServer } from '@/server'
import { showFailure } from '@/components/NewFileMGT'
import { env, uploader, NewBoxHttp } from '@/domain'
import { globalSizes } from '@/domain/Box/states'
import { previewImage, showTextEditor } from '@/components'
import { EE, EE_CUSTOM_EVENT } from '@/utils/Event'
import { useTranslation } from 'react-i18next'
import './assets/fileexpo.css'
import { t } from 'testcafe'
const server = serverFactory(newBoxServer)
const { Dragger } = Upload
const NavTitle = props => {
  let src = props.icon || 'folder'

  return (
    <div
      className='navtitle flex prtclk'
      data-action={props.action}
      data-payload={props.payload}
      onClick={dispatchAction}>
      <Icon
        className='mr-1'
        src={'win/' + src + '-sm'}
        width={props.isize || 16}
      />
      <span>{props.title}</span>
    </div>
  )
}

const FolderDrop = ({ dir }) => {
  const files = useSelector(state => state.files)
  const folder = files.data.getId(dir)

  return (
    <>
      {folder.data &&
        folder.data.map((item, i) => {
          if (item.type == 'folder') {
            return (
              <Dropdown
                key={i}
                icon={item.info && item.info.icon}
                title={item.name}
                notoggle={item.data.length == 0}
                dir={item.id}
              />
            )
          }
        })}
    </>
  )
}

const Dropdown = props => {
  const [open, setOpen] = useState(props.isDropped != null)
  const special = useSelector(state => state.files.data.special)
  const [fid, setFID] = useState(() => {
    if (props.spid) return special[props.spid]
    else return props.dir
  })
  const toggle = () => setOpen(!open)

  return (
    <div className='dropdownmenu'>
      <div className='droptitle'>
        {!props.notoggle ? (
          <Icon
            className='arrUi'
            fafa={open ? 'faChevronDown' : 'faChevronRight'}
            width={10}
            onClick={toggle}
            pr
          />
        ) : (
          <Icon className='arrUi opacity-0' fafa='faCircle' width={10} />
        )}
        <NavTitle
          icon={props.icon}
          title={props.title}
          isize={props.isize}
          action={props.action != '' ? props.action || 'FILEDIR' : null}
          payload={fid}
        />
        {props.pinned != null ? (
          <Icon className='pinUi' src='win/pinned' width={16} />
        ) : null}
      </div>
      {!props.notoggle ? (
        <div className='dropcontent'>
          {open ? props.children : null}
          {open && fid != null ? <FolderDrop dir={fid} /> : null}
        </div>
      ) : null}
    </div>
  )
}

const ContentArea = ({ searchtxt, zIndex }) => {
  const files = useSelector(state => state.files)
  const special = useSelector(state => state.files.data.special)
  const [selected, setSelect] = useState([])
  const [selectedName, setSelectName] = useState([])
  const [selectedObj, setSelectObj] = useState([])
  const fdata = files.data.getId(files.cdir)
  const dispatch = useDispatch()
  const container = useRef()
  const selection = useRef()
  const uploadWrap = useRef()
  const gridshow = useRef()
  const contentwrap = useRef()
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [endX, setEndX] = useState(0)
  const [endY, setEndY] = useState(0)
  const { t: tr } = useTranslation()
  const [fileManageWrap, setFileManageWrap] = useState(null)

  const handleClick = e => {
    if (!e.shiftKey) {
      e.stopPropagation()
      setSelect([e.target.dataset.id])
      setSelectName([e.target.dataset.name])
      // setSelectPath([e.target.dataset.path])
      setSelectObj([e.target.dataset])
    }
  }

  const handleDouble = e => {
    // 双击根据id获取
    if (!e.shiftKey) {
      e.stopPropagation()
      e.target.dataset.type !== 'folder' &&
        handleFileDoubleClick(e.target.dataset)
      handleFileOpen(e.target.dataset.id, server)
    }
  }

  const handleFileDoubleClick = async ({ type, path, size, name }) => {
    switch (true) {
      case /je?pg|png|gif|img/gi.test(type):
        const url = await server.getFileUrl(
          [path],
          [true],
          [size],
          true,
          env.getOpenZoneId
        )
        previewImage({ fileName: name, src: url })
        return
      case /unknownfile|txt|dmg|mp4|pdf/gi.test(type):
        showTextEditor({
          path,
          bucket: 'common',
          readonly: true,
          zoneId: env.getOpenZoneId,
          boxServerUtil: newBoxServer
        })
        return
    }
  }

  const emptyClick = e => {
    !endY && e.target.dataset?.parentcontent && setSelect([]) // 点击的是非app的空白地方
    dispatch({
      type: 'FILEOPERATE',
      payload: 'rename'
    })
  }

  // const handleKey = e => {
  //   if (e.key == 'Backspace') {
  //     dispatch({ type: 'FILEPREV' })
  //   }
  // }

  useEffect(() => {
    if (!fileManageWrap) {
      const fileManageWrap = document.getElementById('explorerApp')
      setFileManageWrap(fileManageWrap)
      server
        .fetch('/', {
          // is_dir: true,
          zoneId: env.getOpenZoneId
        })
        .then(res => {
          dispatch({
            type: 'generateFiles',
            payload: res._children
          })
        })
    }
  }, [])

  useEffect(() => {
    if (fdata) {
      server.fetch(fdata.path || '/', {
        zoneId: env.getOpenZoneId
      }).then(res => {
        dispatch({ type: 'FILEDIR', payload: fdata.id, data: res._children })
      })
    }
  }, [zIndex, files.cdir])

  useEffect(() => {
    setSelect([])
    setSelectName([])
    // setSelectPath([])
    setSelectObj([])
  }, [files.cdir])

  const handleBlur = (e, path, id, name) => {
    dispatch({
      type: 'FILEOPERATE',
      payload: 'handleRename',
      data: {
        targetPath: e.target.value,
        originPath: path,
        id,
        name
      }
    })
  }

  const handleMouseDown = e => {
    // 如果同时按下Shift键进行多选或反选
    if (e.shiftKey && !e.target.dataset?.parentcontent) {
      const target = e.target
      // document.querySelectorAll('.conticon').forEach(el => {
      // if (target === el) {
      if (!target.dataset.focus || target.dataset.focus !== 'true') {
        target.classList.add('selected')
        target.dataset.focus = 'true'
        setSelect([...selected, target.dataset.id])
        setSelectName([...selectedName, target.dataset.name])
        // setSelectPath([...selectedPath, target.dataset.path])
        setSelectObj([...selectedObj, target.dataset])
      } else if (target.dataset.focus === 'true') {
        // 反选
        target.classList.remove('selected')
        target.dataset.focus = 'false'
        setSelect(selected.filter(item => item !== target.dataset.id))
        setSelectName(selectedName.filter(item => item !== target.dataset.name))
        // setSelectPath([...selectedPath, target.dataset.path])
        setSelectObj(selectedObj.filter(item => item.id !== target.dataset.id))
      }
      // }
      // })
      return
    } else if (e.button === 0) {
      let startX, startY
      const selectionItem = selection.current
      selectionItem.style.width && (selectionItem.style.width = '0')
      selectionItem.style.height && (selectionItem.style.height = '0')
      startX = e.clientX - 90
      startY = e.clientY - 60
      if (fileManageWrap.style.left || fileManageWrap.dataset.size === 'mini') {
        const left = parseInt(fileManageWrap.style.left) || 300
        startX = e.clientX - left
      }
      if (fileManageWrap.style.top || fileManageWrap.dataset.size === 'mini') {
        const top = parseInt(fileManageWrap.style.top) + 60 || 116
        console.log('top: ', top)
        startY = e.clientY - top
      }
      setStartX(startX)
      setStartY(startY)

      selectionItem.style.display = 'block'
      selectionItem.style.top = startY + 'px'
      selectionItem.style.left = startX + 'px'

      // 左键按下时清除已选择元素的标记
      // document.querySelectorAll('.conticon.selected').forEach(el => {
      //   el.classList.remove('selected')
      //   el.dataset.focus = 'false'
      // })
    }
    // else if (e.button === 2 && selected.length > 0) {
    //   gridshowItem.dataset.menu = 'rightMenu'
    //   contentwrapItem.dataset.menu = 'rightMenu'
    // } else {
    //   gridshowItem.dataset.menu = 'fileManager'
    //   contentwrapItem.dataset.menu = 'fileManager'
    // }
  }

  const isIntersect = (rect1, rect2) => {
    return !(
      rect2.left > rect1.right ||
      rect2.right < rect1.left ||
      rect2.top > rect1.bottom ||
      rect2.bottom < rect1.top
    )
  }

  const handleMouseMove = e => {
    let endX, endY
    const selectionItem = selection.current
    if (selectionItem.style.display === 'block') {
      endX = e.clientX - 90
      endY = e.clientY - 60
      if (fileManageWrap.style.left || fileManageWrap.dataset.size === 'mini') {
        const left = parseInt(fileManageWrap.style.left) || 300
        endX = e.clientX - left
      }
      if (fileManageWrap.style.top || fileManageWrap.dataset.size === 'mini') {
        const top = parseInt(fileManageWrap.style.top) + 60 || 116
        endY = e.clientY - top
      }
      setEndX(endX)
      setEndY(endY)

      selectionItem.style.width = Math.abs(endX - startX) + 'px'
      selectionItem.style.height = Math.abs(endY - startY) + 'px'
      selectionItem.style.top = Math.min(endY, startY) + 'px'
      selectionItem.style.left = Math.min(endX, startX) + 'px'

      // 遍历每个元素，检查是否与选择框相交
      document.querySelectorAll('.conticon').forEach(el => {
        const rect = el.getBoundingClientRect()
        if (isIntersect(rect, selectionItem.getBoundingClientRect())) {
          el.classList.add('selected')
          el.dataset.focus = 'true'
        } else {
          el.dataset.focus = 'false'
          el.classList.remove('selected')
        }
      })
    }
  }
  const handleMouseUp = e => {
    // 收集选中的id
    const collectId = []
    const collectName = []
    const collectPath = []
    const collectObj = []
    document.querySelectorAll('.conticon').forEach(el => {
      if (el.dataset.focus === 'true') {
        collectId.push(el.dataset.id)
        collectName.push(el.dataset.name)
        // collectPath.push(el.dataset.path)
        collectObj.push(el.dataset)
      }
    })
    setSelect(collectId)
    setSelectName(collectName)
    // setSelectPath(collectPath)
    setSelectObj(collectObj)
    // gridshow.current.dataset.menu = 'rightMenu'
    // contentwrap.current.dataset.menu = 'rightMenu'
    selection.current.style.display = 'none'
  }

  // 阻止默认事件Function
  const forbidDefaultEvent = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const collectFiles = files => {
    let fileArray = []
    for (let i = 0; i < files.length; i++) {
      const entry = files[i].webkitGetAsEntry()
      console.log('file: ', entry)
      if (entry.isDirectory) {
        const reader = entry.createReader()
        reader.readEntries(en => {
          console.log(en, 'en')
          en.forEach(item => {
            if (item.isDirectory) {
              collectFiles(item)
            } else {
              fileArray.push(item)
            }
          })
        })
      } else {
        fileArray.push(entry)
      }
    }
    console.log('fileArray: ', fileArray)
    return fileArray
  }

  // const collectFiles = (items, collectedFiles) => {
  //   // 检查每个拖拽项
  //   for (let i = 0; i < items.length; i++) {
  //     let item = items[i]
  //     if (item instanceof DataTransferItem) {
  //       if (item.isFile) {
  //         // 将文件对象添加到收集列表中
  //         collectedFiles.push(item.getAsFile())
  //       } else if (item.isDirectory) {
  //         // 如果是文件夹，则递归调用collectFiles函数
  //         let directoryReader = item.createReader()
  //         directoryReader.readEntries(function(entries) {
  //           collectFiles(entries, collectedFiles)
  //         })
  //       }
  //     }
  //   }
  // }

  // const dropHandler = function (e) {
  //   //将本地图片拖拽到页面中后要进行的处理都在这
  //   function dfs(entry, collectUploadFile) {
  //     if (entry.isDirectory) {
  //       // 目录
  //       const reader = entry.createReader()
  //       reader.readEntries(en => {
  //         console.log(en, 'en')
  //         en.forEach(item => {
  //           if (item.isDirectory) {
  //             dfs(item, collectUploadFile)
  //           } else {
  //             item.file(f => {
  //               collectUploadFile.push(f)
  //             })
  //           }
  //         })
  //       })
  //     } else {
  //       // 文件
  //       entry.file(f => {
  //         collectUploadFile.push(f)
  //       })
  //     }
  //     console.log('collectUploadFile----: ', collectUploadFile)
  //     // return collectUploadFile
  //   }
  //   for (const item of e.dataTransfer.items) {
  //     const entry = item.webkitGetAsEntry()
  //     const collect = []
  //     if (!entry.isDirectory) {
  //       entry.file(f => {
  //         console.log('f: ', f)
  //         collect.push(f)
  //       })
  //     } else {
  //       dfs(entry, [])
  //     }
  //   }
  //   // dispatch({
  //   //   type: 'UPLOAD',
  //   //   payload: false,
  //   //   callback: uploadCallBack
  //   // })
  // }
  useEffect(() => {
    document.addEventListener('drop', forbidDefaultEvent, false)
    document.addEventListener('dragover', forbidDefaultEvent, false)
    const contentarea = container.current
    contentarea.addEventListener(
      'dragover',
      event => {
        // TODO: 拖拽上传
        // uploadWrap.current.style.display = 'block'
      },
      false
    )
    contentarea.addEventListener(
      'drop',
      event => {
        // uploadWrap.current.style.display = 'none'
      },
      false
    )

    // contentarea.addEventListener(
    //   'drop',
    //   event => {
    //     // const res = collectFiles(event.dataTransfer.items)
    //     // console.log('res: ', res)
    //     uploadWrap.current.style.display = 'block'
    //   },
    //   false
    // )
    return () => {
      document.removeEventListener('drop', forbidDefaultEvent, false)
      document.removeEventListener('dragover', forbidDefaultEvent, false)
    }
  }, [])

  const uploadCallBack = async (path, id) => {
    const fileList = await server.fetch(path, {
      zoneId: env.getOpenZoneId
    })
    dispatch({ type: 'FILEDIR', payload: id, data: fileList._children })
  }

  const customRequest = async ({ file }) => {
    const uniqueID = uuid()
    const dirFinal = `/${(fdata.path || '/').replace(/^\//, '')}`
    const resolvedFiles = []
    const rejectedFiles = []
    const filePath = file.webkitRelativePath || file.name
    const fileName = filePath.split('/')[0]

    if (getDuplicate({ id: undefined, name: fileName, data: fdata.data })) {
      rejectedFiles.push(file)
    } else {
      resolvedFiles.push(file)
    }

    globalSizes[uniqueID] += file.size

    console.log('rejectedFiles: ', rejectedFiles, resolvedFiles)
    if (rejectedFiles.length > 0) {
      const coverNodes = await showFailure({
        actionName: tr('upload'),
        items: rejectedFiles.map(item => ({
          name: item.name,
          uid: item.uid,
          isFile: true
        }))
      })
      if (coverNodes.length > 0) {
        await server.delete(
          coverNodes.map(item => `${dirFinal}/${item.name}`),
          env.getOpenZoneId
        )
        resolvedFiles.push(...coverNodes)
      }
      // }
    }

    if (resolvedFiles.length > 0) {
      message.success(tr('startUploadMsg'))
      // 触发显示 dropdown
      EE.emit(EE_CUSTOM_EVENT.TOGGLE_UPLOAD_DROPDOWN, { visible: true })
    }
  }
  const getDuplicate = item => {
    let duplicateNode = null

    item.data.every(child => {
      // check duplicate name
      if (child.name === item.name && item.id !== child.id) {
        duplicateNode = child
        return false
      }

      return true
    })

    return duplicateNode
  }

  const props = {
    name: 'file',
    multiple: true,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    directory: true,
    customRequest,
    beforeUpload: async files => {
      console.log('files: ', files);
    },
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
        EE.emit(EE_CUSTOM_EVENT.TOGGLE_UPLOAD_DROPDOWN, { visible: false })
        uploadCallBack(fdata.path || '/', fdata.id)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
      if (!env.getOpenZoneId) {
        return message.warning(tr('storeCheck'))
      }
      const res = collectFiles(e.dataTransfer.items)
      console.log('res: ', res)
      uploader.fileList = [...e.dataTransfer.files]
    }
  }

  return (
    <div
      className='contentarea'
      onClick={emptyClick}
      // onKeyDown={handleKey}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      data-parentcontent='parentcontent'
      ref={container}
      tabIndex='-1'>
      <div
        className='contentwrap win11Scroll'
        data-currid={files?.cdir}
        ref={contentwrap}
        data-parentcontent='parentcontent'
        data-selected={selected.join(',')}
        data-selectedname={selectedName.join(',')}
        // data-selectedpath={selectedPath.join(',')}
        data-selectedobj={JSON.stringify(selectedObj)}
        data-menu={selected?.length > 0 ? 'rightMenu' : 'fileManager'}>
        <div
          className='gridshow'
          ref={gridshow}
          data-size='lg'
          data-parentcontent='parentcontent'
          data-currid={files?.cdir}
          data-selected={selected.join(',')}
          data-selectedname={selectedName.join(',')}
          // data-selectedpath={selectedPath.join(',')}
          data-selectedobj={JSON.stringify(selectedObj)}
          data-menu={selected?.length > 0 ? 'rightMenu' : 'fileManager'}>
          {(fdata?.data || []).map((item, i) => {
            return (
              item.name.includes(searchtxt) && (
                <Tooltip title={item.name}>
                  <div
                    key={i}
                    data-menu='fileMenu'
                    className='conticon hvtheme flex flex-col items-center prtclk'
                    data-id={item.id}
                    data-originid={item.originId}
                    data-type={item.type}
                    data-selected={selected.join(',')}
                    data-selectedname={selectedName.join(',')}
                    data-path={item.path}
                    data-size={item.size}
                    data-name={item.name}
                    data-isfile={item.isFile}
                    data-focus={selected?.includes(item.id)}
                    onClick={handleClick}
                    onDoubleClick={handleDouble}>
                    <Image src={`icon/win/${item.info.icon}`} />
                    {item.editFlag ? (
                      <Input
                        size='small'
                        autoFocus
                        onFocus={event => event.target.select()}
                        onPressEnter={e =>
                          handleBlur(e, item.path, item.id, item.name)
                        }
                        defaultValue={item.name}
                        onBlur={e =>
                          handleBlur(e, item.path, item.id, item.name)
                        }
                      />
                    ) : (
                      <span>{item.name}</span>
                    )}
                  </div>
                </Tooltip>
              )
            )
          })}
        </div>
        {fdata?.data?.length == 0 ? (
          <span className='text-xs mx-auto'>This folder is empty.</span>
        ) : null}
      </div>
      <div id='selection' ref={selection}></div>
      <div id='uploadWrap' ref={uploadWrap}>
        <Dragger {...props}></Dragger>
      </div>
    </div>
  )
}

const NavPane = ({ }) => {
  const files = useSelector(state => state.files)
  const special = useSelector(state => state.files.data.special)

  return (
    <div className='navpane win11Scroll'>
      <div className='extcont'>
        <Dropdown icon='star' title='Quick access' action='' isDropped>
          <Dropdown
            icon='down'
            title='Downloads'
            spid='%downloads%'
            notoggle
            pinned
          />
          <Dropdown icon='user' title='Blue' spid='%user%' notoggle pinned />
          <Dropdown
            icon='docs'
            title='Documents'
            spid='%documents%'
            notoggle
            pinned
          />
          <Dropdown title='Github' spid='%github%' notoggle />
          <Dropdown icon='pics' title='Pictures' spid='%pictures%' notoggle />
        </Dropdown>
        <Dropdown icon='onedrive' title='OneDrive' spid='%onedrive%' />
        <Dropdown icon='thispc' title='This PC' action='' isDropped>
          <Dropdown icon='desk' title='Desktop' spid='%desktop%' />
          <Dropdown icon='docs' title='Documents' spid='%documents%' />
          <Dropdown icon='down' title='Downloads' spid='%downloads%' />
          <Dropdown icon='music' title='Music' spid='%music%' />
          <Dropdown icon='pics' title='Pictures' spid='%pictures%' />
          <Dropdown icon='vid' title='Videos' spid='%videos%' />
          <Dropdown icon='disc' title='OS (C:)' spid='%cdrive%' />
          <Dropdown icon='disk' title='Blue (D:)' spid='%ddrive%' />
        </Dropdown>
      </div>
    </div>
  )
}

const Ribbon = ({ }) => {
  const dispatch = useDispatch()
  const { t: tr } = useTranslation()
  const uploadCallBack = async (path, id) => {
    const fileList = await server.fetch(path, {
      zoneId: env.getOpenZoneId
    })
    dispatch({ type: 'FILEDIR', payload: id, data: fileList._children })
  }
  const upload = payload => {
    dispatch({
      type: 'UPLOAD',
      payload,
      callback: uploadCallBack
    })
  }
  const handleCreateFile = () => {
    dispatch({
      type: 'CREATEFILEDIR',
      payload: 'createFile',
      t: tr
    })
  }
  return (
    <div className='msribbon flex'>
      <div className='ribsec'>
        <Tooltip title={tr('file.createFolder')}>
          <div className='drdwcont flex' onClick={handleCreateFile}>
            <Icon src='new' ui width={18} margin='0 6px' />
            <span>New</span>
          </div>
        </Tooltip>
      </div>
      <div className='ribsec'>
        {/* <Icon src='cut' ui width={18} margin='0 6px' />
        <Icon src='copy' ui width={18} margin='0 6px' />
        <Icon src='paste' ui width={18} margin='0 6px' />
        <Icon src='rename' ui width={18} margin='0 6px' />
        <Icon src='share' ui width={18} margin='0 6px' /> */}
        <Tooltip title={tr('file.uploadFile')}>
          <div className='upload_wrap' onClick={() => upload(false)}>
            <Icon src='uploadFile' ui width={18} margin='0 6px' />
          </div>
        </Tooltip>
        <Tooltip title={tr('file.uploadFolder')}>
          <div className='upload_wrap' onClick={() => upload(true)}>
            <Icon src='uploadFolder' ui width={18} margin='0 6px' />
          </div>
        </Tooltip>
      </div>
      <div className='ribsec'>
        {/* <div className='drdwcont flex'>
          <Icon src='sort' ui width={18} margin='0 6px' />
          <span>Sort</span>
        </div> */}
        <Tooltip title={tr('transferWindow')}>
          <div className='drdwcont flex' onClick={() => EE.emit(EE_CUSTOM_EVENT.TOGGLE_UPLOAD_DROPDOWN, { visible: true })}>
            <Icon src='list' ui width={18} margin='0 6px' />
            <span>{tr('transferWindow')}</span>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export const Explorer = ({ zIndex }: { zIndex: number | string }) => {
  // const apps = useSelector(state => state.apps)
  // const wnapp = useSelector(state => state.apps.explorer)
  const files = useSelector(state => state.files)
  const fdata = files.data.getId(files.cdir)
  const [cpath, setPath] = useState(files.cpath)
  const [searchtxt, setShText] = useState('')
  const dispatch = useDispatch()

  const handleChange = e => setPath(e.target.value)
  const handleSearchChange = e => setShText(e.target.value)

  const handleEnter = e => {
    if (e.key === 'Enter') {
      dispatch({ type: 'FILEPATH', payload: cpath })
    }
  }

  const DirCont = () => {
    let arr = [],
      curr = fdata,
      index = 0

    while (curr) {
      arr.push(
        <div key={index++} className='dirCont flex items-center'>
          <div
            className='dncont'
            onClick={dispatchAction}
            tabIndex='-1'
            data-action='FILEDIR'
            data-payload={curr.id}>
            {curr.name}
          </div>
          <Icon className='dirchev' fafa='faChevronRight' width={8} />
        </div>
      )

      curr = curr.host
    }

    arr.push(
      <div key={index++} className='dirCont flex items-center'>
        <div className='dncont' tabIndex='-1'>
          This PC
        </div>
        <Icon className='dirchev' fafa='faChevronRight' width={8} />
      </div>
    )

    arr.push(
      <div key={index++} className='dirCont flex items-center'>
        <Icon
          className='pr-1 pb-px'
          src={'win/' + fdata?.info?.icon + '-sm'}
          width={16}
        />
        <Icon className='dirchev' fafa='faChevronRight' width={8} />
      </div>
    )

    return (
      <div key={index++} className='dirfbox h-full flex'>
        {arr.reverse()}
      </div>
    )
  }

  useEffect(() => {
    setPath(files.cpath)
    setShText('')
  }, [files.cpath])

  return (
    <>
      <Ribbon />
      <div className='restWindow flex-grow flex flex-col'>
        <div className='sec1'>
          <Icon
            className={'navIcon hvtheme' + (files.hid == 0 ? ' disableIt' : '')}
            fafa='faArrowLeft'
            width={14}
            click='FILEPREV'
            pr
          />
          <Icon
            className={
              'navIcon hvtheme' +
              (files.hid + 1 == files.hist.length ? ' disableIt' : '')
            }
            fafa='faArrowRight'
            width={14}
            click='FILENEXT'
            pr
          />
          <Icon
            className='navIcon hvtheme'
            fafa='faArrowUp'
            width={14}
            click='FILEBACK'
            pr
          />
          <div className='path-bar noscroll' tabIndex='-1'>
            <input
              className='path-field'
              type='text'
              value={cpath}
              onChange={handleChange}
              onKeyDown={handleEnter}
            />
            <DirCont />
          </div>
          <div className='srchbar'>
            <Icon className='searchIcon' src='search' width={12} />
            <input
              type='text'
              onChange={handleSearchChange}
              value={searchtxt}
              placeholder='Search'
            />
          </div>
        </div>
        <div className='sec2'>
          {/* <NavPane /> */}
          <ContentArea searchtxt={searchtxt} zIndex={zIndex} />
        </div>
        <div className='sec3'>
          <div className='item-count text-xs'>
            {fdata?.data?.length || 0} items
          </div>
          {/* <div className='view-opts flex'>
            <Icon
              className='viewicon hvtheme p-1'
              click='FILEVIEW'
              payload='5'
              open={files.view == 5}
              src='win/viewinfo'
              width={16}
            />
            <Icon
              className='viewicon hvtheme p-1'
              click='FILEVIEW'
              payload='1'
              open={files.view == 1}
              src='win/viewlarge'
              width={16}
            />
          </div> */}
        </div>
      </div>
    </>
  )
}
