/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { useDispatch } from 'react-redux'
import { BottomActionStyle } from './style'
import { Button, Modal } from '@ys/components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Completeness } from './Completeness'
import { account, env } from '@/domain'
import { useStore } from '../store'
import { showSummary } from './Summary'
import { buryPoint, getFilenameByPath, history } from '@/utils'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = {
  onOk?: () => void
  pushHistoryUrl?: string
}

export const BottomAction = observer(({ onOk, pushHistoryUrl }: Props) => {
  const store = useStore()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    get prerequisites() {
      return [
        {
          label: t('ApplicationOfChoice'),
          key: 'ApplicationOfChoice',
          isCompleted: !!store.data.currentApp?.id
        },
        {
          label: t('UploadTheModel'),
          key: 'UploadTheModel',
          isCompleted:
            store.fileTree.flatten().filter(node => node.status === 'done')
              .length > 0 &&
            store.fileTree.filterNodes(
              file => file.isFile && file.status !== 'done'
            ).length === 0
        },
        {
          label: t('SetTheMainFile'),
          key: 'SetTheMainFile',
          isCompleted: store.mainFiles?.length > 0
        },
        {
          label: t('ParameterConfiguration'),
          key: 'ParameterConfiguration',
          isCompleted: store.data.scIds.length > 0 && store.displayCoreValid
        }
      ]
    },
    async onSubmit() {
      buryPoint({
        category: t('JobSubmission'),
        action: t('submit')
      })
      const map = new Map()
      const jobs = await showSummary({
        store,
        jobs: store.mainFiles.map(file => {
          let jobName = getFilenameByPath(file.path)

          if (map.has(jobName)) {
            let num = map.get(jobName)
            map.set(jobName, num + 1)
            jobName = `${jobName}_${num}`
          } else {
            map.set(jobName, 1)
          }

          return {
            mainFile: file.path,
            jobName,
            content: ''
          }
        })
      }, t)
      const isSuccess = await store.create(jobs)
      if (isSuccess) {
        store.removeHistoryBlock()
        message.success(t('WorkToCreateSuccess'))
        dispatch({
          type: 'JOBMANAGE',
          payload: 'togg'
        })
        onOk && onOk()
        pushHistoryUrl && history.push(pushHistoryUrl)
      }
    },
    async onCancel() {
      const content = {
        default: t('WillResetToEmptyWorkspaceFileAndHaveToFillInParameters,IfConfirmed'),
        redeploy: t('ResetWillResumeToSubmitAJobAgainWhenParameter,IfConfirmed'),
        continuous: t('ResetWillResumeContinuedToSubmitHomeworkParameterIsConfirmed')
      }[store.jobBuildMode]

      await Modal.showConfirm({
        title: t('reset'),
        content
      })

      buryPoint({
        category: t('JobSubmission'),
        action: t('reset')
      })

      await store.clean(true)
      message.success(t('HasBeenReset'))
    },
    get createBtnDisabled() {
      const { data, isJobSubmitting, fileTree } = store
      const { currentApp, paramsModel } = data

      if (!env.canSubmitJob) {
        return t('NoJobSubmission')
      }

      // if (env.boxExhaust) {
      //   return t('StorageSpaceHasRunOut')
      // }

      if (store.is_trial === true) {
        if (account.free_trail_balance <= 0) {
          return t('AfterTheTrialNuclearIsInsufficient,PleaseApplyForUse')
        }

        if (env.accountIsFreeze) {
          return t('YourAccountHasBeenFrozen,UnableToSubmitAJob,')+t('ContinueToUse,PleaseContactCustomerService')
        }
        if (account.account_balance < 0) {
          return t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
        }
      } else if (store.is_trial === false) {
        if (env.accountIsFreeze) {
          return t('YourAccountHasBeenFrozen,UnableToSubmitAJob,')+t('ContinueToUse,PleaseContactCustomerService')
        }
        if (env.accountFrozen) {
          return t('AccountBalanceIsInsufficient,PleasePrepaidPhoneAfterUse')
        }
      }

      const successFiles = store.fileTree
        .flatten()
        .filter(node => node.status === 'done')

      if (successFiles.length === 0) {
        return t('PleaseUploadTheModelToTheWorkingDirectory')
      }

      if (!store.mainFilePaths.length) {
        return t('PleaseSelectAtLeastOneMainFile')
      }

      if (
        store.fileTree.filterNodes(
          file => file.isFile && file.status !== 'done'
        ).length > 0
      ) {
        return t('PleaseWaitForAllTheFileUploadIsComplete')
      }

      if (store.data.scIds.length === 0) {
        return t('PleaseCheckTheWorkForceResources')
      }

      // if (!store.displayCoreValid) {
      //   return t('PleaseFillInTheCorrectNumberOfNuclear')
      // }

      if (!currentApp) {
        return t('PleaseChooseToApply')
      }

      for (const key of Object.keys(paramsModel)) {
        const param = paramsModel[key]
        if (
          param &&
          param.required &&
          !param.value &&
          param.values.length === 0
        ) {
          return `${t('PleaseFillOutThe')}${param.label}`
        }
      }

      const uploadingFile = fileTree
        .flatten()
        .find(node => node.isFile && node.status === 'uploading')
      if (uploadingFile) {
        return `${t('PleaseWaitFor')}${uploadingFile.name}t('UploadToComplete')`
      }

      return isJobSubmitting
    }
  }))

  return (
    <BottomActionStyle className='bottomToolbar'>
      <div className='formCompleteness'>
        <span className='label'>{t('HomeworkCompleteSituation')}：</span>
        {state.prerequisites.map((prerequisite, index) => (
          <span className='completeness' key={index}>
            <Completeness isCompleted={prerequisite.isCompleted} />
            {t(prerequisite.key)}
          </span>
        ))}
      </div>

      <div className='actions'>
        <Button onClick={state.onCancel}>{t('reset')}</Button>
        <Button
          type='primary'
          className='submitJob'
          onClick={state.onSubmit}
          disabled={state.createBtnDisabled}
          loading={store.isJobSubmitting}>
          {t('submit')}
        </Button>
      </div>
    </BottomActionStyle>
  )
})
