/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observer, useLocalStore } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { message, Switch } from 'antd'
import { jobServer } from '@/server'
import { useTranslation } from 'react-i18next'

type Props = {
  id: string
  name: string
  paused: boolean
}

export const Switcher = observer(function Switcher({
  id,
  name,
  paused,
}: Props) {
  const lockHandler = useRef(undefined)
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    locked: true,
    setLocked(bool) {
      this.locked = bool
    },
    loading: false,
    setLoading(flag) {
      this.loading = flag
    },
    localChecked: !paused,
    setLocalChecked(bool) {
      if (this.locked) {
        this.localChecked = bool
      }
    },
  }))

  useEffect(() => {
    state.setLocalChecked(!paused)
  }, [paused])

  useEffect(() => {
    return () => clearTimeout(lockHandler.current)
  }, [])

  return (
    <Switch
      checkedChildren={t('job.start')}
      unCheckedChildren={t('job.pause')}
      loading={state.loading}
      checked={state.localChecked}
      onChange={async checked => {
        state.setLoading(true)
        state.setLocked(false)

        try {
          await jobServer.pauseOrResume(id, checked)
          state.localChecked = checked
          message.success(`${t('job')} ${name} ${checked ? t('job.start') : t('job.pause')}${t('job.return')}`)
          // hack: t('BecauseBackStopIsAsynchronous')，t('AddA5')t('SecondLockCanPreventRefreshAutomaticallyResetSwitchState')
          lockHandler.current = setTimeout(() => state.setLocked(true), 5000)
        } finally {
          state.setLoading(false)
        }
      }}
    />
  )
})
