/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useDispatch } from 'react-redux'
import { useStore } from './store'
import { useStore as fatherStore } from '../store'
import { message, Pagination } from 'antd'
import { Table, Button } from '@ys/components'
import { Switcher } from './Switcher'
import { buryPoint, history } from '@/utils'
import { JobName } from './JobName'
import { JobStatus } from '@/components'
import { Operator } from './Operator'
import { Toolbar } from './Toolbar'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'

export { Context, useModel } from './store'

const zoneMap = {
  'az-shanghai': '华东1',
  'az-wuxi': '华东2',
  'az-jinan': '华东3',
  'ys-box': '远算盒子'
}

const StyledLayout = styled.div`
  > .body {
    padding: 16px 20px;
  }

  > .pagination {
    text-align: right;
    margin: 20px;
  }
`

const NameStyled = styled.div`
  max-width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};
`

type Props = {
  showJobSetName?: boolean
  operatable?: boolean
  onNameClick?: (id: string) => void
  zoneId?: string
}

export const JobList = observer(function JobList({
  showJobSetName = true,
  operatable = true,
  onNameClick,
  zoneId
}: Props) {
  const { t, i18n } = useTranslation()
  const store = useStore()
  const dispatch = useDispatch()
  const managerStore = fatherStore()
  const { model } = store
  const state = useLocalStore(() => ({
    get dataSource() {
      return model.list.map(item => {
        // t('TheRunningTime')
        function getDisplayRunTime(j) {
          if (!j.runtime?.cpu_time) return null

          const hour = Math.floor(j.runtime.cpu_time / 3600)
            .toString()
            .padStart(2, '0')
          const minute = Math.floor((j.runtime.cpu_time % 3600) / 60)
            .toString()
            .padStart(2, '0')
          const second = (j.runtime.cpu_time % 60).toString().padStart(2, '0')
          return `${hour}:${minute}:${second}`
        }

        return {
          ...item,
          deleteable: item.deleteable,
          showContinuousRedeploy: item.showContinuousRedeploy,
          cancelable: item.cancelable,
          downloadable: item.downloadable,
          residualVisible: !!item.runtime?.have_residual,
          monitorVisible:
            item?.runtime?.server_params?.map?.MONITOR_CHART_ENABLE
              ?.values[0] === 'yes' && item.display_state !== 7, // t('DoNotDisplayMonitorQueuingInTheAssignments')
          displayCpus: item.resource_usage.cpus || 0,
          displayRunTime: getDisplayRunTime(item),
          displayCreateTime: item.create_time.toString(),
          displayStartTime: item.runtime?.start_time?.toString(),
          displayEndTime: item.runtime?.end_time?.toString(),
          displayState: {
            // 1: t('InTheOperationOfThe'),
            // 2: t('HasBeenSuccessfully'),
            // 3: t('failure'),
            // 4: t('HasBeenCancelled'),
            // 7: t('InTheQueue')
            1: t('job.stateRunning'),
            2: t('job.succeed'),
            3: t('job.fail'),
            4: t('job.canceled'),
            7: t('job.queue')
          }[item.display_state]
        }
      })
    }
  }))

  function onPageChange(index, size) {
    runInAction(() => {
      store.setPageIndex(index)
      store.setPageSize(size)
      store.setSelectedKeys([])
    })
  }

  function onJobIdClick(id, file_storage_zone) {
    // t('IfYouClickOnTheListOfDataStorageAreaWithTheSelectionOfTheFilterAreaIsNotTheSameRiskIsOldData')，t('NeedSpecialHandling')
    !zoneId &&
      (zoneId = managerStore?.domainList.filter(
        item => item.zone === file_storage_zone
      )[0].storage_domains[0])
    buryPoint({
      category: t('JobManagement'),
      action: t('JobNumber')
    })
    history.push(`/new-job/${id}?zoneId=${zoneId}`)
    dispatch({
      type: 'NEWJODETAIL',
      payload: 'close'
    })
    setTimeout(() => {
      dispatch({
        type: 'JOBMANAGE',
        payload: 'togg'
      })
      dispatch({
        type: 'NEWJODETAIL',
        payload: 'full'
      })
    }, 500)
  }

  return (
    <StyledLayout>
      <Toolbar />
      <div className='body'>
        <Table
          tableId={operatable ? 'job_list_table' : undefined}
          defaultConfig={[
            { key: 'name', active: true },
            { key: 'id', active: true },
            // { key: 'file_storage_zone', active: true },
            { key: 'user_name', active: true },
            { key: 'tier_name', active: true },
            { key: 'app_name', active: true },
            { key: 'displayCpus', active: true },
            { key: 'displayRunTime', active: true },
            { key: 'displayCreateTime', active: true },
            { key: 'displayStartTime', active: true },
            { key: 'displayEndTime', active: true },
            { key: 'displayState', active: true },
            { key: 'display_back_state', active: true },
            { key: 'options', active: true }
          ]}
          props={{
            data: state.dataSource,
            rowKey: 'id',
            autoHeight: true,
            shouldUpdateScroll: false
          }}
          rowSelection={{
            selectedKeys: store.selectedKeys,
            onChange: keys => {
              store.setSelectedKeys(keys)
              // t('DetermineWhetherWorkInDifferentAreasOfTheSelectedData')
              const storageZone = []
              keys.forEach(item => {
                if (storageZone.length > 1) return
                const currentStorageZone = state.dataSource.find(
                  value => value.id === item
                )?.file_storage_zone
                !storageZone.includes(currentStorageZone) &&
                  storageZone.push(currentStorageZone)
              })
              if (storageZone.length > 1) {
                managerStore?.setDownloadFlag(true)
                // message.warning(t('TheFileIsNotInTheSameStorageArea')，t('DoesNotSupportBatchOperation')!')
                message.warning(t('job.batchOperations'))
              } else {
                managerStore?.setDownloadFlag(false)
                managerStore?.setSelectDataZone(storageZone)
              }
            },
            props: {
              fixed: true
            }
          }}
          columns={[
            {
              // header: t('TheNameOfThe'),
              header: t('job.columnsName'),
              headerClassName: 'table-job-name-header',
              props: {
                resizable: true,
                width: 200
              },
              dataKey: 'name',
              cell: {
                props: {
                  dataKey: 'name'
                },
                render: ({ rowData }) => (
                  <JobName
                    onClick={onNameClick}
                    {...rowData}
                    jobRuntimeId={rowData.runtime.id}
                    projectId={rowData.project_id}
                    userId={rowData.user_id}
                    zoneId={zoneId}
                    cloudGraphicVisible={rowData.residualVisible} //TODO t('AccordingToTheResidualFigureFirstJudgment')
                  />
                )
              }
            },
            {
              // header: t('JobNumber'),
              header: t('job.columnsId'),
              props: {
                width: 200
              },
              dataKey: 'id',
              cell: {
                props: {
                  dataKey: 'id'
                },
                render: ({ rowData }) => (
                  <NameStyled
                    title={rowData['id']}
                    onClick={() =>
                      onJobIdClick(rowData['id'], rowData['file_storage_zone'])
                    }>
                    {rowData['id']}
                  </NameStyled>
                )
              }
            },
            showJobSetName && {
              // header: t('HomeworkSetName'),
              header: t('job.columnsSet'),
              headerClassName: 'table-jobset-name-header',
              props: {
                width: 200
              },
              dataKey: 'id',
              cell: {
                props: {
                  dataKey: 'id'
                },
                render: ({ rowData }) =>
                  rowData['job_set_name'] ? (
                    // <Button
                    //   style={{ padding: 0 }}
                    //   type='link'
                    //   onClick={() => {
                    //     let zoneId = managerStore.domainList.filter(
                    //       item => item.zone === rowData['file_storage_zone']
                    //     )[0].storage_domains[0]
                    //     // t('LocalDevelopmentAgency')
                    //     // rowData['file_storage_zone'].includes('jinan') &&
                    //     //   (zoneId = 'http://localhost:8081')
                    //     history.push(
                    //       `/new-jobset/${rowData['job_set_id']}?zoneId=${zoneId}&zoneKey=${managerStore?.zoneKey}&scId=${rowData['sc_id']}`,
                    //       history.location.state
                    //     )
                    //     dispatch({
                    //       type: 'NEWJODETAIL',
                    //       payload: 'togg'
                    //     })
                    //   }}>
                    <span>{rowData['job_set_name']}</span>
                  ) : (
                    // </Button>
                    '--'
                  )
              }
            },
            {
              // header: t('founder'),
              header: t('job.columnsFounder'),
              props: {
                width: 120
              },
              dataKey: 'user_name',
              cell: {
                render: ({ rowData }) => {
                  return <div>{rowData.user_name}</div>
                }
              }
            },
            {
              // header: t('WorkForceResources'),
              header: t('job.columnsResource'),
              props: {
                width: 120
              },
              dataKey: 'tier_name'
            },
            {
              // header: t('software')
              header: t('job.columnsSoftware'),
              props: {
                width: 120
              },
              dataKey: 'app_name'
            },
            {
              // header: t('auditing'),
              header: t('job.columnsCore'),
              props: {
                width: 100
              },
              dataKey: 'displayCpus'
            },
            {
              // header: t('SubmitTime'),
              header: t('job.columnsCommitTime'),
              props: {
                width: 200
              },
              dataKey: 'displayCreateTime'
            },
            {
              // header:t('TheStartTime'),
              header: t('job.columnsStartTime'),
              props: {
                width: 200
              },
              dataKey: 'displayStartTime'
            },
            {
              // header: t('TheEndOfTime'),
              header: t('job.columnsEndTime'),
              props: {
                width: 200
              },
              dataKey: 'displayEndTime'
            },
            {
              // header: t('TheRunningTime'),
              header: t('job.columnsRunTime'),
              props: {
                width: 140
              },
              dataKey: 'displayRunTime'
            },
            {
              // header: t('JobStatus'),
              header: t('job.columnsJobStatus'),
              props: {
                width: 120,
                fixed: 'right' as 'right'
              },
              cell: {
                props: {
                  dataKey: 'displayState'
                },
                render: ({ rowData }) => {
                  return <JobStatus showDropDown={true} job={rowData} />
                }
              }
            },
            {
              // header: t('ReturnsStatus'),
              header: t('job.columnsReturnStatus'),
              props: {
                width: 140,
                fixed: 'right' as 'right'
              },
              cell: {
                props: {
                  dataKey: 'display_back_state'
                },
                render: ({ rowData, dataKey }) =>
                  ({
                    0: '--',
                    1: !rowData.paused ? t('job.columnsReturning') : t('job.columnsReturnPause'),
                    2: t('job.columnsReturnCompleted')
                  }[rowData[dataKey]])
              }
            },
            {
              // header: t('BackTo'),
              header: t('job.columnsReturnSS'),
              props: {
                width: 140,
                fixed: 'right' as 'right'
              },
              cell: {
                props: {
                  dataKey: 'display_back_state'
                },
                render: ({ rowData, dataKey }) =>
                  ({
                    0: '--',
                    1: (
                      <Switcher
                        id={rowData['id']}
                        paused={rowData['paused']}
                        name={rowData['name']}
                      />
                    ),
                    2: '--'
                  }[rowData[dataKey]])
              }
            },
            operatable && {
              // header: t('operation'),
              header: t('job.columnsOperations'),
              props: {
                width: 180,
                fixed: 'right' as 'right'
              },
              cell: {
                props: {
                  dataKey: 'options'
                },
                render: ({ rowData }) => <Operator {...rowData} />
              }
            }
          ].filter(Boolean)}
        />
      </div>

      <Pagination
        className='pagination'
        showSizeChanger
        pageSize={store.pageSize}
        pageSizeOptions={['10', '25', '50']}
        current={store.pageIndex}
        total={model.page_ctx.total}
        onChange={onPageChange}
      />
    </StyledLayout>
  )
})
