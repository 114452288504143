/* Copyright (C) 2016-present, Yuansuan.cn */
import { observable, runInAction } from 'mobx'
import { Zone } from './Zone'
import { Http } from '@/utils'
import { PLATFORM_CURRENT_ZONE_KEY } from '@/constant'

export class ZoneList {
  @observable list: Zone[] = []
  @observable map: Map<string, string> = new Map()
  @observable _currentZoneName: string = null

  constructor() {
    this.fetch()
  }

  async fetch () {
    const res = await Http.get('/vis_ibv/company/zone/list')
    const { data } = res

    runInAction(() => {
      this.list = data?.items?.map((item) => {
        this.map.set(item.name, item.desc)
        return new Zone({...item})
      })
    })

    return res
  }

  get currentZoneName() {
    if (this._currentZoneName) {
      return this._currentZoneName
    } else {
      // get zoneName from localstorage
      let currentZoneName = localStorage.getItem(PLATFORM_CURRENT_ZONE_KEY)

      if (currentZoneName && this.map.has(currentZoneName)) {
        this._currentZoneName = currentZoneName
        return currentZoneName
      } else {
        this.currentZoneName = this.list[0].name
        return this.list[0].name
      }
    }
  }

  set currentZoneName(val) {
    this._currentZoneName = val
    // save zoneName to localstorage
    localStorage.setItem(PLATFORM_CURRENT_ZONE_KEY, val)
  }
}