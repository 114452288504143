/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useState, useEffect, useRef } from 'react'
import { Context, useModel, useStore } from './store'
import { observer } from 'mobx-react-lite'
import { useAsync } from '@/utils/hooks'
import { Modal } from 'antd'
import { ListWrapper } from '../style'
import {
  ListSessionRequest,
  ListSessionResponse,
  SESSION_STATUS_ALL,
  StartSessionResponse
} from '@/domain/Vis'
import { Action } from './Action'
import { DataTable } from './DataTable'
import { PortableCreate } from '../Create/Portable'
import { ZoneToolbar } from './ZoneToolbar'
import { env } from '@/domain'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const List = observer(({zone}) => {
  const store = useStore()
  const { vis } = store
  const [hardware, setHardware] = useState([])
  const [software, setSoftware] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [response, setResponse] = useState(new ListSessionResponse())
  const [request, setRequest] = useState(
    new ListSessionRequest({ status: SESSION_STATUS_ALL })
  )
  const { execute: listSession, loading } = useAsync(async () => {
    return await vis.listSession({ ...request, zone: store.zoneId })
  }, true)
  const [isStopFetch, setStopFetch] = useState(false)
  const { t } = useTranslation()

  const [disabledCreateSessionBtn, setDisabledCreateSessionBtn] = useState(null)

  const timerRef = useRef(null)
  const intervalRef = useRef(null)

  const fetchActiveSessions = async () => {
    const data = await vis.getActiveSessions()
    const { terminalCountLimit } = await vis.getTerminalLimit()

    if (Number(terminalCountLimit) <= (data?.items?.length || 0)) {
      setDisabledCreateSessionBtn(t('TheTerminalConfigurationHasReachedTheUpperLimit,PleaseContactCustomerService'))
    } else {
      setDisabledCreateSessionBtn(null)
    }
  }

  useEffect(() => {
    store.refresh(zone)
  }, [zone])

  useEffect(() => {
    checkVisRinningOrClosing()
  }, [])

  useEffect(() => {
    if (store.zoneId) {
      fetchActiveSessions()
      setRequest({ ...request, zone: store.zoneId })
    }
  }, [store.zoneId])

  useEffect(() => {
    if (store.zoneId) {
      vis
        .listAllHardware(store.zoneId)
        .then(list => {
          setHardware(list)
          return vis.listAllSoftware(store.zoneId)
        })
        .then(list => setSoftware(list))
      return () => {
        setStopFetch(true)
        clearInterval(timerRef.current)
        timerRef.current = null
      }
    }
  }, [store.zoneId])

  useEffect(() => {
    if (isStopFetch) {
      setStopFetch(true)
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [isStopFetch])

  useEffect(() => {
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      const data = response?.items.filter(
        item =>
          item.session?.is_auto_close === true &&
          item.session?.auto_close_time?.seconds &&
          item.session?.status === 3
      )

      for (let index = 0; index < data?.length; index++) {
        let nowTime = new Date().getTime() / 1000
        let closeTime = data[index].session.auto_close_time?.seconds

        if (closeTime - nowTime <= 0) {
          if (!loading) {
            checkVisRinningOrClosing()
            listSession().then(res => {
              setResponse(res)
            })
          }

          break
        }
      }
    }, 1000)

    return () => clearInterval(intervalRef.current)
  }, [response])

  const onPagination = (index: number, size: number) => {
    request.page_index = index
    request.page_size = size
    setRequest(request)
    listSession().then((res: any) => setResponse(res))
  }

  const onQuerySession = async (values?: any) => {
    if (
      values?.software_id ||
      values?.hardware_id ||
      values?.status ||
      values?.user_id
    ) {
      await vis.listSession(values).then((res: any) => setResponse(res))
    } else {
      listSession().then((res: any) => setResponse(res))
    }
  }

  const checkVisRinningOrClosing = () => {
    // 状态变化时，先触发一次，之后走轮询
    fetchActiveSessions()
    clearInterval(timerRef.current)
    if (!isStopFetch || !timerRef.current) {
      timerRef.current = setInterval(() => {
        visualIsOpening()
      }, 10 * 1000)
    }
  }
  const onCloseSession = (id: string) => {
    return new Promise((resolve, reject) => {
      vis
        .closeSession(id)
        .then(() => {
          setStopFetch(false)
        })
        .then(() => checkVisRinningOrClosing())
        .then(() => {
          return listSession()
        })
        .then(res => {
          setResponse(res)
          resolve(id)
        })

        .catch(() => {
          setStopFetch(true)
          reject(t('ClosingSessionFailed'))
        })
    })
  }

  const onDeleteSession = (id: string) => {
    return new Promise((resolve, reject) => {
      vis
        .deleteSession(id)
        .then(() => {
          setStopFetch(false)
        })
        .then(() => {
          return listSession()
        })
        .then(res => {
          setResponse(res)
          resolve(id)
        })
        .catch(() => {
          setStopFetch(true)
          reject(t('DeleteTheSessionFailed'))
        })
    })
  }

  const onUpdateSession = (id: string, autoClose: boolean, time?: string) => {
    return new Promise((resolve, reject) => {
      vis
        .updateSession(id, autoClose, time)
        .then(() => {
          setStopFetch(false)
        })
        .then(() => {
          return listSession()
        })
        .then(res => {
          setResponse(res)
          resolve(id)
        })
        .catch(() => {
          setStopFetch(true)
          reject(t('UpdateSessionClosingTimeFailure'))
        })
    })
  }

  const visualIsOpening = async () => {
    const lists = await vis.listSession({ ...request, zone: store.zoneId })
    setResponse(lists)
    const { items } = JSON.parse(JSON.stringify(lists))
    const findStartingVis = items.find(({ session }) => session.status !== 5)

    // 会话状态变化中，check 活动会话数
    fetchActiveSessions()

    if (findStartingVis) {
      // 应用还在启动中
    } else {
      //已启动
      clearInterval(timerRef.current)
      timerRef.current = null
      onQuerySession()
      return
    }
  }

  const createdSession = (response: StartSessionResponse) => {
    onQuerySession()
    setIsModalVisible(false)
    setStopFetch(false)
    checkVisRinningOrClosing()
  }

  const onOpenSession = async (id: string, row: any) => {
    return row.session.stream_url.concat(`&session_id=${id}`)
  }

  const onOpenRemoteApp = async (id: string, row: any) => {
    const app_name = row.session?.remote_apps[0]?.remote_app_name
    if (app_name) {
      return vis.getRemoteAppUrl(id, app_name)
    } else {
      return ''
    }
  }

  const setModalVisible = () => {
    setIsModalVisible(true)
    setStopFetch(false)
  }
  return (
    <>
      <ZoneToolbar />
      <ListWrapper>
        <Action
          hardware={hardware}
          software={software}
          value={request}
          disabledCreate={disabledCreateSessionBtn}
          onCreate={setModalVisible}
          onSubmit={value => {
            request.page_index = value.page_index
            setRequest(request)
            onQuerySession(value)
          }}
        />
        <DataTable
          request={request}
          response={response}
          loading={loading}
          onPagination={onPagination}
          deleteSession={onDeleteSession}
          closeSession={onCloseSession}
          updateSession={onUpdateSession}
          openSession={onOpenSession}
          openRemoteApp={onOpenRemoteApp}
          zone={zone}
          ></DataTable>
      </ListWrapper>
      <Modal
        width={'80%'}
        footer={null}
        title={t('CreateAVisualSession')}
        maskClosable={false}
        centered={true}
        visible={isModalVisible}
        destroyOnClose={true}
        onOk={() => setStopFetch(false)}
        onCancel={() => setIsModalVisible(false)}>
        <PortableCreate
          loading={loading}
          hardware={hardware}
          software={software}
          vis={vis}
          onCreated={createdSession}
        />
      </Modal>
    </>
  )
})

export default observer((props) => {
  const model = useModel()

  return (
    <Context.Provider value={model}>
      <List {...props}/>
    </Context.Provider>
  )
})
