/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import moment, { Moment } from 'moment'
import { DatePicker, Radio } from 'antd'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const yesterday = moment().subtract(1, 'day')
const lastMonth = moment().subtract(1, 'day').subtract(1, 'month')
const lastYear = moment().subtract(1, 'day').subtract(12, 'month')

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
`

interface Props {
  defaultTimeStep: number
  defaultTimeRange: [Moment, Moment]
  onChange: (
    val: Partial<{
      timeStep: number
      timeRange: [Moment, Moment]
    }>
  ) => void
}


export const TimeController = observer(function TimeController({
  defaultTimeStep,
  defaultTimeRange,
  onChange
}: Props) {
  const { t } = useTranslation()
  const timeStepMapper = {
    day: {
      value: 1,
      zh: t('day'),
      rangePickerType: 'date' as 'date' | 'month'
    },
    month: {
      value: 2,
      zh: t('month'),
      rangePickerType: 'month' as 'date' | 'month'
    }
  }
  const [timeRange, setTimeRange] = React.useState<[Moment, Moment]>(
    defaultTimeRange
  )
  const [rangePickerType, setRangePickerType] = React.useState<
    'date' | 'month'
  >('date')

  const datesFormatter = (dates, type: 'month' | 'day') => {
    return dates?.map(date => moment(date).utcOffset(0).startOf(type)) as [
      Moment,
      Moment
    ]
  }

  /**
   * timeStep 发生变更时，会有3个影响：
   * 1. 更改 range picker 的 picker 类型: date / month
   * 2. 更改 range picker 的时间跨度：最近一个月 / 最近一年
   * 3. 更改父组件的数据
   */

  const onTimeStepChange = timeStep => {
    if (timeStep === timeStepMapper.day.value) {
      setRangePickerType(timeStepMapper.day.rangePickerType)
      const timeRange = datesFormatter([lastMonth, yesterday], 'day')
      setTimeRange(timeRange)
      onChange({
        timeStep,
        timeRange
      })
    } else if (timeStep === timeStepMapper.month.value) {
      setRangePickerType(timeStepMapper.month.rangePickerType)
      const timeRange = datesFormatter([lastYear, yesterday], 'month')
      setTimeRange(timeRange)
      onChange({
        timeStep,
        timeRange
      })
    }
  }

  const onTimeRangeChange = dates => {
    if (!dates) return // 清空时间时，保留原有的表格数据不变
    const timeRange = datesFormatter(
      dates,
      rangePickerType === 'month' ? 'month' : 'day'
    )
    setTimeRange(timeRange)
    onChange({
      timeRange: timeRange
    })
  }

  return (
    <StyledLayout>
      <Radio.Group
        buttonStyle='solid'
        defaultValue={defaultTimeStep}
        onChange={event => {
          onTimeStepChange(event.target.value)
        }}>
        {Object.values(timeStepMapper).map(item => (
          <Radio.Button
            style={{ width: 56, textAlign: 'center' }}
            key={item.value}
            value={+item.value}>
            {item.zh}
          </Radio.Button>
        ))}
      </Radio.Group>
      <RangePicker
        disabledDate={current =>
          current && current > moment().subtract(1, 'day').endOf('day')
        }
        defaultValue={timeRange}
        value={timeRange}
        picker={rangePickerType}
        style={{ width: 386, marginLeft: 20 }}
        onChange={dates => {
          onTimeRangeChange(dates)
        }}
      />
    </StyledLayout>
  )
})
