/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { Modal, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Icon } from '../../utils/general'
// import './menu.scss'
import './menu.css'

import * as Actions from '../../actions'
import { history } from '@/utils'
import { vis, env } from '@/domain'
import { serverFactory } from '@/components/NewFileMGT/store/common'
import { newBoxServer } from '@/server'
import { useTranslation } from 'react-i18next'
const server = serverFactory(newBoxServer)

export const ActMenu = () => {
  const menu = useSelector(state => state.menus)
  const menudata = menu.data[menu.opts]
  const { t } = useTranslation()
  const dataSet = menu.dataset
  const { abpos, isLeft } = useSelector(state => {
    let acount = state.menus.menus[state.menus.opts].length
    let tmpos = {
        top: state.menus.top,
        left: state.menus.left
      },
      tmpleft = false

    let wnwidth = window.innerWidth,
      wnheight = window.innerHeight

    let ewidth = 312,
      eheight = acount * 28

    tmpleft = wnwidth - tmpos.left > 504
    if (wnwidth - tmpos.left < ewidth) {
      tmpos.left = wnwidth - ewidth
    }

    if (wnheight - tmpos.top < eheight) {
      tmpos.bottom = wnheight - tmpos.top
      tmpos.top = null
    }

    return {
      abpos: tmpos,
      isLeft: tmpleft
    }
  })

  const dispatch = useDispatch()

  const uploadCallBack = async (path, id) => {
    const fileList = await server.fetch(path, {
      zoneId: env.getOpenZoneId
    })
    dispatch({ type: 'FILEDIR', payload: id, data: fileList._children })
  }

  const clickDispatch = event => {
    // console.log('event: ', event.target, dataSet)
    event.stopPropagation()
    let action = {
      type: event.target.dataset.action,
      payload: event.target.dataset.payload
    }
    // console.log('action: ', action)
    dataSet.routerpath && history.push(dataSet.routerpath)
    if (action.type) {
      if (action.payload === 'delshort') {
        Modal.confirm({
          title: t('close3DApp'),
          content: t('confirmClose'),
          okText: t('confirm'),
          cancelText: t('cancel'),
          onOk: () => {
            // 3D云应用删除逻辑
            if (dataSet.action === 'EXTERNAL' || dataSet.url) {
              // TODO: 3D云应用删除
              vis
                .closeSession(dataSet.sessionid)
                .then(() => {
                  message.success(t('sessionCloseMsg'))
                  dispatch({
                    type: 'desktop',
                    data: {
                      sessionId: dataSet.sessionid,
                      action: dataSet.icon
                    },
                    payload: 'closeSession'
                  })
                })
                .catch(() => {
                  message.error(t('sessionCloseFail'))
                })
            } else {
              if (action.type != action.type.toUpperCase()) {
                Actions[action.type](action.payload, menu)
              } else {
                dispatch(action)
              }
            }
          }
        })
      } else {
        if (action.payload === 'createFile') {
          dispatch({
            type: 'CREATEFILEDIR',
            payload: 'createFile',
            data: dataSet.currid,
            t
          })
        } else if (action.payload === 'rename') {
          dispatch({
            type: 'FILEOPERATE',
            payload: 'rename',
            data: dataSet.id
          })
        } else if (action.payload === 'appList') {
          dispatch({
            type: '3DCLOUDAPP',
            payload: 'full',
          })
        }  else if (action.payload === 'remove') {
          const selected = dataSet.selected ? dataSet.selected?.split(',') : [dataSet.id]
          const selectedName = dataSet.selectedname?.split(',')
          Modal.confirm({
            title: t('deleteFile'),
            content:
              selected.length <= 1 ? (
                `${t('confirm')}${t('deleteFile')} ${selectedName[0] || dataSet.name} ?`
              ) : (
                <div>
                  <p>{t('confirmDeleteFiles')}</p>
                  <ul style={{ marginLeft: 20 }}>
                    {selectedName.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              ),
            okText: t('confirm'),
            cancelText: t('cancel'),
            onOk: () => {
              dispatch({
                type: 'FILEREMOVE',
                payload: 'remove',
                data: { path: dataSet.path, id: dataSet.id, selected }
              })
            }
          })
        } else if (action.payload === 'uploadFile') {
          dispatch({
            type: 'UPLOAD',
            payload: false,
            callback: uploadCallBack
          })
        } else if (action.payload === 'uploadFiles') {
          dispatch({
            type: 'UPLOAD',
            payload: true,
            callback: uploadCallBack
          })
        } else if (action.payload === 'download') {
          const action = {
            type: 'DOWNLOAD',
            payload: dataSet.id || dataSet.currid
          }
          if (dataSet.currid) {
            const selectObj = JSON.parse(dataSet?.selectedobj || '[]')
            action.data = selectObj
          }
          dispatch(action)
        } else if (action.payload === 'move') {
          dispatch({
            type: 'FIlESMOVE',
            payload: dataSet.currid ? JSON.parse(dataSet?.selectedobj || '[]') : [dataSet],
            callback: uploadCallBack
          })
        } else if (action.type != action.type.toUpperCase()) {
          Actions[action.type](action.payload, menu)
        } else {
          dispatch(action)
        }
      }
      dispatch({ type: 'MENUHIDE' })
    }
  }

  const menuobj = data => {
    let mnode = []
    data.map((opt, i) => {
      if (opt.type == 'hr') {
        mnode.push(<div key={i} className='menuhr'></div>)
      } else {
        mnode.push(
          <div
            key={i}
            className='menuopt'
            data-dsb={opt.dsb}
            onClick={clickDispatch}
            data-action={opt.action}
            data-url={opt.url}
            data-payload={opt.payload}>
            {menudata.ispace != false ? (
              <div className='spcont'>
                {opt.icon && opt.type == 'svg' ? (
                  <Icon icon={opt.icon} width={16} />
                ) : null}
                {opt.icon && opt.type == 'fa' ? (
                  <Icon fafa={opt.icon} width={16} />
                ) : null}
                {opt.icon && opt.type == null ? (
                  <Icon src={opt.icon} width={16} />
                ) : null}
              </div>
            ) : null}
            {/* <div className='nopt'>{opt.name}</div> */}
            <div className='nopt'>{t(opt.key)}</div>
            {opt.opts ? (
              <Icon
                className='micon rightIcon'
                fafa='faChevronRight'
                width={10}
                color='#999'
              />
            ) : null}
            {opt.dot ? (
              <Icon
                className='micon dotIcon'
                fafa='faCircle'
                width={4}
                height={4}
              />
            ) : null}
            {opt.check ? (
              <Icon
                className='micon checkIcon'
                fafa='faCheck'
                width={8}
                height={8}
              />
            ) : null}
            {opt.opts ? (
              <div
                className='minimenu'
                style={{
                  minWidth: menudata.secwid
                }}>
                {menuobj(opt.opts)}
              </div>
            ) : null}
          </div>
        )
      }
    })

    return mnode
  }

  return (
    <div
      className='actmenu'
      id='actmenu'
      style={{
        ...abpos,
        '--prefix': 'MENU',
        width: menudata.width,
        backgroundColor: menudata.backgroundColor
      }}
      data-hide={menu.hide}
      data-left={isLeft}>
      {menuobj(menu.menus[menu.opts])}
    </div>
  )
}

export default ActMenu
