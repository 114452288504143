/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { account, env } from '@/domain'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  &.freeze {
    > .value {
      &::before {
        background: #ef5350;
        border: 2px solid #f9d9d9;
      }
    }
  }

  > .value {
    font-size: 14px;
    font-weight: normal;

    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: #52c41a;
      border: 2px solid #d7f9c7;
      margin-right: 8px;
    }
  }
`

export const Status = observer(function Status() {
  const { t } = useTranslation()
  const status = () => {
    if (env.isPersonal) {
      // 个人用户
      if (account.account_balance > 0) return t('normal')
      else return t('freeze')
    } else {
      // 企业用户
      if (account.account_balance > 0) return t('normal')
      else {
        if (account.account_balance > -account.credit_quota)
          return t('normal') + `(${t('InTheLetter')})`
        else return t('freeze')
      }
    }
  }

  return (
    <StyledLayout
      className={
        account.account_balance <= -account.credit_quota ? 'freeze' : ''
      }>
      <div className='value'>{status()}</div>
    </StyledLayout>
  )
})
