/* Copyright (C) 2016-present, Yuansuan.cn */
import React from 'react'
import { useSelector } from 'react-redux'
import { ToolBar } from '@/utils/general'
import VisList from '@/pages/VisList/List'
import Page404 from '@/pages/404'
import { BrowserRouter } from 'react-router-dom'
import { env } from '@/domain'
import { useTranslation } from 'react-i18next'
import { getUrlParams } from '@ys/utils'

export const CloudApp = () => {
  const params: ParamsProps = getUrlParams()
  const wnapp = useSelector(state => state.apps['3dcloudApp'])
  const { t } = useTranslation()

  return wnapp ? (
    <div
      className='calcApp floatTab dpShad'
      data-size={wnapp.size}
      id={wnapp.icon + 'App'}
      data-max={wnapp.max}
      style={{
        ...(wnapp.size == 'cstm' ? wnapp.dim : null),
        zIndex: wnapp.z
      }}
      data-hide={wnapp.hide}>
      <ToolBar
        app={wnapp.action}
        icon={env.isPersonal ? 'search' : wnapp.icon}
        size={wnapp.size}
        name={t('3dCloudContainer')}
      />
      <div className='windowScreen flex flex-col' data-dock='true'>
        { !wnapp.hide && (env.isPersonal ? <BrowserRouter><Page404/></BrowserRouter> : <VisList zone={params?.zone} />) }
      </div>
    </div>
  ) : null
}
