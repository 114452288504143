/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Dropdown, Menu, message } from 'antd'
import { Button, Modal } from '@ys/components'
import { useStore } from './store'
import { box, account, env } from '@/domain'
import { showDownloader } from './showDownloader'
import { newBoxServer, jobServer } from '@/server'
import { useStore as fatherStore } from '../store'
import { getUrlParams } from '@ys/utils'
import { SearchFiles } from './SearchFiles'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    > * {
      margin-right: 10px;
    }
  }
`

export const Toolbar = observer(function Toolbar() {
  const { t, i18n } = useTranslation()
  const params = getUrlParams()
  const store = useStore()
  const managerStore = fatherStore()
  // t('AreaPartitionFront-endUrl')
  // const url = params.zoneId ? params.zoneId as string : managerStore.zoneId
  const url = params.zoneId
    ? (params.zoneId as string)
    : managerStore?.domainList.filter(
        item => item.zone === managerStore?.selectDataZone
      )[0]?.storage_domains?.[0]
  const { model } = store
  const state = useLocalStore(() => ({
    get downloadDisabled() {
      if (env.accountHasDebt) {
        // return t('AccountBalanceIsInsufficient')，t('PleasePrepaidPhoneAfterUse')
        return t('job.accountBalance')
      }

      // if (!store.selectedKeys.length) return t('PleaseChooseToDownloadTheHomework')
      if (!store.selectedKeys.length) return t('job.downloadJob')
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.downloadable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('job')} ${invalidJobNames.join(', ')} ${t('job.cannotDownload')}`
      }
      return false
    },
    get cancelDisabled() {
      // if (!store.selectedKeys.length) return t('PleaseChooseToCancelTheHomework')
      if (!store.selectedKeys.length) return t('job.cancelJob')
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.cancelable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('job')} ${invalidJobNames.join(', ')} ${t('job.cannotCancel')}`
      }
      return false
    },
    get deleteDisabled() {
      if (!store.selectedKeys.length) return ' '
      const invalidJobNames = store.selectedKeys
        .map(key => model.list.find(item => item.id === key))
        .filter(item => !!item && !item.deleteable)
        .map(item => item.name)
      if (invalidJobNames.length > 0) {
        return `${t('job')} ${invalidJobNames.join(', ')} ${t('job.cannotDelete')}`
      }
      return false
    }
  }))

  async function downloadJobs() {
    const jobs = store.selectedKeys
      .map(key => model.list.find(item => item.id === key))
      .map(item => ({
        jobId: item.id,
        jobName: item.name
      }))
    box.newJob.downloadJobs(jobs, url) // t('TheNewArea')
    store.setSelectedKeys([])
  }

  async function downloadJobsToSearch() {
    await Modal.show({
      // title: t('DownloadToLocalSearch'),
      title: t('job.searchAndDownload'),
      footer: null,
      width: 800,
      bodyStyle: {
        height: 600
      },
      // okText: t('download'),
      okText: t('job.download'),
      content: ({ onCancel, onOk }) => {
        const OK = async selectedDownloadFile => {
          await newBoxServer.download({
            url: url,
            paths: selectedDownloadFile,
            base: '.',
            bucket: 'result'
          })
          onOk()
        }
        return (
          <SearchFiles
            onOk={OK}
            zoneId={url}
            selectedJob={store.selectedKeys}
          />
        )
      }
    })
  }

  async function downloadJobsToCommon() {
    const selectedJobs = model.list
      .filter(item => store.selectedKeys.includes(item.id))
      .map(item => ({
        id: item.id,
        name: item.name
      }))
    const resolvedJobs = await showDownloader(selectedJobs, url, t)
    if (Object.keys(resolvedJobs).length > 0) {
      await box.newResult.linkToCommon(resolvedJobs, url) // t('TheNewArea')
      message.success(t('job.download') + t('job.success'))
      store.setSelectedKeys([])
    }
  }
  async function cancelJobs() {
    await Modal.showConfirm({
      // title: t('ConfirmToCancel'),
      title: t('job.confirm') + t('job.cancel'),
      // content: t('IfConfirmUncheckTheHomework')？'
      content: t('job.cancelSelectedJob')
    })
    await jobServer.cancel(store.selectedKeys)
    await store.refresh()
    store.setSelectedKeys([])
    message.success(t('job.cancel') + t('job.success'))
  }

  async function deleteJobs() {
    await Modal.showConfirm({
      // title: t('ConfirmTheDeletion'),
      // content: t('DeleteOperationCanProduceFileDeleteOperationAtTheSameTime')，t('WhetherToConfirm')？'
      title: t('job.confirm') + t('job.delete'),
      content: t('job.confirmDelete')
    })
    await jobServer.delete(store.selectedKeys)
    const paths = store.selectedKeys
      .map(key => model.list.find(item => item.id === key))
      .map(item => item.id)
    await newBoxServer.delete({
      bucket: 'result',
      paths,
      url
    })

    await store.refresh()
    //id && (await jobSetList.fetch())
    store.setSelectedKeys([])
    message.success( t('job.delete') + t('job.success'))
  }

  return (
    <StyledLayout>
      <div className='left'>
        {!state.downloadDisabled ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  disabled={managerStore?.downloadFlag}
                  onClick={downloadJobs}
                  style={{
                    // t('ShanghaiBestRun')（t('AFormal')）t('CustomizedRequirements')：t('HideTheDownloadFunction')
                    display: env?.company?.id === '4wBZP1cZW3u' ? 'none' : ''
                  }}>
                  {t('job.downloadToLocal')}
                </Menu.Item>
                <Menu.Item
                  disabled={managerStore?.downloadFlag}
                  onClick={downloadJobsToSearch}>
                  {/* t('DownloadToLocalSearch') */}
                    {t('job.searchAndDownloadToLocal')}
                </Menu.Item>
                <Menu.Item
                  disabled={managerStore?.downloadFlag}
                  onClick={downloadJobsToCommon}>
                  {/* t('DownloadTheFilesToMe') */}
                    {t('job.downloadToMyFileExplorer')}
                </Menu.Item>
              </Menu>
            }
            placement='bottomLeft'>
            <Button type='primary'>{t('job.download')}</Button>
          </Dropdown>
        ) : (
          <Button type='primary' disabled={state.downloadDisabled}>
            {t('job.download')}
          </Button>
        )}
        <Button onClick={cancelJobs} disabled={state.cancelDisabled}>
          {t('job.cancel')}
        </Button>
        <Button onClick={deleteJobs} disabled={state.deleteDisabled}>
          {t('job.delete')}
        </Button>
      </div>
    </StyledLayout>
  )
})
