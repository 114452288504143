/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import moment, { Moment } from 'moment'
import { ceilNumber } from '@/utils'
import { maxBy } from 'lodash'
import { BaseStats } from './BaseStats'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'
import { COREHOUR_STATS } from '@/server/dashboardServer'
import { env } from '@/domain'
import { Slider } from 'bizcharts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  timeStep: number
  timeRange: [Moment, Moment]
}

export const CoreHourStats = observer(function CoreHourStats({
  timeStep,
  timeRange
}: Props) {
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    maxValue: 10,
    data: [],
    update(
      props: Partial<{
        maxValue: number
        data: {
          type: string
          value: any
          date: string
        }[]
      }>
    ) {
      Object.assign(this, props)
    }
  }))
  const timeGap = useMemo(() => timeRange[1].diff(timeRange[0], 'days'), [
    timeRange[1],
    timeRange[0]
  ])

  const queryResult = useQuery(COREHOUR_STATS, {
    variables: {
      payload: {
        project_id: env.project?.id,
        start_time: timeRange[0]?.unix(),
        end_time: timeRange[1]?.unix(),
        time_step: timeStep
      }
    }
  })

  const data = queryResult.data?.coreHourStats
  useEffect(() => {
    if (!data) {
      return
    }

    if (data.length !== 0) {
      state.update({
        data,
        maxValue: ceilNumber(maxBy(data, 'value')?.['value'])
      })
    }
  }, [data])

  return (
    <BaseStats
      title={t('HomeworkCost') + `(${t('unit')}：${t('WhenTheNuclear')})`}
      {...queryResult}
      data={state.data}
      maxValue={state.maxValue}
      scale={{
        type: {
          formatter: text =>
            ({
              all: t('ATotalOf'),
              failed: t('HasFailed'),
              completed: t('HasBeenCompleted'),
              canceled: t('HasBeenCancelled'),
              running: t('InTheOperationOfThe')
            }[text])
        },
        date: {
          formatter: date =>
            moment
              .unix(date)
              .format(
                timeStep === 1
                  ? timeGap < 4
                    ? 'MM/DD HH:00'
                    : 'MM/DD'
                  : 'YY/MM'
              )
        }
      }}>
      <Slider
        formatter={date =>
          moment.unix(date).format(timeStep === 1 ? 'YYYY/MM/DD' : 'YYYY/MM')
        }
      />
    </BaseStats>
  )
})
