/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { createStore } from '@ys/utils/hooks'
import { useLocalStore } from 'mobx-react-lite'
import { JobList } from '@/domain/JobList'
import { history } from '@/utils'
import { jobServer } from '@/server'
import { ListParams } from '@/server/jobServer'
import { runInAction } from 'mobx'
import { pageStateStore } from '@/utils'

export const initialQuery = {
  user_filters: [],
  app_filters: [],
  state_filters: [],
  core_filters: [],
  download_filters: [],
  jobset_filters: [],
  fuzzy_key: '',
  start_seconds: '',
  end_seconds: '',
  job_id: ''
}

export function useModel() {
  return useLocalStore(() => {
    // restore state from pageStateStore
    const historyState = pageStateStore.getByPath<{
      query: typeof initialQuery
      pageIndex: number
      pageSize: number
    }>()
    const query = historyState?.query || initialQuery

    return {
      model: new JobList(),
      loading: false,
      setLoading(flag) {
        this.loading = flag
      },
      selectedKeys: [],
      setSelectedKeys(keys) {
        this.selectedKeys = keys
      },
      query,
      setQuery(query) {
        this.query = query
      },
      pageIndex: historyState?.pageIndex || 1,
      setPageIndex(index) {
        this.pageIndex = index
      },
      pageSize: historyState?.pageSize || 10,
      setPageSize(size) {
        this.pageSize = size
      },
      order: '',
      setOrder(order) {
        this.order = order
      },
      async fetch(params: ListParams) {
        const { data } = await jobServer.list(params)

        runInAction(() => {
          this.model.update({
            list: data.jobs,
            page_ctx: data.page_ctx
          })
        })
      },
      async refresh() {
        try {
          this.setLoading(true)
          await this.fetch({
            ...this.query,
            order_by: this.order,
            page_index: this.pageIndex,
            page_size: this.pageSize
          })
        } finally {
          this.setLoading(false)
        }
      }
    }
  })
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
