/* Copyright (C) 2016-present, Yuansuan.cn */
import { Bin, Item } from '../utils/bin'
import { message } from 'antd'
import fdata from './dir.json'
import { env, uploader, NewBoxHttp } from '@/domain'
import { serverFactory } from '@/components/NewFileMGT/store/common'
import { showDirSelector } from '@/components/NewFileMGT/DirSelector'
import { showFailure } from '@/components/NewFileMGT'
import { newBoxServer } from '@/server'
import { v4 as uuid } from 'uuid'
import { globalSizes } from '@/domain/Box/states'
import { EE, EE_CUSTOM_EVENT } from '@/utils/Event'
const server = serverFactory(newBoxServer)

const defState = {
  cdir: '%homedrive%', // 当前所在的目录
  hist: [],
  hid: 0,
  view: 1,
  editing: false
}

const getDuplicate = item => {
  let duplicateNode = null

  item.data.every(child => {
    // check duplicate name
    if (child.name === item.name && item.id !== child.id) {
      duplicateNode = child
      return false
    }

    return true
  })

  return duplicateNode
}

const upload = props => {
  uploader.upload({
    action: '/filemanager/upload',
    httpAdapter: NewBoxHttp(env.getOpenZoneId),
    ...props,
    origin: props.origin,
    data: {
      ...props.data,
      bucket: 'common',
      project_id: env.project?.id
    }
  })
}
const _upload = (directory = false, dir, callback) => {
  const UPLOAD_ID = uuid()
  if (!env.getOpenZoneId) {
    return message.warning('暂无可用存储区域！')
  }
  const uniqueID = uuid()
  const dirFinal = `/${(dir.path || '/').replace(/^\//, '')}`
  let uploadFilesLength = 0
  let uploadSucceedCount = 0
  upload({
    origin: UPLOAD_ID,
    by: 'chunk',
    multiple: true,
    data: {
      directory,
      _uid: uniqueID,
      dir: dirFinal
    },
    directory,
    beforeUpload: async files => {
      const resolvedFiles = []
      const rejectedFiles = []
      files.forEach(file => {
        const filePath = file.webkitRelativePath || file.name
        const fileName = filePath.split('/')[0]

        if (getDuplicate({ id: undefined, name: fileName, data: dir.data })) {
          rejectedFiles.push(file)
        } else {
          resolvedFiles.push(file)
        }
      })

      globalSizes[uniqueID] = 0
      files.forEach(file => {
        globalSizes[uniqueID] += file.size
      })

      if (rejectedFiles.length > 0) {
        if (directory) {
          const filePath =
            rejectedFiles[0].webkitRelativePath || rejectedFiles[0].name
          const topDirName = filePath.split('/')[0]

          const coverNodes = await showFailure({
            actionName: '上传',
            items: [
              {
                isFile: false,
                name: topDirName
              }
            ]
          })
          if (coverNodes.length > 0) {
            // remove dir
            await server.delete(
              [`${dirFinal}/${topDirName}`],
              env.getOpenZoneId
            )
            // should upload newFiles
            resolvedFiles.push(...rejectedFiles)
          }
        } else {
          const coverNodes = await showFailure({
            actionName: '上传',
            items: rejectedFiles.map(item => ({
              name: item.name,
              uid: item.uid,
              isFile: true
            }))
          })
          if (coverNodes.length > 0) {
            await server.delete(
              coverNodes.map(item => `${dirFinal}/${item.name}`),
              env.getOpenZoneId
            )
            resolvedFiles.push(...coverNodes)
          }
        }
      }

      if (resolvedFiles.length > 0) {
        uploadFilesLength = resolvedFiles.length
        message.success('文件开始上传')
        // 触发显示 dropdown
        EE.emit(EE_CUSTOM_EVENT.TOGGLE_UPLOAD_DROPDOWN, { visible: true })
      }

      return resolvedFiles.map(item => item.uid)
    },
    onChange: ({ file, origin }) => {
      if (origin !== UPLOAD_ID) {
        return
      }
      if (file.status === 'done') {
        uploadSucceedCount++
        // 有文件上传完成，check 是否要关闭 dropdown
        if (uploadSucceedCount === uploadFilesLength) {
          EE.emit(EE_CUSTOM_EVENT.TOGGLE_UPLOAD_DROPDOWN, { visible: false })
        }
        callback(dir.path || '/', dir.id)
      }
    }
  })
}

const onMove = (selectedNodes, callback) => {
  showDirSelector({
    disabledPaths: selectedNodes.map(item => item.path),
    zoneId: env.getOpenZoneId
  }).then(path => {
    moveTo(path, selectedNodes, callback)
  })
}

async function moveTo(path, selectedNodes, callback) {
  const nodes = selectedNodes

  // check duplicate
  const targetDir = await server.fetch(path, { zoneId: env.getOpenZoneId })
  const rejectedNodes = []
  const resolvedNodes = []

  nodes.forEach(item => {
    if (targetDir.getDuplicate({ id: undefined, name: item.name })) {
      rejectedNodes.push(item)
    } else {
      resolvedNodes.push(item)
    }
  })

  const destMoveNodes = [...resolvedNodes]
  if (rejectedNodes.length > 0) {
    const coverNodes = await showFailure({
      actionName: '移动',
      items: rejectedNodes
    })
    if (coverNodes.length > 0) {
      // coverNodes 中的要删除
      await server.delete(
        coverNodes.map(item => `${path}/${item.name}`),
        env.getOpenZoneId
      )
      destMoveNodes.push(...coverNodes)
    }
  }

  if (destMoveNodes.length > 0) {
    await server.move(
      destMoveNodes.map(item => [item.path, `${path}/${item.name}`]),
      env.getOpenZoneId
    )
    // const targetNode = dirTree.filterFirstNode(item => item.path === path, { self: true, })
    // // TODO: 区域参数
    // const { children } = await server.fetch(targetNode.path, {
    //   recursion: true,
    //   is_dir: true,
    //   zoneId
    // })
    // targetNode.children = children
    message.success('文件移动成功')
  }
  callback && callback()
}

defState.hist.push(defState.cdir)
defState.data = new Bin()
// defState.data.parse(fdata || '{}')

const handleData = data => {
  const collectData = {}
  data.forEach(item => {
    item.originId = item.id
    collectData[item.name] = item
  })
  return collectData
}

const fileReducer = (state = defState, action) => {
  let tmp = { ...state }
  let navHist = false

  // 生成第一层级的folder和file
  if (action.type === 'generateFiles') {
    fdata['Home:'].data = handleData(action.payload)
    tmp.data.parse(fdata)
  }

  // 第二层级的文件夹和文件的生成
  if (action.type === 'FILEDIR') {
    tmp.cdir = action.payload
    if (action.data) {
      let itemById = tmp.data.getId(action.payload)
      const mapData = handleData(action.data)
      let drives = Object.keys(mapData)
      itemById.data = drives.map(item => {
        const data = mapData[item]
        return tmp.data.parseFolder(mapData[item], item, itemById)
      })
    }
    // tmp.data.parse(handleData(action.data), action.payload)
  } else if (action.type === 'CREATEFILEDIR') {
    // 新建文件夹
    // let itemById = tmp.data.getId(action.data)
    let itemById = tmp.data.getId(tmp.cdir)
    // 判断当前目录下有多少个未命名文件夹，进行递增
    const findUnKnowFolders = itemById.data?.filter(item =>
      (/^(未命名文件夹|unnamed folder)\d?/).test(item?.name)
    )
    const { t } = action
    let name =
      findUnKnowFolders.length > 0
        ? `${t('unnamed folder')}${findUnKnowFolders.length}`
        : t('unnamed folder')
    // 如果递增的名字也存在于当前目录，修改为时间戳拼接
    const findResult = itemById.data?.find(item => item.name === name)
    if (findResult) {
      name = `${t('unnamed folder')}${Date.now()}`
    }
    const path = `${itemById.path ? itemById.path + '/' : ''}${name}`
    const createObj = {
      data: [],
      editFlag: false,
      host: null,
      id: Math.random().toString(36).substring(2, 10).toLowerCase(),
      info: { icon: 'folder' },
      name,
      path,
      size: 0,
      type: 'folder'
    }
    server.mkdir(path, env.getOpenZoneId).then(res => {
      console.log(res, 'res')
    })
    const createItem = tmp.data.parseFolder(createObj, name, itemById)
    createItem.editFlag = true
    tmp.editing = true
    itemById.data.push(createItem)
  } else if (action.type === 'FILEOPERATE') {
    // 修改文件名称
    // 排他，将其他处于修改的关闭
    if (tmp.editing) {
      let currentFolder = tmp.data.getId(tmp.cdir)
      currentFolder.data.forEach(
        item => item.editFlag && (item.editFlag = false)
      )
      tmp.editing = false
    }
    // 将对应的name变成input
    if (action.data && action.payload !== 'handleRename') {
      let itemById = tmp.data.getId(action.data)
      itemById.editFlag = true
      tmp.editing = true
    }
    // 修改名称
    if (
      action.payload === 'handleRename' &&
      action.data.targetPath !== action.data.name
    ) {
      let currentFolder = tmp.data.getId(tmp.cdir)
      let targetItem = tmp.data.getId(action.data.id)
      // 判断是否已经存在名称
      const findResult = currentFolder.data.find(
        item => item.name === action.data.targetPath
      )
      if (findResult) {
        message.error(`${action.data.targetPath}名称已存在！`)
      } else {
        const path =
          (currentFolder.path ? currentFolder.path + '/' : '') +
          action.data.targetPath
        server.move([[action.data.originPath, path]], env.getOpenZoneId)
        targetItem.name = action.data.targetPath
        targetItem.path = path
      }
    }
  } else if (action.type === 'FILEREMOVE') {
    // 删除文件和文件夹
    // let itemById = tmp.data.getId(action.data.id)
    let currentItem = tmp.data.getId(tmp.cdir)
    let deletePath = [action.data.path]
    // 批量删除
    if (action.data?.selected.length > 0) {
      const deleteItems = currentItem.data?.filter(item =>
        action.data.selected?.includes(item.id)
      )
      deletePath = deleteItems.map(item => item.path)
    }
    // 调用删除接口
    server.delete(deletePath, env.getOpenZoneId).then(res => {})
    currentItem.data = currentItem.data.filter(
      item => !action.data.selected.includes(item.id)
    )
  } else if (action.type === 'UPLOAD') {
    const current = tmp.data.getId(tmp.cdir)
    _upload(action.payload, current, action.callback)
  } else if (action.type === 'DOWNLOAD') {
    let item = tmp.data.getId(action.payload)
    let path = [item.path]
    let isFile = [item.isFile]
    let size = [item.size]
    if (action.data) {
      path = action.data.map(item => item.path)
      isFile = action.data.map(item => item.isfile)
      size = action.data.map(item => item.size)
    }
    server.download(path, isFile, size, env.getOpenZoneId)
  } else if (action.type === 'FIlESMOVE') {
    let item = tmp.data.getId(tmp.cdir)
    onMove(action.payload, () => action.callback(item.path || '/', item.id))
  } else if (action.type === 'FILEPATH') {
    let pathid = tmp.data.parsePath(action.payload)
    if (pathid) tmp.cdir = pathid
  } else if (action.type === 'FILEBACK') {
    let item = tmp.data.getId(tmp.cdir)
    if (item.host) {
      tmp.cdir = item.host.id
    }
  } else if (action.type === 'FILEVIEW') {
    tmp.view = action.payload
  } else if (action.type === 'FILEPREV') {
    tmp.hid--
    if (tmp.hid < 0) tmp.hid = 0
    navHist = true
  } else if (action.type === 'FILENEXT') {
    tmp.hid++
    if (tmp.hid > tmp.hist.length - 1) tmp.hid = tmp.hist.length - 1
    navHist = true
  }

  if (!navHist && tmp.cdir != tmp.hist[tmp.hid]) {
    tmp.hist.splice(tmp.hid + 1)
    tmp.hist.push(tmp.cdir)
    tmp.hid = tmp.hist.length - 1
  }

  tmp.cdir = tmp.hist[tmp.hid]
  if (tmp.cdir.includes('%')) {
    if (tmp.data.special[tmp.cdir] != null) {
      tmp.cdir = tmp.data.special[tmp.cdir]
      tmp[tmp.hid] = tmp.cdir
    }
  }

  tmp.cpath = tmp.data.getPath(tmp.cdir)
  return tmp
}

export default fileReducer
