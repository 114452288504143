/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Table } from '@ys/components'
import { useDispatch } from 'react-redux'
import { observer, useLocalStore } from 'mobx-react-lite'
import {
  ListSessionResponse,
  ListSessionRequest,
  HARDWARE_PLATFORM_MAP,
  SESSION_STATUS_MAP,
  SESSION_STATUS_BUTTON_LOADING,
  statusMapping
} from '@/domain/Vis'
import { useStore } from './store'
import { ListDataWrapper, StatusWrapper, UpdateWapper } from '../style'
import { ColumnProps } from '@ys/components/dist/Table'
import { Button, Pagination, Space, message, Tooltip, Divider } from 'antd'
import { Icon } from '@ys/components'
import { formatDate } from '@/utils'
import { useInterval } from '@/utils/hooks'
import { UpdateForm } from './UpdateForm'
import { useTranslation } from 'react-i18next'

interface IProps {
  response: ListSessionResponse
  request: ListSessionRequest
  loading: boolean
  deleteSession?: (id: string) => Promise<any>
  closeSession?: (id: string) => Promise<any>
  updateSession?: (
    id: string,
    autoClose: boolean,
    time?: string
  ) => Promise<any>
  openSession?: (id: string, row: any) => Promise<string>
  onPagination: (index: number, size: number) => void
  openRemoteApp?: (id: string, row: any) => Promise<string>
  zone?: string
}

const colorMap = {
  success: {
    color: '#63B03D',
    borderColor: '#D7F9C7'
  },
  warn: {
    color: '#FF9100',
    borderColor: '#FDEFC7'
  },
  error: {
    color: '#EF5350',
    borderColor: '#F9D9D9'
  },
  primary: {
    color: '#2A8FDF',
    borderColor: '#C7E3F9'
  },
  canceled: {
    color: '#C5C5C5',
    borderColor: '#E6E4E4'
  }
}

export const DataTable = observer(
  ({
    request,
    response,
    loading,
    onPagination,
    closeSession,
    openSession,
    deleteSession,
    updateSession,
    openRemoteApp,
    zone
  }: IProps) => {
    const { vis } = useStore()
    const dispatch = useDispatch()
    const state = useLocalStore(() => ({
      stopFetch: false,
      setStopFetch(bool) {
        this.stopFetch = bool
      },
      dataSource: new Array().fill({}) as any[],
      setDataSource(data) {
        this.dataSource = data
      }
    }))
    const { t } = useTranslation()

    const findVisExistLoading = response?.items.find(
      item => item.loading === true
    )

    useEffect(() => {
      if (loading || findVisExistLoading) {
        state.setStopFetch(true)
      } else {
        state.setStopFetch(false)
      }
    }, [loading, findVisExistLoading])

    useEffect(() => {
      state.setStopFetch(!!zone)
    }, [zone])

    const formatDataSource = response => {
      if (response?.length) {
        response.forEach(async item => {
          if (item.status === 3) {
            item.realStatus = 'READYING'
            await vis
              .pollFetchRequest(item.roomId)
              .then(data => {
                item.loading = !data.ready
                if (data.ready) {
                  item['realStatus'] = 'READIED'
                  state.setStopFetch(false)
                }
              })
              .then(() => {
                state.setDataSource(response)
              })
              .finally(() => {
                if (findVisExistLoading || item.loading) {
                  setTimeout(() => {
                    state.setStopFetch(true)
                  }, 5 * 1000)
                }
              })
              .catch(() => {
                state.setStopFetch(false)
              })
          }
        })
      }
    }

    useInterval(
      () => {
        formatDataSource(response && response?.items)
      },
      state.stopFetch ? 2000 : null
    )

    const onOpenSession = (id: string, row: any) => {
      openSession(id, row).then((url: string) => {
        window.open(url)
      })
    }

    const onOpenRemoteApp = (id: string, row: any) => {
      openRemoteApp(id, row).then((url: string) => {
        if (url) {
          window.open(url)
        } else {
          message.error(t('RemoteApplicationWasNotFound,') + t('CheckToSeeIfTheRemoteApplicationHasBeenConfigured'))
        }
      })
    }

    const onDeleteSession = (id: string) => {
      Modal.showConfirm({
        title: t('DeleteTheSession'),
        content: t('ConfirmToDeleteTheSession?'),
        onOk() {
          return new Promise((resolve, reject) => {
            deleteSession(id)
              .then(
                res => {
                  message.success(t('DeleteTheSessionSuccessfully'))
                  resolve(res)
                },
                () => {
                  reject(t('DeleteFailed'))
                }
              )
              .catch(() => message.error(t('DeleteFailed')))
          })
        }
      })
    }

    const onCloseSession = (id: string, zone: any) => {
      Modal.showConfirm({
        title: t('CloseTheSession'),
        content: t('ConfirmToCloseTheSession?'),
        onOk() {
          return new Promise((resolve, reject) => {
            closeSession(id)
              .then(
                res => {
                  message.success(t('CloseTheSessionIsSuccessful,TheSessionWillClose')+ '...')
                  dispatch({
                    type: 'desktop',
                    data: { sessionId: id },
                    payload: 'closeSession'
                  })
                  resolve(res)
                },
                () => {
                  reject(t('ClosingSessionFailed'))
                }
              )
              .catch(() => message.error(t('ClosingSessionFailed')))
          })
        }
      })
    }
    const onUpdateSession = rowData => {
      Modal.show({
        title: t('SessionSetting'),
        content: ({ onCancel, onOk }) => {
          const OK = (autoClose, time) => {
            onOk()
            new Promise((resolve, reject) => {
              updateSession(rowData.session?.id, autoClose, time)
                .then(
                  res => {
                    message.success(t('ModifySessionClosingTimeSuccess'))
                    resolve(res)
                  },
                  () => {
                    reject(t('ModifySessionClosingTimeFailure'))
                  }
                )
                .catch(() => message.error(t('ModifySessionClosingTimeFailure')))
            })
          }
          return <UpdateForm onCancel={onCancel} onOk={OK} rowData={rowData} />
        },
        footer: null
      })
    }

    const columns: ColumnProps[] = useMemo(() => {
      return [
        {
          header: t('TheNameOfTheSoftware'),
          dataKey: 'instance.software.name',
          props: {
            width: 200,
            fixed: 'left',
          },
          cell: {
            render: ({ rowData }) => {
              return (
                <Tooltip
                  placement='topLeft'
                  title={rowData.instance.software.name}>
                  {rowData.instance.software.name}
                </Tooltip>
              )
            }
          }
        },
        {
          header: t('SoftwareDescription'),
          dataKey: 'instance.software.desc',
          props: {
            minWidth: 120,
            flexGrow: 1
          },
          cell: {
            render: ({ rowData }) => {
              return (
                <Tooltip
                  placement='topLeft'
                  title={rowData.instance.software.desc}>
                  {rowData.instance.software.desc}
                </Tooltip>
              )
            }
          }
        },
        {
          header: t('TheSoftwarePlatform'),
          dataKey: 'instance.software.platform',
          props: {
            width: 100,
            resizable: true
          },
          cell: {
            render: ({ rowData }) => {
              return (
                <Tooltip
                  placement='topLeft'
                  title={
                    HARDWARE_PLATFORM_MAP[rowData.instance.software.platform]
                  }>
                  {HARDWARE_PLATFORM_MAP[rowData.instance.software.platform]}
                </Tooltip>
              )
            }
          }
        },
        {
          header: t('TheHardwareConfiguration'),
          dataKey: 'instance.hardware.name',
          props: {
            minWidth: 180,
            align: 'center',
            flexGrow: 2
          },
          cell: {
            render: ({ rowData }) => {
              const hardware = rowData.instance.hardware
              const text =
                hardware.desc +
                '(' +
                hardware.number_of_cpu +
                'C' +
                hardware.number_of_mem +
                'G)'
              return (
                <Tooltip placement='topLeft' title={text}>
                  {text}
                </Tooltip>
              )
            }
          }
        },
        {
          header: t('SessionState'),
          dataKey: 'session.status',
          props: {
            width: 120,
            resizable: true
          },
          cell: {
            render: ({ rowData }) => {
              const getIcon = text => {
                if (text === t('HasStarted') || t('InTheStart')) {
                  return <Icon type='running' />
                } else if (text === t('InTheClosed') || t('WaitingForResources')) {
                  return <Icon type='loading' />
                } else {
                  return null
                }
              }
              const text = SESSION_STATUS_MAP[rowData.status]
              const type = statusMapping[SESSION_STATUS_MAP[rowData.status]]

              return (
                <StatusWrapper>
                  <div
                    className='icon'
                    style={{
                      background: colorMap[type]?.color,
                      border: `2px solid ${colorMap[type]?.borderColor}`
                    }}
                  />
                  <div className='text'>{rowData.status === 3 && rowData.loading ? '房间初始化中' : text}</div>
                  <div className='icon-right'>{getIcon(text)}</div>
                </StatusWrapper>
              )
            }
          }
        },
        {
          header: t('TheCreator'),
          dataKey: 'user?.phone',
          props: {
            width: 200,
            resizable: true
          },
          cell: {
            render: ({ rowData }) => {
              const name = rowData?.user?.real_name || rowData?.user?.username
              if (!name) {
                return rowData?.user?.phone || '--'
              }
              return `${name}（${rowData?.user?.phone || '--'}）`
            }
          }
        },
        {
          header: t('CreationTime'),
          dataKey: 'session.create_time?.seconds',
          props: {
            width: 180,
            resizable: true
          },
          cell: {
            render: ({ rowData }) =>
              formatDate(rowData.session.create_time?.seconds) || '--'
          }
        },
        {
          header: t('TheStartTime'),
          dataKey: 'session.start_time?.seconds',
          props: {
            width: 180,
            resizable: true
          },
          cell: {
            render: ({ rowData }) =>
              formatDate(rowData.session.start_time?.seconds) || '--'
          }
        },
        {
          header: t('TheEndOfTime'),
          dataKey: 'session.end_time?.seconds',
          props: {
            width: 180
          },
          cell: {
            render: ({ rowData }) =>
              formatDate(rowData.session.end_time?.seconds) || '--'
          }
        },
        {
          header: t('SessionID'),
          dataKey: 'session.id',
          props: {
            width: 160
          },
          cell: {
            render: ({ rowData }) => {
              return <div>{rowData.session.id}</div>
            }
          }
        },
        {
          header: t('ForTheRestOf')+(t('hours')),
          dataKey: 'session.time_left',
          props: {
            width: 130,
            fixed: 'right'
          },
          cell: {
            render: ({ rowData }) => {
              return rowData.session.status !== 4 &&
                rowData.session.status !== 5 &&
                rowData.session.is_auto_close
                ? rowData.session?.time_left || '--'
                : '--'
            }
          }
        },
        {
          header: t('operation'),
          dataKey: 'session.id',
          props: {
            width: 380,
            align: 'center',
            fixed: 'right'
          },
          cell: {
            render: ({ rowData }) => {
              return (
                <Space size={0}>
                  {rowData.session.status === 5 ? (
                    <Button
                      type='link'
                      onClick={() => onDeleteSession(rowData.session.id)}>
                      {t('delete')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        type='link'
                        disabled={rowData.session.status !== 3}
                        onClick={() => onUpdateSession(rowData)}>
                        {t('SessionSetting')}
                      </Button>
                      <Divider type="vertical" style={{margin: 2}}/>
                      <Tooltip
                        title={
                          SESSION_STATUS_BUTTON_LOADING[rowData.realStatus]
                        }
                        color={'#108ee9'}
                        key={'#108ee9'}>
                        <Button
                          type='link'
                          disabled={
                            rowData.session.status !== 3 ||
                            (rowData.session.status === 3 && rowData.loading)
                          }
                          loading={
                            rowData.session.status === 2 ||
                            (rowData.session.status === 3 && rowData.loading)
                          }
                          onClick={() =>
                            onOpenSession(rowData.session.id, rowData)
                          }>
                          {t('OpenTheSession')}
                        </Button>
                      </Tooltip>
                      <Divider type="vertical" style={{margin: 2}}/>
                      <Tooltip
                        title={
                          rowData.session.remote_apps.length === 0 ? t('ConfigureTheRemoteApplication') : SESSION_STATUS_BUTTON_LOADING[rowData.realStatus]
                        }
                        color={'#108ee9'}
                        key={'#108ee9'}>
                        <Button
                          type='link'
                          disabled={
                            rowData.session.remote_apps.length === 0 ||
                            rowData.session.status !== 3 ||
                            (rowData.session.status === 3 && rowData.loading)
                          }
                          loading={
                            rowData.session.remote_apps.length === 0 ? false :
                            rowData.session.status === 2 ||
                            (rowData.session.status === 3 && rowData.loading)
                          }
                          onClick={() =>
                            onOpenRemoteApp(rowData.session.id, rowData)
                          }>
                          {t('OpenTheRemoteApplication')}
                        </Button>
                      </Tooltip>
                      <Divider type="vertical" style={{margin: 2}}/>
                      <Button
                        type='link'
                        danger
                        disabled={
                          rowData.session.status === 4 ||
                          rowData.session.status === 5 ||
                          rowData.session.status === 2
                        }
                        onClick={() => onCloseSession(rowData.session.id)}>
                        {t('CloseTheSession')}
                      </Button>
                    </>
                  )}
                </Space>
              )
            }
          }
        }
      ]
    }, [])

    return (
      <ListDataWrapper>
        <div style={{ display: 'none' }}>{JSON.stringify(response?.items)}</div>
        <Table
          columns={columns}
          props={{
            loading,
            rowKey: 'id',
            autoHeight: true,
            data: response?.items || state.dataSource,
            locale: {
              emptyMessage: t('AlsoDidNotCreateASession'),
              loading: t('TheDataLoad') + '...'
            }
          }}></Table>
        <Pagination
          className='pagination'
          showSizeChanger
          defaultPageSize={20}
          onChange={onPagination}
          current={request.page_index}
          total={response?.total || 10}
        />
      </ListDataWrapper>
    )
  }
)
