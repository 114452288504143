/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Dropdown, Menu } from 'antd'
import { observer, useLocalStore } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { currentUser, env } from '@/domain'
import LangSwitch from '@/components/Langswitch'
import { useTranslation } from 'react-i18next'
import PersonalSetting from '@/pages/PersonalSetting';

import { Modal, Icon } from '@ys/components'
import {
  CaretDownFilled,
  CaretUpFilled,
  ClockCircleOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import { Hover } from '@/components'
import { buryPoint, history } from '@/utils'
import { userServer, TroubleShootWorkOrderListService } from '@/server'
import { guide } from '@/utils'

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 8px 20px;
    color: #666;

    a {
      color: #666;
    }
  }
  .ant-dropdown-menu-item {
    > * {
      display: flex;
      align-items: center;
    }

    .anticon.ysicon {
      margin-right: 8px;
      font-size: 16px;
      color: #666;

      &.hovered {
        color: ${props => props.theme.primaryColor};

        > span {
          background: white;
          display: inline-block;
        }
      }
    }
  }
`

const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  /* position: absolute;
  left: 20px;
  top: 12px; */

  > .username {
    margin-left: 4px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
type Props = {
  type?: 'portal' | 'inside'
}
export const UserInfo = observer(function UserInfo({ type = 'inside' }: Props) {
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    visible: false,
    setVisible(flag) {
      this.visible = flag
    },
    workOrderCount: 0,
    setWorkOrderCount(count) {
      this.workOrderCount = count
    }
  }))
  const dispatch = useDispatch()
  const { visible } = state

  useEffect(() => {
    userServer.current().then(({ data }) => {
      runInAction(() => {
        currentUser.update(data)
      })
    })
  }, [])

  useEffect(() => {
    if (!env.isPersonal && env.isManager) {
      guide.start('2')
    }
  }, [])

  useEffect(() => {
    const fetch = async function () {
      const { unresoled_count } =
        await TroubleShootWorkOrderListService.getTroubleShootCount({
          user_id: currentUser.id
        })
      state.setWorkOrderCount(unresoled_count)
    }
    visible && fetch()
  }, [visible])

  useEffect(() => {
    const fetch = async function () {
      const { unresoled_count } =
        await TroubleShootWorkOrderListService.getTroubleShootCount({
          user_id: currentUser.id
        })
      state.setWorkOrderCount(unresoled_count)
    }
    fetch()
  }, [])

  async function logout() {
    await Modal.showConfirm({
      title: t('LogOut'),
      content: t('ConfirmToLogOut?')
    })
    env.logout()
  }

  if (!currentUser.id) return null

  function onMenuClick(props) {
    const { key } = props
    buryPoint({
      category: t('PersonalInformation'),
      action: {
        person: t('PersonalSettings'),
        account: t('AccountManagement'),
        handbook: t('TheUserManual'),
        workorder: t('ToDoTheRepairOrder'),
        logout: t('LogOut')
      }[key]
    })
    state.setVisible(false)
  }

  const openCompanyManage = () => {
    dispatch({
      type: 'ENTERPRISEMANAGE',
      payload: 'togg'
    })
    history.push('/company/workspaces')
  }

  const openDashboard = () => {
    dispatch({
      type: 'DASHBOARD',
      payload: 'togg'
    })
    history.push('/dashboard')
  }

const personSetting = () => {
  Modal.show({
    title: t('personalSetting'),
    content: <PersonalSetting />,
  })
}

  return (
    <Dropdown
      visible={state.visible}
      onVisibleChange={visible => state.setVisible(visible)}
      overlay={
        <StyledMenu onClick={onMenuClick}>
          <Hover
            render={hovered => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon
                  className={hovered ? 'hovered' : ''}
                  type='rename'
                />
                <LangSwitch />
              </div>
            )}
          />
          {/* {env.isCompanyManager && (
            <Menu.Item key='company' onClick={openCompanyManage}>
              <Hover
                render={hovered => (
                  <>
                    <Icon
                      className={hovered ? 'hovered' : ''}
                      type='company_mgt_default'
                    />
                    <span>{t('TheEnterpriseManagement')}</span>
                  </>
                )}
              />
            </Menu.Item>
          )}
          <Menu.Item key='dashboard' onClick={openDashboard}>
            <Hover
              render={hovered => (
                <div>
                  <Icon
                    className={hovered ? 'hovered' : ''}
                    type='dashboard_default'
                  />
                  <span>{t('TheDashboard')}</span>
                </div>
              )}
            />
          </Menu.Item> */}
          {/* <Menu.Item key='switchLanguage'> */}
          {/* </Menu.Item> */}
          <Menu.Item key='logout' onClick={logout}>
            <Hover
              render={hovered => (
                <div>
                  <Icon
                    className={hovered ? 'hovered' : ''}
                    type='logout_default'
                  />
                  <span>{t('LogOut')}</span>
                </div>
              )}
            />
          </Menu.Item>
          {type === 'inside' && (
            <Menu.Item key='person'>
              <Hover
                render={hovered => (
                  <div onClick={personSetting}>
                    <Icon
                      className={hovered ? 'hovered' : ''}
                      type='personal_setting_default'
                    />
                    <span>{t('personalSetting')}</span>
                  </div>
                )}
              />
            </Menu.Item>
          )}
        </StyledMenu>
      }
      placement='bottomCenter'>
      <StyledUserInfo id='ys_header_user_menu'>
        <Icon
          type={
            state.visible ? 'global_setting_active' : 'global_setting_default'
          }
          className={`${state.visible ? 'active' : ''}`}>
          <span></span>
        </Icon>
        <div className='username' title={currentUser.displayName}>
          {currentUser.displayName || '--'}
        </div>
        {visible && (
          <CaretUpFilled style={{ marginLeft: 4, fontSize: '14px' }} />
        )}
        {!visible && (
          <CaretDownFilled style={{ marginLeft: 4, fontSize: '14px' }} />
        )}
      </StyledUserInfo>
    </Dropdown>
  )
})
