/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Select, Form } from 'antd'
import { Button } from '@ys/components'
import { runInAction } from 'mobx'
import { ListActionWrapper } from '../style'
import { useStore } from './store'
import { useTranslation } from 'react-i18next'

import {
  Hardware,
  Software,
  ListSessionRequest,
  SESSION_STATUS_MAP
} from '@/domain/Vis'
import { currentUser } from '@/domain'

const { Option } = Select
const { useForm } = Form

const resetValue: ListSessionRequest = new ListSessionRequest({
  status: 0,
  hardware_id: '',
  software_id: '',
  user_id: ''
})

const selectStyle = { width: '220px' }

interface IProps {
  disabledCreate?: string
  value: ListSessionRequest
  hardware?: Array<Hardware>
  software?: Array<Software>
  onCreate: () => void
  onSubmit: (value: ListSessionRequest) => void
}

export const Action = observer(
  ({
    onSubmit,
    onCreate,
    value,
    hardware,
    software,
    disabledCreate
  }: IProps) => {
    const store = useStore()
    const { vis } = store
    const [form] = useForm()
    const { t } = useTranslation()

    const reset = () => {
      form.setFieldsValue({ ...resetValue })
      submit()
    }

    // TODO 自动刷新
    useEffect(() => {
      if (store.zoneId) {
        form.setFieldsValue({ ...resetValue })
        onSubmit(value)
      }
    }, [store.zoneId])

    const onFinish = (values: any) => {
      runInAction(() => {
        values.zone = store.zoneId
        values.page_index = 1
        values.page_size = 20
        values.status = +values.status || 0
        onSubmit(values)
        vis.setFilterParams(values)
      })
    }

    const submit = () => {
      form.submit()
    }

    return (
      <ListActionWrapper>
        <Form form={form} layout='inline' onFinish={onFinish} className='item'>
          <Form.Item label={t('SessionState')} name='status'>
            <Select
              className={'status'}
              value={value.status}
              onChange={submit}
              allowClear={true}
              placeholder={t('all')}
              style={selectStyle}>
              <Option value={0}>{t('all')}</Option>
              {Object.keys(SESSION_STATUS_MAP).map(k => (
                <Option value={k} key={k}>
                  {SESSION_STATUS_MAP[k]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('TheHardwareConfiguration')} name='hardware_id'>
            <Select
              className={'hardware_id'}
              value={value.hardware_id}
              onChange={submit}
              allowClear={true}
              placeholder={t('AllHardware')}
              style={selectStyle}>
              <Option value={''}>{t('AllHardware')}</Option>
              {(hardware || []).map(h => (
                <Option value={h.id} key={h.id}>
                  {h.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('UsingTheSoftware')} name='software_id'>
            <Select
              className={'software_id'}
              value={value.software_id}
              onChange={submit}
              allowClear={true}
              placeholder={t('AllSoftware')}
              style={selectStyle}>
              <Option value={''}>{t('AllSoftware')}</Option>
              {(software || []).map(s => (
                <Option value={s.id} key={s.id}>
                  {s.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('TheCreator')} name='user_id'>
            <Select
              className={'user_id'}
              value={value.user_id}
              onChange={submit}
              allowClear={true}
              placeholder={t('CreateASessionUser')}
              style={selectStyle}>
              <Option value={''}>{t('AllUsers')}</Option>
              <Option value={currentUser.id}>{t('OnlyLookAtTheir')}</Option>
            </Select>
          </Form.Item>
        </Form>
        <div className='item'>
          <Button
            className={'btn'}
            onClick={() => {
              reset()
            }}>
            {t('reset')}
          </Button>
          <Button
            className={'btn'}
            disabled={disabledCreate}
            onClick={onCreate}
            type='primary'>
            {t('CreateASession')}
          </Button>
        </div>
      </ListActionWrapper>
    )
  }
)
