/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { useLocalStore, useObserver } from 'mobx-react-lite'
import { Dropdown, Input, Checkbox, Button } from 'antd'
import { FileTree } from '@/domain/JobBuilder/FileTree'
import { FileActions } from './FileActions'
import { ToolbarStyle, FileSearchResultListStyle } from './style'
import { buryPoint } from '@/utils'

interface IProps {
  fileTree: FileTree
}

export const Toolbar = ({ fileTree }: IProps) => {
  const store = useLocalStore(() => ({
    fileTree,
    val: undefined,
    visible: false,
    selection: [],
    get filterFiles() {
      if (!this.val) return []
      return store.fileTree.filterNodes(
        node => node.isFile && node.name.indexOf(this.val) > -1
      )
    },
    get indeterminate() {
      return (
        this.selection.length > 0 &&
        this.selection.length < this.filterFiles.length
      )
    },
    get checkAll() {
      return this.selection.length === this.filterFiles.length
    },
  }))

  React.useEffect(() => {
    store.fileTree = fileTree
  }, [fileTree])

  const onChange = selection => {
    store.selection = selection
  }

  const onCheckAll = e => {
    if (e.target.checked) {
      store.selection = store.filterFiles.map(file => file.id)
    } else {
      store.selection = []
    }
  }

  const setSelectToMainFile = () => {
    buryPoint({
      category: '作业提交',
      action: '批量设置主文件',
    })
    fileTree.tapNodes(
      node => store.selection.includes(node.id),
      node => {
        node.isMain = true
      }
    )
    store.visible = false
    store.selection = []
    store.val = undefined
  }

  const resetAllMainFile = () => {
    buryPoint({
      category: '作业提交',
      action: '重置主文件',
    })
    fileTree.tapNodes(
      () => true,
      node => {
        node.isMain = false
      }
    )
  }

  const FileSearchResultList = useObserver(() => (
    <FileSearchResultListStyle>
      {store.filterFiles.length ? (
        <>
          <li>
            <Checkbox
              onChange={onCheckAll}
              indeterminate={store.indeterminate}
              checked={store.checkAll}>
              全部
            </Checkbox>
          </li>
          <Checkbox.Group
            value={store.selection}
            onChange={onChange}
            className='dropdown-content'>
            {store.filterFiles.map(file => (
              <li key={file.id} title={file.name}>
                <Checkbox value={file.id}>{file.name}</Checkbox>
              </li>
            ))}
          </Checkbox.Group>
          <div className='actions'>
            <Button type='primary' onClick={setSelectToMainFile}>
              设置主文件
            </Button>
          </div>
        </>
      ) : null}
    </FileSearchResultListStyle>
  ))

  return useObserver(() => (
    <ToolbarStyle>
      <FileActions />
      <div className='right-search'>
        <span className='label'>选择主文件：</span>
        <Dropdown
          visible={store.visible}
          onVisibleChange={e => (store.visible = e)}
          trigger={['click']}
          overlay={FileSearchResultList}
          placement='bottomLeft'>
          <Input.Search
            value={store.val}
            onChange={e => (store.val = e.target.value)}
            placeholder={'输入关键词'}
          />
        </Dropdown>
        <Button onClick={resetAllMainFile}>重置主文件</Button>
      </div>
    </ToolbarStyle>
  ))
}
