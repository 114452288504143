/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import moment, { Moment } from 'moment'

const PREFIX = 'PLATFORM_'

export const PLATFORM_CURRENT_ZONE_KEY = `${PREFIX}CURRENT_ZONE`

export const EDITABLE_SIZE = 3 * 1024 * 1024
export const UPLOAD_CHUNK_SIZE = 5 * 1024 * 1024

export const LAST_COMPANY_ID = PREFIX + 'LAST_COMPANY_ID'
export const LAST_PROJECT_ID = PREFIX + 'LAST_PROJECT_ID'

export enum FeatureEnum {
  CLOUD_APP = 'cloud_app'
}

export enum JobDraftEnum {
  JOB_DRAFT_STORE_KEY = 'JOB_DRAFT_STORE_KEY',
  JOB_REDEPLOY_DRAFT_STORE_KEY = 'JOB_REDEPLOY_DRAFT_STORE_KEY',
  JOB_CONTINUOUS_DRAFT_STORE_KEY = 'JOB_CONTINUOUS_DRAFT_STORE_KEY'
}

export enum CloudShellPermissionEnum {
  NONE,
  PENDING,
  APPROVED
}

export const GeneralDatePickerRange: Record<string, [Moment, Moment]> = {
  当月: [moment().startOf('month'), moment().endOf('month')],
  上月: [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')
  ],
  前三月: [
    moment().subtract(3, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')
  ],
  本年度: [moment().startOf('year'), moment().endOf('year')],
  上一年度: [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year')
  ]
}

export enum JobFileTypeEnum {
  all,
  result,
  model,
  log,
  middle,
  others
}

export enum RESOURCE_TYPE {
  UNKNOWN = 0,
  COMPUTE_APP = 1,
  VISUAL_APP = 2,
  CLOUD_STORAGE = 3,
  SC_TEMINAL_APP = 4,
  IBV_SOFTWARE = 5,
  IBV_HARDWARE = 6,
  STANDARD_COMPUTE_APP = 7,
  BUNDLE_VISUAL_APP_ALL = 101,
  CLOUD_APP_COMBO = 102, // 3D云应用套餐（购买套餐）
  //对前端来说，账单关注使用情况
  CLOUD_APP_COMBO_USAGE = 103 // 3D云应用使用套餐
}

export * from './job'
export * from './custom'

export const VISUAL_TASK_STATUS_NAME = {
  0: '未知',
  1: '排队中',
  2: '提交中',
  3: '运行中',
  4: '已失败',
  5: '已关闭',
  6: '离线'
}

export const SPACE_MGR_ROLE_NAMES = ['普通用户', '外协人员']

export const CAN_CHANGE_SPACE_OWNER_ROLE_NAMES = ['管理员', '子管理员']

export const INNER_COMPANY_IDS = ['4mhk5sRjJNd', '49S5JQbqeb9']

if (process.env.NODE_ENV === 'development') {
  INNER_COMPANY_IDS.push('3P6Yp9xVJZW')
}

// 资源中心 操作系统平台
export const OPERATING_SYSTEM_PLATFORM = {
  0: 'ALL',
  1: 'LINUX',
  2: 'WINDOWS'
}
// 资源中心 软件形式
export const SOFTWARE_FORM = {
  0: '未知', //  UNKNOWN
  1: '桌面形式', //  DESKTOP
  2: '应用形式' //  APPLICATION
}

// 那些作业状态下可以显示可视化分析:残差图，监控项，云图等
export const SHOW_JOB_VISIBLE = [
  'running',
  'completing',
  'completed',
  'failed',
  'canceled'
]

// 单价类型
export enum CHARGE_TYPE {
  ALL_TYPE = 0,
  REAL_QUATITY_TYPE = 1, // 按量
  MONTHLY_TYPE = 2, // 包年包月
  HOURLY_TYPE = 3 // 包时
}
export const BILLING_TYPE_MAP = {
  0: '未知',
  1: '按量计费',
  2: '包年包月',
  3: '包时长'
}

export const AppNameMap = {
  FileManage: '文件管理',
  Calculator: '作业提交',
  JobManage: '作业管理',
  '3dcloudApp': '3D云应用',
  EnterpriseManage: '企业管理',
  'Starccm+': '3D-StarCCM+',
  'Starccm+Calc': '求解-StarCCM+'
}

export const RouterTogg = {
  'files': 'FILEMANAGE',
  'new-job-creator': 'CALCUAPP',
  'new-jobs': 'JOBMANAGE',
  'company': 'ENTERPRISEMANAGE',
  'messages': 'MESSAGES',
  'vis-session': '3DCLOUDAPP',
  'new-job': 'NEWJODETAIL',
  'dashboard': 'DASHBOARD'
}

export const RouterWappMap = {
  'files': 'fileManage',
  'new-job-creator': 'calculator',
  'new-jobs': 'jobManage',
  'vis-session': '3dcloudApp',
  'new-job': 'jobDetail',
}