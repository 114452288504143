/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { JobDirectory } from './JobDirectory'
import { JobFile } from './JobFile'
import { v4 as uuidv4 } from 'uuid'

interface IRemoteCommonFile {
  name: string
  size: number
  mod_time: number
  is_dir: boolean
  common_path?: string
}

export class FileTree extends JobDirectory {
  uploadLocalFile = (id, file) => {
    const node = this.filterFirstNode(item => item.id === id)
    if (node && !node.isFile) {
      const { webkitRelativePath = '' } = file.originFileObj || {}
      if (!webkitRelativePath) {
        node.unshift(
          new JobFile({
            name: file.name,
            uid: file.uid,
            size: file.size,
            percent: file.percent,
            status: file.status,
          })
        )
      } else {
        const paths = webkitRelativePath.split('/')
        const filename = paths.pop()
        const dirPath = paths.join('/')
        const dir = this.ensureDir(`${node.path}/${dirPath}`)
        dir.unshift(
          new JobFile({
            name: filename,
            uid: file.uid,
            size: file.size,
            percent: file.percent,
            status: file.status,
          })
        )
      }
    }
  }

  uploadCommonFiles(files: IRemoteCommonFile[]) {
    // 处理目录
    files
      .filter(i => i.is_dir)
      .forEach(file => {
        const paths = file.name.split('/').filter(i => !!i)
        const dirPath = paths.join('/')

        let common_path_prefix = ''

        if (file?.common_path) {
          let lastIndex = file?.common_path.lastIndexOf(file.name)
          common_path_prefix = file?.common_path.substring(0, lastIndex)
        }

        this.ensureDir(dirPath, common_path_prefix)
      })

    // 处理文件
    files
      .filter(i => !i.is_dir)
      .forEach(file => {
        const paths = file.name.split('/').filter(i => !!i)
        const filename = paths.pop()
        const dirPath = paths.join('/')

        let common_path_prefix = ''

        if (file?.common_path) {
          let lastIndex = file?.common_path.lastIndexOf(file.name)
          common_path_prefix = file?.common_path.substring(0, lastIndex)
        }

        const dir = this.ensureDir(dirPath, common_path_prefix)
        dir.unshift(
          new JobFile({
            name: filename,
            uid: uuidv4(),
            size: file.size,
            realCommomPath: file?.common_path
          })
        )
      })
  }
}
