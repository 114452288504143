/* Copyright (C) 2016-present, Yuansuan.cn */

import { action, observable } from 'mobx'

export class ConnectionFactory {
  url: URL
  protocols: string[]

  constructor(url: string, protocols?: string[]) {
    this.url = new URL(url)
    this.protocols = protocols
  }

  create(queryStr?: string): Connection {
    let url = this.url.href

    if (queryStr) {
      if (this.url.search) {
        url = `${this.url.href}&${queryStr}`
      } else {
        url = `${this.url.href}?${queryStr}`
      }
    }

    return new Connection(url, this.protocols)
  }
}

export class Connection {
  bare: WebSocket
  @observable status = 0
  @action
  setStatus(status) {
    this.status = status
  }

  constructor(url: string, protocols?: string[]) {
    this.bare = new WebSocket(url, protocols)
  }

  open() {
    // nothing todo for websocket
  }

  close() {
    this.bare.close()
  }

  send(data: string) {
    this.bare.send(data)
  }

  isOpen(): boolean {
    if (
      this.bare.readyState == WebSocket.CONNECTING ||
      this.bare.readyState == WebSocket.OPEN
    ) {
      return true
    }
    return false
  }

  onOpen(callback: () => void) {
    this.bare.onopen = event => {
      callback()
      this.setStatus(1)
    }
  }

  onReceive(callback: (data: string) => void) {
    this.bare.onmessage = event => {
      callback(event.data)
    }
  }

  onClose(callback: () => void) {
    this.bare.onclose = e => {
      callback()
      this.setStatus(-1)
    }
  }

  onError(callback: () => void) {
    this.bare.onerror = e => {
      callback()
      this.setStatus(-2)
    }
  }
}
