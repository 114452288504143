/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { message } from 'antd'
import { Provider, useStore } from './store'
import { observer } from 'mobx-react-lite'
import { Page } from '@/components'
import { useDidUpdate } from '@ys/utils/hooks'
import { Filter } from './Filter'
import { JobList, Context, useModel } from './JobList'
import { pageStateStore } from '@/utils'
import AreaSelect from '@/components/areaSelect'
import { env } from '@/domain'

const StyledLayout = styled.div`
  .areaSelectWrap {
    display: none;
    padding: 10px 20px;
    border-bottom: 6px solid #f5f5f5;
    background: #fff;
    > div {
      display: flex;
      align-items: center;
      /* h3 {
    margin-bottom: 0;
    font-weight: normal;
  } */
    }
  }
`

const JobListPage = observer(function JobListPage() {
  const store = useStore()
  const jobListStore = useModel(store)
  useEffect(() => {
    store.getSCList()
    store.refresh()
  }, [])

  // useEffect(() => {
  //   if (env?.job_zone_list?.length) {
  //     const OMS_job_zone_list = JSON.parse(env?.job_zone_list)
  //     const findRes = OMS_job_zone_list.find(item => item.key === env.currentZoneKey)
  //     if (findRes && !findRes.checked) {
  //       // t('FindAndSwitchIsNotFalse')
  //       store.setScId('-') // t('Sc_idEmptyServedWithDirtyData,SoWhenThereIsNoSuperCalculateIdOrNotOpen') - t('OnBehalfOfTheRegionAreNotOpenOrOpen')
  //       // t('EmptyTheList')
  //       store.model.update({
  //         list: [],
  //       })
  //       message.warning(t('TheAreaWasn'tOpen')！')
  //     } else {
  //       // t('Couldn'tFindAccessSwitchIsNotTrue')
  //       store.setScId(env.currentZoneScId || '-')
  //       // t('IfTheCurrentSc_idNoDataToProveThatNoOpenArea')
  //       !env.currentZoneScId && message.warning(t('TheRegionTemporarilyNotOpened')！')
  //     }
  //   } else {
  //     store.setScId(env.currentZoneScId)
  //   }
  //   store.setZoneKey(env.currentZoneKey)
  // }, [env.currentZoneScId])

  useEffect(() => {
    store.refresh()
    // reserve query && page state
    pageStateStore.setByPath({
      query: store.query,
      pageIndex: store.pageIndex,
      pageSize: store.pageSize
    })
  }, [store.query, store.pageIndex, store.pageSize, store.zoneId])

  // t('IfTheCurrentPageIsGreaterThan1AndTheCurrentPageDoesNotExist')，t('JumpQueryTheFirstPageOfData')
  useDidUpdate(() => {
    if (store.model.list.length === 0 && store.pageIndex > 1) {
      store.setPageIndex(1)
    }
  }, [store.model.list.length])

  useEffect(() => {
    let handler = setTimeout(function pull() {
      store.refresh().finally(() => {
        handler = setTimeout(pull, 5000)
      })
    }, 5000)

    return () => clearTimeout(handler)
  }, [])

  return (
    <StyledLayout id='job_manager'>
      <Page header={null}>
        <div className='areaSelectWrap'>
          {/* t('RegionalDrop-downBox') */}
          <AreaSelect
            {...{
              zoneList: store.SCList,
              value: store.scId,
              changeCallBack: store.setScId
            }}
          />
        </div>
        {/* t('InTheMiddleOfTheScreenArea') */}
        <Filter />
        <Context.Provider value={jobListStore}>
          {/* t('ThreeButtons')+t('form')+t('paging') */}
          <JobList />
        </Context.Provider>
      </Page>
    </StyledLayout>
  )
})

export default function PageWithStore() {
  return (
    <Provider>
      <JobListPage />
    </Provider>
  )
}
