/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { JobStats } from './JobStats'

const StyledLayout = styled.div``

export const Chart = observer(function Chart() {
  return (
    <StyledLayout>
      <JobStats />
    </StyledLayout>
  )
})
