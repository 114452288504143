/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { createStore } from '@ys/utils/hooks'
import { useLocalStore } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { JobDraftEnum } from '@/constant'
import { appList, box, currentUser, env, uploader } from '@/domain'
import { FileTree } from '@/domain/JobBuilder/FileTree'
import { getFilenameByPath, history, Http, parseUrlParam } from '@/utils'
import { fromJSON2Tree } from '@/domain/JobBuilder/utils'
import App from '@/domain/Application/App'
import { message } from 'antd'
import { companyServer } from '@/server'
import {
  getContinuousRedeployInfo,
  getRedeployInfo
} from '@/domain/JobBuilder/NewJobBuilder'
import { DraftType, NewDraft } from '@/domain/Box'
import { getUrlParams } from '@ys/utils'

class IJobBuilderData {
  name: string
  currentApp: App
  paramsModel: any
  scIds: string[]
  numCores: number
  currentAppId?: string
  mainFilePaths?: string[]
}

const initData = {
  name: '',
  currentApp: null,
  paramsModel: { isTyping: false },
  scIds: [], // 用户选中的超算
  numCores: null,
  mainFilePaths: undefined
}

export const zoneMap = {
  'az-shanghai': '华东1',
  'az-wuxi': '华东2',
  'az-jinan': '华东3',
  'az-gansu': '西北1',
  'ys-box': '远算盒子'
}

export function useModel() {
  return useLocalStore(() => {
    const params = parseUrlParam(window.location.search)
    return {
      tabKey: 'formal',
      setTabKey(key) {
        this.tabKey = key
      },
      get is_trial() {
        return this.tabKey === 'trial'
      },
      unblock: undefined,
      setUnblock(v) {
        this.unblock = v
      },
      unlisten: undefined,
      setUnlisten(v) {
        this.unlisten = v
      },
      // 作业提交区域选择
      // zoneId: '', // 分区对应的前缀url
      // setZoneId(id: string, option = { key: '' }) {
      //   this.zoneId = id
      //   option?.key && this.setScId(option?.key)
      //   option?.key && this.setZoneKey(option?.key)
      //   // 重新获取草稿箱中的数据
      //   // option?.key && this.restore()
      // },
      // zoneList: [],
      // setZoneList(list) {
      //   this.zoneList = list
      // },
      zoneKey: '', // 分区对的key，az-shanghai az-jinan ...
      setZoneKey(key: string) {
        this.zoneKey = key
      },
      // 上传模型处的区域选择
      moduleZoneList: [],
      setModuleZoneList(list) {
        this.moduleZoneList = list
      },
      moduleZoneId: '', // 分区对应的前缀url
      setModuleZoneId(id: string, option = { key: '' }) {
        this.moduleZoneId = id
        option?.key && this.setModuleZoneKey(option?.key)
        // option?.key &&  this.restore()
        option?.key && this.restoreFileList()
      },
      moduleZoneKey: '', // 分区对的key，az-shanghai az-jinan ...
      setModuleZoneKey(key: string) {
        this.moduleZoneKey = key
      },
      scId: '', // 超算ID
      setScId(id: string, option = { key: '' }) {
        this.scId = id
        option?.key && this.setZoneKey(option?.key)
        this.initScsCoresList()
      },
      SCList: [], // 超算列表
      setSCList(list) {
        this.SCList = list
      },
      removeHistoryBlock() {
        this.unblock && this.unblock()
        this.unlisten && this.unlisten()
      },

      /**
       * 由于目前后端接口没有齐全，通过代理的方式进行跨域处理操作
       * @param url 分区对应的url
       * @returns url
       */
      getBaseUrl(url: string) {
        // switch (window.location.hostname) {
        //   case 'localhost': // 本地开发环境
        //     return url === 'az-jinan' ? window.location.origin : box.url
        //   case 'platform.intern.yuansuan.cn': // 开发开发环境
        //     return url === 'az-jinan' ? 'https://10.0.64.60' : box.url
        //   case 'euclid-test.yuansuan.cn':
        //     return url === 'az-jinan'
        //       ? 'https://shanhe-test.yuansuan.cn:21312'
        //       : box.url // 测试环境 https://shanhe-test.yuansuan.cn:21312
        //   case 'future-test.yuansuan.cloud':
        //     return url === 'az-jinan'
        //       ? 'https://shanhe-test.yuansuan.cn:21312'
        //       : box.url // 智算未来测试环境
        //   default:
        //     return url.includes('az-jinan')
        //       ? 'https://jinan-storage.yuansuan.cn:21443'
        //       : box.url // 线上环境 https://jinan-storage.yuansuan.cn:21443
        // }
        if (window.location.hostname === 'localhost') {
          // 本地开发环境济南进行代理
          return url === 'https://10.0.64.60' ? window.location.origin : url
        }
        return url // 其他环境直接返回这个地址
      },

      async refresh(moduleUploadZoneId, moduleUploadZoneKey) {
        try {
          // 请求domain接口获取分区前缀
          const {
            data,
            success,
            message: errmsg
          } = await companyServer.getDomain(env.company_id)
          if (!success) {
            return message.error(errmsg)
          }
          const OMS_zone_list = env?.zone_list?.length
            ? JSON.parse(env?.zone_list)
            : []
          // 和oms的开关切换进行过滤筛选，并且过滤掉盒子
          // 如果OMS_zone_list没有数据则证明没有操作过oms区域的开关控制，那么默认无锡关闭, 反之则与OMS_zone_list列表进行兼容过滤
          let filterZoneList = !OMS_zone_list.length
            ? data.result.filter(
                item => item.zone !== 'az-wuxi' || item.zone !== 'az-gansu'
              )
            : data.result.filter(item => {
                // 如果是盒子直接false
                if (item.zone.includes('box')) {
                  return false
                }
                // 判断有没有该区域
                const findResult = OMS_zone_list.find(
                  value => value.key === item.zone
                ) // find没找到是undfined
                if (!findResult) {
                  return true
                }
                // 如果有，判断checked是否为false
                return findResult.checked
              })
          // 上传模型处区域下拉列表数据
          const moduleUpLodaZoneList = filterZoneList.map(item => ({
            name: item.zone,
            // key: this.getBaseUrl(item.storage_domains[0]),
            key: item.storage_domains[0],
            desc: zoneMap[item.zone]
          }))

          // // 判断区域domain在超算列表中是否有对应的区域
          // filterZoneList = filterZoneList.filter(item => {
          //   return this.SCList.findIndex(sc_item => sc_item.zone === item.zone) !== -1
          // })
          // // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
          // const selectData = filterZoneList.map(item => ({
          //     name: item.zone,
          //     // key: result[item].includes('az-jinan') ? 'http://10.0.64.60:8899' : result[item], // 调试济南测试地址
          //     // key: this.getBaseUrl(item.key),
          //     key: item.storage_domains[0],
          //     desc: zoneMap[item.zone]
          // }))
          // 顶部区域选择和作业提交文件存储选区进行初始化的关联
          // 判断顶部选区是否moduleUpLodaZoneList中有该区域，没有默认拿moduleUpLodaZoneList第一个，（回显使用moduleUploadZoneId）
          // 1. 初始化一个关联zoneid和key
          let relateModuleZoneId = '',
            relateModuleZoneKey = ''
          // 2. 遍历moduleUpLodaZoneList
          !moduleUploadZoneId &&
            moduleUpLodaZoneList.forEach(item => {
              // 回显直接使用url中的参数，不需要遍历寻找了
              if (item.name === this.zoneKey) {
                relateModuleZoneKey = item.name
                relateModuleZoneId = item.key
              }
            })
          runInAction(() => {
            this.setModuleZoneList(moduleUpLodaZoneList)
            this.setModuleZoneId(
              moduleUploadZoneId ||
                relateModuleZoneId ||
                moduleUpLodaZoneList[0]?.key
            )
            this.setModuleZoneKey(
              moduleUploadZoneKey ||
                relateModuleZoneKey ||
                moduleUpLodaZoneList[0]?.name
            )
          })
        } catch (error) {
          console.log('error: ', error)
        }
      },

      // 获取超算列表
      async getSCList(SCID) {
        try {
          const {
            data,
            success,
            message: errmsg
          } = await companyServer.getSCList()
          if (!success) {
            return message.error(errmsg)
          }
          // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
          const OMS_job_zone_list = env?.job_zone_list?.length
            ? JSON.parse(env?.job_zone_list)
            : []
          const filterData = data.result.filter(item => {
            // 如果是盒子直接false
            if (item.zone.includes('box')) {
              return false
            }
            // 判断有没有该区域
            const findResult = OMS_job_zone_list.find(
              value => value.key === item.zone
            ) // find没找到是undfined
            if (!findResult) {
              return true
            }
            // 如果有，判断checked是否为false
            return findResult.checked
          })
          const selectData = filterData.map(item => ({
            name: item.zone,
            // key: result[item].includes('az-jinan') ? 'http://10.0.64.60:8899' : result[item], // 调试济南测试地址
            // key: this.getBaseUrl(item.key),
            key: item.sc_id,
            desc: zoneMap[item.zone]
          }))
          runInAction(() => {
            this.setSCList(selectData)
            this.setScId(SCID || selectData[0]?.key || '') // 超算ID
            this.setZoneKey(selectData[0]?.name || '') // 分区对的key，az-shanghai az-jinan
          })
        } catch (error) {
          console.log('error: ', error)
        }
      },

      jobBuildMode: 'default',
      get draftKey(): string {
        switch (this.jobBuildMode) {
          case 'default':
            return JobDraftEnum.JOB_DRAFT_STORE_KEY
          case 'redeploy':
            return JobDraftEnum.JOB_REDEPLOY_DRAFT_STORE_KEY
          case 'continuous':
            return JobDraftEnum.JOB_CONTINUOUS_DRAFT_STORE_KEY
        }
        return null
      },
      // 新版添加区域分区
      get draft(): NewDraft {
        switch (this.jobBuildMode) {
          case 'default':
            return new NewDraft(DraftType.Default, this.moduleZoneId)
          case 'redeploy':
            return new NewDraft(DraftType.Redeploy, this.moduleZoneId)
          case 'continuous':
            return new NewDraft(DraftType.Continuous, this.moduleZoneId)
        }
        return null
      },

      fileTree: new FileTree({
        name: ''
      }),
      data: initData,

      params: [],
      scs_cores_list: [],
      isJobSubmitting: false,

      get isInRedeployMode() {
        return this.jobBuildMode === 'redeploy'
      },

      get isInConMode() {
        return this.jobBuildMode === 'continuous'
      },

      get isDefault() {
        return this.jobBuildMode === 'default'
      },

      restoreData: undefined,
      redeployJobId: '',
      continuousJobId: '',
      redeployType: 'job',

      setJobBuilderMode(
        key: 'default' | 'redeploy' | 'continuous',
        param?: { id: string; type: 'job' | 'jobset' }
      ) {
        this.jobBuildMode = key
        if (key === 'redeploy') {
          this.redeployJobId = param.id
          this.redeployType = param.type
        } else if (key === 'continuous') {
          this.continuousJobId = param.id
        }
      },

      async restore(moduleUploadZoneId) {
        // reset draft
        const draftCache = localStorage.getItem(this.draftKey)
        if (draftCache) {
          try {
            const draft = JSON.parse(draftCache)
            if (
              draft.project_id !== env.project?.id ||
              draft.user_id !== currentUser.id
            ) {
              localStorage.removeItem(this.draftKey)
            }
          } catch (e) {
            localStorage.removeItem(this.draftKey)
          }
        }
        switch (this.jobBuildMode) {
          case 'default':
            await this.restoreFromDraft()
            break
          case 'redeploy':
            try {
              this.restoreData = await getRedeployInfo({
                id: this.redeployJobId,
                type: this.redeployType as any,
                clean: false,
                zoneId: this.moduleZoneId || moduleUploadZoneId
              })
            } catch (e) {
              setTimeout(() => history.replace('/new-jobs'), 300)
              return
            }

            await this.restoreFromRedeploy()
            break
          case 'continuous':
            try {
              this.restoreData = await getContinuousRedeployInfo({
                id: this.continuousJobId,
                type: this.redeployType as any,
                clean: false,
                zoneId: this.moduleZoneId || moduleUploadZoneId
              })
            } catch (e) {
              setTimeout(() => history.replace('/new-jobs'), 300)
              return
            }

            await this.restoreContinuousFromResult()
            break
        }
      },

      get apps() {
        return appList.list
          .filter(item => !item.type.includes('workflow'))
          .filter(item => !item.is_trial)
          .filter(item => item.sc_ids.includes(this.scId))
      },

      // 获取所有所选超算的核数范围list
      get selectedCoresScopes() {
        return this.scs_cores_list
          .filter(cores => this.data.scIds.includes(cores.sc_id))
          .map(availableCores =>
            availableCores.cores.slice(0).sort((a, b) => a - b)
          )
          .map(cores => [cores[0], cores.pop()])
      },

      // 获取所选超算的推荐核数
      get suggestedCores() {
        return this.data.scIds.length === 1
          ? this.scs_cores_list?.find(item => item.sc_id === this.data.scIds[0])
              ?.suggestion
          : null
      },

      // 计算核数交集
      get cores_scope() {
        if (
          this.selectedCoresScopes.length === 0 ||
          !this.selectedCoresScopes[0][0]
        )
          return [0, 0]

        let [min, max] = this.selectedCoresScopes[0]
        this.selectedCoresScopes.forEach(scopes => {
          min = Math.max(min, scopes[0])
          max = Math.min(max, scopes[1])
        })
        return [min, max]
      }, // 根据核数交集展示提示

      // TODO - Jimmy 显示核数
      get displayCoresScope() {
        if (this.data.scIds.length === 0) return '请先勾选算力资源'
        const [min, max] = this.cores_scope

        if (min > max) return '核数可选范围为空，请重勾选算力资源。'
        else return `${min}的倍数,范围(${min}-${max})核`
      },

      // 填写核数，核数交集范围是否正确
      get displayCoreValid() {
        const [min, max] = this.cores_scope
        return (
          this.data.numCores >= min &&
          // this.data.numCores <= max &&
          typeof this.data.numCores === 'number'
        )
      },

      get displayExpectCoresScope() {
        return '范围(1-1000)核'
      },
      // 填写期望核数，新的核数范围固定为 1-200
      get displayExpectCoreValid() {
        if (this.data.numCores) {
          return (
            this.data.numCores >= 1 &&
            this.data.numCores <= 1000 &&
            typeof this.data.numCores === 'number'
          )
        } else {
          if (this.data.numCores === 0) {
            return false
          }
          return true
        }
      },

      // 核并集
      get cores_scope_union(): number[] {
        const cores = this.scs_cores_list
          .filter(cores => this.data.scIds.includes(cores.sc_id))
          .reduce((arr, prev) => arr.concat(prev.cores), [])
          .sort((a, b) => a - b)

        return cores.length > 0 ? [cores[0], cores[cores.length - 1]] : [1, 1]
      },

      // only can be used for constructor function
      // to restore data from localstorage when page refresh
      async init(moduleUploadZoneId) {
        // await this.fetchApps()

        // 从草稿恢复作业
        await this.restore(moduleUploadZoneId)
      },

      async fetchApps() {
        await Promise.all([appList.fetch()])
      },

      async fetchParams() {
        await Promise.all([
          // 获取超算和核数信息
          this.initScsCoresList(),
          // 获取应用参数
          this.initSection()
        ])

        // 初始化应用参数
        this.initParamsData()
      },
      // // 切换app
      // appDisposer: reaction(
      //   () => this.data.currentApp,
      //   async app => {
      //     // 点击重置按钮后 此时app为空 自动选择第一个app
      //     if (!app) {
      //       this.updateData({
      //         currentApp: this.apps[0],
      //       })
      //       return
      //     }
      //
      //     await this.fetchParams()
      //   }
      // ),

      updateData(data: Partial<IJobBuilderData>) {
        Object.assign(this.data, data)
      },

      get defaultJobName() {
        return this.mainFilePaths.length === 1
          ? getFilenameByPath(this.mainFilePaths[0])
          : ''
      },

      get mainFiles() {
        return this.fileTree.flatten().filter(node => {
          return node.isFile && node.isMain
        })
      },

      get mainFilePaths() {
        return this.mainFiles.map(node => node.path)
      },

      // // cache job creation data
      // disposer = reaction(
      //   () => {
      //     return {
      //       ...this.data,
      //       mainFilePaths: this.mainFilePaths,
      //       currentAppId: this.currentAppId,
      //       isTyping: this.data.paramsModel.isTyping,
      //     }
      //   },
      //   ({ currentApp, isTyping, ...data }) => {
      //     if (this.data.paramsModel.isTyping) {
      //       if (this.isInRedeployMode) return
      //
      //       localStorage.setItem(
      //         this.draftKey,
      //         JSON.stringify({
      //           ...data,
      //           user_id: currentUser.id,
      //           project_id: env.project?.id,
      //         })
      //       )
      //     }
      //   }
      // )

      get currentAppId() {
        return this.data.currentApp ? this.data.currentApp.id : ''
      },

      async initScsCoresList() {
        this.scs_cores_list = []
        this.updateData({
          scIds: []
        })
        this.scs_cores_list =
          (await this.data.currentApp?.getRuntimeScsCoresList()) || []
        this.scs_cores_list = this.scs_cores_list?.filter(
          item => item.sc_id === this.scId
        )
        this.selectDefaultCoresOption()
      },

      selectDefaultCoresOption() {
        if (this.data.currentApp) {
          // 返回的值为已排序 所以需要更新当前app的scIds
          const scIds = this.scs_cores_list?.map(c => c.sc_id)
          this.data.currentApp.setScIds(scIds)

          // 切换、加载app时 没有选中的id则帮助勾选
          if (this.data.scIds.length === 0) {
            const localIds = this.scs_cores_list
              .filter(o => o.is_local)
              .map(o => o.sc_id)

            // 如果有本地集群 则勾选 否则勾选第一个
            if (localIds.length > 0)
              this.updateData({
                scIds: localIds
              })
            else
              this.updateData({
                scIds: [scIds[0]]
              })
          } else {
            // 有选中的id则
            // 保留已存在的id
            this.data.scIds = this.data.scIds.filter(id => scIds.includes(id))
          }
        }
      },

      // 获取app参数
      async initSection() {
        if (!this.data.currentApp) return
        this.params = []
        this.params = await this.data.currentApp.getParams()
      },

      // 初始化应用参数，合并缓存中的数据
      initParamsData(cacheParams: any = {}) {
        if (!this.data.currentApp) {
          return
        }

        const data = {}

        this.params.forEach(field => {
          const cache = cacheParams[field.id]
          const value =
            cache?.value === '' || cache?.value === undefined
              ? field.value === ''
                ? field.defaultValue
                : field.value
              : cache?.value
          const values =
            Array.isArray(cache?.values) && cache.values.length > 0
              ? cache?.values
              : field.values.length > 0
              ? field.values
              : field.defaultValues
          data[field.id] = {
            ...field,
            value,
            values
          }
        })
        this.updateData({ paramsModel: data })
      },

      async jobSetNameCheck(name): Promise<boolean> {
        const { data } = await Http.get('job/set/name_check', {
          params: {
            name
          }
        })
        return data.exists
      },

      /**
       * 创建作业：调用盒子api将draft目录移到input目录
       * 将返回的参数传给创建作业模块
       */
      async create(entries) {
        const { paramsModel, currentApp, name, scIds, numCores } = this.data
        if (!this.beforeCreate()) return false
        entries.forEach(async e => {
          if (e.mainFile.endsWith('.jobs')) {
            // fetch file content
            e.content = await this.draft.getFileContent(e.mainFile).then(a => a)
          }
        })

        const res = await this.draft.submit()
        const fields = Object.keys(paramsModel).map(key => {
          return {
            id: key,
            value: paramsModel[key].value,
            values: paramsModel[key].values,
            type: paramsModel[key].type
          }
        })

        const data = {
          entries,
          name: name || this.defaultJobName,
          app_id: currentApp.id,
          user_params: { fields },
          user_params_version: currentApp.app_params_version,
          // sc_ids: scIds,
          sc_ids: [this.scId], // 选择区域对应的超算Id
          num_cores: numCores,
          upload_file_info: {
            source_file_storage_id: res.data.file_storage_id,
            source_path: res.data.input_path,
            sync_runner_id: res.data.file_sync_runner_id,
            file_zone: this.moduleZoneKey // 分区区域标识
          },
          csptag: 1
        }

        const store = {
          name: data.name,
          paramsModel,
          scIds: this.data.scIds,
          numCores: this.data.numCores,
          currentAppId: currentApp.id,
          mainFilePaths: this.mainFilePaths,
          input_folder_uuid: res.data.input_folder_uuid
        }

        try {
          this.isJobSubmitting = true

          const createRes = await Http.post('/job/solution', { ...data, store })
          // const createRes = await Http.post('/job', { ...data, store })
          if (!createRes.success) {
            await this.draft.back(res.data.input_folder_uuid)
            return false
          }
        } catch (e) {
          await this.draft.back(res.data.input_folder_uuid)
          return false
        } finally {
          this.isJobSubmitting = false
        }
        // this.clean()
        return true
      },

      beforeCreate() {
        // 校验作业参数
        if (!this.mainFilePaths.length) {
          message.error('请至少选择一个主文件')
          return false
        }

        if (!this.data.currentApp) {
          message.error('请选择应用')
          return false
        }

        const jobName = this.data.name || this.defaultJobName
        if (!jobName) {
          message.error('请填写作业名称')
          return false
        }

        // 校验作业模板
        const { paramsModel } = this.data
        for (const key of Object.keys(paramsModel)) {
          const param = paramsModel[key]
          if (param && param.required && !param.value && param.values === 0) {
            message.error(`请填写${param.label}`)
            return false
          }
        }
        return true
      },

      // 清理文件树
      reset() {
        this.fileTree = new FileTree({
          name: ''
        })
        // 重置时保留app
        this.updateData({ ...initData, currentApp: this.data.currentApp })
      },

      // 清理draft、取消上传、清理文件树
      async clean(refetch = false) {
        await this.draft.clean()

        localStorage.removeItem(this.draftKey)

        // cancel uploader
        this.fileTree.tapNodes(
          () => true,
          node => {
            uploader.remove(node.uid)
          }
        )

        this.reset()

        if (this.isInRedeployMode || this.isInConMode) {
          // 重提交下重置会通过api恢复作业参数
          await this.init()
        } else if (refetch) {
          // 非重提交下 点击重置按钮仅需重新获取参数
          await this.fetchParams()
        }
      },

      async restoreFileList(mainFilePaths?: string[]) {
        if (!mainFilePaths) {
          mainFilePaths = this.data.mainFilePaths || []
        }

        // 恢复上传的文件，获取盒子中draft的文件
        let files = await this.draft.listFile()

        this.fileTree = fromJSON2Tree(files)
        // 恢复主文件，从文件树中找到对应的主文件 id
        this.fileTree.tapNodes(
          node => mainFilePaths.includes(node.path),
          node => {
            node.isMain = true
          }
        )
      },

      // 从草稿中恢复到工作区
      async restoreFromDraft() {
        if (!this.apps.length) return

        let cache: IJobBuilderData, isCacheEmpty

        // parse cache
        const cacheStr = localStorage.getItem(this.draftKey)

        if (!cacheStr) {
          cache = {} as IJobBuilderData
          isCacheEmpty = true
        } else {
          cache = JSON.parse(cacheStr)
          isCacheEmpty = false
        }

        delete cache['project_id']
        delete cache['user_id']

        // 恢复app，如果找不到，重置为第一个
        this.updateData({
          currentApp:
            this.apps.find(app => app.id === cache.currentAppId) ||
            this.apps.filter(app => app.is_trial === this.is_trial)[0]
        })
        delete cache.currentAppId
        await this.restoreFileList(cache.mainFilePaths)

        if (this.data.currentApp) {
          // 恢复作业参数
          await this.initSection()
          this.initParamsData(cache?.paramsModel)

          // 恢复算力资源，去除不存在的算力资源
          cache.scIds = (cache.scIds || []).filter(scId =>
            this.data.currentApp.sc_ids.includes(scId)
          )

          // 刷新重新获取coresList
          await this.initScsCoresList()
        }

        !isCacheEmpty &&
          this.updateData({
            name: cache.name,
            numCores: cache.numCores
          })
      },

      // 从重提交远端中恢复到工作区
      async restoreFromRedeploy() {
        if (!this.apps.length) return

        let cache: IJobBuilderData, isCacheEmpty
        cache = { ...this.restoreData }
        cache.name += '_re'
        // await this.draft.back(cache['input_folder_uuid']).catch(() => {
        //   setTimeout(() => (location.href = '/new-jobs'), 300)
        // })

        delete cache['project_id']
        delete cache['user_id']

        // 恢复app，如果找不到，重置为第一个
        this.updateData({
          currentApp:
            this.apps.find(app => app.id === cache.currentAppId) ||
            this.apps.filter(app => app.is_trial === this.is_trial)[0]
        })
        delete cache.currentAppId

        await this.restoreFileList(cache.mainFilePaths)

        if (this.data.currentApp) {
          // 恢复作业参数
          await this.initSection()
          this.initParamsData(cache?.paramsModel)

          // 恢复算力资源，去除不存在的算力资源
          cache.scIds = (cache.scIds || []).filter(scId =>
            this.data.currentApp.sc_ids.includes(scId)
          )

          // 刷新重新获取coresList
          await this.initScsCoresList()
        }

        !isCacheEmpty && this.updateData(cache)
      },

      // 从作业结果恢复到提交界面
      async restoreContinuousFromResult() {
        if (!this.apps.length) return

        let cache: IJobBuilderData, isCacheEmpty
        cache = { ...this.restoreData }
        cache.name += '_continuous'

        delete cache['project_id']
        delete cache['user_id']

        // 恢复app，如果找不到，重置为第一个
        this.updateData({
          currentApp:
            this.apps.find(app => app.id === cache.currentAppId) ||
            this.apps.filter(app => app.is_trial === this.is_trial)[0]
        })
        delete cache.currentAppId

        await this.restoreFileList(cache.mainFilePaths)

        if (this.data.currentApp) {
          // 恢复作业参数
          await this.initSection()
          this.initParamsData(cache?.paramsModel)

          // 恢复算力资源，去除不存在的算力资源
          cache.scIds = (cache.scIds || []).filter(scId =>
            this.data.currentApp.sc_ids.includes(scId)
          )

          // 刷新重新获取coresList
          await this.initScsCoresList()
        }

        !isCacheEmpty && this.updateData(cache)
      }
    }
  })
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
