/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { List, Empty } from 'antd'
import { ChromeOutlined } from '@ant-design/icons'
import { Button, Modal } from '@ys/components'
import { observer } from 'mobx-react-lite'
import { format } from 'timeago.js'
import { lastInvitations } from '@/domain'
import { Invitation, InvitedUserType } from '@/domain/InvitationList/Invitation'
import { StyledPanel } from './style'
import { useTranslation } from 'react-i18next'

interface IProps {
  hideDropdown: () => void
}

export const Todo = observer(function Todo({ hideDropdown }: IProps) {
  const { t } = useTranslation()
  async function accept(item: Invitation) {
    hideDropdown()
    await Modal.showConfirm({
      title: t('AcceptTheInvitation'),
      content: t('ConfirmToAcceptTheInvitation?'),
    })
    await item.accept()
    await lastInvitations.fetchLast()
  }

  async function reject(item: Invitation) {
    hideDropdown()
    await Modal.showConfirm({
      title: t('RefusedToInvite'),
      content: t('ConfirmWillRefuseTheInvitation?'),
    })
    await item.reject()
    await lastInvitations.fetchLast()
  }

  return (
    <StyledPanel>
      <List
        bordered
        locale={{ emptyText: <Empty description={t('NoPendingInvitations')} /> }}
        dataSource={lastInvitations.list}
        renderItem={item => (
          <List.Item key={item.id} className='item'>
            <List.Item.Meta
              avatar={<ChromeOutlined />}
              title={
                <div className='title'>
                  <div>{t('BusinessInvitation')}</div>
                  <div className='time'>
                    {format(item.create_time.toString(), 'zh_CN')}
                  </div>
                  <div className='actions'>
                    <Button type='link' onClick={() => accept(item)}>
                      {t('accept')}
                    </Button>
                    <Button
                      type='link'
                      style={{ color: '#f5222d' }}
                      onClick={() => reject(item)}>
                      {t('RefusedTo')}
                    </Button>
                  </div>
                </div>
              }
              description={
                item.is_admin === InvitedUserType.INVITE_IS_ADMIN
                  ? `${item.company_name}${t('InviteYouToBecomeManagers')}`
                  : `${item.company_name}${t('InviteYouToJoin')}`
              }
            />
          </List.Item>
        )}
      />
    </StyledPanel>
  )
})
