/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { env } from '@/domain'
import { Button } from '@ys/components'
import { buryPoint, history } from '@/utils'
import { useTranslation } from 'react-i18next'

export const Recharge = observer(function Recharge() {
  const { t } = useTranslation()
  return (
    env.isPersonal && (
      <div style={{ padding: '0 10px', marginLeft: 8 }}>
        <Button
          type='secondary'
          size='small'
          onClick={() => {
            buryPoint({
              category: t('TheNavigationBar'),
              action: t('top-up'),
            })
            history.push('/recharge')
          }}>
          {t('top-up')}
        </Button>
      </div>
    )
  )
})
