/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action } from 'mobx'
import { Job, JobRequest } from './Job'

export class BaseJobList {
  @observable list: Job[] = []
  @observable page_ctx: {
    index: number
    size: number
    total: number
  } = {
    index: 1,
    size: 10,
    total: 0,
  }
}

type JobListRequest = Omit<BaseJobList, 'list'> & {
  list: JobRequest[]
}

export class JobList extends BaseJobList {
  constructor(props?: Partial<JobListRequest>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  @action
  update({ list, ...props }: Partial<JobListRequest>) {
    Object.assign(this, props)

    if (list) {
      this.list = list.map(item => new Job(item))
    }
  }
}
