/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

export { downloadTestFile } from './downloadTestFile'
export { default as history } from './history'
export { Http, RawHttp } from './Http'
export { NewHttp, NewRawHttp } from './NewHttp'
export { Http as v2Http } from './v2Http'
export * from './formatter'
export * from './Visual'
export { buryPoint } from './buryPoint'
export { pageStateStore } from './pageStateStore'
export { apolloClient } from './apolloClient'
import { formatUnixTime } from '@ys/utils'
import { Http } from '@/utils'
import { default as history } from './history'
import guides from '@/guide/guides.json'
import { default as guideServices } from '@/guide/service'
import { guideServer } from '@/server/guideServer'
import { Guide } from '@ys/guide-sdk'
export * from './apps'

export const guide = new Guide({
  driverOptions: {
    opacity: 0.3,
    allowClose: false,
    overlayClickNext: false,
    padding: 0,
    stageBackground: 'white',
    doneBtnText: '完成',
    closeBtnText: '跳过',
    nextBtnText: '下一步',
    prevBtnText: '上一步',
    keyboardControl: true,
    onHighlightStarted() {},
    onHighlighted() {},
    onReset() {},
    onPrevious: () => {}
  },
  history,
  guides,
  services: guideServices,
  config: {
    onClose: guideServer?.finish,
    onDone: guideServer?.finish
  }
})

guide.hooks.guide.onStart.tapPromise('checkIsGuideRead', async ({ guide }) => {
  const guideId = guide?.id
  if (guideId) {
    const { data: isGuideRead } = await Http.get(`/guide/${guideId}`)
    return isGuideRead ? true : undefined
  } else {
    return undefined
  }
})

export const getFilenameByPath = (p: string) => {
  const dashIndex = p.lastIndexOf('/')
  const name = p.substring(dashIndex + 1)
  const dotIndex = name.lastIndexOf('.')
  return name.substring(0, dotIndex > 0 ? dotIndex : name.length)
}

export const formatPath = (path: string): string => {
  if (!path?.length) {
    return './'
  } else {
    if (/^\/.*$/.test(path)) {
      return `.${path}`
    } else if (/^[^\/].*$/.test(path)) {
      return `./${path}`
    } else {
      return './'
    }
  }
}

export const ceilNumber = (value: number) => {
  if (!value) return 0
  let bite = 0
  if (value < 10) {
    return 10
  }
  while (value >= 10) {
    value /= 10
    bite += 1
  }
  return Math.ceil(value) * Math.pow(10, bite)
}

export const formatDate = dateISOString => {
  if (dateISOString) {
    return formatUnixTime(dateISOString)
  }
}

export const getSearchParamByKey = (searchParmasStr, key) => {
  return new URLSearchParams(searchParmasStr).get(key)
}

export const getMonths = (start, end) => {
  let months = []

  while (start.format('YYYY-MM') !== end.format('YYYY-MM')) {
    months.push(start.format('YYYY-MM'))
    start.add(1, 'months')
  }

  months.push(end.format('YYYY-MM'))

  return months
}


export const copy2clipboard = (text) => {
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(text)
  } else {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    // 隐藏此输入框
    textarea.style.position = 'fixed'
    textarea.style.clip = 'rect(0 0 0 0)'
    textarea.style.top = '10px'
    // 赋值
    textarea.value = text
    // 选中
    textarea.select()
    // 复制
    document.execCommand('copy', true)
    // 移除输入框
    document.body.removeChild(textarea)
  }
}

export function parseUrlParam(url) {
  if (!url.includes('?')) return {}
  const params = {}
  // 截取问号之后的的参数
  const urlParams = url.slice(1)
  // 根据&截取
  const dataArr = urlParams.split('&')
  dataArr.forEach(item => {
    const [ key, value ] = item.split('=')
    params[key] = value.replace('%20', ' ')
  })
  return params
}

export function checkImgExists(imgurl) {
  return new Promise(function(resolve, reject) {
    let ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = function(res) {
      resolve({success: true, res})
    }
    ImgObj.onerror = function(err) {
      resolve({success: false, err})
    }
  })
}
