/* Copyright (C) 2016-present, Yuansuan.cn */
import React, { useEffect, useState } from 'react'
import { CloseTimeWrapper, ListWrapper } from '../style'
import {
  ArrearsWrapper,
  BillingLayout,
  Content,
  Layout,
  Sider,
  SiderTitle
} from './styles'
import { Hardware, Software, StartSessionResponse } from '@/domain/Vis'
import { history } from '@/utils'
import { Button, Modal } from '@ys/components'
import { observer } from 'mobx-react-lite'
import { HardwareSelector } from './HardwareSelector'
import { SoftwareSelector } from './SoftwareSelector'
import { ComboSelector } from './ComboSelector'
import { env } from '@/domain'
import { InfoCircleFilled } from '@ant-design/icons'
import { useStore } from '../List/store'

import qs from 'qs'
import { DatePicker, Radio } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const centerStyle = {
  zIndex: 99999,
  position: 'absolute',
  left: '50%',
  top: '30%',
  transform: 'translate(-50%, 0)'
}

interface IProps {
  vis: any
  loading: boolean
  hardware: Array<Hardware>
  software: Array<Software>
  onCreated?: (response: StartSessionResponse) => void
}

export const PortableCreate = observer(
  ({ vis, hardware, software, loading, onCreated }: IProps) => {
    const store = useStore()
    const [hardwareId, setHardwareId] = useState(null)
    const [softwareId, setSoftwareId] = useState(null)
    const [price, setPrice] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [comboList, setComboList] = useState([])
    const [preset, setPreset] = useState({ items: [] })
    const [comboLoading, setComboLoading] = useState(false)
    const [billModel, setBillModel] = useState(1)
    const [comboTicketId, setComboTicketId] = useState('')
    const [comboSoftwareId, setComboSoftwareId] = useState('')
    const [autoClose, setAutoClose] = useState(false)
    const [closeTime, setCloseTime] = useState('')

    const { t } = useTranslation()

    useEffect(() => {
      if (store.zoneId) {
        setComboLoading(true)
        vis
          .getComboList(store.zoneId, env.productId)
          .then(list => {
            setComboList(list?.item)
          })
          .finally(() => {
            setComboLoading(false)
          })
      }
    }, [store.zoneId])

    // 当修改 softwareID 时获取该软件的预设清单
    useEffect(() => {
      if (softwareId) {
        vis.autoChoseHardware(softwareId).then(res => {
          setPreset(res)
        })
      }
    }, [softwareId])

    useEffect(() => {
      setHardwareId(preset?.items?.find(item => item.defaulted)?.hardware.id)
    }, [preset])

    useEffect(() => {
      setComboTicketId('')
    }, [billModel])

    useEffect(() => {
      if (errMsg) {
        setTimeout(() => {
          setErrMsg('')
        }, 5 * 1000)
      }
    }, [errMsg])

    useEffect(() => {
      //原价 = 所选中的软件的价格 + 所选中的硬件的价格
      const selectedSoftwarePrice =
        software.find(item => item.id === softwareId)?.price.unit || 0
      const selectedHardtwarePrice =
        hardware?.find(item => item.id === hardwareId)?.price.unit || 0

      setPrice(
        ((selectedSoftwarePrice + selectedHardtwarePrice) / 100000).toFixed(2)
      )
    }, [softwareId, hardwareId])

    const onStartSession = () => {
      if (billModel == 1) {
        if (!softwareId || !hardwareId) {
          let allAnimateDiv: NodeListOf<Element> =
            document.querySelectorAll('.validate_tip')
          allAnimateDiv &&
            Array.from(allAnimateDiv).map(sub => sub.classList.add('errtips'))

          setTimeout(() => {
            allAnimateDiv &&
              Array.from(allAnimateDiv).map(sub =>
                sub.classList.remove('errtips')
              )
          }, 2000)
        } else {
          //所选中的 软件 期望得到 GPU 才能正常工作，如果用户所选择的硬件没有GPU的话，提醒用户并且请求多一个 gpu_confirm = true 的参数
          const selectedSoftware = software.find(item => item.id === softwareId)
          const selectedHardtware = hardware?.find(
            item => item.id === hardwareId
          )

          const pass =
            selectedSoftware.gpu_desired &&
            selectedHardtware.number_of_gpu === 0

          Modal.showConfirm({
            title: t('ConfirmThatCreateASession'),
            content: pass
              ? t('TheCurrentlySelectedSoftwareYouNeedGPUCanWorkNormally,') + t('AreYouSureYouUseTheCurrentConfigurationToCreateTheSession?')
              : t('ConfirmAccordingToTheSelectedConfigurationToCreateTheSession?'),

            onOk() {
              return new Promise((resolve, reject) => {
                // check 是否可以创建新会话
                Promise.all([vis.getActiveSessions(), vis.getTerminalLimit()])
                  .then(values => {
                    if (
                      values[0]?.items?.length >= values[1]?.terminalCountLimit
                    ) {
                      resolve(t("TerminalConfigurationHasReachedTheUpperLimit,Can'tCreateANewSession,") + t('PleaseContactCustomerService'))
                      setErrMsg(
                        t("TerminalConfigurationHasReachedTheUpperLimit,Can'tCreateANewSession,") + t('PleaseContactCustomerService')
                      )
                      return
                    }

                    vis
                      .startSession({
                        hardware_id: hardwareId,
                        software_id: softwareId,
                        gpu_confirm: pass,
                        is_auto_close: autoClose,
                        auto_close_time: closeTime
                      })
                      .then(res => {
                        const room_id = qs.parse(
                          res?.stream_url.split('?')[1]
                        ).room_id
                        resolve(res)
                        onCreated(res)
                        history.push(`/vis-session?room-id=${room_id}`)
                      })
                      .catch(() => {
                        resolve(t('FailedToCreateASession'))
                      })
                  })
                  .catch(() => {
                    resolve(t('FailedToCreateASession'))
                    setErrMsg(t('FailedToCreateASession'))
                  })
              })
            }
          })
        }
      } else {
        if (!comboTicketId) {
          let allAnimateDiv: NodeListOf<Element> =
            document.querySelectorAll('.validate_tip')
          allAnimateDiv &&
            Array.from(allAnimateDiv).map(sub => sub.classList.add('errtips'))

          setTimeout(() => {
            allAnimateDiv &&
              Array.from(allAnimateDiv).map(sub =>
                sub.classList.remove('errtips')
              )
          }, 2000)
        } else {
          const selectCombo = comboList?.find(
            item => item.ticket_id === comboTicketId
          )

          Modal.showConfirm({
            title: t('ConfirmThatCreateASession'),
            content: t('ConfirmAccordingToTheSelectedPackageCreateASession?'),

            onOk() {
              return new Promise((resolve, reject) => {
                // check 是否可以创建新会话
                Promise.all([vis.getActiveSessions(), vis.getTerminalLimit()])
                  .then(values => {
                    if (
                      values[0]?.items?.length >= values[1]?.terminalCountLimit
                    ) {
                      resolve(t("TerminalConfigurationHasReachedTheUpperLimit,Can'tCreateANewSession,") + t('PleaseContactCustomerService'))
                      setErrMsg(
                        t("TerminalConfigurationHasReachedTheUpperLimit,Can'tCreateANewSession,") + t('PleaseContactCustomerService')
                      )
                      return
                    }

                    vis
                      .startSession({
                        combo_id: selectCombo?.combo_id,
                        hardware_id: selectCombo?.hardwares[0]?.id,
                        software_id: comboSoftwareId,
                        charge_type: billModel === 4 ? 3 : billModel,
                        gpu_confirm: true,
                        ticket_id: comboTicketId,
                        is_auto_close: autoClose,
                        auto_close_time: closeTime
                      })
                      .then(res => {
                        const room_id = qs.parse(
                          res?.stream_url.split('?')[1]
                        ).room_id
                        resolve(res)
                        onCreated(res)
                        history.push(`/vis-session?room-id=${room_id}`)
                      })
                      .catch(() => {
                        resolve(t('FailedToCreateASession'))
                      })
                  })
                  .catch(() => {
                    resolve(t('FailedToCreateASession'))
                    setErrMsg(t('FailedToCreateASession'))
                  })
              })
            }
          })
        }
      }
    }

    const disabledBtn = () => {
      if (env.accountIsFreeze) {
        return true
      }

      //自定义了关闭时间创建时做二次校验
      if (autoClose) {
        const nowTime = new Date().getTime() / 1000

        return (
          Number(closeTime) - nowTime < 3600 &&
          t('TheSelectedClosingTimeShouldBeGreaterThanTheCurrentTimeMoreThanAnHour')
        )
      }

      //按量计费时只需判断账户是否欠费
      if (billModel !== 1) {
        const selectCombo = comboList?.find(
          item => item.ticket_id === comboTicketId
        )
        if (selectCombo) {
          const nowTime = new Date().getTime()
          const beginTime = selectCombo?.valid_begin_time?.seconds * 1000
          const endTime = selectCombo?.valid_end_time?.seconds * 1000
          // 包年包月看 现在时间 是否在 valid_begin_time ->  valid_end_time 之间
          // 包小时/赠送 1。看 激活时间 是否小于 现在时间 2.未使用时间 是否 为正数
          if (billModel === 2) {
            const isShow = nowTime >= beginTime && nowTime <= endTime
            return !isShow && t('TheSelectedPackageIsNotInThePeriodOfValidity')
          } else {
            const time = nowTime >= beginTime
            const available = selectCombo?.remain_time > 0
            return (
              (!time || !available) &&
              t("TheSelectedPackageHasn'tBeenToTheActivationTimeMayBeToUseTimeHasFinished")
            )
          }
        }
      }
      return env.accountFrozen
    }

    const onChange = (date, dateString) => {
      setCloseTime(date?.unix())
    }
    const disabledDate = current => {
      return current && current < dayjs().startOf('day')
    }

    const range = (start, end) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    const disabledDateTime = date => ({
      disabledHours: () =>
        moment(new Date()).isSame(date, 'day')
          ? range(0, 24).splice(0, dayjs().hour() + 2)
          : []
    })

    return (
      <ListWrapper>
        {errMsg && (
          <div className='ant-message-notice' style={centerStyle as any}>
            <div className='ant-message-notice-content'>
              <div className='ant-message-custom-content ant-message-error'>
                <span
                  role='img'
                  aria-label='check-circle'
                  className='anticon anticon-check-circle'>
                  <svg
                    viewBox='64 64 896 896'
                    focusable='false'
                    data-icon='close-circle'
                    width='1em'
                    height='1em'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z'></path>
                  </svg>
                </span>
                <span style={{ padding: '0 10px' }}>{errMsg}</span>
              </div>
            </div>
          </div>
        )}
        {(env.accountIsFreeze || env.accountFrozen) && (
          <ArrearsWrapper>
            <InfoCircleFilled className='icon' />
            {env.accountIsFreeze
              ? t('YourAccountHasBeenFrozen,UnableToCreateASession,') + t('ContinueToUse,PleaseContactCustomerService')
              : env.accountFrozen &&
                t('YouHaveOverdueBills,TemporarilyCannotUseSessionFunction,') + t('PleaseContactTheAdministratorPrepaidPhone!')}
          </ArrearsWrapper>
        )}

        <BillingLayout>
          <Sider>
            <SiderTitle>{t('PricingModel')}</SiderTitle>
          </Sider>
          <Content>
            {[
              [t('VolumePricing'), 1],
              [t('PackageInMonthly'), 2],
              [t('PackageHours'), 3],
              [t('giving'), 4]
            ].map(([name, model]) => (
              <Button
                type={billModel === model ? 'primary' : 'default'}
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setBillModel(Number(model))
                }}>
                {name}
              </Button>
            ))}
          </Content>
        </BillingLayout>
        {billModel === 1 ? (
          <>
            <SoftwareSelector
              software={software}
              loading={loading}
              onSelect={setSoftwareId}
            />
            <HardwareSelector
              hardware={hardware}
              loading={loading}
              defaultId={hardwareId}
              onSelect={setHardwareId}
            />
            <Layout>
              <Sider>
                <SiderTitle>{t('ConfigurationCost')}</SiderTitle>
              </Sider>
              <Content>
                <span className='cost'> {price}{t('yuan')}/{t('hours')}</span>
              </Content>
            </Layout>
          </>
        ) : (
          <ComboSelector
            comboList={comboList}
            loading={comboLoading}
            comboTicketId={comboTicketId}
            setComboTicketId={setComboTicketId}
            comboSoftwareId={comboSoftwareId}
            setComboSoftwareId={setComboSoftwareId}
            billModel={billModel}
          />
        )}
        <Modal.Footer
          className='footer'
          OkButton={
            <Button
              type='primary'
              style={{ marginRight: 0 }}
              disabled={disabledBtn()}
              onClick={onStartSession}>
              {t('CreateASession')}
            </Button>
          }
          CancelButton={
            <CloseTimeWrapper>
              <Sider>
                <SiderTitle>{t('ClosingTime')}</SiderTitle>
              </Sider>
              <Content>
                <Radio.Group
                  onChange={e => setAutoClose(e.target.value)}
                  value={autoClose}>
                  <Radio value={false}>{t('NeverShutDown')}</Radio>
                  <Radio value={true}>
                    <DatePicker
                      inputReadOnly={true}
                      disabled={!autoClose}
                      showNow={false}
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                      showTime={{
                        format: 'HH',
                        defaultValue: moment().hour(moment().hour() + 2)
                      }}
                      format='YYYY-MM-DD HH:00'
                      onChange={onChange}
                    />
                  </Radio>
                </Radio.Group>
              </Content>
            </CloseTimeWrapper>
          }
        />
      </ListWrapper>
    )
  }
)
