/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action } from 'mobx'
import { Timestamp } from '@/domain/common'
import { env } from '@/domain'
import { JobRuntime, JobRuntimeRequest } from './JobRuntime'
import { INNER_COMPANY_IDS } from '@/constant'

export class BaseJob {
  @observable id: string
  @observable project_id: string
  @observable name: string
  @observable app_name: string
  @observable app_version: string
  @observable app_type: string
  @observable app_id: string
  @observable user_name: string
  @observable user_id: string
  @observable sc_id: string
  @observable tier_name: string
  @observable display_state: number
  @observable display_back_state: number
  @observable is_deleted: boolean
  @observable resource_usage: {
    cpus: number
    nodes: number
  } = {
    cpus: 0,
    nodes: 0
  }
  @observable state: number
  @observable runtime: JobRuntime = new JobRuntime()
  @observable user_cancel: boolean
  @observable is_billed: boolean
  @observable pend_reason: string
  @observable exit_reason: string
  @observable create_time: Timestamp
  @observable amount: number
  @observable paused: boolean
  @observable job_set_name: string
}

export type JobRequest = Omit<BaseJob, 'create_time' | 'runtime'> & {
  create_time: {
    seconds: number
    nanos: number
  }
  runtime: JobRuntimeRequest
}

export class Job extends BaseJob {
  constructor(props?: Partial<JobRequest>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  @action
  update({ create_time, runtime, ...props }: Partial<JobRequest>) {
    Object.assign(this, props)

    if (create_time) {
      this.create_time = new Timestamp(create_time)
    }

    if (runtime) {
      this.runtime = new JobRuntime(runtime)
    }
  }

  get deleteable() {
    return this.display_state >= 2
  }

  get cancelable() {
    return this.display_state === 1 || this.display_state === 7
  }

  get downloadable() {
    return !!this.runtime?.download_finished
  }

  get showContinuousRedeploy() {
    // 需要为内部企业开启续算功能
    return this.isFluent || INNER_COMPANY_IDS.includes(env?.company?.id)
  }

  get isFluent() {
    return this.app_type?.toLowerCase().includes('fluent')
  }

  // 是否使用超算文件
  get useRemote() {
    return this?.runtime?.download_task_id && !this?.runtime?.download_finished
  }

  get displayRunTime() {
    if (!this.runtime.cpu_time) {
      return '--'
    }
    const hour = Math.floor(this.runtime.cpu_time / 3600)
      .toString()
      .padStart(2, '0')
    const minute = Math.floor((this.runtime.cpu_time % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const second = (this.runtime.cpu_time % 60).toString().padStart(2, '0')
    return `${hour}:${minute}:${second}`
  }
}
