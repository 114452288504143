/* Copyright (C) 2016-present, Yuansuan.cn */
import { Button, Modal, Table } from '@ys/components'
import { formatByte, formatUnixTime } from '@ys/utils'
import { Input, message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useLocalStore } from 'mobx-react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { NewBoxHttp } from '@/domain/Box/NewBoxHttp'
import { env } from '@/domain'
import { useTranslation } from 'react-i18next'

const StyledLayout = styled.div`
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
  }
  p {
    font-size: 16px;
    padding-top: 1em;
  }
  > .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 17px 10px 0;
    border-top: 1px solid ${({ theme }) => theme.borderColorBase};
  }
`
const MAX_SIZE = 10737418240 //10G

type Props = {
  onOk: (keys?: any[]) => void
  zoneId: string
  selectedJob: any
}
export const SearchFiles = observer(({ zoneId, onOk, selectedJob }: Props) => {
  const { t } = useTranslation()
  const state = useLocalStore(() => ({
    loading: false,
    name: '',
    files: [],
    setName(name) {
      this.name = name
    },
    setLoading(loading) {
      this.loading = loading
    },
    setFiles(list) {
      this.files = list
    },
    selectedKeys: [],
    setSelectedKeys(keys) {
      this.selectedKeys = keys
    },
    get searchDisabled() {
      if (this.name === '') {
        return  t('PleaseEnterTheKeywordsToSearch')
      }
      return false
    },
    get downloadDisabled() {
      if (this.selectedKeys.length === 0) {
        return t('TheSelectedFile')
      }
      return false
    }
  }))

  const debouncedSetName = useCallback(
    debounce(name => {
      state.setName(name)
    }, 300),
    []
  )

  async function getFileList() {
    state.setLoading(true)
    try {
      await NewBoxHttp(zoneId)
        .post('/filemanager/search', {
          project_id: env.project?.id,
          keyword: state.name,
          job_ids: selectedJob
        })
        .then(res => {
          state.setFiles(res?.data || [])
        })
    } catch (e) {
      if (e['response']?.data?.code === 110018) {
        message.error(
          t('job.errResponse')
        )
      } else {
        message.error(`${e['response']?.data?.message}`)
      }
    }
    state.setLoading(false)
  }

  return (
    <StyledLayout>
      <div className='toolbar'>
        <div>
          {t('job.keyword')}：
          <Input
            allowClear
            style={{ width: 280 }}
            placeholder={t('job.searchPlaceholder')}
            onChange={e => {
              debouncedSetName(e.target.value)
            }}
          />
        </div>
        <Button onClick={getFileList} disabled={state.searchDisabled}>
          {t('job.search')}
        </Button>
      </div>
      {/* <p>t('InTheSelectedJobFileSearch')</p> */}
      <p>{t('job.searchText')}</p>
      <Table
        props={{
          data: state.files,
          height: 400,
          rowKey: 'rel_path',
          loading: state.loading
        }}
        rowSelection={{
          selectedRowKeys: state.selectedKeys,
          onChange: keys => {
            const selectedRow = state.files
              .filter(file => keys?.find(path => path === file.rel_path))
              .map(file => file['size'])

            const totalSize =
              selectedRow.length !== 0 &&
              selectedRow.reduce((pre, current) => pre + current)

            if (totalSize >= MAX_SIZE) {
              // message.info(t('TheCurrentlySelectedFileHasMoreThan1')0GB,t('PleaseCheckAgain'))
              message.info(t('job.maxSizeInfo'))

              state.setSelectedKeys([...state.selectedKeys])
            } else {
              state.setSelectedKeys(keys)
            }
          }
        }}
        columns={[
          {
            header: t('job.fileName'),
            props: {
              fixed: true,
              flexGrow: 3
            },
            dataKey: 'name'
          },
          {
            header: t('job.columnsId'),
            props: {
              fixed: true,
              flexGrow: 2
            },
            dataKey: 'job_id'
          },
          {
            header: t('job.createTime'),
            props: {
              fixed: true,
              flexGrow: 2
            },
            dataKey: 'mod_time',
            cell: {
              render: ({ rowData }) => (
                <div>{formatUnixTime(rowData.mod_time)}</div>
              )
            }
          },
          {
            header: t('job.size'),
            props: {
              fixed: true,
              flexGrow: 1
            },
            dataKey: 'size',
            cell: {
              render: ({ rowData }) => <div>{formatByte(rowData.size)}</div>
            }
          }
        ]}
      />
      <Modal.Footer
        className='footer'
        CancelButton={null}
        OkButton={
          <Button
            type='primary'
            onClick={() => {
              onOk(state.selectedKeys)
              state.setSelectedKeys([])
            }}
            disabled={state.downloadDisabled}>
            {/* t('download') */}
            {t('job.download')}
          </Button>
        }
      />
    </StyledLayout>
  )
})
