/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { createStore } from '@ys/utils/hooks'
import { useLocalStore } from 'mobx-react-lite'
import { JobDraftEnum } from '@/constant'
import Draft from '@/domain/Box/Draft'
import { appList, box, currentUser, env, uploader } from '@/domain'
import { FileTree } from '@/domain/JobBuilder/FileTree'
import { getFilenameByPath, history, Http } from '@/utils'
import { fromJSON2Tree } from '@/domain/JobBuilder/utils'
import App from '@/domain/Application/App'
import BoxHttp from '@/domain/Box/BoxHttp'
import { message } from 'antd'
import {
  getContinuousRedeployInfo,
  getRedeployInfo
} from '@/domain/JobBuilder/JobBuilder'

class IJobBuilderData {
  name: string
  currentApp: App
  paramsModel: any
  scIds: string[]
  numCores: number
  currentAppId?: string
  mainFilePaths?: string[]
}

const initData = {
  name: '',
  currentApp: null,
  paramsModel: { isTyping: false },
  scIds: [], // 用户选中的超算
  numCores: null,
  mainFilePaths: undefined
}

export function useModel() {
  return useLocalStore(() => ({
    tabKey: 'formal',
    setTabKey(key) {
      this.tabKey = key
    },
    get is_trial() {
      return this.tabKey === 'trial'
    },
    unblock: undefined,
    setUnblock(v) {
      this.unblock = v
    },
    unlisten: undefined,
    setUnlisten(v) {
      this.unlisten = v
    },
    removeHistoryBlock() {
      this.unblock && this.unblock()
      this.unlisten && this.unlisten()
    },

    jobBuildMode: 'default',
    get draftKey(): string {
      switch (this.jobBuildMode) {
        case 'default':
          return JobDraftEnum.JOB_DRAFT_STORE_KEY
        case 'redeploy':
          return JobDraftEnum.JOB_REDEPLOY_DRAFT_STORE_KEY
        case 'continuous':
          return JobDraftEnum.JOB_CONTINUOUS_DRAFT_STORE_KEY
      }
      return null
    },
    get draft(): Draft {
      switch (this.jobBuildMode) {
        case 'default':
          return box.jobDraft
        case 'redeploy':
          return box.redeployDraft
        case 'continuous':
          return box.continuousDraft
      }
      return null
    },

    fileTree: new FileTree({
      name: ''
    }),
    data: initData,

    params: [],
    scs_cores_list: [],
    isJobSubmitting: false,

    get isInRedeployMode() {
      return this.jobBuildMode === 'redeploy'
    },

    get isInConMode() {
      return this.jobBuildMode === 'continuous'
    },

    get isDefault() {
      return this.jobBuildMode === 'default'
    },

    restoreData: undefined,
    redeployJobId: '',
    continuousJobId: '',
    redeployType: 'job',

    setJobBuilderMode(
      key: 'default' | 'redeploy' | 'continuous',
      param?: { id: string; type: 'job' | 'jobset' }
    ) {
      this.jobBuildMode = key
      if (key === 'redeploy') {
        this.redeployJobId = param.id
        this.redeployType = param.type
      } else if (key === 'continuous') {
        this.continuousJobId = param.id
      }
    },

    async restore() {
      // reset draft
      const draftCache = localStorage.getItem(this.draftKey)
      if (draftCache) {
        try {
          const draft = JSON.parse(draftCache)
          if (
            draft.project_id !== env.project?.id ||
            draft.user_id !== currentUser.id
          ) {
            localStorage.removeItem(this.draftKey)
          }
        } catch (e) {
          localStorage.removeItem(this.draftKey)
        }
      }

      switch (this.jobBuildMode) {
        case 'default':
          await this.restoreFromDraft()
          break
        case 'redeploy':
          try {
            this.restoreData = await getRedeployInfo({
              id: this.redeployJobId,
              type: this.redeployType as any,
              clean: false
            })
          } catch (e) {
            setTimeout(() => history.replace('/jobs'), 300)
            return
          }

          await this.restoreFromRedeploy()
          break
        case 'continuous':
          try {
            this.restoreData = await getContinuousRedeployInfo({
              id: this.continuousJobId,
              type: this.redeployType as any,
              clean: false
            })
          } catch (e) {
            setTimeout(() => history.replace('/jobs'), 300)
            return
          }

          await this.restoreContinuousFromResult()
          break
      }
    },

    get apps() {
      return appList.list.filter(item => !item.type.includes('workflow'))
    },

    // 获取所有所选超算的核数范围list
    get selectedCoresScopes() {
      return this.scs_cores_list
        .filter(cores => this.data.scIds.includes(cores.sc_id))
        .map(availableCores =>
          availableCores.cores.slice(0).sort((a, b) => a - b)
        )
        .map(cores => [cores[0], cores.pop()])
    },

    // 获取所选超算的推荐核数
    get suggestedCores() {
      return this.data.scIds.length === 1
        ? this.scs_cores_list?.find(item => item.sc_id === this.data.scIds[0])
            ?.suggestion
        : null
    },

    // 计算核数交集
    get cores_scope() {
      if (
        this.selectedCoresScopes.length === 0 ||
        !this.selectedCoresScopes[0][0]
      )
        return [0, 0]

      let [min, max] = this.selectedCoresScopes[0]
      this.selectedCoresScopes.forEach(scopes => {
        min = Math.max(min, scopes[0])
        max = Math.min(max, scopes[1])
      })
      return [min, max]
    }, // 根据核数交集展示提示

    // TODO - Jimmy 显示核数
    get displayCoresScope() {
      if (this.data.scIds.length === 0) return '请先勾选算力资源'

      const [min, max] = this.cores_scope

      if (min > max) return '核数可选范围为空，请重勾选算力资源。'
      else return `${min}的倍数,范围(${min}-${max})核`
    },

    // 填写核数，核数交集范围是否正确
    get displayCoreValid() {
      const [min, max] = this.cores_scope
      return (
        this.data.numCores >= min &&
        this.data.numCores <= max &&
        typeof this.data.numCores === 'number'
      )
    },

    // 核并集
    get cores_scope_union(): number[] {
      const cores = this.scs_cores_list
        .filter(cores => this.data.scIds.includes(cores.sc_id))
        .reduce((arr, prev) => arr.concat(prev.cores), [])
        .sort((a, b) => a - b)

      return cores.length > 0 ? [cores[0], cores[cores.length - 1]] : [1, 1]
    },

    // only can be used for constructor function
    // to restore data from localstorage when page refresh
    async init() {
      await this.fetchApps()

      // 从草稿恢复作业
      await this.restore()
    },

    async fetchApps() {
      await Promise.all([appList.fetch()])
    },

    async fetchParams() {
      await Promise.all([
        // 获取超算和核数信息
        this.initScsCoresList(),
        // 获取应用参数
        this.initSection()
      ])

      // 初始化应用参数
      this.initParamsData()
    },
    // // 切换app
    // appDisposer: reaction(
    //   () => this.data.currentApp,
    //   async app => {
    //     // 点击重置按钮后 此时app为空 自动选择第一个app
    //     if (!app) {
    //       this.updateData({
    //         currentApp: this.apps[0],
    //       })
    //       return
    //     }
    //
    //     await this.fetchParams()
    //   }
    // ),

    updateData(data: Partial<IJobBuilderData>) {
      Object.assign(this.data, data)
    },

    get defaultJobName() {
      return this.mainFilePaths.length === 1
        ? getFilenameByPath(this.mainFilePaths[0])
        : ''
    },

    get mainFiles() {
      return this.fileTree.flatten().filter(node => {
        return node.isFile && node.isMain
      })
    },

    get mainFilePaths() {
      return this.mainFiles.map(node => node.path)
    },

    // // cache job creation data
    // disposer = reaction(
    //   () => {
    //     return {
    //       ...this.data,
    //       mainFilePaths: this.mainFilePaths,
    //       currentAppId: this.currentAppId,
    //       isTyping: this.data.paramsModel.isTyping,
    //     }
    //   },
    //   ({ currentApp, isTyping, ...data }) => {
    //     if (this.data.paramsModel.isTyping) {
    //       if (this.isInRedeployMode) return
    //
    //       localStorage.setItem(
    //         this.draftKey,
    //         JSON.stringify({
    //           ...data,
    //           user_id: currentUser.id,
    //           project_id: env.project?.id,
    //         })
    //       )
    //     }
    //   }
    // )

    get currentAppId() {
      return this.data.currentApp ? this.data.currentApp.id : ''
    },

    async initScsCoresList() {
      this.scs_cores_list = []
      this.updateData({
        scIds: []
      })
      this.scs_cores_list = await this.data.currentApp?.getRuntimeScsCoresList()
      this.selectDefaultCoresOption()
    },

    selectDefaultCoresOption() {
      if (this.data.currentApp) {
        // 返回的值为已排序 所以需要更新当前app的scIds
        const scIds = this.scs_cores_list?.map(c => c.sc_id)
        this.data.currentApp.setScIds(scIds)

        // 切换、加载app时 没有选中的id则帮助勾选
        if (this.data.scIds.length === 0) {
          const localIds = this.scs_cores_list
            .filter(o => o.is_local)
            .map(o => o.sc_id)

          // 如果有本地集群 则勾选 否则勾选第一个
          if (localIds.length > 0)
            this.updateData({
              scIds: localIds
            })
          else
            this.updateData({
              scIds: [scIds[0]]
            })
        } else {
          // 有选中的id则
          // 保留已存在的id
          this.data.scIds = this.data.scIds.filter(id => scIds.includes(id))
        }
      }
    },

    // 获取app参数
    async initSection() {
      if (!this.data.currentApp) return
      this.params = []
      this.params = await this.data.currentApp.getParams()
    },

    // 初始化应用参数，合并缓存中的数据
    initParamsData(cacheParams: any = {}) {
      if (!this.data.currentApp) {
        return
      }

      const data = {}

      this.params.forEach(field => {
        const cache = cacheParams[field.id]
        const value =
          cache?.value === '' || cache?.value === undefined
            ? field.value === ''
              ? field.defaultValue
              : field.value
            : cache?.value
        const values =
          Array.isArray(cache?.values) && cache.values.length > 0
            ? cache?.values
            : field.values.length > 0
            ? field.values
            : field.defaultValues
        data[field.id] = {
          ...field,
          value,
          values
        }
      })
      this.updateData({ paramsModel: data })
    },

    async jobSetNameCheck(name): Promise<boolean> {
      const { data } = await Http.get('job/set/name_check', {
        params: {
          name
        }
      })
      return data.exists
    },

    /**
     * 创建作业：调用盒子api将draft目录移到input目录
     * 将返回的参数传给创建作业模块
     */
    async create(entries) {
      const { paramsModel, currentApp, name, scIds, numCores } = this.data
      if (!this.beforeCreate()) return false
      entries.forEach(async e => {
        if (e.mainFile.endsWith('.jobs')) {
          // fetch file content
          e.content = await this.draft.getFileContent(e.mainFile).then(a => a)
        }
      })

      const res = await this.draft.submit()
      const fields = Object.keys(paramsModel).map(key => {
        return {
          id: key,
          value: paramsModel[key].value,
          values: paramsModel[key].values,
          type: paramsModel[key].type
        }
      })

      const data = {
        entries,
        name: name || this.defaultJobName,
        app_id: currentApp.id,
        user_params: { fields },
        user_params_version: currentApp.app_params_version,
        sc_ids: scIds,
        num_cores: numCores,
        upload_file_info: {
          source_file_storage_id: res.data.file_storage_id,
          source_path: res.data.input_path,
          sync_runner_id: res.data.file_sync_runner_id
        }
      }

      const store = {
        name: data.name,
        paramsModel,
        scIds: this.data.scIds,
        numCores: this.data.numCores,
        currentAppId: currentApp.id,
        mainFilePaths: this.mainFilePaths,
        input_folder_uuid: res.data.input_folder_uuid
      }

      try {
        this.isJobSubmitting = true

        const createRes = await Http.post('/job', { ...data, store })
        if (!createRes.success) {
          await this.draft.back(res.data.input_folder_uuid)
          return false
        }
      } catch (e) {
        await this.draft.back(res.data.input_folder_uuid)
        return false
      } finally {
        this.isJobSubmitting = false
      }
      this.clean()
      return true
    },

    beforeCreate() {
      // 校验作业参数
      if (!this.mainFilePaths.length) {
        message.error('请至少选择一个主文件')
        return false
      }

      if (!this.data.currentApp) {
        message.error('请选择应用')
        return false
      }

      const jobName = this.data.name || this.defaultJobName
      if (!jobName) {
        message.error('请填写作业名称')
        return false
      }

      // 校验作业模板
      const { paramsModel } = this.data
      for (const key of Object.keys(paramsModel)) {
        const param = paramsModel[key]
        if (param && param.required && !param.value && param.values === 0) {
          message.error(`请填写${param.label}`)
          return false
        }
      }
      return true
    },

    // 清理文件树
    reset() {
      this.fileTree = new FileTree({
        name: ''
      })
      // 重置时保留app
      this.updateData({ ...initData, currentApp: this.data.currentApp })
    },

    // 清理draft、取消上传、清理文件树
    async clean(refetch = false) {
      await this.draft.clean()

      localStorage.removeItem(this.draftKey)

      // cancel uploader
      this.fileTree.tapNodes(
        () => true,
        node => {
          uploader.remove(node.uid)
        }
      )

      this.reset()

      if (this.isInRedeployMode || this.isInConMode) {
        // 重提交下重置会通过api恢复作业参数
        await this.init()
      } else if (refetch) {
        // 非重提交下 点击重置按钮仅需重新获取参数
        await this.fetchParams()
      }
    },

    async restoreFileList(mainFilePaths?: string[]) {
      if (!mainFilePaths) {
        mainFilePaths = this.data.mainFilePaths || []
      }

      // 恢复上传的文件，获取盒子中draft的文件
      let files = await this.draft.listFile()

      this.fileTree = fromJSON2Tree(files)
      // 恢复主文件，从文件树中找到对应的主文件 id
      this.fileTree.tapNodes(
        node => mainFilePaths.includes(node.path),
        node => {
          node.isMain = true
        }
      )
    },

    // 从草稿中恢复到工作区
    async restoreFromDraft() {
      if (!this.apps.length) return

      let cache: IJobBuilderData, isCacheEmpty

      // parse cache
      const cacheStr = localStorage.getItem(this.draftKey)

      if (!cacheStr) {
        cache = {} as IJobBuilderData
        isCacheEmpty = true
      } else {
        cache = JSON.parse(cacheStr)
        isCacheEmpty = false
      }

      delete cache['project_id']
      delete cache['user_id']

      // 恢复app，如果找不到，重置为第一个
      this.updateData({
        currentApp:
          this.apps.find(app => app.id === cache.currentAppId) ||
          this.apps.filter(app => app.is_trial === this.is_trial)[0]
      })
      delete cache.currentAppId
      await this.restoreFileList(cache.mainFilePaths)

      if (this.data.currentApp) {
        // 恢复作业参数
        await this.initSection()
        this.initParamsData(cache?.paramsModel)

        // 恢复算力资源，去除不存在的算力资源
        cache.scIds = (cache.scIds || []).filter(scId =>
          this.data.currentApp.sc_ids.includes(scId)
        )

        // 刷新重新获取coresList
        await this.initScsCoresList()
      }

      !isCacheEmpty &&
        this.updateData({
          name: cache.name,
          numCores: cache.numCores
        })
    },

    // 从重提交远端中恢复到工作区
    async restoreFromRedeploy() {
      if (!this.apps.length) return

      let cache: IJobBuilderData, isCacheEmpty
      cache = { ...this.restoreData }
      cache.name += '_re'
      // await this.draft.back(cache['input_folder_uuid']).catch(() => {
      //   setTimeout(() => (location.href = '/jobs'), 300)
      // })

      delete cache['project_id']
      delete cache['user_id']

      // 恢复app，如果找不到，重置为第一个
      this.updateData({
        currentApp:
          this.apps.find(app => app.id === cache.currentAppId) ||
          this.apps.filter(app => app.is_trial === this.is_trial)[0]
      })
      delete cache.currentAppId

      await this.restoreFileList(cache.mainFilePaths)

      if (this.data.currentApp) {
        // 恢复作业参数
        await this.initSection()
        this.initParamsData(cache?.paramsModel)

        // 恢复算力资源，去除不存在的算力资源
        cache.scIds = (cache.scIds || []).filter(scId =>
          this.data.currentApp.sc_ids.includes(scId)
        )

        // 刷新重新获取coresList
        await this.initScsCoresList()
      }

      !isCacheEmpty && this.updateData(cache)
    },

    // 从作业结果恢复到提交界面
    async restoreContinuousFromResult() {
      if (!this.apps.length) return

      let cache: IJobBuilderData, isCacheEmpty
      cache = { ...this.restoreData }
      cache.name += '_continuous'

      delete cache['project_id']
      delete cache['user_id']

      // 恢复app，如果找不到，重置为第一个
      this.updateData({
        currentApp:
          this.apps.find(app => app.id === cache.currentAppId) ||
          this.apps.filter(app => app.is_trial === this.is_trial)[0]
      })
      delete cache.currentAppId

      await this.restoreFileList(cache.mainFilePaths)

      if (this.data.currentApp) {
        // 恢复作业参数
        await this.initSection()
        this.initParamsData(cache?.paramsModel)

        // 恢复算力资源，去除不存在的算力资源
        cache.scIds = (cache.scIds || []).filter(scId =>
          this.data.currentApp.sc_ids.includes(scId)
        )

        // 刷新重新获取coresList
        await this.initScsCoresList()
      }

      !isCacheEmpty && this.updateData(cache)
    }
  }))
}

const store = createStore(useModel)

export const Provider = store.Provider
export const Context = store.Context
export const useStore = store.useStore
