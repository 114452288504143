/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Provider, useStore } from './store'
import { observer } from 'mobx-react-lite'
import { Page } from '@/components'
import { useDidUpdate } from '@ys/utils/hooks'
import { Filter } from './Filter'
import { JobList, Context, useModel } from './JobList'
import { pageStateStore } from '@/utils'

const StyledLayout = styled.div``

const JobListPage = observer(function JobListPage() {
  const store = useStore()
  const jobListStore = useModel(store)

  useEffect(() => {
    store.refresh()
    // reserve query && page state
    pageStateStore.setByPath({
      query: store.query,
      pageIndex: store.pageIndex,
      pageSize: store.pageSize,
    })
  }, [store.query, store.pageIndex, store.pageSize])

  // 如果当前分页大于1且当前页不存在数据，则跳转查询第一页数据
  useDidUpdate(() => {
    if (store.model.list.length === 0 && store.pageIndex > 1) {
      store.setPageIndex(1)
    }
  }, [store.model.list.length])

  useEffect(() => {
    let handler = setTimeout(function pull() {
      store.refresh().finally(() => {
        handler = setTimeout(pull, 5000)
      })
    }, 5000)

    return () => clearTimeout(handler)
  }, [])

  return (
    <StyledLayout id='job_manager'>
      <Page header={null}>
        <Filter />
        <Context.Provider value={jobListStore}>
          <JobList />
        </Context.Provider>
      </Page>
    </StyledLayout>
  )
})

export default function PageWithStore() {
  return (
    <Provider>
      <JobListPage />
    </Provider>
  )
}
